import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

var storageKey = "store";
var flags = localStorage.getItem(storageKey);
var app = Elm.Main.init({
  flags: flags,
  node: document.getElementById('root')
});

app.ports.storeCache.subscribe(function (val) {
  if (val === null) {
    localStorage.removeItem(storageKey);
  } else {
    console.log(val);
    localStorage.setItem(storageKey, JSON.stringify(val));
  }
  // Report that the new session was stored succesfully.
  setTimeout(function () { app.ports.onStoreChange.send(val); }, 0);
});
// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener("storage", function (event) {
  if (event.storageArea === localStorage && event.key === storageKey) {
    app.ports.onStoreChange.send(event.newValue);
  }
}, false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
