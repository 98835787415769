(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.ci.aT === region.cN.aT)
	{
		return 'on line ' + region.ci.aT;
	}
	return 'on lines ' + region.ci.aT + ' through ' + region.cN.aT;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cZ,
		impl.dX,
		impl.dO,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		c7: func(record.c7),
		dM: record.dM,
		dt: record.dt
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.c7;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dM;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.dt) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cZ,
		impl.dX,
		impl.dO,
		function(sendToApp, initialModel) {
			var view = impl.d_;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.cZ,
		impl.dX,
		impl.dO,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cg && impl.cg(sendToApp)
			var view = impl.d_;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.a7);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.g5) && (_VirtualDom_doc.title = title = doc.g5);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dh;
	var onUrlRequest = impl.di;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cg: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.dw === next.dw
							&& curr.cW === next.cW
							&& curr.ds.a === next.ds.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		cZ: function(flags)
		{
			return A3(impl.cZ, flags, _Browser_getUrl(), key);
		},
		d_: impl.d_,
		dX: impl.dX,
		dO: impl.dO
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { fm: 'hidden', eE: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { fm: 'mozHidden', eE: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { fm: 'msHidden', eE: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { fm: 'webkitHidden', eE: 'webkitvisibilitychange' }
		: { fm: 'hidden', eE: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dF: _Browser_getScene(),
		d$: {
			hh: _Browser_window.pageXOffset,
			hi: _Browser_window.pageYOffset,
			he: _Browser_doc.documentElement.clientWidth,
			fl: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		he: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		fl: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dF: {
				he: node.scrollWidth,
				fl: node.scrollHeight
			},
			d$: {
				hh: node.scrollLeft,
				hi: node.scrollTop,
				he: node.clientWidth,
				fl: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dF: _Browser_getScene(),
			d$: {
				hh: x,
				hi: y,
				he: _Browser_doc.documentElement.clientWidth,
				fl: _Browser_doc.documentElement.clientHeight
			},
			e7: {
				hh: x + rect.left,
				hi: y + rect.top,
				he: rect.width,
				fl: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.bk.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.bk.b, xhr)); });
		$elm$core$Maybe$isJust(request.g9) && _Http_track(router, xhr, request.g9.a);

		try {
			xhr.open(request.bp, request.bL, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.bL));
		}

		_Http_configureRequest(xhr, request);

		request.a7.a && xhr.setRequestHeader('Content-Type', request.a7.a);
		xhr.send(request.a7.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bn; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.bJ.a || 0;
	xhr.responseType = request.bk.d;
	xhr.withCredentials = request.ef;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		bL: xhr.responseURL,
		gD: xhr.status,
		gE: xhr.statusText,
		bn: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			gr: event.loaded,
			gx: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			gf: event.loaded,
			gx: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$Main$ChangedUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$ClickedLink = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.f) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.f * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.j) : builder.j;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.f);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{j: nodeList, f: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ff: fragment, cW: host, $7: path, ds: port_, dw: protocol, ge: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Api$Cred = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Username$Username = $elm$core$Basics$identity;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Username$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Api$credDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'username',
		$author$project$Username$decoder,
		$elm$json$Json$Decode$succeed($author$project$Api$Cred)));
var $author$project$Api$decoderFromCred = function (decoder) {
	return A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (fromCred, cred) {
				return fromCred(cred);
			}),
		decoder,
		$author$project$Api$credDecoder);
};
var $author$project$Api$storageDecoder = function (viewerDecoder) {
	return A2(
		$elm$json$Json$Decode$field,
		'user',
		$author$project$Api$decoderFromCred(viewerDecoder));
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$application = F2(
	function (viewerDecoder, config) {
		var init = F3(
			function (flags, url, navKey) {
				var maybeViewer = $elm$core$Result$toMaybe(
					A2(
						$elm$core$Result$andThen,
						$elm$json$Json$Decode$decodeString(
							$author$project$Api$storageDecoder(viewerDecoder)),
						A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$string, flags)));
				return A3(config.cZ, maybeViewer, url, navKey);
			});
		return $elm$browser$Browser$application(
			{cZ: init, dh: config.dh, di: config.di, dO: config.dO, dX: config.dX, d_: config.d_});
	});
var $author$project$Viewer$Viewer = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Avatar$Avatar = $elm$core$Basics$identity;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Avatar$decoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Viewer$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'userType',
	$elm$json$Json$Decode$int,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		A2($elm$json$Json$Decode$field, 'image', $author$project$Avatar$decoder),
		$elm$json$Json$Decode$succeed($author$project$Viewer$Viewer)));
var $author$project$Route$Login = {$: 2};
var $author$project$Main$Redirect = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$About = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$BorrowList = function (a) {
	return {$: 46, a: a};
};
var $author$project$Main$CategoryForm = F2(
	function (a, b) {
		return {$: 31, a: a, b: b};
	});
var $author$project$Main$CategoryList = function (a) {
	return {$: 32, a: a};
};
var $author$project$Main$GotAboutMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotBorrowListMsg = function (a) {
	return {$: 47, a: a};
};
var $author$project$Main$GotCategoryFormMsg = function (a) {
	return {$: 32, a: a};
};
var $author$project$Main$GotCategoryListMsg = function (a) {
	return {$: 33, a: a};
};
var $author$project$Main$GotHomeMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$GotImageCropMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Main$GotImageFormMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotImageListMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$GotImageViewMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$GotImportListMsg = function (a) {
	return {$: 36, a: a};
};
var $author$project$Main$GotImportNewMsg = function (a) {
	return {$: 34, a: a};
};
var $author$project$Main$GotImportPoMsg = function (a) {
	return {$: 35, a: a};
};
var $author$project$Main$GotItemFormMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$GotItemListMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$GotItemMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$GotLocationFormMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$GotLocationListMsg = function (a) {
	return {$: 22, a: a};
};
var $author$project$Main$GotLocationMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Main$GotLoginMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$GotMoveListMsg = function (a) {
	return {$: 37, a: a};
};
var $author$project$Main$GotMoveNewMsg = function (a) {
	return {$: 39, a: a};
};
var $author$project$Main$GotMoveToOriginalMsg = function (a) {
	return {$: 40, a: a};
};
var $author$project$Main$GotMoveViewMsg = function (a) {
	return {$: 42, a: a};
};
var $author$project$Main$GotPackingFormMsg = function (a) {
	return {$: 29, a: a};
};
var $author$project$Main$GotPackingListMsg = function (a) {
	return {$: 31, a: a};
};
var $author$project$Main$GotPackingViewMsg = function (a) {
	return {$: 30, a: a};
};
var $author$project$Main$GotPaymentFormMsg = function (a) {
	return {$: 23, a: a};
};
var $author$project$Main$GotPaymentListMsg = function (a) {
	return {$: 25, a: a};
};
var $author$project$Main$GotPaymentMsg = function (a) {
	return {$: 24, a: a};
};
var $author$project$Main$GotPoConfirmAndChatMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Main$GotPoFormMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$GotPoKnownPriceFormMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$GotPoListMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$Main$GotPoReceiveMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$GotPoSupplierUpdateMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$GotPoViewMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Main$GotSupplierFormMsg = function (a) {
	return {$: 26, a: a};
};
var $author$project$Main$GotSupplierListMsg = function (a) {
	return {$: 28, a: a};
};
var $author$project$Main$GotSupplierMsg = function (a) {
	return {$: 27, a: a};
};
var $author$project$Main$GotTemplateCalMsg = function (a) {
	return {$: 51, a: a};
};
var $author$project$Main$GotTemplateFormMsg = function (a) {
	return {$: 45, a: a};
};
var $author$project$Main$GotTemplateListMsg = function (a) {
	return {$: 44, a: a};
};
var $author$project$Main$GotTemplateViewMsg = function (a) {
	return {$: 46, a: a};
};
var $author$project$Main$GotWipListMsg = function (a) {
	return {$: 48, a: a};
};
var $author$project$Main$GotWipNewMsg = function (a) {
	return {$: 49, a: a};
};
var $author$project$Main$GotWipViewMsg = function (a) {
	return {$: 50, a: a};
};
var $author$project$Main$GotWithdrawListMsg = function (a) {
	return {$: 38, a: a};
};
var $author$project$Main$GotWithdrawNewMsg = function (a) {
	return {$: 41, a: a};
};
var $author$project$Main$GotWithdrawViewMsg = function (a) {
	return {$: 43, a: a};
};
var $author$project$Main$Home = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$Home = {$: 0};
var $author$project$Main$ImageCrop = function (a) {
	return {$: 18, a: a};
};
var $author$project$Main$ImageForm = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Main$ImageList = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$ImageView = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$ImportList = function (a) {
	return {$: 35, a: a};
};
var $author$project$Main$ImportNew = function (a) {
	return {$: 33, a: a};
};
var $author$project$Main$ImportPo = function (a) {
	return {$: 34, a: a};
};
var $author$project$Main$ItemForm = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Main$ItemList = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$ItemView = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$LocationForm = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Main$LocationList = function (a) {
	return {$: 21, a: a};
};
var $author$project$Main$LocationView = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$Login = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$MoveList = function (a) {
	return {$: 36, a: a};
};
var $author$project$Main$MoveNew = function (a) {
	return {$: 38, a: a};
};
var $author$project$Main$MoveToOriginal = function (a) {
	return {$: 39, a: a};
};
var $author$project$Main$MoveView = function (a) {
	return {$: 41, a: a};
};
var $author$project$Main$NotFound = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$PackingForm = F2(
	function (a, b) {
		return {$: 28, a: a, b: b};
	});
var $author$project$Main$PackingList = function (a) {
	return {$: 30, a: a};
};
var $author$project$Main$PackingView = function (a) {
	return {$: 29, a: a};
};
var $author$project$Main$PaymentForm = function (a) {
	return {$: 22, a: a};
};
var $author$project$Main$PaymentList = function (a) {
	return {$: 24, a: a};
};
var $author$project$Main$PaymentView = function (a) {
	return {$: 23, a: a};
};
var $author$project$Main$PoConfirmAndChat = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Main$PoForm = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Main$PoKnownPriceForm = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Main$PoList = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$PoReceive = function (a) {
	return {$: 16, a: a};
};
var $author$project$Main$PoSupplierUpdate = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Main$PoView = function (a) {
	return {$: 15, a: a};
};
var $author$project$Main$SupplierForm = F2(
	function (a, b) {
		return {$: 25, a: a, b: b};
	});
var $author$project$Main$SupplierList = function (a) {
	return {$: 27, a: a};
};
var $author$project$Main$SupplierView = function (a) {
	return {$: 26, a: a};
};
var $author$project$Main$TemplateCal = function (a) {
	return {$: 50, a: a};
};
var $author$project$Main$TemplateForm = F2(
	function (a, b) {
		return {$: 44, a: a, b: b};
	});
var $author$project$Main$TemplateList = function (a) {
	return {$: 43, a: a};
};
var $author$project$Main$TemplateView = function (a) {
	return {$: 45, a: a};
};
var $author$project$Main$WipList = function (a) {
	return {$: 47, a: a};
};
var $author$project$Main$WipNew = function (a) {
	return {$: 48, a: a};
};
var $author$project$Main$WipView = function (a) {
	return {$: 49, a: a};
};
var $author$project$Main$WithdrawList = function (a) {
	return {$: 37, a: a};
};
var $author$project$Main$WithdrawNew = function (a) {
	return {$: 40, a: a};
};
var $author$project$Main$WithdrawView = function (a) {
	return {$: 42, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Page$About$Loading = {$: 0};
var $author$project$Page$About$Waiting = {$: 0};
var $pablen$toasty$Toasty$Stack = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $pablen$toasty$Toasty$initialState = A2(
	$pablen$toasty$Toasty$Stack,
	_List_Nil,
	$elm$random$Random$initialSeed(0));
var $author$project$Session$isLoggedIn = function (session) {
	if (!session.$) {
		var val = session.b;
		return true;
	} else {
		return false;
	}
};
var $author$project$Session$navKey = function (session) {
	if (!session.$) {
		var key = session.a;
		return key;
	} else {
		var key = session.a;
		return key;
	}
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Items$Slug$toString = function (_v0) {
	var str = _v0;
	return str;
};
var $author$project$Route$routeToString = function (page) {
	var query = function () {
		if (page.$ === 8) {
			var maybeQuery = page.a;
			if (maybeQuery.$ === 1) {
				return '';
			} else {
				var q = maybeQuery.a;
				return '?query=' + q;
			}
		} else {
			return '';
		}
	}();
	var pieces = function () {
		switch (page.$) {
			case 0:
				return _List_Nil;
			case 1:
				return _List_Nil;
			case 2:
				return _List_fromArray(
					['login']);
			case 3:
				return _List_fromArray(
					['logout']);
			case 5:
				return _List_fromArray(
					['editor']);
			case 6:
				return _List_fromArray(
					['items', 'new']);
			case 9:
				var slug = page.a;
				return _List_fromArray(
					[
						'items',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 30:
				var slug = page.a;
				return _List_fromArray(
					[
						'templates',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 31:
				var slug = page.a;
				return _List_fromArray(
					[
						'templates',
						$author$project$Items$Slug$toString(slug),
						'fork'
					]);
			case 7:
				var slug = page.a;
				return _List_fromArray(
					[
						'items',
						$author$project$Items$Slug$toString(slug)
					]);
			case 8:
				var maybeQuery = page.a;
				return _List_fromArray(
					['items']);
			case 11:
				return _List_fromArray(
					['locations', 'new']);
			case 12:
				var slug = page.a;
				return _List_fromArray(
					[
						'locations',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 13:
				var slug = page.a;
				return _List_fromArray(
					[
						'locations',
						$author$project$Items$Slug$toString(slug)
					]);
			case 10:
				return _List_fromArray(
					['locations']);
			case 50:
				return _List_fromArray(
					['suppliers', 'new']);
			case 51:
				var slug = page.a;
				return _List_fromArray(
					[
						'suppliers',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 52:
				var slug = page.a;
				return _List_fromArray(
					[
						'suppliers',
						$author$project$Items$Slug$toString(slug)
					]);
			case 49:
				return _List_fromArray(
					['suppliers']);
			case 54:
				return _List_fromArray(
					['payments', 'new']);
			case 55:
				var slug = page.a;
				return _List_fromArray(
					[
						'payments',
						$author$project$Items$Slug$toString(slug)
					]);
			case 53:
				return _List_fromArray(
					['payments']);
			case 58:
				return _List_fromArray(
					['packings', 'new']);
			case 59:
				var slug = page.a;
				return _List_fromArray(
					[
						'packings',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 60:
				var slug = page.a;
				return _List_fromArray(
					[
						'packings',
						$author$project$Items$Slug$toString(slug)
					]);
			case 57:
				return _List_fromArray(
					['packings']);
			case 15:
				return _List_fromArray(
					['categories', 'new']);
			case 16:
				var slug = page.a;
				return _List_fromArray(
					[
						'categories',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 14:
				return _List_fromArray(
					['categories']);
			case 4:
				return _List_fromArray(
					['about']);
			case 17:
				return _List_fromArray(
					['imports', 'new']);
			case 18:
				return _List_fromArray(
					['imports', 'po']);
			case 19:
				return _List_fromArray(
					['imports']);
			case 61:
				return _List_fromArray(
					['borrows']);
			case 32:
				return _List_fromArray(
					['wips']);
			case 27:
				return _List_fromArray(
					['templates']);
			case 22:
				return _List_fromArray(
					['moves']);
			case 23:
				var slug = page.a;
				return _List_fromArray(
					[
						'moves',
						$author$project$Items$Slug$toString(slug)
					]);
			case 26:
				var slug = page.a;
				return _List_fromArray(
					[
						'withdraws',
						$author$project$Items$Slug$toString(slug)
					]);
			case 29:
				var slug = page.a;
				return _List_fromArray(
					[
						'templates',
						$author$project$Items$Slug$toString(slug)
					]);
			case 34:
				var slug = page.a;
				return _List_fromArray(
					[
						'wips',
						$author$project$Items$Slug$toString(slug)
					]);
			case 25:
				return _List_fromArray(
					['withdraws']);
			case 36:
				return _List_fromArray(
					['images']);
			case 37:
				var slug = page.a;
				return _List_fromArray(
					[
						'images',
						$author$project$Items$Slug$toString(slug)
					]);
			case 38:
				return _List_fromArray(
					['images', 'new']);
			case 40:
				return _List_fromArray(
					['images', 'crop']);
			case 39:
				var slug = page.a;
				return _List_fromArray(
					[
						'images',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 35:
				return _List_fromArray(
					['templates', 'cal']);
			case 20:
				return _List_fromArray(
					['moves', 'new']);
			case 21:
				return _List_fromArray(
					['moves', 'to-original']);
			case 33:
				return _List_fromArray(
					['wips', 'new']);
			case 24:
				return _List_fromArray(
					['withdraws', 'new']);
			case 28:
				return _List_fromArray(
					['templates', 'new']);
			case 41:
				return _List_fromArray(
					['po']);
			case 42:
				var slug = page.a;
				return _List_fromArray(
					[
						'po',
						$author$project$Items$Slug$toString(slug)
					]);
			case 43:
				return _List_fromArray(
					['po', 'new']);
			case 44:
				return _List_fromArray(
					['po', 'known-price']);
			case 47:
				var slug = page.a;
				return _List_fromArray(
					[
						'po',
						$author$project$Items$Slug$toString(slug),
						'receive'
					]);
			case 46:
				var slug = page.a;
				return _List_fromArray(
					[
						'po',
						$author$project$Items$Slug$toString(slug),
						'edit'
					]);
			case 45:
				var slug = page.a;
				return _List_fromArray(
					[
						'po',
						$author$project$Items$Slug$toString(slug),
						'known-price'
					]);
			case 56:
				var slug = page.a;
				return _List_fromArray(
					[
						'po',
						$author$project$Items$Slug$toString(slug),
						'supplier'
					]);
			default:
				var slug = page.a;
				return _List_fromArray(
					[
						'po',
						$author$project$Items$Slug$toString(slug),
						'confirm'
					]);
		}
	}();
	return '/' + (A2($elm$core$String$join, '/', pieces) + query);
};
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Page$About$init = function (session) {
	var initCmd = $author$project$Session$isLoggedIn(session) ? $elm$core$Platform$Cmd$none : A2(
		$author$project$Route$replaceUrl,
		$author$project$Session$navKey(session),
		$author$project$Route$Login);
	return _Utils_Tuple2(
		{bg: 0, bl: $author$project$Page$About$Loading, ac: false, b1: $elm$core$Maybe$Nothing, aY: _List_Nil, aA: session, ak: false, cl: $pablen$toasty$Toasty$initialState, am: $author$project$Page$About$Waiting},
		initCmd);
};
var $author$project$Page$Categories$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Categories$List$Loading = {$: 0};
var $author$project$Viewer$cred = function (_v0) {
	var val = _v0.c;
	return val;
};
var $author$project$Session$cred = function (session) {
	if (!session.$) {
		var val = session.b;
		return $elm$core$Maybe$Just(
			$author$project$Viewer$cred(val));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Categories$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Categories$List$articlesPerPage = 10;
var $author$project$Api$Endpoint$Endpoint = $elm$core$Basics$identity;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Api$Endpoint$url = F2(
	function (paths, queryParams) {
		return A2(
			$elm$url$Url$Builder$absolute,
			A2($elm$core$List$cons, 'api', paths),
			queryParams);
	});
var $author$project$Api$Endpoint$categories = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['categories']),
		params);
};
var $author$project$PaginatedList$PaginatedList = $elm$core$Basics$identity;
var $author$project$PaginatedList$fromList = F2(
	function (totalCount, list) {
		return {dU: totalCount, bM: list};
	});
var $author$project$Category$Category = F3(
	function (name, id, deletable) {
		return {e2: deletable, cY: id, fU: name};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Category$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'deletable',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Category$Category))));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Category$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Category$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Category$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Category$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$Api$credHeader = function (_v0) {
	var str = _v0.b;
	return A2($elm$http$Http$header, 'authorization', 'Bearer ' + str);
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.gD));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {dz: reqs, dN: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.g9;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.dz));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.dN)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					ef: r.ef,
					a7: r.a7,
					bk: A2(_Http_mapExpect, func, r.bk),
					bn: r.bn,
					bp: r.bp,
					bJ: r.bJ,
					g9: r.g9,
					bL: r.bL
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{ef: false, a7: r.a7, bk: r.bk, bn: r.bn, bp: r.bp, bJ: r.bJ, g9: r.g9, bL: r.bL}));
};
var $author$project$Api$Endpoint$unwrap = function (_v0) {
	var str = _v0;
	return str;
};
var $author$project$Api$Endpoint$request = function (config) {
	return $elm$http$Http$request(
		{
			a7: config.a7,
			bk: config.bk,
			bn: config.bn,
			bp: config.bp,
			bJ: config.bJ,
			g9: $elm$core$Maybe$Nothing,
			bL: $author$project$Api$Endpoint$unwrap(config.bL)
		});
};
var $author$project$Api$get = F4(
	function (url, maybeCred, expectMsg, decoder) {
		return $author$project$Api$Endpoint$request(
			{
				a7: $elm$http$Http$emptyBody,
				bk: A2($elm$http$Http$expectJson, expectMsg, decoder),
				bn: function () {
					if (!maybeCred.$) {
						var cred = maybeCred.a;
						return _List_fromArray(
							[
								$author$project$Api$credHeader(cred)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				bp: 'GET',
				bJ: $elm$core$Maybe$Nothing,
				bL: url
			});
	});
var $author$project$Category$fetchAll = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$categories(params),
			maybeCred,
			toMsg,
			A2($author$project$Category$decoder, maybeCred, 10));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$PaginatedList$params = function (_v0) {
	var page = _v0.f1;
	var resultsPerPage = _v0.gh;
	var query = _v0.ge;
	var offset = (page - 1) * resultsPerPage;
	return _List_fromArray(
		[
			A2(
			$elm$url$Url$Builder$string,
			'limit',
			$elm$core$String$fromInt(resultsPerPage)),
			A2(
			$elm$url$Url$Builder$string,
			'offset',
			$elm$core$String$fromInt(offset)),
			A2($elm$url$Url$Builder$string, 'query', query)
		]);
};
var $author$project$Page$Categories$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Categories$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Category$fetchAll, maybeCred, params, $author$project$Page$Categories$List$CompletedLoadItem);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$Categories$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{bj: _List_Nil, aP: 1, aR: $author$project$Page$Categories$List$Loading, ai: '', aA: session, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Categories$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Categories$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Home$init = function (session) {
	return _Utils_Tuple2(
		{aA: session},
		$elm$core$Platform$Cmd$none);
};
var $debois$elm_dom$DOM$Rectangle = F4(
	function (top, left, width, height) {
		return {fl: height, c4: left, dT: top, he: width};
	});
var $mikaxyz$elm_cropper$Cropper$Types$Vector = F2(
	function (x, y) {
		return {hh: x, hi: y};
	});
var $mikaxyz$elm_cropper$Cropper$init = function (a_) {
	return {
		a9: A4($debois$elm_dom$DOM$Rectangle, 0, 0, 0, 0),
		cI: a_.cI,
		z: $elm$core$Maybe$Nothing,
		au: $elm$core$Maybe$Nothing,
		aW: A2($mikaxyz$elm_cropper$Cropper$Types$Vector, 0.5, 0.5),
		bL: a_.bL,
		hl: 1.0
	};
};
var $author$project$Page$Images$Crop$init = function (session) {
	return _Utils_Tuple2(
		{
			as: $mikaxyz$elm_cropper$Cropper$init(
				{
					cI: {fl: 480, he: 720},
					bL: 'assets/kittens-1280x711.jpg'
				}),
			aA: session,
			dR: 42
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Images$List$CompletedLoadCategory = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Images$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Images$List$Loading = {$: 0};
var $author$project$Category$decodeToList = A2(
	$elm$json$Json$Decode$field,
	'data',
	$elm$json$Json$Decode$list($author$project$Category$fullDecoder));
var $author$project$Category$fetchAllList = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$categories(params),
			maybeCred,
			toMsg,
			$author$project$Category$decodeToList);
	});
var $author$project$Page$Images$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Images$List$articlesPerPage = 30;
var $author$project$Image$Image = function (deletable) {
	return function (name) {
		return function (created_at) {
			return function (create_id) {
				return function (creator) {
					return function (category_id) {
						return function (category_name) {
							return function (path_full) {
								return function (path_tn) {
									return function (id) {
										return {eC: category_id, eD: category_name, eU: create_id, eV: created_at, eW: creator, e2: deletable, cY: id, fU: name, dp: path_full, dq: path_tn};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$parser$Parser$deadEndsToString = function (deadEnds) {
	return 'TODO deadEndsToString';
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {cF: col, eQ: contextStack, du: problem, dE: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.dE, s.cF, x, s.c));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b9) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b9, offset) < 0,
					0,
					{cF: col, c: s0.c, d: s0.d, b9: offset, dE: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b9, s.dE, s.cF, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b9, s1.b9, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b9, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cF: 1, c: s.c, d: s.d, b9: s.b9 + 1, dE: s.dE + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cF: s.cF + 1, c: s.c, d: s.d, b9: newOffset, dE: s.dE, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b9, s.dE, s.cF, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{cF: newCol, c: s.c, d: s.d, b9: newOffset, dE: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {cF: col, du: problem, dE: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.dE, p.cF, p.du);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{cF: 1, c: _List_Nil, d: 1, b9: 0, dE: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$elm$parser$Parser$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Image$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'id',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'path_tn',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'path_full',
			$elm$json$Json$Decode$string,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'category_name',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'category_id',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'creator',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'create_id',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'created_at',
								$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'name',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'deletable',
										$elm$json$Json$Decode$bool,
										$elm$json$Json$Decode$succeed($author$project$Image$Image)))))))))));
var $author$project$Image$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Image$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Image$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Image$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$images = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['images']),
		params);
};
var $author$project$Image$fetchAll = F4(
	function (pageSize, maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$images(params),
			maybeCred,
			toMsg,
			A2($author$project$Image$decoder, maybeCred, pageSize));
	});
var $author$project$Page$Images$List$fetchFeed = F4(
	function (session, page, query, categoryID) {
		var paginatedParams = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Images$List$articlesPerPage});
		var params = function () {
			if (!categoryID.$) {
				var val = categoryID.a;
				return A2(
					$elm$core$List$cons,
					A2(
						$elm$url$Url$Builder$string,
						'category_id',
						$elm$core$String$fromInt(val)),
					paginatedParams);
			} else {
				return paginatedParams;
			}
		}();
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Image$fetchAll, $author$project$Page$Images$List$articlesPerPage, maybeCred, params, $author$project$Page$Images$List$CompletedLoadItem);
	});
var $author$project$Page$Images$List$init = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 5000});
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{aK: $author$project$Page$Images$List$Loading, aP: 1, aR: $author$project$Page$Images$List$Loading, aZ: $elm$core$Maybe$Nothing, ai: '', aA: session, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A4($author$project$Page$Images$List$fetchFeed, session, 1, '', $elm$core$Maybe$Nothing),
					A3($author$project$Category$fetchAllList, maybeCred, params, $author$project$Page$Images$List$CompletedLoadCategory),
					A2($elm$core$Task$perform, $author$project$Page$Images$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Images$View$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Images$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Images$View$Loading = {$: 0};
var $author$project$Api$Endpoint$image = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'images',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Image$fetch = F3(
	function (maybeCred, articleSlug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$image(articleSlug),
			maybeCred,
			toMsg,
			$author$project$Image$fullDecoder);
	});
var $author$project$Page$Images$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{bj: _List_Nil, aO: $author$project$Page$Images$View$Loading, aA: session, aB: false, bG: slug, ck: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Image$fetch, maybeCred, slug, $author$project$Page$Images$View$CompletedLoadItem),
						A2($elm$core$Task$perform, $author$project$Page$Images$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Imports$Form$Loading = {$: 2};
var $author$project$Page$Imports$Form$init = function (session) {
	return _Utils_Tuple2(
		{
			_: false,
			v: {eL: '', fB: 0, P: 0, gd: 0},
			aM: $author$project$Page$Imports$Form$Loading,
			aR: $author$project$Page$Imports$Form$Loading,
			bo: $author$project$Page$Imports$Form$Loading,
			ax: _List_Nil,
			bE: '',
			bF: '',
			aA: session,
			aE: false,
			cl: $pablen$toasty$Toasty$initialState,
			C: true
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Imports$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Imports$List$Loading = {$: 0};
var $author$project$Page$Imports$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Imports$List$articlesPerPage = 10;
var $author$project$MovementImport$MovementImport = function (id) {
	return function (lot_detail_id) {
		return function (item_name) {
			return function (item_thumbnail) {
				return function (location_name) {
					return function (quantity) {
						return function (creator) {
							return function (comments) {
								return function (deletable) {
									return function (created_at) {
										return function (lot_remaining) {
											return {eL: comments, eV: created_at, eW: creator, e2: deletable, cY: id, fC: item_name, fD: item_thumbnail, fF: location_name, fG: lot_detail_id, fH: lot_remaining, gd: quantity};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$MovementImport$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lot_remaining',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'created_at',
		$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'deletable',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'comments',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'creator',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'quantity',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'location_name',
							$elm$json$Json$Decode$string,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'item_thumbnail',
								$elm$json$Json$Decode$string,
								'https://via.placeholder.com/150',
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'item_name',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'lot_detail_id',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$elm$json$Json$Decode$int,
											$elm$json$Json$Decode$succeed($author$project$MovementImport$MovementImport))))))))))));
var $author$project$MovementImport$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$MovementImport$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$MovementImport$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$MovementImport$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$movementImports = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['movements', 'import']),
		params);
};
var $author$project$MovementImport$fetchAll = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$movementImports(params),
			maybeCred,
			toMsg,
			A2($author$project$MovementImport$decoder, maybeCred, 10));
	});
var $author$project$Page$Imports$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Imports$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$MovementImport$fetchAll, maybeCred, params, $author$project$Page$Imports$List$CompletedLoadItem);
	});
var $author$project$Page$Imports$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{Y: 0, bj: _List_Nil, aP: 1, aR: $author$project$Page$Imports$List$Loading, ai: '', aA: session, aB: false, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Imports$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Imports$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Imports$Po$Loading = {$: 1};
var $author$project$Page$Imports$Po$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Imports$Po$CompletedLoadItems = function (a) {
	return {$: 6, a: a};
};
var $author$project$Models$PurchaseOrder$PurchaseOrderDetail = F8(
	function (item_id, item_name, item_thumbnail, quantity, received, price_total, status, purchase_order_id) {
		return {fB: item_id, fC: item_name, fD: item_thumbnail, gb: price_total, gc: purchase_order_id, gd: quantity, gf: received, dL: status};
	});
var $author$project$Models$PurchaseOrder$fullDecoderDetail = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'purchase_order_id',
	$elm$json$Json$Decode$int,
	0,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$elm$json$Json$Decode$int,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'price_total',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'received',
				$elm$json$Json$Decode$int,
				0,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'quantity',
					$elm$json$Json$Decode$int,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'item_thumbnail',
						$elm$json$Json$Decode$string,
						'https://via.placeholder.com/150',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'item_name',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'item_id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Models$PurchaseOrder$PurchaseOrderDetail)))))))));
var $author$project$Models$PurchaseOrder$detailDecoder = $elm$json$Json$Decode$list($author$project$Models$PurchaseOrder$fullDecoderDetail);
var $author$project$Api$Endpoint$purchaseOrderShippedListNotReceived = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['po', 'shipped-list-not-received']),
	_List_Nil);
var $author$project$Models$PurchaseOrder$fetchShipListNotReceived = F2(
	function (maybeCred, expectMsg) {
		return A4($author$project$Api$get, $author$project$Api$Endpoint$purchaseOrderShippedListNotReceived, maybeCred, expectMsg, $author$project$Models$PurchaseOrder$detailDecoder);
	});
var $author$project$Page$Imports$Po$fetchFeed = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return A2($author$project$Models$PurchaseOrder$fetchShipListNotReceived, maybeCred, $author$project$Page$Imports$Po$CompletedLoadItems);
};
var $author$project$Page$Imports$Po$init = function (session) {
	return _Utils_Tuple2(
		{
			_: false,
			v: {
				eL: '',
				fB: 0,
				P: 0,
				gc: 0,
				gd: 0,
				I: A2($author$project$Page$Imports$Po$PriceField, $elm$core$Maybe$Nothing, ''),
				aj: 0
			},
			aM: $author$project$Page$Imports$Po$Loading,
			aR: $author$project$Page$Imports$Po$Loading,
			bo: $author$project$Page$Imports$Po$Loading,
			ax: _List_Nil,
			bE: '',
			bF: '',
			aA: session,
			aE: false,
			cl: $pablen$toasty$Toasty$initialState,
			C: true
		},
		$author$project$Page$Imports$Po$fetchFeed(session));
};
var $author$project$Page$Items$List$CompletedLoadCategory = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Items$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Items$List$Loading = {$: 0};
var $author$project$Page$Items$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Items$List$articlesPerPage = 30;
var $author$project$Item$Item = F9(
	function (thumbnail, remaining, deletable, title, createdAt, category_id, category_name, body, id) {
		return {a7: body, eC: category_id, eD: category_name, cH: createdAt, e2: deletable, cY: id, dx: remaining, g2: thumbnail, g5: title};
	});
var $author$project$Item$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'id',
	$elm$json$Json$Decode$int,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'body',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'category_name',
			$elm$json$Json$Decode$string,
			'',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'category_id',
				$elm$json$Json$Decode$int,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'createdAt',
					$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
					$elm$time$Time$millisToPosix(0),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'title',
						$elm$json$Json$Decode$string,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'deletable',
							$elm$json$Json$Decode$bool,
							false,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'remaining',
								$elm$json$Json$Decode$int,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'thumbnail',
									$elm$json$Json$Decode$string,
									'https://via.placeholder.com/150',
									$elm$json$Json$Decode$succeed($author$project$Item$Item))))))))));
var $author$project$Item$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Item$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'items',
			$elm$json$Json$Decode$list($author$project$Item$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'itemsCount',
				$author$project$Item$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$items = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['items']),
		params);
};
var $author$project$Item$fetchAll = F4(
	function (pageSize, maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$items(params),
			maybeCred,
			toMsg,
			A2($author$project$Item$decoder, maybeCred, pageSize));
	});
var $author$project$Page$Items$List$fetchFeed = F4(
	function (session, page, query, categoryID) {
		var paginatedParams = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Items$List$articlesPerPage});
		var params = function () {
			if (!categoryID.$) {
				var val = categoryID.a;
				return A2(
					$elm$core$List$cons,
					A2(
						$elm$url$Url$Builder$string,
						'category_id',
						$elm$core$String$fromInt(val)),
					paginatedParams);
			} else {
				return paginatedParams;
			}
		}();
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchAll, $author$project$Page$Items$List$articlesPerPage, maybeCred, params, $author$project$Page$Items$List$CompletedLoadItem);
	});
var $author$project$Page$Items$List$init = F2(
	function (session, maybeQuery) {
		var query = function () {
			if (!maybeQuery.$) {
				var q = maybeQuery.a;
				return q;
			} else {
				return '';
			}
		}();
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: query, gh: 5000});
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{aK: $author$project$Page$Items$List$Loading, aP: 1, aR: $author$project$Page$Items$List$Loading, aZ: $elm$core$Maybe$Nothing, ai: query, aA: session, ck: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Page$Items$List$fetchFeed, session, 1, query, $elm$core$Maybe$Nothing),
						A3($author$project$Category$fetchAllList, maybeCred, params, $author$project$Page$Items$List$CompletedLoadCategory),
						A2($elm$core$Task$perform, $author$project$Page$Items$List$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Items$View$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Items$View$CompletedLoadItemImage = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Items$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Items$View$Loading = {$: 0};
var $author$project$Api$Endpoint$item = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'items',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Item$fetch = F3(
	function (maybeCred, articleSlug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$item(articleSlug),
			maybeCred,
			toMsg,
			$author$project$Item$fullDecoder);
	});
var $author$project$Api$Endpoint$itemImage = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'items',
				$author$project$Items$Slug$toString(slug),
				'images'
			]),
		_List_Nil);
};
var $author$project$Image$listDecoder = $elm$json$Json$Decode$list($author$project$Image$fullDecoder);
var $author$project$Image$fetchImages = F3(
	function (maybeCred, articleSlug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$itemImage(articleSlug),
			maybeCred,
			toMsg,
			$author$project$Image$listDecoder);
	});
var $author$project$Page$Items$View$CompletedLoadItemMovement = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Endpoint$itemMovements = F2(
	function (slug, params) {
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'items',
					$author$project$Items$Slug$toString(slug),
					'movements'
				]),
			params);
	});
var $author$project$Item$ItemMovement = F9(
	function (createdAt, transaction_id, move_type, quantity, creator, remaining, lot_detail_id, location_name, comments) {
		return {eL: comments, cH: createdAt, eW: creator, fF: location_name, fG: lot_detail_id, fS: move_type, gd: quantity, dx: remaining, hb: transaction_id};
	});
var $author$project$Item$moveDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'comments',
	$elm$json$Json$Decode$string,
	'',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'location_name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'lot_detail_id',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'remaining',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'creator',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'quantity',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'move_type',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'transaction_id',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'createdAt',
									$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
									$elm$json$Json$Decode$succeed($author$project$Item$ItemMovement))))))))));
var $author$project$Item$moveMetaDecoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Item$moveDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Item$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Item$fetchMovement = F4(
	function (maybeCred, id, params, toMsg) {
		return A4(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$itemMovements, id, params),
			maybeCred,
			toMsg,
			A2($author$project$Item$moveMetaDecoder, maybeCred, 10));
	});
var $author$project$Page$Items$View$fetchMovements = F3(
	function (session, page, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: '', gh: 10});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchMovement, maybeCred, slug, params, $author$project$Page$Items$View$CompletedLoadItemMovement);
	});
var $author$project$Page$Items$View$CompletedLoadItemPositiveMovement = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$Endpoint$itemPositiveMovements = F2(
	function (slug, params) {
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'items',
					$author$project$Items$Slug$toString(slug),
					'positive_movement'
				]),
			params);
	});
var $author$project$Item$fetchPositiveMovement = F4(
	function (maybeCred, id, params, toMsg) {
		return A4(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$itemPositiveMovements, id, params),
			maybeCred,
			toMsg,
			A2($author$project$Item$moveMetaDecoder, maybeCred, 10));
	});
var $author$project$Page$Items$View$fetchPositiveMovements = F3(
	function (session, page, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: '', gh: 10});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchPositiveMovement, maybeCred, slug, params, $author$project$Page$Items$View$CompletedLoadItemPositiveMovement);
	});
var $author$project$Page$Items$View$CompletedLoadRemainLot = function (a) {
	return {$: 10, a: a};
};
var $author$project$Stock$Stock = F7(
	function (remaining, location_id, location_name, lot_detail_id, item_name, item_thumbnail, quantity) {
		return {fC: item_name, fD: item_thumbnail, P: location_id, fF: location_name, fG: lot_detail_id, gd: quantity, dx: remaining};
	});
var $author$project$Stock$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'quantity',
	$elm$json$Json$Decode$int,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'item_thumbnail',
		$elm$json$Json$Decode$string,
		'https://via.placeholder.com/150',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'item_name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'lot_detail_id',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'location_name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'location_id',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'remaining',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Stock$Stock))))))));
var $author$project$Stock$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Stock$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Stock$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Stock$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$stockRemainItem = F2(
	function (slug, params) {
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'stocks',
					'remain',
					$author$project$Items$Slug$toString(slug)
				]),
			params);
	});
var $author$project$Stock$fetchRemainItem = F4(
	function (slug, maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$stockRemainItem, slug, params),
			maybeCred,
			toMsg,
			A2($author$project$Stock$decoder, maybeCred, 10));
	});
var $author$project$Page$Items$View$fetchRemainLot = F3(
	function (session, page, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: '', gh: 10});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Stock$fetchRemainItem, slug, maybeCred, params, $author$project$Page$Items$View$CompletedLoadRemainLot);
	});
var $author$project$Page$Items$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{o: _List_Nil, bj: _List_Nil, aO: $author$project$Page$Items$View$Loading, br: $author$project$Page$Items$View$Loading, bs: 1, bx: $author$project$Page$Items$View$Loading, by: 1, bA: 1, bB: $author$project$Page$Items$View$Loading, aA: session, aB: false, bG: slug, ck: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Item$fetch, maybeCred, slug, $author$project$Page$Items$View$CompletedLoadItem),
						A3(
						$author$project$Image$fetchImages,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Items$View$CompletedLoadItemImage),
						A3($author$project$Page$Items$View$fetchMovements, session, 1, slug),
						A3($author$project$Page$Items$View$fetchPositiveMovements, session, 1, slug),
						A3($author$project$Page$Items$View$fetchRemainLot, session, 1, slug),
						A2($elm$core$Task$perform, $author$project$Page$Items$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Locations$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Locations$List$Loading = {$: 0};
var $author$project$Page$Locations$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Locations$List$articlesPerPage = 10;
var $author$project$Location$Location = F3(
	function (name, id, deletable) {
		return {e2: deletable, cY: id, fU: name};
	});
var $author$project$Location$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'deletable',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Location$Location))));
var $author$project$Location$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Location$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Location$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Location$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$locations = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['locations']),
		params);
};
var $author$project$Location$fetchAll = F3(
	function (maybeCred, params, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$locations(params),
			maybeCred,
			expectMsg,
			A2($author$project$Location$decoder, maybeCred, 10));
	});
var $author$project$Page$Locations$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Locations$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Location$fetchAll, maybeCred, params, $author$project$Page$Locations$List$CompletedLoadItem);
	});
var $author$project$Page$Locations$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{Y: 0, bj: _List_Nil, aP: 1, aR: $author$project$Page$Locations$List$Loading, ai: '', aA: session, aB: false, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Locations$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Locations$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Locations$View$CompletedLdList = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Locations$View$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Locations$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Locations$View$Loading = {$: 0};
var $author$project$Api$Endpoint$location = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'locations',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Location$fetch = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$location(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Location$fullDecoder);
	});
var $author$project$Api$Endpoint$stockInLocation = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'stocks',
				'in',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Stock$fetchInLocation = F3(
	function (maybeCred, slug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$stockInLocation(slug),
			maybeCred,
			expectMsg,
			A2($author$project$Stock$decoder, maybeCred, 100));
	});
var $author$project$Page$Locations$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{
				bj: _List_Nil,
				aO: $author$project$Page$Locations$View$Loading,
				aA: session,
				ch: $author$project$Items$Slug$toString(slug),
				aD: $author$project$Page$Locations$View$Loading,
				ck: $elm$time$Time$utc
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Location$fetch, maybeCred, slug, $author$project$Page$Locations$View$CompletedLoadItem),
						A3($author$project$Stock$fetchInLocation, maybeCred, slug, $author$project$Page$Locations$View$CompletedLdList),
						A2($elm$core$Task$perform, $author$project$Page$Locations$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Login$init = function (session) {
	return _Utils_Tuple2(
		{
			v: {O: '', R: ''},
			aU: false,
			ax: _List_Nil,
			aA: session
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Moves$Form$Loading = {$: 2};
var $author$project$Page$Moves$Form$init = function (session) {
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			v: {eL: '', x: 0},
			aR: $author$project$Page$Moves$Form$Loading,
			bo: $author$project$Page$Moves$Form$Loading,
			ax: _List_Nil,
			bE: '',
			bF: '',
			aA: session
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Moves$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$List$Loading = {$: 0};
var $author$project$Page$Moves$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Moves$List$articlesPerPage = 10;
var $author$project$MovementMove$MovementMove = F4(
	function (id, comments, creator, created_at) {
		return {eL: comments, eV: created_at, eW: creator, cY: id};
	});
var $author$project$MovementMove$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'created_at',
	$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'creator',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'comments',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$MovementMove$MovementMove)))));
var $author$project$MovementMove$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$MovementMove$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$MovementMove$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$MovementMove$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$movementMoves = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['moves']),
		params);
};
var $author$project$MovementMove$fetchAll = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$movementMoves(params),
			maybeCred,
			toMsg,
			A2($author$project$MovementMove$decoder, maybeCred, 10));
	});
var $author$project$Page$Moves$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Moves$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$MovementMove$fetchAll, maybeCred, params, $author$project$Page$Moves$List$CompletedLoadItem);
	});
var $author$project$Page$Moves$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{bj: _List_Nil, aP: 1, aR: $author$project$Page$Moves$List$Loading, ai: '', aA: session, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Moves$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Moves$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Moves$Original$Loading = {$: 2};
var $author$project$Page$Moves$Original$init = function (session) {
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			v: {
				eL: '',
				p: {e2: false, cY: 0, fU: ''}
			},
			aR: $author$project$Page$Moves$Original$Loading,
			bo: $author$project$Page$Moves$Original$Loading,
			ax: _List_Nil,
			bD: false,
			bE: '',
			bF: '',
			aA: session
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Moves$View$CompletedLoadMove = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Moves$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$View$Loading = {$: 0};
var $author$project$Api$Endpoint$movementMove = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'moves',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$MovementMove$fetch = F3(
	function (maybeCred, articleSlug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$movementMove(articleSlug),
			maybeCred,
			toMsg,
			$author$project$MovementMove$fullDecoder);
	});
var $author$project$Page$Moves$View$CompletedLoadMoveDetail = function (a) {
	return {$: 5, a: a};
};
var $author$project$Stock$decodeFullList = $elm$json$Json$Decode$list($author$project$Stock$fullDecoder);
var $author$project$Api$Endpoint$stockMoveDetial = F2(
	function (slug, params) {
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'stocks',
					'move-detail',
					$author$project$Items$Slug$toString(slug)
				]),
			params);
	});
var $author$project$Stock$fetchMoveDetail = F4(
	function (slug, maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$stockMoveDetial, slug, params),
			maybeCred,
			toMsg,
			$author$project$Stock$decodeFullList);
	});
var $author$project$Page$Moves$View$fetchMovements = F3(
	function (session, page, slug) {
		var params = _List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'ref_type',
				$elm$core$String$fromInt(2))
			]);
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Stock$fetchMoveDetail, slug, maybeCred, params, $author$project$Page$Moves$View$CompletedLoadMoveDetail);
	});
var $author$project$Page$Moves$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{bj: _List_Nil, aO: $author$project$Page$Moves$View$Loading, br: $author$project$Page$Moves$View$Loading, aA: session, bG: slug, ck: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$MovementMove$fetch, maybeCred, slug, $author$project$Page$Moves$View$CompletedLoadMove),
						A3($author$project$Page$Moves$View$fetchMovements, session, 1, slug),
						A2($elm$core$Task$perform, $author$project$Page$Moves$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Packings$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Packings$List$Loading = {$: 0};
var $author$project$Page$Packings$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Packings$List$articlesPerPage = 10;
var $author$project$Api$Endpoint$purchaseOrdersShipping = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['shipping']),
		params);
};
var $author$project$Models$PurchaseOrder$PurchaseOrderShipping = F7(
	function (id, shipper, ship_fee, paid_status, tracking, comments, createdAt) {
		return {eL: comments, cH: createdAt, cY: id, dm: paid_status, dI: ship_fee, gv: shipper, ha: tracking};
	});
var $author$project$Models$PurchaseOrder$fullShippingDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'created_at',
	$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'comments',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'tracking',
			$elm$json$Json$Decode$string,
			'',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'paid_status',
				$elm$json$Json$Decode$bool,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'ship_fee',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'shipper',
						$elm$json$Json$Decode$string,
						'',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Models$PurchaseOrder$PurchaseOrderShipping))))))));
var $author$project$Models$PurchaseOrder$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Models$PurchaseOrder$shippingDecoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Models$PurchaseOrder$fullShippingDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Models$PurchaseOrder$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Models$PurchaseOrder$fetchAllShipping = F3(
	function (maybeCred, params, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$purchaseOrdersShipping(params),
			maybeCred,
			expectMsg,
			A2($author$project$Models$PurchaseOrder$shippingDecoder, maybeCred, 10));
	});
var $author$project$Page$Packings$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Packings$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PurchaseOrder$fetchAllShipping, maybeCred, params, $author$project$Page$Packings$List$CompletedLoadItem);
	});
var $author$project$Page$Packings$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{Y: 0, bj: _List_Nil, aP: 1, aR: $author$project$Page$Packings$List$Loading, ai: '', aA: session, aB: false, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Task$perform, $author$project$Page$Packings$List$GotTimeZone, $elm$time$Time$here),
					A3($author$project$Page$Packings$List$fetchFeed, session, 1, '')
				])));
};
var $author$project$Page$Packings$View$CompletedLdList = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Packings$View$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Packings$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Packings$View$Loading = {$: 0};
var $author$project$Page$Packings$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{
				bj: _List_Nil,
				aO: $author$project$Page$Packings$View$Loading,
				aA: session,
				ch: $author$project$Items$Slug$toString(slug),
				aD: $author$project$Page$Packings$View$Loading,
				ck: $elm$time$Time$utc
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Location$fetch, maybeCred, slug, $author$project$Page$Packings$View$CompletedLoadItem),
						A3($author$project$Stock$fetchInLocation, maybeCred, slug, $author$project$Page$Packings$View$CompletedLdList),
						A2($elm$core$Task$perform, $author$project$Page$Packings$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Payments$List$GotTimeNow = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Payments$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Payments$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Payments$List$CompletedLoadItem = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Payments$List$articlesPerPage = 10;
var $author$project$Models$PurchaseOrder$PurchaseOrderPayment = F6(
	function (id, creator, currency, conversion_rate, amount, created_at) {
		return {ei: amount, eR: conversion_rate, eV: created_at, eW: creator, cJ: currency, cY: id};
	});
var $author$project$Models$PurchaseOrder$fullPaymentDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'created_at',
	$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'amount',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'conversion_rate',
			$elm$json$Json$Decode$string,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'currency',
				$elm$json$Json$Decode$string,
				'',
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'creator',
					$elm$json$Json$Decode$string,
					'',
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Models$PurchaseOrder$PurchaseOrderPayment)))))));
var $author$project$Models$PurchaseOrder$paymentDecoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Models$PurchaseOrder$fullPaymentDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Models$PurchaseOrder$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$payments = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['payments']),
		params);
};
var $author$project$Models$PurchaseOrder$fetchAllPayment = F3(
	function (maybeCred, params, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$payments(params),
			maybeCred,
			expectMsg,
			A2($author$project$Models$PurchaseOrder$paymentDecoder, maybeCred, 10));
	});
var $author$project$Page$Payments$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Payments$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PurchaseOrder$fetchAllPayment, maybeCred, params, $author$project$Page$Payments$List$CompletedLoadItem);
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Page$Payments$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{
			Y: 0,
			bj: _List_Nil,
			aP: 1,
			aR: $author$project$Page$Payments$List$Loaded(
				A2($author$project$PaginatedList$fromList, 0, _List_Nil)),
			b9: 0,
			ai: '',
			aA: session,
			aB: false,
			ck: $elm$time$Time$utc,
			aG: $elm$time$Time$millisToPosix(0)
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Task$perform, $author$project$Page$Payments$List$GotTimeZone, $elm$time$Time$here),
					A2($elm$core$Task$perform, $author$project$Page$Payments$List$GotTimeNow, $elm$time$Time$now),
					A3($author$project$Page$Payments$List$fetchFeed, session, 1, '')
				])));
};
var $author$project$Page$Payments$View$CompletedLoadDetails = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Payments$View$CompletedLoadItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Payments$View$Loading = {$: 1};
var $author$project$Page$Payments$View$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$Endpoint$payment = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'payments',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Models$PurchaseOrder$fetchPayment = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$payment(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Models$PurchaseOrder$fullPaymentDecoder);
	});
var $author$project$Api$Endpoint$paymentDetails = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'payments',
				$author$project$Items$Slug$toString(slug),
				'details'
			]),
		_List_Nil);
};
var $author$project$Models$PurchaseOrder$fetchPaymentDetails = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$paymentDetails(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Models$PurchaseOrder$detailDecoder);
	});
var $author$project$Page$Payments$View$init = F2(
	function (session, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: '', gh: 100});
		return _Utils_Tuple2(
			{
				Z: $author$project$Page$Payments$View$Loading,
				v: {
					ei: '',
					eR: A2($author$project$Page$Payments$View$PriceField, $elm$core$Maybe$Nothing, '')
				},
				aO: $author$project$Page$Payments$View$Loading,
				aA: session,
				bG: slug
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Models$PurchaseOrder$fetchPaymentDetails,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Payments$View$CompletedLoadDetails),
						A3(
						$author$project$Models$PurchaseOrder$fetchPayment,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Payments$View$CompletedLoadItem)
					])));
	});
var $author$project$Page$PurchaseOrders$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$List$Loading = {$: 0};
var $author$project$Page$PurchaseOrders$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$List$articlesPerPage = 10;
var $author$project$Models$PurchaseOrder$PurchaseOrder = function (id) {
	return function (supplier_id) {
		return function (supplier_name) {
			return function (comments) {
				return function (creator) {
					return function (created_at) {
						return function (flow_type) {
							return function (pay_to) {
								return function (currency) {
									return function (ship_fee) {
										return function (paid_status) {
											return function (status) {
												return {eL: comments, eV: created_at, eW: creator, cJ: currency, fb: flow_type, cY: id, dm: paid_status, f4: pay_to, dI: ship_fee, dL: status, gK: supplier_id, gL: supplier_name};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Models$PurchaseOrder$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'status',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'paid_status',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'ship_fee',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'currency',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'pay_to',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'flow_type',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'created_at',
							$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'creator',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'comments',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'supplier_name',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'supplier_id',
											$elm$json$Json$Decode$int,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$elm$json$Json$Decode$int,
												$elm$json$Json$Decode$succeed($author$project$Models$PurchaseOrder$PurchaseOrder)))))))))))));
var $author$project$Models$PurchaseOrder$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Models$PurchaseOrder$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Models$PurchaseOrder$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$purchaseOrders = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['po']),
		params);
};
var $author$project$Models$PurchaseOrder$fetchAll = F3(
	function (maybeCred, params, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$purchaseOrders(params),
			maybeCred,
			expectMsg,
			A2($author$project$Models$PurchaseOrder$decoder, maybeCred, 10));
	});
var $author$project$Page$PurchaseOrders$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$PurchaseOrders$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PurchaseOrder$fetchAll, maybeCred, params, $author$project$Page$PurchaseOrders$List$CompletedLoadItem);
	});
var $author$project$Page$PurchaseOrders$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{Y: 0, bj: _List_Nil, aP: 1, aR: $author$project$Page$PurchaseOrders$List$Loading, ai: '', aA: session, aB: false, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Task$perform, $author$project$Page$PurchaseOrders$List$GotTimeZone, $elm$time$Time$here),
					A3($author$project$Page$PurchaseOrders$List$fetchFeed, session, 1, '')
				])));
};
var $author$project$Page$PurchaseOrders$Receive$CompletedLoadPo = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$CompletedLoadPoDetail = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$Loading = {$: 0};
var $author$project$Api$Endpoint$purchaseOrder = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Models$PurchaseOrder$fetch = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$purchaseOrder(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Models$PurchaseOrder$fullDecoder);
	});
var $author$project$Api$Endpoint$purchaseOrderDetail = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'detail'
			]),
		_List_Nil);
};
var $author$project$Models$PurchaseOrder$fetchDetails = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$purchaseOrderDetail(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Models$PurchaseOrder$detailDecoder);
	});
var $author$project$Page$PurchaseOrders$Receive$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{
				Z: $author$project$Page$PurchaseOrders$Receive$Loading,
				_: true,
				bj: _List_Nil,
				v: {
					eL: 'From PO: ' + $author$project$Items$Slug$toString(slug),
					fB: 0,
					P: 0,
					gd: 0
				},
				aM: _List_Nil,
				aO: $author$project$Page$PurchaseOrders$Receive$Loading,
				bo: $author$project$Page$PurchaseOrders$Receive$Loading,
				ax: _List_Nil,
				T: 0,
				bE: '',
				bF: '',
				aA: session,
				bG: slug,
				aE: false,
				ck: $elm$time$Time$utc,
				cl: $pablen$toasty$Toasty$initialState,
				C: true
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Models$PurchaseOrder$fetch, maybeCred, slug, $author$project$Page$PurchaseOrders$Receive$CompletedLoadPo),
						A3($author$project$Models$PurchaseOrder$fetchDetails, maybeCred, slug, $author$project$Page$PurchaseOrders$Receive$CompletedLoadPoDetail),
						A2($elm$core$Task$perform, $author$project$Page$PurchaseOrders$Receive$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$PurchaseOrders$View$CompletedLoadPo = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$View$CompletedLoadPoDetail = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PurchaseOrders$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$View$Loading = {$: 0};
var $author$project$Page$PurchaseOrders$View$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$View$CompletedLoadItemMovement = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Endpoint$poMovements = F2(
	function (slug, params) {
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'po',
					$author$project$Items$Slug$toString(slug),
					'movements'
				]),
			params);
	});
var $author$project$Item$fetchPoMovement = F4(
	function (maybeCred, id, params, toMsg) {
		return A4(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$poMovements, id, params),
			maybeCred,
			toMsg,
			A2($author$project$Item$moveMetaDecoder, maybeCred, 10));
	});
var $author$project$Page$PurchaseOrders$View$fetchMovements = F3(
	function (session, page, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: '', gh: 100});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchPoMovement, maybeCred, slug, params, $author$project$Page$PurchaseOrders$View$CompletedLoadItemMovement);
	});
var $author$project$Page$PurchaseOrders$View$CompletedLoadOtherCosts = function (a) {
	return {$: 5, a: a};
};
var $author$project$Models$PoOtherCost$PoOtherCost = F6(
	function (id, cost_type, cost, comments, creator, created_at) {
		return {eL: comments, eS: cost, eT: cost_type, eV: created_at, eW: creator, cY: id};
	});
var $author$project$Models$PoOtherCost$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'created_at',
	$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'creator',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'comments',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'cost',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'cost_type',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Models$PoOtherCost$PoOtherCost)))))));
var $author$project$Models$PoOtherCost$listDecoder = $elm$json$Json$Decode$list($author$project$Models$PoOtherCost$fullDecoder);
var $author$project$Api$Endpoint$poOtherCost = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'other-costs'
			]),
		_List_Nil);
};
var $author$project$Models$PoOtherCost$fetch = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$poOtherCost(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Models$PoOtherCost$listDecoder);
	});
var $author$project$Page$PurchaseOrders$View$fetchOtherCosts = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PoOtherCost$fetch, maybeCred, slug, $author$project$Page$PurchaseOrders$View$CompletedLoadOtherCosts);
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Page$PurchaseOrders$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{
				Z: $author$project$Page$PurchaseOrders$View$Loading,
				bj: _List_Nil,
				v: {
					eL: '',
					eS: A2($author$project$Page$PurchaseOrders$View$PriceField, $elm$core$Maybe$Nothing, ''),
					eT: 'Tax',
					gc: A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(
							$author$project$Items$Slug$toString(slug)))
				},
				aO: $author$project$Page$PurchaseOrders$View$Loading,
				br: $author$project$Page$PurchaseOrders$View$Loading,
				w: $author$project$Page$PurchaseOrders$View$Loading,
				aA: session,
				bG: slug,
				ck: $elm$time$Time$utc
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Models$PurchaseOrder$fetch, maybeCred, slug, $author$project$Page$PurchaseOrders$View$CompletedLoadPo),
						A3($author$project$Models$PurchaseOrder$fetchDetails, maybeCred, slug, $author$project$Page$PurchaseOrders$View$CompletedLoadPoDetail),
						A3($author$project$Page$PurchaseOrders$View$fetchMovements, session, 1, slug),
						A3($author$project$Page$PurchaseOrders$View$fetchOtherCosts, session, 1, slug),
						A2($elm$core$Task$perform, $author$project$Page$PurchaseOrders$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Suppliers$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Suppliers$List$Loading = {$: 0};
var $author$project$Page$Suppliers$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Suppliers$List$articlesPerPage = 10;
var $author$project$Supplier$Supplier = F5(
	function (name, id, email, deletable, currency) {
		return {cJ: currency, e2: deletable, O: email, cY: id, fU: name};
	});
var $author$project$Supplier$fullDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'currency',
	$elm$json$Json$Decode$string,
	'THB',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'deletable',
		$elm$json$Json$Decode$bool,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'email',
			$elm$json$Json$Decode$string,
			'',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'name',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Supplier$Supplier))))));
var $author$project$Supplier$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Supplier$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Supplier$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Supplier$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$suppliers = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['suppliers']),
		params);
};
var $author$project$Supplier$fetchAll = F3(
	function (maybeCred, params, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$suppliers(params),
			maybeCred,
			expectMsg,
			A2($author$project$Supplier$decoder, maybeCred, 10));
	});
var $author$project$Page$Suppliers$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Suppliers$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Supplier$fetchAll, maybeCred, params, $author$project$Page$Suppliers$List$CompletedLoadItem);
	});
var $author$project$Page$Suppliers$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{Y: 0, bj: _List_Nil, aP: 1, aR: $author$project$Page$Suppliers$List$Loading, ai: '', aA: session, aB: false, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Suppliers$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Suppliers$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Suppliers$View$CompletedLdList = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Suppliers$View$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Suppliers$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Suppliers$View$Loading = {$: 0};
var $author$project$Page$Suppliers$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{
				bj: _List_Nil,
				aO: $author$project$Page$Suppliers$View$Loading,
				aA: session,
				ch: $author$project$Items$Slug$toString(slug),
				aD: $author$project$Page$Suppliers$View$Loading,
				ck: $elm$time$Time$utc
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Location$fetch, maybeCred, slug, $author$project$Page$Suppliers$View$CompletedLoadItem),
						A3($author$project$Stock$fetchInLocation, maybeCred, slug, $author$project$Page$Suppliers$View$CompletedLdList),
						A2($elm$core$Task$perform, $author$project$Page$Suppliers$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Templates$Cal$Loading = {$: 1};
var $author$project$Page$Templates$Cal$CompletedLoadSuppliers = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Templates$Cal$fetchFeedSuppliers = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 1000});
	var maybeCred = $author$project$Session$cred(session);
	return A3($author$project$Supplier$fetchAll, maybeCred, params, $author$project$Page$Templates$Cal$CompletedLoadSuppliers);
};
var $author$project$Page$Templates$Cal$CompletedLoadPo = function (a) {
	return {$: 16, a: a};
};
var $author$project$Api$Endpoint$calPoList = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['cal', 'po-list']),
	_List_Nil);
var $author$project$Wip$WipIngredient = F5(
	function (id, item_name, item_thumbnail, remaining, quantity) {
		return {cY: id, fC: item_name, fD: item_thumbnail, gd: quantity, dx: remaining};
	});
var $author$project$Wip$ingredientsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'quantity',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'remaining',
		$elm$json$Json$Decode$int,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'item_thumbnail',
			$elm$json$Json$Decode$string,
			'https://via.placeholder.com/150',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'item_name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$Wip$WipIngredient))))));
var $author$project$Wip$ingredientsListDecoder = $elm$json$Json$Decode$list($author$project$Wip$ingredientsDecoder);
var $author$project$Wip$fetchCalPoingList = F2(
	function (maybeCred, toMsg) {
		return A4($author$project$Api$get, $author$project$Api$Endpoint$calPoList, maybeCred, toMsg, $author$project$Wip$ingredientsListDecoder);
	});
var $author$project$Page$Templates$Cal$fetchPoList = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return A2($author$project$Wip$fetchCalPoingList, maybeCred, $author$project$Page$Templates$Cal$CompletedLoadPo);
};
var $author$project$Page$Templates$Cal$init = function (session) {
	return _Utils_Tuple2(
		{
			m: _List_Nil,
			v: {
				eL: '',
				n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
			},
			i: _List_Nil,
			aN: _List_Nil,
			aR: $author$project$Page$Templates$Cal$Loading,
			aX: _List_Nil,
			ax: _List_Nil,
			bE: '',
			aA: session,
			aF: $author$project$Page$Templates$Cal$Loading,
			a6: _List_Nil
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Page$Templates$Cal$fetchFeedSuppliers(session),
					$author$project$Page$Templates$Cal$fetchPoList(session)
				])));
};
var $author$project$Page$Templates$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Templates$List$Loading = {$: 0};
var $author$project$Page$Templates$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Templates$List$articlesPerPage = 10;
var $author$project$Template$Template = F9(
	function (id, target_item_id, target_item_name, target_item_image, target_item_thumbnail, comments, creator, created_at, deletable) {
		return {eL: comments, eV: created_at, eW: creator, e2: deletable, cY: id, a1: target_item_id, gO: target_item_image, dQ: target_item_name, cj: target_item_thumbnail};
	});
var $author$project$Template$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'deletable',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'created_at',
		$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'creator',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'comments',
				$elm$json$Json$Decode$string,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'target_item_thumbnail',
					$elm$json$Json$Decode$string,
					'https://via.placeholder.com/150',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'target_item_image',
						$elm$json$Json$Decode$string,
						'https://via.placeholder.com/500',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'target_item_name',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'target_item_id',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$elm$json$Json$Decode$int,
									$elm$json$Json$Decode$succeed($author$project$Template$Template))))))))));
var $author$project$Template$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Template$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Template$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Template$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$templates = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['templates']),
		params);
};
var $author$project$Template$fetchList = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$templates(params),
			maybeCred,
			toMsg,
			A2($author$project$Template$decoder, maybeCred, 10));
	});
var $author$project$Page$Templates$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Templates$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Template$fetchList, maybeCred, params, $author$project$Page$Templates$List$CompletedLoadItem);
	});
var $author$project$Page$Templates$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{Y: 0, bj: _List_Nil, aP: 1, aR: $author$project$Page$Templates$List$Loading, ai: '', aA: session, aB: false, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Templates$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Templates$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Templates$View$CompletedLoadMove = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Templates$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Templates$View$Loading = {$: 0};
var $author$project$Api$Endpoint$template = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'templates',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Template$fetch = F3(
	function (maybeCred, slug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$template(slug),
			maybeCred,
			toMsg,
			$author$project$Template$fullDecoder);
	});
var $author$project$Page$Templates$View$CompletedLoadMoveDetail = function (a) {
	return {$: 5, a: a};
};
var $author$project$Template$TemplateDetail = F4(
	function (item_id, item_name, item_thumbnail, quantity) {
		return {fB: item_id, fC: item_name, fD: item_thumbnail, gd: quantity};
	});
var $author$project$Template$detailDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'quantity',
	$elm$json$Json$Decode$int,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'item_thumbnail',
		$elm$json$Json$Decode$string,
		'https://via.placeholder.com/150',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'item_name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'item_id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Template$TemplateDetail)))));
var $author$project$Template$detailListDecoder = $elm$json$Json$Decode$list($author$project$Template$detailDecoder);
var $author$project$Api$Endpoint$templateDetail = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'templates',
				'details',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Template$fetchDetail = F3(
	function (maybeCred, slug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$templateDetail(slug),
			maybeCred,
			toMsg,
			$author$project$Template$detailListDecoder);
	});
var $author$project$Page$Templates$View$fetchMovements = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Template$fetchDetail, maybeCred, slug, $author$project$Page$Templates$View$CompletedLoadMoveDetail);
	});
var $author$project$Page$Templates$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{bj: _List_Nil, aO: $author$project$Page$Templates$View$Loading, br: $author$project$Page$Templates$View$Loading, aA: session, bG: slug, ck: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Template$fetch, maybeCred, slug, $author$project$Page$Templates$View$CompletedLoadMove),
						A3($author$project$Page$Templates$View$fetchMovements, session, 1, slug),
						A2($elm$core$Task$perform, $author$project$Page$Templates$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Wips$Form$Loading = {$: 1};
var $author$project$Page$Wips$Form$CompletedLoadLocations = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Wips$Form$fetchFeedLocation = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 1000});
	var maybeCred = $author$project$Session$cred(session);
	return A3($author$project$Location$fetchAll, maybeCred, params, $author$project$Page$Wips$Form$CompletedLoadLocations);
};
var $author$project$Page$Wips$Form$init = function (session) {
	return _Utils_Tuple2(
		{
			v: {
				eL: '',
				p: {e2: false, cY: 0, fU: ''},
				gd: 0,
				l: {cY: 0, g5: ''}
			},
			i: $author$project$Page$Wips$Form$Loading,
			aR: $author$project$Page$Wips$Form$Loading,
			bo: $author$project$Page$Wips$Form$Loading,
			ax: _List_Nil,
			bD: false,
			bE: '',
			aA: session
		},
		$author$project$Page$Wips$Form$fetchFeedLocation(session));
};
var $author$project$Page$Wips$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wips$List$Loading = {$: 0};
var $author$project$Page$Wips$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Wips$List$articlesPerPage = 10;
var $author$project$Wip$Wip = function (id) {
	return function (target_item_name) {
		return function (target_item_thumbnail) {
			return function (target_item_image) {
				return function (status) {
					return function (quantity) {
						return function (comments) {
							return function (creator) {
								return function (created_at) {
									return function (location_id) {
										return function (location_name) {
											return {eL: comments, eV: created_at, eW: creator, cY: id, P: location_id, fF: location_name, gd: quantity, dL: status, gO: target_item_image, dQ: target_item_name, cj: target_item_thumbnail};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Wip$fullDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'location_name',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'location_id',
		$elm$json$Json$Decode$int,
		0,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'created_at',
			$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'creator',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'comments',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'quantity',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'status',
							$elm$json$Json$Decode$string,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'target_item_image',
								$elm$json$Json$Decode$string,
								'https://via.placeholder.com/500',
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'target_item_thumbnail',
									$elm$json$Json$Decode$string,
									'https://via.placeholder.com/150',
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'target_item_name',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'id',
											$elm$json$Json$Decode$int,
											$elm$json$Json$Decode$succeed($author$project$Wip$Wip))))))))))));
var $author$project$Wip$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$Wip$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$Wip$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$Wip$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$wips = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['wips']),
		params);
};
var $author$project$Wip$fetchList = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$wips(params),
			maybeCred,
			toMsg,
			A2($author$project$Wip$decoder, maybeCred, 10));
	});
var $author$project$Page$Wips$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Wips$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Wip$fetchList, maybeCred, params, $author$project$Page$Wips$List$CompletedLoadItem);
	});
var $author$project$Page$Wips$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{Y: 0, bj: _List_Nil, aP: 1, aR: $author$project$Page$Wips$List$Loading, ai: '', aA: session, aB: false, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Wips$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Wips$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Wips$View$CompletedLoadMove = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Wips$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wips$View$Loading = {$: 0};
var $author$project$Api$Endpoint$wip = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'wips',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Wip$fetch = F3(
	function (maybeCred, slug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$wip(slug),
			maybeCred,
			toMsg,
			$author$project$Wip$fullDecoder);
	});
var $author$project$Page$Wips$View$CompletedLoadMoveDetail = function (a) {
	return {$: 6, a: a};
};
var $author$project$Wip$WipDetail = F4(
	function (item_id, item_name, item_thumbnail, quantity) {
		return {fB: item_id, fC: item_name, fD: item_thumbnail, gd: quantity};
	});
var $author$project$Wip$detailDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'quantity',
	$elm$json$Json$Decode$int,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'item_thumbnail',
		$elm$json$Json$Decode$string,
		'https://via.placeholder.com/150',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'item_name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'item_id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Wip$WipDetail)))));
var $author$project$Wip$detailListDecoder = $elm$json$Json$Decode$list($author$project$Wip$detailDecoder);
var $author$project$Api$Endpoint$wipDetail = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'wips',
				'details',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Wip$fetchDetail = F3(
	function (maybeCred, slug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$wipDetail(slug),
			maybeCred,
			toMsg,
			$author$project$Wip$detailListDecoder);
	});
var $author$project$Page$Wips$View$fetchMovements = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Wip$fetchDetail, maybeCred, slug, $author$project$Page$Wips$View$CompletedLoadMoveDetail);
	});
var $author$project$Page$Wips$View$CompletedLoadPickList = function (a) {
	return {$: 7, a: a};
};
var $author$project$Api$Endpoint$wipPickList = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'wips',
				'pick_list',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Wip$fetchPickList = F3(
	function (maybeCred, slug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$wipPickList(slug),
			maybeCred,
			toMsg,
			$author$project$Stock$decodeFullList);
	});
var $author$project$Page$Wips$View$fetchWipPickList = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Wip$fetchPickList, maybeCred, slug, $author$project$Page$Wips$View$CompletedLoadPickList);
	});
var $author$project$Page$Wips$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{bj: _List_Nil, aO: $author$project$Page$Wips$View$Loading, br: $author$project$Page$Wips$View$Loading, aV: $author$project$Page$Wips$View$Loading, ay: false, aA: session, bG: slug, ck: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Wip$fetch, maybeCred, slug, $author$project$Page$Wips$View$CompletedLoadMove),
						A3($author$project$Page$Wips$View$fetchMovements, session, 1, slug),
						A3($author$project$Page$Wips$View$fetchWipPickList, session, 1, slug),
						A2($elm$core$Task$perform, $author$project$Page$Wips$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Withdraws$Borrow$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Withdraws$Borrow$Loading = {$: 0};
var $author$project$Page$Withdraws$Borrow$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Withdraws$Borrow$articlesPerPage = 10;
var $author$project$Api$Endpoint$borrows = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['borrows']),
		params);
};
var $author$project$Stock$fetchBorrow = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$borrows(params),
			maybeCred,
			toMsg,
			A2($author$project$Stock$decoder, maybeCred, 10));
	});
var $author$project$Page$Withdraws$Borrow$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Withdraws$Borrow$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Stock$fetchBorrow, maybeCred, params, $author$project$Page$Withdraws$Borrow$CompletedLoadItem);
	});
var $author$project$Page$Withdraws$Borrow$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{bj: _List_Nil, aP: 1, aR: $author$project$Page$Withdraws$Borrow$Loading, ai: '', aA: session, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Withdraws$Borrow$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Withdraws$Borrow$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Withdraws$Form$Loading = {$: 2};
var $author$project$MovementWithdraw$withdrawTypes = _List_fromArray(
	['Lost', 'Consume', 'Deploy', 'Sell', 'Repair', 'Borrow']);
var $author$project$Page$Withdraws$Form$init = function (session) {
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			v: {eL: '', hf: 0},
			aR: $author$project$Page$Withdraws$Form$Loading,
			bo: $author$project$Page$Withdraws$Form$Loading,
			ax: _List_Nil,
			bE: '',
			bF: '',
			aA: session,
			bN: $author$project$MovementWithdraw$withdrawTypes
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Withdraws$List$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Withdraws$List$Loading = {$: 0};
var $author$project$Page$Withdraws$List$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Withdraws$List$articlesPerPage = 10;
var $author$project$MovementWithdraw$MovementWithdraw = F5(
	function (id, withdraw_type, comments, creator, created_at) {
		return {eL: comments, eV: created_at, eW: creator, cY: id, hf: withdraw_type};
	});
var $author$project$MovementWithdraw$fullDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'created_at',
	$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'creator',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'comments',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'withdraw_type',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$MovementWithdraw$MovementWithdraw))))));
var $author$project$MovementWithdraw$pageCountDecoder = function (resultsPerPage) {
	return A2(
		$elm$json$Json$Decode$map,
		function (total) {
			return $elm$core$Basics$ceiling(total / resultsPerPage);
		},
		$elm$json$Json$Decode$int);
};
var $author$project$MovementWithdraw$decoder = F2(
	function (maybeCred, resultsPerPage) {
		return A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'data',
			$elm$json$Json$Decode$list($author$project$MovementWithdraw$fullDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'count',
				$author$project$MovementWithdraw$pageCountDecoder(resultsPerPage),
				$elm$json$Json$Decode$succeed($author$project$PaginatedList$fromList)));
	});
var $author$project$Api$Endpoint$movementWithdraws = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['withdraws']),
		params);
};
var $author$project$MovementWithdraw$fetchAll = F3(
	function (maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$movementWithdraws(params),
			maybeCred,
			toMsg,
			A2($author$project$MovementWithdraw$decoder, maybeCred, 10));
	});
var $author$project$Page$Withdraws$List$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: $author$project$Page$Withdraws$List$articlesPerPage});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$MovementWithdraw$fetchAll, maybeCred, params, $author$project$Page$Withdraws$List$CompletedLoadItem);
	});
var $author$project$Page$Withdraws$List$init = function (session) {
	var maybeCred = $author$project$Session$cred(session);
	return _Utils_Tuple2(
		{bj: _List_Nil, aP: 1, aR: $author$project$Page$Withdraws$List$Loading, ai: '', aA: session, ck: $elm$time$Time$utc},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Withdraws$List$fetchFeed, session, 1, ''),
					A2($elm$core$Task$perform, $author$project$Page$Withdraws$List$GotTimeZone, $elm$time$Time$here)
				])));
};
var $author$project$Page$Withdraws$View$CompletedLoadedWithdraw = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Withdraws$View$GotTimeZone = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Withdraws$View$Loading = {$: 0};
var $author$project$Api$Endpoint$movementWithdraw = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'withdraws',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$MovementWithdraw$fetch = F3(
	function (maybeCred, articleSlug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$movementWithdraw(articleSlug),
			maybeCred,
			toMsg,
			$author$project$MovementWithdraw$fullDecoder);
	});
var $author$project$Page$Withdraws$View$CompletedLoadedWithdrawDetail = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Endpoint$stockWithdrawDetial = F2(
	function (slug, params) {
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'stocks',
					'withdraw-detail',
					$author$project$Items$Slug$toString(slug)
				]),
			params);
	});
var $author$project$Stock$fetchWithdrawDetail = F4(
	function (slug, maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$stockWithdrawDetial, slug, params),
			maybeCred,
			toMsg,
			$author$project$Stock$decodeFullList);
	});
var $author$project$Page$Withdraws$View$fetchMovements = F3(
	function (session, page, slug) {
		var params = _List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'ref_type',
				$elm$core$String$fromInt(1))
			]);
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Stock$fetchWithdrawDetail, slug, maybeCred, params, $author$project$Page$Withdraws$View$CompletedLoadedWithdrawDetail);
	});
var $author$project$Page$Withdraws$View$init = F2(
	function (session, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			{bj: _List_Nil, aO: $author$project$Page$Withdraws$View$Loading, br: $author$project$Page$Withdraws$View$Loading, aA: session, bG: slug, ck: $elm$time$Time$utc},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$MovementWithdraw$fetch, maybeCred, slug, $author$project$Page$Withdraws$View$CompletedLoadedWithdraw),
						A3($author$project$Page$Withdraws$View$fetchMovements, session, 1, slug),
						A2($elm$core$Task$perform, $author$project$Page$Withdraws$View$GotTimeZone, $elm$time$Time$here)
					])));
	});
var $author$project$Page$Templates$Form$Loading = {$: 1};
var $author$project$Page$Templates$Form$LoadingForkItem = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Templates$Form$CompletedLoadCart = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Templates$Form$fetchCart = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Template$fetchDetail, maybeCred, slug, $author$project$Page$Templates$Form$CompletedLoadCart);
	});
var $author$project$Page$Templates$Form$initFork = F2(
	function (session, slug) {
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				v: {
					eL: '',
					l: {cY: 0, g5: ''}
				},
				aR: $author$project$Page$Templates$Form$Loading,
				ax: _List_Nil,
				bE: '',
				bF: '',
				aA: session,
				K: $author$project$Page$Templates$Form$LoadingForkItem(slug)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Page$Templates$Form$fetchCart, session, 1, slug)
					])));
	});
var $author$project$Page$Categories$Form$EditingNew = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Categories$Form$initNew = function (session) {
	return _Utils_Tuple2(
		{
			aA: session,
			dL: A2(
				$author$project$Page$Categories$Form$EditingNew,
				_List_Nil,
				{g5: ''})
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Images$Form$CompletedLoadCategories = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Images$Form$EditingNew = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Images$Form$LoadingImage = {$: 0};
var $author$project$Page$Images$Form$Waiting = {$: 0};
var $author$project$Page$Images$Form$initNew = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 5000});
	return _Utils_Tuple2(
		{
			aK: _List_Nil,
			_: false,
			bl: $author$project$Page$Images$Form$LoadingImage,
			ah: '',
			aA: session,
			dL: A2(
				$author$project$Page$Images$Form$EditingNew,
				_List_Nil,
				{eC: 1, dp: '', dq: '', g5: ''}),
			cl: $pablen$toasty$Toasty$initialState,
			am: $author$project$Page$Images$Form$Waiting
		},
		A3(
			$author$project$Category$fetchAllList,
			$author$project$Session$cred(session),
			params,
			$author$project$Page$Images$Form$CompletedLoadCategories));
};
var $author$project$Page$Items$Form$CompletedLoadCategories = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Items$Form$EditingNew = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Items$Form$LoadingImage = {$: 0};
var $author$project$Page$Items$Form$initNew = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 5000});
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			aK: _List_Nil,
			_: false,
			a_: '',
			a$: $author$project$Page$Items$Form$LoadingImage,
			aA: session,
			dL: A2(
				$author$project$Page$Items$Form$EditingNew,
				_List_Nil,
				{a7: '', eC: 1, g5: ''}),
			cl: $pablen$toasty$Toasty$initialState
		},
		A3(
			$author$project$Category$fetchAllList,
			$author$project$Session$cred(session),
			params,
			$author$project$Page$Items$Form$CompletedLoadCategories));
};
var $author$project$Page$Locations$Form$EditingNew = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Locations$Form$initNew = function (session) {
	return _Utils_Tuple2(
		{
			aA: session,
			dL: A2(
				$author$project$Page$Locations$Form$EditingNew,
				_List_Nil,
				{g5: ''})
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Packings$Form$CompletedLoadItemToShip = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Packings$Form$EditingNew = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Packings$Form$LoadingStatus = {$: 0};
var $author$project$Page$Packings$Form$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$Endpoint$purchaseOrderDetailReadyToShip = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['shipping', 'ready_to_ship']),
	_List_Nil);
var $author$project$Models$PurchaseOrder$fetchItemReadyToShip = F2(
	function (maybeCred, expectMsg) {
		return A4($author$project$Api$get, $author$project$Api$Endpoint$purchaseOrderDetailReadyToShip, maybeCred, expectMsg, $author$project$Models$PurchaseOrder$detailDecoder);
	});
var $author$project$Page$Packings$Form$initNew = function (session) {
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			v: {
				eL: '',
				dI: A2($author$project$Page$Packings$Form$PriceField, $elm$core$Maybe$Nothing, ''),
				gv: '',
				ha: ''
			},
			aQ: $author$project$Page$Packings$Form$LoadingStatus,
			ax: _List_Nil,
			ai: '',
			aA: session,
			dL: A2(
				$author$project$Page$Packings$Form$EditingNew,
				_List_Nil,
				{
					eL: '',
					dI: A2($author$project$Page$Packings$Form$PriceField, $elm$core$Maybe$Nothing, ''),
					gv: '',
					ha: ''
				})
		},
		A2(
			$author$project$Models$PurchaseOrder$fetchItemReadyToShip,
			$author$project$Session$cred(session),
			$author$project$Page$Packings$Form$CompletedLoadItemToShip));
};
var $author$project$Page$Payments$Form$CompletedLoadDetails = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Payments$Form$Loading = {$: 1};
var $author$project$Page$Payments$Form$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$Endpoint$poReadyToPay = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['po', 'ready-to-pay']),
	_List_Nil);
var $author$project$Models$PurchaseOrder$fetchAllReadyToPay = F2(
	function (maybeCred, expectMsg) {
		return A4($author$project$Api$get, $author$project$Api$Endpoint$poReadyToPay, maybeCred, expectMsg, $author$project$Models$PurchaseOrder$detailDecoder);
	});
var $author$project$Page$Payments$Form$initNew = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 100});
	return _Utils_Tuple2(
		{
			Z: $author$project$Page$Payments$Form$Loading,
			v: {
				ei: '',
				eR: A2($author$project$Page$Payments$Form$PriceField, $elm$core$Maybe$Nothing, '')
			},
			ax: _List_Nil,
			bD: false,
			bE: '',
			aA: session
		},
		A2(
			$author$project$Models$PurchaseOrder$fetchAllReadyToPay,
			$author$project$Session$cred(session),
			$author$project$Page$Payments$Form$CompletedLoadDetails));
};
var $author$project$Page$PurchaseOrders$Form$CompletedLoadSuppliers = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PurchaseOrders$Form$Loading = {$: 1};
var $author$project$Supplier$fullDecodeWithoutPage = A2(
	$elm$json$Json$Decode$field,
	'data',
	$elm$json$Json$Decode$list($author$project$Supplier$fullDecoder));
var $author$project$Supplier$fetchAllWithoutPage = F3(
	function (maybeCred, params, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$suppliers(params),
			maybeCred,
			expectMsg,
			$author$project$Supplier$fullDecodeWithoutPage);
	});
var $author$project$Page$PurchaseOrders$Form$initNew = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 100});
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			v: {
				eL: '',
				n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
			},
			aR: $author$project$Page$PurchaseOrders$Form$Loading,
			ax: _List_Nil,
			bE: '',
			bF: '',
			aA: session,
			aF: _List_Nil,
			K: $author$project$Page$PurchaseOrders$Form$Loading
		},
		A3(
			$author$project$Supplier$fetchAllWithoutPage,
			$author$project$Session$cred(session),
			params,
			$author$project$Page$PurchaseOrders$Form$CompletedLoadSuppliers));
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadSuppliers = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$Loading = {$: 1};
var $author$project$Page$PurchaseOrders$KnownPriceForm$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$initNew = function (session) {
	var params = $author$project$PaginatedList$params(
		{f1: 1, ge: '', gh: 100});
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			v: {
				eL: '',
				cJ: 'THB',
				f4: '0',
				dI: A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, ''),
				n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
			},
			aR: $author$project$Page$PurchaseOrders$KnownPriceForm$Loading,
			ax: _List_Nil,
			bE: '',
			bF: '',
			aA: session,
			aF: _List_Nil,
			K: $author$project$Page$PurchaseOrders$KnownPriceForm$Loading
		},
		A3(
			$author$project$Supplier$fetchAllWithoutPage,
			$author$project$Session$cred(session),
			params,
			$author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadSuppliers));
};
var $author$project$Page$Suppliers$Form$EditingNew = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Suppliers$Form$initNew = function (session) {
	return _Utils_Tuple2(
		{
			aA: session,
			dL: A2(
				$author$project$Page$Suppliers$Form$EditingNew,
				_List_Nil,
				{cJ: '', O: '', fU: ''})
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Templates$Form$initNew = function (session) {
	return _Utils_Tuple2(
		{
			o: _List_Nil,
			v: {
				eL: '',
				l: {cY: 0, g5: ''}
			},
			aR: $author$project$Page$Templates$Form$Loading,
			ax: _List_Nil,
			bE: '',
			bF: '',
			aA: session,
			K: $author$project$Page$Templates$Form$Loading
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Items$List$initSearch = F2(
	function (model, maybeQuery) {
		var query = function () {
			if (!maybeQuery.$) {
				var q = maybeQuery.a;
				return q;
			} else {
				return '';
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aP: 1, ai: query}),
			A4($author$project$Page$Items$List$fetchFeed, model.aA, 1, query, $elm$core$Maybe$Nothing));
	});
var $author$project$Page$Categories$Form$CompletedLoadItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Categories$Form$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Endpoint$category = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'categories',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Category$fetch = F3(
	function (maybeCred, articleSlug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$category(articleSlug),
			maybeCred,
			toMsg,
			$author$project$Category$fullDecoder);
	});
var $author$project$Page$Categories$Form$initUpdate = F2(
	function (session, slug) {
		return _Utils_Tuple2(
			{
				aA: session,
				dL: $author$project$Page$Categories$Form$Loading(slug)
			},
			A3(
				$author$project$Category$fetch,
				$author$project$Session$cred(session),
				slug,
				$author$project$Page$Categories$Form$CompletedLoadItem));
	});
var $author$project$Page$Images$Form$CompletedLoadItem = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Images$Form$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Images$Form$initUpdate = F2(
	function (session, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: '', gh: 5000});
		return _Utils_Tuple2(
			{
				aK: _List_Nil,
				_: false,
				bl: $author$project$Page$Images$Form$LoadingImage,
				ah: '',
				aA: session,
				dL: $author$project$Page$Images$Form$Loading(slug),
				cl: $pablen$toasty$Toasty$initialState,
				am: $author$project$Page$Images$Form$Waiting
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Item$fetch,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Images$Form$CompletedLoadItem),
						A3(
						$author$project$Category$fetchAllList,
						$author$project$Session$cred(session),
						params,
						$author$project$Page$Images$Form$CompletedLoadCategories)
					])));
	});
var $author$project$Page$Items$Form$CompletedLoadItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Items$Form$CompletedLoadItemImage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Items$Form$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Items$Form$initUpdate = F2(
	function (session, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: '', gh: 5000});
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				aK: _List_Nil,
				_: false,
				a_: '',
				a$: $author$project$Page$Items$Form$LoadingImage,
				aA: session,
				dL: $author$project$Page$Items$Form$Loading(slug),
				cl: $pablen$toasty$Toasty$initialState
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Item$fetch,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Items$Form$CompletedLoadItem),
						A3(
						$author$project$Image$fetchImages,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Items$Form$CompletedLoadItemImage),
						A3(
						$author$project$Category$fetchAllList,
						$author$project$Session$cred(session),
						params,
						$author$project$Page$Items$Form$CompletedLoadCategories)
					])));
	});
var $author$project$Page$Locations$Form$CompletedLoadItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Locations$Form$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Locations$Form$initUpdate = F2(
	function (session, slug) {
		return _Utils_Tuple2(
			{
				aA: session,
				dL: $author$project$Page$Locations$Form$Loading(slug)
			},
			A3(
				$author$project$Location$fetch,
				$author$project$Session$cred(session),
				slug,
				$author$project$Page$Locations$Form$CompletedLoadItem));
	});
var $author$project$Page$Packings$Form$CompletedLoadCart = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Packings$Form$CompletedLoadItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Packings$Form$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Endpoint$purchaseOrderShippedCart = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'shipping',
				$author$project$Items$Slug$toString(slug),
				'details'
			]),
		_List_Nil);
};
var $author$project$Models$PurchaseOrder$fetchShipedCart = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$purchaseOrderShippedCart(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Models$PurchaseOrder$detailDecoder);
	});
var $author$project$Api$Endpoint$purchaseOrderShipped = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'shipping',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Models$PurchaseOrder$fetchShipping = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$purchaseOrderShipped(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Models$PurchaseOrder$fullShippingDecoder);
	});
var $author$project$Page$Packings$Form$initUpdate = F2(
	function (session, slug) {
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				v: {
					eL: '',
					dI: A2($author$project$Page$Packings$Form$PriceField, $elm$core$Maybe$Nothing, ''),
					gv: '',
					ha: ''
				},
				aQ: $author$project$Page$Packings$Form$LoadingStatus,
				ax: _List_Nil,
				ai: '',
				aA: session,
				dL: $author$project$Page$Packings$Form$Loading(slug)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Models$PurchaseOrder$fetchShipedCart,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Packings$Form$CompletedLoadCart),
						A3(
						$author$project$Models$PurchaseOrder$fetchShipping,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Packings$Form$CompletedLoadItem)
					])));
	});
var $author$project$Page$PurchaseOrders$Confirm$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Confirm$CompletedLoadCart = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$fetchCart = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PurchaseOrder$fetchDetails, maybeCred, slug, $author$project$Page$PurchaseOrders$Confirm$CompletedLoadCart);
	});
var $author$project$Page$PurchaseOrders$Confirm$initUpdate = F2(
	function (session, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: '', gh: 100});
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				v: {
					fB: 0,
					gb: A2($author$project$Page$PurchaseOrders$Confirm$PriceField, $elm$core$Maybe$Nothing, ''),
					gd: 0
				},
				u: _List_Nil,
				ax: _List_Nil,
				bD: false,
				bE: '',
				aA: session,
				bG: slug,
				an: 0
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Page$PurchaseOrders$Confirm$fetchCart, session, 1, slug)
					])));
	});
var $author$project$Page$PurchaseOrders$Form$CompletedLoadItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$PurchaseOrders$Form$LoadingItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$Form$CompletedLoadCart = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PurchaseOrders$Form$fetchCart = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PurchaseOrder$fetchDetails, maybeCred, slug, $author$project$Page$PurchaseOrders$Form$CompletedLoadCart);
	});
var $author$project$Page$PurchaseOrders$Form$initUpdate = F2(
	function (session, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: '', gh: 100});
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				v: {
					eL: '',
					n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
				},
				aR: $author$project$Page$PurchaseOrders$Form$Loading,
				ax: _List_Nil,
				bE: '',
				bF: '',
				aA: session,
				aF: _List_Nil,
				K: $author$project$Page$PurchaseOrders$Form$LoadingItem(slug)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Models$PurchaseOrder$fetch,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$PurchaseOrders$Form$CompletedLoadItem),
						A3($author$project$Page$PurchaseOrders$Form$fetchCart, session, 1, slug),
						A3(
						$author$project$Supplier$fetchAllWithoutPage,
						$author$project$Session$cred(session),
						params,
						$author$project$Page$PurchaseOrders$Form$CompletedLoadSuppliers)
					])));
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$LoadingItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadCart = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$fetchCart = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PurchaseOrder$fetchDetails, maybeCred, slug, $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadCart);
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$initUpdate = F2(
	function (session, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: '', gh: 100});
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				v: {
					eL: '',
					cJ: 'THB',
					f4: '0',
					dI: A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, ''),
					n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
				},
				aR: $author$project$Page$PurchaseOrders$KnownPriceForm$Loading,
				ax: _List_Nil,
				bE: '',
				bF: '',
				aA: session,
				aF: _List_Nil,
				K: $author$project$Page$PurchaseOrders$KnownPriceForm$LoadingItem(slug)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Models$PurchaseOrder$fetch,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadItem),
						A3($author$project$Page$PurchaseOrders$KnownPriceForm$fetchCart, session, 1, slug),
						A3(
						$author$project$Supplier$fetchAllWithoutPage,
						$author$project$Session$cred(session),
						params,
						$author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadSuppliers)
					])));
	});
var $author$project$Page$PurchaseOrders$Supplier$PriceField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Supplier$CompletedLoadCart = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$fetchCart = F3(
	function (session, page, slug) {
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Models$PurchaseOrder$fetchDetails, maybeCred, slug, $author$project$Page$PurchaseOrders$Supplier$CompletedLoadCart);
	});
var $author$project$Page$PurchaseOrders$Supplier$initUpdate = F2(
	function (session, slug) {
		var params = $author$project$PaginatedList$params(
			{f1: 1, ge: '', gh: 100});
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				v: {
					fB: 0,
					gb: A2($author$project$Page$PurchaseOrders$Supplier$PriceField, $elm$core$Maybe$Nothing, '')
				},
				u: _List_Nil,
				ax: _List_Nil,
				bD: false,
				bE: '',
				aA: session,
				bG: slug,
				an: 0
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$Page$PurchaseOrders$Supplier$fetchCart, session, 1, slug)
					])));
	});
var $author$project$Page$Suppliers$Form$CompletedLoadItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Suppliers$Form$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Endpoint$supplier = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'suppliers',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Supplier$fetch = F3(
	function (maybeCred, articleSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$supplier(articleSlug),
			maybeCred,
			expectMsg,
			$author$project$Supplier$fullDecoder);
	});
var $author$project$Page$Suppliers$Form$initUpdate = F2(
	function (session, slug) {
		return _Utils_Tuple2(
			{
				aA: session,
				dL: $author$project$Page$Suppliers$Form$Loading(slug)
			},
			A3(
				$author$project$Supplier$fetch,
				$author$project$Session$cred(session),
				slug,
				$author$project$Page$Suppliers$Form$CompletedLoadItem));
	});
var $author$project$Page$Templates$Form$CompletedLoadItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Templates$Form$LoadingItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Templates$Form$initUpdate = F2(
	function (session, slug) {
		return _Utils_Tuple2(
			{
				o: _List_Nil,
				v: {
					eL: '',
					l: {cY: 0, g5: ''}
				},
				aR: $author$project$Page$Templates$Form$Loading,
				ax: _List_Nil,
				bE: '',
				bF: '',
				aA: session,
				K: $author$project$Page$Templates$Form$LoadingItem(slug)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Template$fetch,
						$author$project$Session$cred(session),
						slug,
						$author$project$Page$Templates$Form$CompletedLoadItem),
						A3($author$project$Page$Templates$Form$fetchCart, session, 1, slug)
					])));
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Api$storeCache = _Platform_outgoingPort(
	'storeCache',
	function ($) {
		return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$core$Basics$identity, $);
	});
var $author$project$Api$logout = $author$project$Api$storeCache($elm$core$Maybe$Nothing);
var $author$project$Page$About$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Categories$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Categories$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Home$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Images$Crop$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Images$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Images$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Images$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Imports$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Imports$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Imports$Po$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Items$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Items$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Items$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Locations$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Locations$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Locations$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Login$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Moves$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Moves$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Moves$Original$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Moves$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Packings$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Packings$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Packings$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Payments$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Payments$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Payments$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$PurchaseOrders$Confirm$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$PurchaseOrders$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$PurchaseOrders$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$PurchaseOrders$Receive$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$PurchaseOrders$Supplier$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$PurchaseOrders$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Suppliers$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Suppliers$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Suppliers$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Templates$Cal$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Templates$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Templates$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Templates$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Wips$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Wips$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Wips$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Withdraws$Borrow$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Withdraws$Form$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Withdraws$List$toSession = function (model) {
	return model.aA;
};
var $author$project$Page$Withdraws$View$toSession = function (model) {
	return model.aA;
};
var $author$project$Main$toSession = function (page) {
	switch (page.$) {
		case 0:
			var session = page.a;
			return session;
		case 1:
			var session = page.a;
			return session;
		case 2:
			var home = page.a;
			return $author$project$Page$Home$toSession(home);
		case 3:
			var login = page.a;
			return $author$project$Page$Login$toSession(login);
		case 4:
			var about = page.a;
			return $author$project$Page$About$toSession(about);
		case 5:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$Items$Form$toSession(item);
		case 6:
			var item = page.a;
			return $author$project$Page$Items$View$toSession(item);
		case 7:
			var item = page.a;
			return $author$project$Page$Items$List$toSession(item);
		case 8:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$Images$Form$toSession(item);
		case 9:
			var item = page.a;
			return $author$project$Page$Images$View$toSession(item);
		case 10:
			var item = page.a;
			return $author$project$Page$Images$List$toSession(item);
		case 11:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$PurchaseOrders$Form$toSession(item);
		case 12:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$PurchaseOrders$KnownPriceForm$toSession(item);
		case 13:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$PurchaseOrders$Supplier$toSession(item);
		case 14:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$PurchaseOrders$Confirm$toSession(item);
		case 15:
			var item = page.a;
			return $author$project$Page$PurchaseOrders$View$toSession(item);
		case 16:
			var item = page.a;
			return $author$project$Page$PurchaseOrders$Receive$toSession(item);
		case 17:
			var item = page.a;
			return $author$project$Page$PurchaseOrders$List$toSession(item);
		case 18:
			var item = page.a;
			return $author$project$Page$Images$Crop$toSession(item);
		case 19:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$Locations$Form$toSession(item);
		case 20:
			var item = page.a;
			return $author$project$Page$Locations$View$toSession(item);
		case 21:
			var item = page.a;
			return $author$project$Page$Locations$List$toSession(item);
		case 25:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$Suppliers$Form$toSession(item);
		case 26:
			var item = page.a;
			return $author$project$Page$Suppliers$View$toSession(item);
		case 27:
			var item = page.a;
			return $author$project$Page$Suppliers$List$toSession(item);
		case 22:
			var item = page.a;
			return $author$project$Page$Payments$Form$toSession(item);
		case 23:
			var item = page.a;
			return $author$project$Page$Payments$View$toSession(item);
		case 24:
			var item = page.a;
			return $author$project$Page$Payments$List$toSession(item);
		case 28:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$Packings$Form$toSession(item);
		case 29:
			var item = page.a;
			return $author$project$Page$Packings$View$toSession(item);
		case 30:
			var item = page.a;
			return $author$project$Page$Packings$List$toSession(item);
		case 31:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$Categories$Form$toSession(item);
		case 32:
			var item = page.a;
			return $author$project$Page$Categories$List$toSession(item);
		case 33:
			var item = page.a;
			return $author$project$Page$Imports$Form$toSession(item);
		case 34:
			var item = page.a;
			return $author$project$Page$Imports$Po$toSession(item);
		case 38:
			var item = page.a;
			return $author$project$Page$Moves$Form$toSession(item);
		case 39:
			var item = page.a;
			return $author$project$Page$Moves$Original$toSession(item);
		case 40:
			var item = page.a;
			return $author$project$Page$Withdraws$Form$toSession(item);
		case 44:
			var slug = page.a;
			var item = page.b;
			return $author$project$Page$Templates$Form$toSession(item);
		case 48:
			var item = page.a;
			return $author$project$Page$Wips$Form$toSession(item);
		case 35:
			var item = page.a;
			return $author$project$Page$Imports$List$toSession(item);
		case 36:
			var item = page.a;
			return $author$project$Page$Moves$List$toSession(item);
		case 41:
			var item = page.a;
			return $author$project$Page$Moves$View$toSession(item);
		case 42:
			var item = page.a;
			return $author$project$Page$Withdraws$View$toSession(item);
		case 45:
			var item = page.a;
			return $author$project$Page$Templates$View$toSession(item);
		case 49:
			var item = page.a;
			return $author$project$Page$Wips$View$toSession(item);
		case 37:
			var item = page.a;
			return $author$project$Page$Withdraws$List$toSession(item);
		case 43:
			var item = page.a;
			return $author$project$Page$Templates$List$toSession(item);
		case 46:
			var item = page.a;
			return $author$project$Page$Withdraws$Borrow$toSession(item);
		case 47:
			var item = page.a;
			return $author$project$Page$Wips$List$toSession(item);
		default:
			var item = page.a;
			return $author$project$Page$Templates$Cal$toSession(item);
	}
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Main$updateWith = F4(
	function (toModel, toMsg, model, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Main$changeRouteTo = F2(
	function (maybeRoute, model) {
		var session = $author$project$Main$toSession(model);
		if (maybeRoute.$ === 1) {
			return _Utils_Tuple2(
				$author$project$Main$NotFound(session),
				$elm$core$Platform$Cmd$none);
		} else {
			switch (maybeRoute.a.$) {
				case 1:
					var _v1 = maybeRoute.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(session),
							$author$project$Route$Home));
				case 3:
					var _v2 = maybeRoute.a;
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[$author$project$Api$logout])));
				case 0:
					var _v3 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Home,
						$author$project$Main$GotHomeMsg,
						model,
						$author$project$Page$Home$init(session));
				case 4:
					var _v4 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$About,
						$author$project$Main$GotAboutMsg,
						model,
						$author$project$Page$About$init(session));
				case 2:
					var _v5 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Login,
						$author$project$Main$GotLoginMsg,
						model,
						$author$project$Page$Login$init(session));
				case 5:
					var _v6 = maybeRoute.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(session),
							$author$project$Route$Home));
				case 6:
					var _v7 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ItemForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotItemFormMsg,
						model,
						$author$project$Page$Items$Form$initNew(session));
				case 9:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ItemForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotItemFormMsg,
						model,
						A2($author$project$Page$Items$Form$initUpdate, session, slug));
				case 7:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ItemView,
						$author$project$Main$GotItemMsg,
						model,
						A2($author$project$Page$Items$View$init, session, slug));
				case 8:
					var maybeQuery = maybeRoute.a.a;
					if (model.$ === 7) {
						var newModel = model.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ItemList,
							$author$project$Main$GotItemListMsg,
							model,
							A2($author$project$Page$Items$List$initSearch, newModel, maybeQuery));
					} else {
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ItemList,
							$author$project$Main$GotItemListMsg,
							model,
							A2($author$project$Page$Items$List$init, session, maybeQuery));
					}
				case 11:
					var _v9 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$LocationForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotLocationFormMsg,
						model,
						$author$project$Page$Locations$Form$initNew(session));
				case 12:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$LocationForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotLocationFormMsg,
						model,
						A2($author$project$Page$Locations$Form$initUpdate, session, slug));
				case 13:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$LocationView,
						$author$project$Main$GotLocationMsg,
						model,
						A2($author$project$Page$Locations$View$init, session, slug));
				case 10:
					var _v10 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$LocationList,
						$author$project$Main$GotLocationListMsg,
						model,
						$author$project$Page$Locations$List$init(session));
				case 50:
					var _v11 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$SupplierForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotSupplierFormMsg,
						model,
						$author$project$Page$Suppliers$Form$initNew(session));
				case 51:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$SupplierForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotSupplierFormMsg,
						model,
						A2($author$project$Page$Suppliers$Form$initUpdate, session, slug));
				case 52:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$SupplierView,
						$author$project$Main$GotSupplierMsg,
						model,
						A2($author$project$Page$Suppliers$View$init, session, slug));
				case 49:
					var _v12 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$SupplierList,
						$author$project$Main$GotSupplierListMsg,
						model,
						$author$project$Page$Suppliers$List$init(session));
				case 54:
					var _v13 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PaymentForm,
						$author$project$Main$GotPaymentFormMsg,
						model,
						$author$project$Page$Payments$Form$initNew(session));
				case 55:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PaymentView,
						$author$project$Main$GotPaymentMsg,
						model,
						A2($author$project$Page$Payments$View$init, session, slug));
				case 53:
					var _v14 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PaymentList,
						$author$project$Main$GotPaymentListMsg,
						model,
						$author$project$Page$Payments$List$init(session));
				case 58:
					var _v15 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PackingForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotPackingFormMsg,
						model,
						$author$project$Page$Packings$Form$initNew(session));
				case 59:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PackingForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotPackingFormMsg,
						model,
						A2($author$project$Page$Packings$Form$initUpdate, session, slug));
				case 60:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PackingView,
						$author$project$Main$GotPackingViewMsg,
						model,
						A2($author$project$Page$Packings$View$init, session, slug));
				case 61:
					var _v16 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$BorrowList,
						$author$project$Main$GotBorrowListMsg,
						model,
						$author$project$Page$Withdraws$Borrow$init(session));
				case 57:
					var _v17 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PackingList,
						$author$project$Main$GotPackingListMsg,
						model,
						$author$project$Page$Packings$List$init(session));
				case 15:
					var _v18 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$CategoryForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotCategoryFormMsg,
						model,
						$author$project$Page$Categories$Form$initNew(session));
				case 16:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$CategoryForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotCategoryFormMsg,
						model,
						A2($author$project$Page$Categories$Form$initUpdate, session, slug));
				case 14:
					var _v19 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$CategoryList,
						$author$project$Main$GotCategoryListMsg,
						model,
						$author$project$Page$Categories$List$init(session));
				case 17:
					var _v20 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImportNew,
						$author$project$Main$GotImportNewMsg,
						model,
						$author$project$Page$Imports$Form$init(session));
				case 18:
					var _v21 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImportPo,
						$author$project$Main$GotImportPoMsg,
						model,
						$author$project$Page$Imports$Po$init(session));
				case 19:
					var _v22 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImportList,
						$author$project$Main$GotImportListMsg,
						model,
						$author$project$Page$Imports$List$init(session));
				case 22:
					var _v23 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$MoveList,
						$author$project$Main$GotMoveListMsg,
						model,
						$author$project$Page$Moves$List$init(session));
				case 27:
					var _v24 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$TemplateList,
						$author$project$Main$GotTemplateListMsg,
						model,
						$author$project$Page$Templates$List$init(session));
				case 25:
					var _v25 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$WithdrawList,
						$author$project$Main$GotWithdrawListMsg,
						model,
						$author$project$Page$Withdraws$List$init(session));
				case 32:
					var _v26 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$WipList,
						$author$project$Main$GotWipListMsg,
						model,
						$author$project$Page$Wips$List$init(session));
				case 36:
					var _v27 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImageList,
						$author$project$Main$GotImageListMsg,
						model,
						$author$project$Page$Images$List$init(session));
				case 40:
					var _v28 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImageCrop,
						$author$project$Main$GotImageCropMsg,
						model,
						$author$project$Page$Images$Crop$init(session));
				case 38:
					var _v29 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImageForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotImageFormMsg,
						model,
						$author$project$Page$Images$Form$initNew(session));
				case 39:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImageForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotImageFormMsg,
						model,
						A2($author$project$Page$Images$Form$initUpdate, session, slug));
				case 37:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$ImageView,
						$author$project$Main$GotImageViewMsg,
						model,
						A2($author$project$Page$Images$View$init, session, slug));
				case 41:
					var _v30 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoList,
						$author$project$Main$GotPoListMsg,
						model,
						$author$project$Page$PurchaseOrders$List$init(session));
				case 43:
					var _v31 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotPoFormMsg,
						model,
						$author$project$Page$PurchaseOrders$Form$initNew(session));
				case 44:
					var _v32 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoKnownPriceForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotPoKnownPriceFormMsg,
						model,
						$author$project$Page$PurchaseOrders$KnownPriceForm$initNew(session));
				case 46:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotPoFormMsg,
						model,
						A2($author$project$Page$PurchaseOrders$Form$initUpdate, session, slug));
				case 45:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoKnownPriceForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotPoKnownPriceFormMsg,
						model,
						A2($author$project$Page$PurchaseOrders$KnownPriceForm$initUpdate, session, slug));
				case 56:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoSupplierUpdate(slug),
						$author$project$Main$GotPoSupplierUpdateMsg,
						model,
						A2($author$project$Page$PurchaseOrders$Supplier$initUpdate, session, slug));
				case 48:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoConfirmAndChat(slug),
						$author$project$Main$GotPoConfirmAndChatMsg,
						model,
						A2($author$project$Page$PurchaseOrders$Confirm$initUpdate, session, slug));
				case 42:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoView,
						$author$project$Main$GotPoViewMsg,
						model,
						A2($author$project$Page$PurchaseOrders$View$init, session, slug));
				case 47:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$PoReceive,
						$author$project$Main$GotPoReceiveMsg,
						model,
						A2($author$project$Page$PurchaseOrders$Receive$init, session, slug));
				case 20:
					var _v33 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$MoveNew,
						$author$project$Main$GotMoveNewMsg,
						model,
						$author$project$Page$Moves$Form$init(session));
				case 21:
					var _v34 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$MoveToOriginal,
						$author$project$Main$GotMoveToOriginalMsg,
						model,
						$author$project$Page$Moves$Original$init(session));
				case 33:
					var _v35 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$WipNew,
						$author$project$Main$GotWipNewMsg,
						model,
						$author$project$Page$Wips$Form$init(session));
				case 23:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$MoveView,
						$author$project$Main$GotMoveViewMsg,
						model,
						A2($author$project$Page$Moves$View$init, session, slug));
				case 34:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$WipView,
						$author$project$Main$GotWipViewMsg,
						model,
						A2($author$project$Page$Wips$View$init, session, slug));
				case 29:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$TemplateView,
						$author$project$Main$GotTemplateViewMsg,
						model,
						A2($author$project$Page$Templates$View$init, session, slug));
				case 26:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$WithdrawView,
						$author$project$Main$GotWithdrawViewMsg,
						model,
						A2($author$project$Page$Withdraws$View$init, session, slug));
				case 35:
					var _v36 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$TemplateCal,
						$author$project$Main$GotTemplateCalMsg,
						model,
						$author$project$Page$Templates$Cal$init(session));
				case 24:
					var _v37 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$WithdrawNew,
						$author$project$Main$GotWithdrawNewMsg,
						model,
						$author$project$Page$Withdraws$Form$init(session));
				case 28:
					var _v38 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$TemplateForm($elm$core$Maybe$Nothing),
						$author$project$Main$GotTemplateFormMsg,
						model,
						$author$project$Page$Templates$Form$initNew(session));
				case 30:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$TemplateForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotTemplateFormMsg,
						model,
						A2($author$project$Page$Templates$Form$initUpdate, session, slug));
				default:
					var slug = maybeRoute.a.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$TemplateForm(
							$elm$core$Maybe$Just(slug)),
						$author$project$Main$GotTemplateFormMsg,
						model,
						A2($author$project$Page$Templates$Form$initFork, session, slug));
			}
		}
	});
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {aa: frag, ag: params, U: unvisited, cq: value, ao: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.U;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.cq);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.cq);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.$7),
					$elm$url$Url$Parser$prepareQuery(url.ge),
					url.ff,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$About = {$: 4};
var $author$project$Route$BorrowList = {$: 61};
var $author$project$Route$Categories = {$: 14};
var $author$project$Route$EditCategory = function (a) {
	return {$: 16, a: a};
};
var $author$project$Route$EditItem = function (a) {
	return {$: 9, a: a};
};
var $author$project$Route$EditLocation = function (a) {
	return {$: 12, a: a};
};
var $author$project$Route$EditSupplier = function (a) {
	return {$: 51, a: a};
};
var $author$project$Route$ImageCrop = {$: 40};
var $author$project$Route$ImageList = {$: 36};
var $author$project$Route$ImageNew = {$: 38};
var $author$project$Route$ImageUpdate = function (a) {
	return {$: 39, a: a};
};
var $author$project$Route$ImageView = function (a) {
	return {$: 37, a: a};
};
var $author$project$Route$ImportList = {$: 19};
var $author$project$Route$ImportNew = {$: 17};
var $author$project$Route$ImportPo = {$: 18};
var $author$project$Route$Item = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Items = function (a) {
	return {$: 8, a: a};
};
var $author$project$Route$Location = function (a) {
	return {$: 13, a: a};
};
var $author$project$Route$Locations = {$: 10};
var $author$project$Route$Logout = {$: 3};
var $author$project$Route$MoveList = {$: 22};
var $author$project$Route$MoveNew = {$: 20};
var $author$project$Route$MoveToOriginal = {$: 21};
var $author$project$Route$MoveView = function (a) {
	return {$: 23, a: a};
};
var $author$project$Route$NewArticle = {$: 5};
var $author$project$Route$NewCategory = {$: 15};
var $author$project$Route$NewItem = {$: 6};
var $author$project$Route$NewLocation = {$: 11};
var $author$project$Route$NewSupplier = {$: 50};
var $author$project$Route$Packing = function (a) {
	return {$: 60, a: a};
};
var $author$project$Route$PackingList = {$: 57};
var $author$project$Route$PackingNew = {$: 58};
var $author$project$Route$PackingUpdate = function (a) {
	return {$: 59, a: a};
};
var $author$project$Route$PaymentList = {$: 53};
var $author$project$Route$PaymentNew = {$: 54};
var $author$project$Route$PaymentView = function (a) {
	return {$: 55, a: a};
};
var $author$project$Route$PoConfirmAndChat = function (a) {
	return {$: 48, a: a};
};
var $author$project$Route$PoKnownPrice = {$: 44};
var $author$project$Route$PoKnownPriceUpdate = function (a) {
	return {$: 45, a: a};
};
var $author$project$Route$PoList = {$: 41};
var $author$project$Route$PoNew = {$: 43};
var $author$project$Route$PoReceive = function (a) {
	return {$: 47, a: a};
};
var $author$project$Route$PoSupplierUpdate = function (a) {
	return {$: 56, a: a};
};
var $author$project$Route$PoUpdate = function (a) {
	return {$: 46, a: a};
};
var $author$project$Route$PoView = function (a) {
	return {$: 42, a: a};
};
var $author$project$Route$Supplier = function (a) {
	return {$: 52, a: a};
};
var $author$project$Route$Suppliers = {$: 49};
var $author$project$Route$TemplateCal = {$: 35};
var $author$project$Route$TemplateFork = function (a) {
	return {$: 31, a: a};
};
var $author$project$Route$TemplateList = {$: 27};
var $author$project$Route$TemplateNew = {$: 28};
var $author$project$Route$TemplateUpdate = function (a) {
	return {$: 30, a: a};
};
var $author$project$Route$TemplateView = function (a) {
	return {$: 29, a: a};
};
var $author$project$Route$WipList = {$: 32};
var $author$project$Route$WipNew = {$: 33};
var $author$project$Route$WipView = function (a) {
	return {$: 34, a: a};
};
var $author$project$Route$WithdrawList = {$: 25};
var $author$project$Route$WithdrawNew = {$: 24};
var $author$project$Route$WithdrawView = function (a) {
	return {$: 26, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.ao;
		var unvisited = _v0.U;
		var params = _v0.ag;
		var frag = _v0.aa;
		var value = _v0.cq;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.ao;
			var unvisited = _v1.U;
			var params = _v1.ag;
			var frag = _v1.aa;
			var value = _v1.cq;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.ao;
		var unvisited = _v1.U;
		var params = _v1.ag;
		var frag = _v1.aa;
		var value = _v1.cq;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.ao;
		var unvisited = _v0.U;
		var params = _v0.ag;
		var frag = _v0.aa;
		var value = _v0.cq;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Items$Slug$Slug = $elm$core$Basics$identity;
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.ao;
			var unvisited = _v0.U;
			var params = _v0.ag;
			var frag = _v0.aa;
			var value = _v0.cq;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $author$project$Items$Slug$urlParser = A2(
	$elm$url$Url$Parser$custom,
	'SLUG',
	function (str) {
		return $elm$core$Maybe$Just(str);
	});
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Login,
			$elm$url$Url$Parser$s('login')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Logout,
			$elm$url$Url$Parser$s('logout')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NewArticle,
			$elm$url$Url$Parser$s('editor')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NewItem,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('items'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$EditItem,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('items'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Item,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('items'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Items,
			A2(
				$elm$url$Url$Parser$questionMark,
				$elm$url$Url$Parser$s('items'),
				$elm$url$Url$Parser$Query$string('query'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImageNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('images'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImageCrop,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('images'),
				$elm$url$Url$Parser$s('crop'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImageUpdate,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('images'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImageView,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('images'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImageList,
			$elm$url$Url$Parser$s('images')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoKnownPrice,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				$elm$url$Url$Parser$s('known-price'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoReceive,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('receive')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoUpdate,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoSupplierUpdate,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('supplier')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoConfirmAndChat,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('confirm')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoKnownPriceUpdate,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('known-price')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoView,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('po'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$BorrowList,
			$elm$url$Url$Parser$s('borrows')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PoList,
			$elm$url$Url$Parser$s('po')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NewLocation,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('locations'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$EditLocation,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('locations'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Location,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('locations'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Locations,
			$elm$url$Url$Parser$s('locations')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NewCategory,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('categories'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$EditCategory,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('categories'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Categories,
			$elm$url$Url$Parser$s('categories')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$About,
			$elm$url$Url$Parser$s('about')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PaymentList,
			$elm$url$Url$Parser$s('payments')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PaymentNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('payments'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PaymentView,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('payments'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImportNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('imports'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImportPo,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('imports'),
				$elm$url$Url$Parser$s('po'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ImportList,
			$elm$url$Url$Parser$s('imports')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MoveList,
			$elm$url$Url$Parser$s('moves')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MoveNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('moves'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MoveToOriginal,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('moves'),
				$elm$url$Url$Parser$s('to-original'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MoveView,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('moves'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$WithdrawList,
			$elm$url$Url$Parser$s('withdraws')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$WithdrawNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('withdraws'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$WithdrawView,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('withdraws'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TemplateList,
			$elm$url$Url$Parser$s('templates')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$WipList,
			$elm$url$Url$Parser$s('wips')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TemplateNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('templates'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TemplateCal,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('templates'),
				$elm$url$Url$Parser$s('cal'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TemplateView,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('templates'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TemplateUpdate,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('templates'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$TemplateFork,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('templates'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('fork')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$WipList,
			$elm$url$Url$Parser$s('wips')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$WipNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('wips'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$WipView,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('wips'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NewSupplier,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suppliers'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$EditSupplier,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suppliers'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Supplier,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('suppliers'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Suppliers,
			$elm$url$Url$Parser$s('suppliers')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PackingNew,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('packings'),
				$elm$url$Url$Parser$s('new'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PackingUpdate,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('packings'),
				A2(
					$elm$url$Url$Parser$slash,
					$author$project$Items$Slug$urlParser,
					$elm$url$Url$Parser$s('edit')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Packing,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('packings'),
				$author$project$Items$Slug$urlParser)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$PackingList,
			$elm$url$Url$Parser$s('packings'))
		]));
var $author$project$Route$fromUrl = function (url) {
	return A2($elm$url$Url$Parser$parse, $author$project$Route$parser, url);
};
var $author$project$Session$Guest = function (a) {
	return {$: 1, a: a};
};
var $author$project$Session$LoggedIn = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Session$fromViewer = F2(
	function (key, maybeViewer) {
		if (!maybeViewer.$) {
			var viewerVal = maybeViewer.a;
			return A2($author$project$Session$LoggedIn, key, viewerVal);
		} else {
			return $author$project$Session$Guest(key);
		}
	});
var $author$project$Route$isSecuredRoute = function (url) {
	var _v0 = $author$project$Route$fromUrl(url);
	if (!_v0.$) {
		var route = _v0.a;
		switch (route.$) {
			case 0:
				return true;
			case 1:
				return true;
			case 2:
				return false;
			default:
				return true;
		}
	} else {
		return false;
	}
};
var $author$project$Main$init = F3(
	function (maybeViewer, url, key) {
		var _v0 = _Utils_Tuple2(
			maybeViewer,
			$author$project$Route$isSecuredRoute(url));
		if (!_v0.a.$) {
			var v = _v0.a.a;
			return A2(
				$author$project$Main$changeRouteTo,
				$author$project$Route$fromUrl(url),
				$author$project$Main$Redirect(
					A2($author$project$Session$fromViewer, key, maybeViewer)));
		} else {
			return _Utils_Tuple2(
				$author$project$Main$Redirect(
					A2($author$project$Session$fromViewer, key, maybeViewer)),
				A2(
					$author$project$Route$replaceUrl,
					$author$project$Session$navKey(
						A2($author$project$Session$fromViewer, key, maybeViewer)),
					$author$project$Route$Login));
		}
	});
var $author$project$Main$GotSession = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$decodeFromChange = F2(
	function (viewerDecoder, val) {
		return $elm$core$Result$toMaybe(
			A2(
				$elm$json$Json$Decode$decodeValue,
				$author$project$Api$storageDecoder(viewerDecoder),
				val));
	});
var $author$project$Api$onStoreChange = _Platform_incomingPort('onStoreChange', $elm$json$Json$Decode$value);
var $author$project$Api$viewerChanges = F2(
	function (toMsg, decoder) {
		return $author$project$Api$onStoreChange(
			function (value) {
				return toMsg(
					A2($author$project$Api$decodeFromChange, decoder, value));
			});
	});
var $author$project$Session$changes = F2(
	function (toMsg, key) {
		return A2(
			$author$project$Api$viewerChanges,
			function (maybeViewer) {
				return toMsg(
					A2($author$project$Session$fromViewer, key, maybeViewer));
			},
			$author$project$Viewer$decoder);
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Page$About$GotProgress = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$About$GotSession = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$track = F2(
	function (tracker, toMsg) {
		return $elm$http$Http$subscription(
			A2($elm$http$Http$MySub, tracker, toMsg));
	});
var $author$project$Page$About$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$http$Http$track, 'upload', $author$project$Page$About$GotProgress),
				A2(
				$author$project$Session$changes,
				$author$project$Page$About$GotSession,
				$author$project$Session$navKey(model.aA))
			]));
};
var $author$project$Page$Categories$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Categories$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Categories$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Categories$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Categories$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Categories$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Home$GotSession = $elm$core$Basics$identity;
var $author$project$Page$Home$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$elm$core$Basics$identity,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Images$Crop$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Page$Images$Form$GotProgress = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Images$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Images$Form$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$http$Http$track, 'upload', $author$project$Page$Images$Form$GotProgress),
				A2(
				$author$project$Session$changes,
				$author$project$Page$Images$Form$GotSession,
				$author$project$Session$navKey(model.aA))
			]));
};
var $author$project$Page$Images$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Images$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Images$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Images$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Images$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Images$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Imports$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Imports$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Imports$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Imports$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Imports$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Imports$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Imports$Po$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Imports$Po$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Imports$Po$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Items$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Items$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Items$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Items$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Items$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Items$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Items$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Items$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Items$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Locations$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Locations$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Locations$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Locations$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Locations$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Locations$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Locations$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Locations$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Locations$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Login$GotSession = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Login$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Login$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Moves$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Moves$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Moves$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Moves$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Moves$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Moves$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Moves$Original$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Moves$Original$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Moves$Original$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Moves$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Moves$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Moves$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Packings$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Packings$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Packings$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Packings$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Packings$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Packings$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Packings$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Packings$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Packings$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Payments$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Payments$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Payments$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Payments$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Payments$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Payments$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Payments$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Payments$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Payments$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$PurchaseOrders$Confirm$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$PurchaseOrders$Confirm$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$PurchaseOrders$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$PurchaseOrders$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$PurchaseOrders$KnownPriceForm$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$PurchaseOrders$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$PurchaseOrders$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$PurchaseOrders$Receive$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$PurchaseOrders$Receive$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$PurchaseOrders$Supplier$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$PurchaseOrders$Supplier$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$PurchaseOrders$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$PurchaseOrders$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Suppliers$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Suppliers$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Suppliers$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Suppliers$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Suppliers$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Suppliers$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Suppliers$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Suppliers$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Suppliers$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Templates$Cal$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Templates$Cal$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Templates$Cal$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Templates$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Templates$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Templates$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Templates$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Templates$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Templates$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Templates$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Templates$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Templates$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Wips$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Wips$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Wips$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Wips$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Wips$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Wips$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Wips$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Wips$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Wips$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Withdraws$Borrow$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Withdraws$Borrow$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Withdraws$Borrow$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Withdraws$Form$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Withdraws$Form$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Withdraws$Form$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Withdraws$List$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Withdraws$List$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Withdraws$List$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Page$Withdraws$View$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Withdraws$View$subscriptions = function (model) {
	return A2(
		$author$project$Session$changes,
		$author$project$Page$Withdraws$View$GotSession,
		$author$project$Session$navKey(model.aA));
};
var $author$project$Main$subscriptions = function (model) {
	switch (model.$) {
		case 1:
			return $elm$core$Platform$Sub$none;
		case 0:
			return A2(
				$author$project$Session$changes,
				$author$project$Main$GotSession,
				$author$project$Session$navKey(
					$author$project$Main$toSession(model)));
		case 2:
			var home = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotHomeMsg,
				$author$project$Page$Home$subscriptions(home));
		case 3:
			var login = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotLoginMsg,
				$author$project$Page$Login$subscriptions(login));
		case 4:
			var about = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotAboutMsg,
				$author$project$Page$About$subscriptions(about));
		case 5:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotItemFormMsg,
				$author$project$Page$Items$Form$subscriptions(item));
		case 6:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotItemMsg,
				$author$project$Page$Items$View$subscriptions(item));
		case 7:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotItemListMsg,
				$author$project$Page$Items$List$subscriptions(item));
		case 8:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotImageFormMsg,
				$author$project$Page$Images$Form$subscriptions(item));
		case 9:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotImageViewMsg,
				$author$project$Page$Images$View$subscriptions(item));
		case 10:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotImageListMsg,
				$author$project$Page$Images$List$subscriptions(item));
		case 11:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPoFormMsg,
				$author$project$Page$PurchaseOrders$Form$subscriptions(item));
		case 12:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPoKnownPriceFormMsg,
				$author$project$Page$PurchaseOrders$KnownPriceForm$subscriptions(item));
		case 13:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPoSupplierUpdateMsg,
				$author$project$Page$PurchaseOrders$Supplier$subscriptions(item));
		case 14:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPoConfirmAndChatMsg,
				$author$project$Page$PurchaseOrders$Confirm$subscriptions(item));
		case 15:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPoViewMsg,
				$author$project$Page$PurchaseOrders$View$subscriptions(item));
		case 16:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPoReceiveMsg,
				$author$project$Page$PurchaseOrders$Receive$subscriptions(item));
		case 46:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotBorrowListMsg,
				$author$project$Page$Withdraws$Borrow$subscriptions(item));
		case 17:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPoListMsg,
				$author$project$Page$PurchaseOrders$List$subscriptions(item));
		case 19:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotLocationFormMsg,
				$author$project$Page$Locations$Form$subscriptions(item));
		case 20:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotLocationMsg,
				$author$project$Page$Locations$View$subscriptions(item));
		case 21:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotLocationListMsg,
				$author$project$Page$Locations$List$subscriptions(item));
		case 25:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotSupplierFormMsg,
				$author$project$Page$Suppliers$Form$subscriptions(item));
		case 26:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotSupplierMsg,
				$author$project$Page$Suppliers$View$subscriptions(item));
		case 27:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotSupplierListMsg,
				$author$project$Page$Suppliers$List$subscriptions(item));
		case 22:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPaymentFormMsg,
				$author$project$Page$Payments$Form$subscriptions(item));
		case 23:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPaymentMsg,
				$author$project$Page$Payments$View$subscriptions(item));
		case 24:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPaymentListMsg,
				$author$project$Page$Payments$List$subscriptions(item));
		case 28:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPackingFormMsg,
				$author$project$Page$Packings$Form$subscriptions(item));
		case 29:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPackingViewMsg,
				$author$project$Page$Packings$View$subscriptions(item));
		case 30:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotPackingListMsg,
				$author$project$Page$Packings$List$subscriptions(item));
		case 31:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotCategoryFormMsg,
				$author$project$Page$Categories$Form$subscriptions(item));
		case 32:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotCategoryListMsg,
				$author$project$Page$Categories$List$subscriptions(item));
		case 33:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotImportNewMsg,
				$author$project$Page$Imports$Form$subscriptions(item));
		case 34:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotImportPoMsg,
				$author$project$Page$Imports$Po$subscriptions(item));
		case 35:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotImportListMsg,
				$author$project$Page$Imports$List$subscriptions(item));
		case 38:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotMoveNewMsg,
				$author$project$Page$Moves$Form$subscriptions(item));
		case 39:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotMoveToOriginalMsg,
				$author$project$Page$Moves$Original$subscriptions(item));
		case 40:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotWithdrawNewMsg,
				$author$project$Page$Withdraws$Form$subscriptions(item));
		case 44:
			var slug = model.a;
			var item = model.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotTemplateFormMsg,
				$author$project$Page$Templates$Form$subscriptions(item));
		case 48:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotWipNewMsg,
				$author$project$Page$Wips$Form$subscriptions(item));
		case 36:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotMoveListMsg,
				$author$project$Page$Moves$List$subscriptions(item));
		case 41:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotMoveViewMsg,
				$author$project$Page$Moves$View$subscriptions(item));
		case 45:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotTemplateViewMsg,
				$author$project$Page$Templates$View$subscriptions(item));
		case 42:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotWithdrawViewMsg,
				$author$project$Page$Withdraws$View$subscriptions(item));
		case 49:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotWipViewMsg,
				$author$project$Page$Wips$View$subscriptions(item));
		case 37:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotWithdrawListMsg,
				$author$project$Page$Withdraws$List$subscriptions(item));
		case 43:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotTemplateListMsg,
				$author$project$Page$Templates$List$subscriptions(item));
		case 47:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotWipListMsg,
				$author$project$Page$Wips$List$subscriptions(item));
		case 50:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotTemplateCalMsg,
				$author$project$Page$Templates$Cal$subscriptions(item));
		default:
			var item = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotImageCropMsg,
				$author$project$Page$Images$Crop$subscriptions(item));
	}
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.dw;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.ff,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.ge,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.ds,
					_Utils_ap(http, url.cW)),
				url.$7)));
};
var $author$project$Page$About$Done = {$: 2};
var $pablen$toasty$Toasty$Defaults$Error = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$About$Fail = {$: 3};
var $author$project$Page$About$GotFile = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$About$GotPreview = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$About$GotPreviews = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$About$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$About$ToastyMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$About$Uploaded = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$About$Uploading = function (a) {
	return {$: 1, a: a};
};
var $pablen$toasty$Toasty$Temporary = 0;
var $pablen$toasty$Toasty$Entered = 0;
var $pablen$toasty$Toasty$TransitionOut = function (a) {
	return {$: 2, a: a};
};
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$random$Random$maxInt = 2147483647;
var $elm$random$Random$minInt = -2147483648;
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $pablen$toasty$Toasty$getNewId = function (seed) {
	return A2(
		$elm$random$Random$step,
		A2($elm$random$Random$int, $elm$random$Random$minInt, $elm$random$Random$maxInt),
		seed);
};
var $elm$core$Process$sleep = _Process_sleep;
var $pablen$toasty$Toasty$addToast_ = F5(
	function (removeBehaviour, _v0, tagger, toast, _v1) {
		var cfg = _v0;
		var model = _v1.a;
		var cmd = _v1.b;
		var _v2 = model.cl;
		var toasts = _v2.a;
		var seed = _v2.b;
		var _v3 = $pablen$toasty$Toasty$getNewId(seed);
		var newId = _v3.a;
		var newSeed = _v3.b;
		var task = function () {
			if (!removeBehaviour) {
				return A2(
					$elm$core$Task$perform,
					function (_v5) {
						return tagger(
							$pablen$toasty$Toasty$TransitionOut(newId));
					},
					$elm$core$Process$sleep(cfg.bW));
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					cl: A2(
						$pablen$toasty$Toasty$Stack,
						_Utils_ap(
							toasts,
							_List_fromArray(
								[
									_Utils_Tuple3(newId, 0, toast)
								])),
						newSeed)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, task])));
	});
var $pablen$toasty$Toasty$addToast = $pablen$toasty$Toasty$addToast_(0);
var $pablen$toasty$Toasty$Config = $elm$core$Basics$identity;
var $pablen$toasty$Toasty$config = {bT: _List_Nil, bW: 5000, b3: _List_Nil, cm: _List_Nil, cn: _List_Nil, co: 0};
var $pablen$toasty$Toasty$containerAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{bT: attrs});
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $pablen$toasty$Toasty$Defaults$containerAttrs = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'right', '0'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'max-width', '300px'),
		A2($elm$html$Html$Attributes$style, 'list-style-type', 'none'),
		A2($elm$html$Html$Attributes$style, 'padding', '0'),
		A2($elm$html$Html$Attributes$style, 'margin', '0')
	]);
var $pablen$toasty$Toasty$delay = F2(
	function (time, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{bW: time});
	});
var $pablen$toasty$Toasty$itemAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{b3: attrs});
	});
var $pablen$toasty$Toasty$Defaults$itemAttrs = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'margin', '1em 1em 0 1em'),
		A2($elm$html$Html$Attributes$style, 'max-height', '100px'),
		A2($elm$html$Html$Attributes$style, 'transition', 'max-height 0.6s, margin-top 0.6s')
	]);
var $pablen$toasty$Toasty$transitionInAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{cm: attrs});
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $pablen$toasty$Toasty$Defaults$transitionInAttrs = _List_fromArray(
	[
		$elm$html$Html$Attributes$class('animated bounceInRight')
	]);
var $pablen$toasty$Toasty$transitionOutAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{cn: attrs});
	});
var $pablen$toasty$Toasty$Defaults$transitionOutAttrs = _List_fromArray(
	[
		$elm$html$Html$Attributes$class('animated fadeOutRightBig'),
		A2($elm$html$Html$Attributes$style, 'max-height', '0'),
		A2($elm$html$Html$Attributes$style, 'margin-top', '0')
	]);
var $pablen$toasty$Toasty$transitionOutDuration = F2(
	function (time, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{co: time});
	});
var $pablen$toasty$Toasty$Defaults$config = A2(
	$pablen$toasty$Toasty$delay,
	5000,
	A2(
		$pablen$toasty$Toasty$itemAttrs,
		$pablen$toasty$Toasty$Defaults$itemAttrs,
		A2(
			$pablen$toasty$Toasty$containerAttrs,
			$pablen$toasty$Toasty$Defaults$containerAttrs,
			A2(
				$pablen$toasty$Toasty$transitionInAttrs,
				$pablen$toasty$Toasty$Defaults$transitionInAttrs,
				A2(
					$pablen$toasty$Toasty$transitionOutAttrs,
					$pablen$toasty$Toasty$Defaults$transitionOutAttrs,
					A2($pablen$toasty$Toasty$transitionOutDuration, 700, $pablen$toasty$Toasty$config))))));
var $author$project$Page$About$myConfig = A2($pablen$toasty$Toasty$delay, 5000, $pablen$toasty$Toasty$Defaults$config);
var $author$project$Page$About$addToast = F2(
	function (toast, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A4(
			$pablen$toasty$Toasty$addToast,
			$author$project$Page$About$myConfig,
			$author$project$Page$About$ToastyMsg,
			toast,
			_Utils_Tuple2(model, cmd));
	});
var $elm$http$Http$cancel = function (tracker) {
	return $elm$http$Http$command(
		$elm$http$Http$Cancel(tracker));
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $elm$http$Http$filePart = _Http_pair;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$http$Http$fractionSent = function (p) {
	return (!p.gx) ? 1 : A3($elm$core$Basics$clamp, 0, 1, p.gr / p.gx);
};
var $elm$http$Http$multipartBody = function (parts) {
	return A2(
		_Http_pair,
		'',
		_Http_toFormData(parts));
};
var $elm$file$File$toUrl = _File_toUrl;
var $pablen$toasty$Toasty$Leaving = 1;
var $pablen$toasty$Toasty$Remove = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $pablen$toasty$Toasty$update = F4(
	function (_v0, tagger, msg, model) {
		var cfg = _v0;
		var _v1 = model.cl;
		var toasts = _v1.a;
		var seed = _v1.b;
		switch (msg.$) {
			case 0:
				var toast = msg.a;
				return A4(
					$pablen$toasty$Toasty$addToast,
					$pablen$toasty$Toasty$config,
					tagger,
					toast,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
			case 1:
				var targetId = msg.a;
				var newStack = A2(
					$elm$core$List$filter,
					function (_v3) {
						var id = _v3.a;
						var toast = _v3.b;
						var status = _v3.c;
						return !_Utils_eq(id, targetId);
					},
					toasts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cl: A2($pablen$toasty$Toasty$Stack, newStack, seed)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var targetId = msg.a;
				var newStack = A2(
					$elm$core$List$map,
					function (_v5) {
						var id = _v5.a;
						var status = _v5.b;
						var toast = _v5.c;
						return _Utils_eq(id, targetId) ? _Utils_Tuple3(id, 1, toast) : _Utils_Tuple3(id, status, toast);
					},
					toasts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cl: A2($pablen$toasty$Toasty$Stack, newStack, seed)
						}),
					A2(
						$elm$core$Task$perform,
						function (_v4) {
							return tagger(
								$pablen$toasty$Toasty$Remove(targetId));
						},
						$elm$core$Process$sleep(cfg.co)));
		}
	});
var $author$project$Api$Endpoint$upload = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['upload']),
	_List_Nil);
var $author$project$Page$About$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bg: model.bg + 1, ak: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var confirmed = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ak: false}),
					$elm$core$Platform$Cmd$none);
			case 3:
				if (msg.a === 'error') {
					return A2(
						$author$project$Page$About$addToast,
						A2($pablen$toasty$Toasty$Defaults$Error, 'Error', 'Sorry, something went wrong...'),
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var subMsg = msg.a;
				return A4($pablen$toasty$Toasty$update, $author$project$Page$About$myConfig, $author$project$Page$About$ToastyMsg, subMsg, model);
			case 5:
				var dimensions = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b1: $elm$core$Maybe$Just(dimensions)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var progress = msg.a;
				if (!progress.$) {
					var p = progress.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								am: $author$project$Page$About$Uploading(
									$elm$http$Http$fractionSent(p))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var result = msg.a;
				if (!result.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{am: $author$project$Page$About$Done}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{am: $author$project$Page$About$Fail}),
						$elm$core$Platform$Cmd$none);
				}
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: $author$project$Page$About$Waiting}),
					$elm$http$Http$cancel('upload'));
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ak: true}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var confirmed = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ak: false}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['image/*']),
						$author$project$Page$About$GotFile));
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: true}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: false}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var file = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bl: $author$project$Page$About$Loaded(file),
							ac: false
						}),
					A2(
						$elm$core$Task$perform,
						$author$project$Page$About$GotPreview,
						$elm$file$File$toUrl(file)));
			case 18:
				var _v3 = model.bl;
				if (_v3.$ === 1) {
					var file = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								am: $author$project$Page$About$Uploading(0)
							}),
						$elm$http$Http$request(
							{
								a7: $elm$http$Http$multipartBody(
									_List_fromArray(
										[
											A2($elm$http$Http$filePart, 'files[]', file)
										])),
								bk: $elm$http$Http$expectWhatever($author$project$Page$About$Uploaded),
								bn: _List_Nil,
								bp: 'POST',
								bJ: $elm$core$Maybe$Nothing,
								g9: $elm$core$Maybe$Just('upload'),
								bL: $author$project$Api$Endpoint$unwrap($author$project$Api$Endpoint$upload)
							}));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 14:
				var file = msg.a;
				var files = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ac: false}),
					A2(
						$elm$core$Task$perform,
						$author$project$Page$About$GotPreviews,
						$elm$core$Task$sequence(
							A2(
								$elm$core$List$map,
								$elm$file$File$toUrl,
								A2($elm$core$List$cons, file, files)))));
			case 15:
				var urls = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aY: urls}),
					$elm$core$Platform$Cmd$none);
			default:
				var url = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aY: _List_fromArray(
								[url])
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Categories$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $author$project$Page$Categories$Form$CompletedCreate = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Categories$Form$CompletedEdit = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Categories$Form$Creating = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Categories$Form$Saving = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Api$post = F5(
	function (url, maybeCred, body, expectMsg, decoder) {
		return $author$project$Api$Endpoint$request(
			{
				a7: body,
				bk: A2($elm$http$Http$expectJson, expectMsg, decoder),
				bn: function () {
					if (!maybeCred.$) {
						var cred = maybeCred.a;
						return _List_fromArray(
							[
								$author$project$Api$credHeader(cred)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				bp: 'POST',
				bJ: $elm$core$Maybe$Nothing,
				bL: url
			});
	});
var $author$project$Page$Categories$Form$create = F3(
	function (_v0, cred, toMsg) {
		var form = _v0;
		var article = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(form.g5))
				]));
		var body = $elm$http$Http$jsonBody(article);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$categories(_List_Nil),
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$author$project$Category$fullDecoder);
	});
var $author$project$Api$put = F5(
	function (url, cred, body, expectMsg, decoder) {
		return $author$project$Api$Endpoint$request(
			{
				a7: body,
				bk: A2($elm$http$Http$expectJson, expectMsg, decoder),
				bn: _List_fromArray(
					[
						$author$project$Api$credHeader(cred)
					]),
				bp: 'PUT',
				bJ: $elm$core$Maybe$Nothing,
				bL: url
			});
	});
var $author$project$Page$Categories$Form$edit = F4(
	function (articleSlug, _v0, cred, toMsg) {
		var form = _v0;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(form.g5))
					])));
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$category(articleSlug),
			cred,
			body,
			toMsg,
			$author$project$Category$fullDecoder);
	});
var $author$project$Page$Categories$Form$Title = 0;
var $author$project$Page$Categories$Form$fieldsToValidate = _List_fromArray(
	[0]);
var $author$project$Page$Categories$Form$Trimmed = $elm$core$Basics$identity;
var $elm$core$String$trim = _String_trim;
var $author$project$Page$Categories$Form$trimFields = function (form) {
	return {
		g5: $elm$core$String$trim(form.g5)
	};
};
var $author$project$Page$Categories$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Categories$Form$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Categories$Form$InvalidEntry(field),
			$elm$core$String$isEmpty(form.g5) ? _List_fromArray(
				['title can\'t be blank.']) : _List_Nil);
	});
var $author$project$Page$Categories$Form$validate = function (form) {
	var trimmedForm = $author$project$Page$Categories$Form$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Categories$Form$validateField(trimmedForm),
		$author$project$Page$Categories$Form$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Categories$Form$save = F2(
	function (cred, status) {
		switch (status.$) {
			case 4:
				var slug = status.a;
				var form = status.c;
				var _v1 = $author$project$Page$Categories$Form$validate(form);
				if (!_v1.$) {
					var validForm = _v1.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Categories$Form$Saving, slug, form),
						A4($author$project$Page$Categories$Form$edit, slug, validForm, cred, $author$project$Page$Categories$Form$CompletedEdit));
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						A3($author$project$Page$Categories$Form$Editing, slug, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var form = status.b;
				var _v2 = $author$project$Page$Categories$Form$validate(form);
				if (!_v2.$) {
					var validForm = _v2.a;
					return _Utils_Tuple2(
						$author$project$Page$Categories$Form$Creating(form),
						A3($author$project$Page$Categories$Form$create, validForm, cred, $author$project$Page$Categories$Form$CompletedCreate));
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Categories$Form$EditingNew, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(status, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Categories$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Categories$Form$savingError = F2(
	function (error, status) {
		var problems = _List_fromArray(
			[
				$author$project$Page$Categories$Form$ServerError('Error saving article')
			]);
		switch (status.$) {
			case 3:
				var slug = status.a;
				var form = status.b;
				return A3($author$project$Page$Categories$Form$Editing, slug, problems, form);
			case 6:
				var form = status.a;
				return A2($author$project$Page$Categories$Form$EditingNew, problems, form);
			default:
				return status;
		}
	});
var $author$project$Items$Slug$idToSlug = function (id) {
	return $elm$core$String$fromInt(id);
};
var $author$project$Category$slug = function (item) {
	return $author$project$Items$Slug$idToSlug(item.cY);
};
var $author$project$Page$Categories$Form$updateForm = F2(
	function (transform, model) {
		var newModel = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return model;
				case 1:
					return model;
				case 2:
					return model;
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Categories$Form$Saving,
								slug,
								transform(form))
						});
				case 4:
					var slug = _v0.a;
					var errors = _v0.b;
					var form = _v0.c;
					return _Utils_update(
						model,
						{
							dL: A3(
								$author$project$Page$Categories$Form$Editing,
								slug,
								errors,
								transform(form))
						});
				case 5:
					var errors = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Categories$Form$EditingNew,
								errors,
								transform(form))
						});
				default:
					var form = _v0.a;
					return _Utils_update(
						model,
						{
							dL: $author$project$Page$Categories$Form$Creating(
								transform(form))
						});
			}
		}();
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Categories$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 2:
				var title = msg.a;
				return A2(
					$author$project$Page$Categories$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{g5: title});
					},
					model);
			case 1:
				var cred = msg.a;
				return A2(
					$elm$core$Tuple$mapFirst,
					function (status) {
						return _Utils_update(
							model,
							{dL: status});
					},
					A2($author$project$Page$Categories$Form$save, cred, model.dL));
			case 4:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Categories$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Categories));
				}
			case 3:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Categories$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Categories));
				}
			default:
				if (!msg.a.$) {
					var item = msg.a.a;
					var status = A3(
						$author$project$Page$Categories$Form$Editing,
						$author$project$Category$slug(item),
						_List_Nil,
						{g5: item.fU});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dL: status}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Categories$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Categories$List$Failed = {$: 3};
var $author$project$Page$Categories$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$addServerError = function (list) {
	return A2($elm$core$List$cons, 'Server error', list);
};
var $author$project$Api$delete = F5(
	function (url, cred, body, expectMsg, decoder) {
		return $author$project$Api$Endpoint$request(
			{
				a7: body,
				bk: A2($elm$http$Http$expectJson, expectMsg, decoder),
				bn: _List_fromArray(
					[
						$author$project$Api$credHeader(cred)
					]),
				bp: 'DELETE',
				bJ: $elm$core$Maybe$Nothing,
				bL: url
			});
	});
var $author$project$Page$Categories$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$category(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Api$intercept401 = F2(
	function (key, error) {
		var mustReLogin = function () {
			if (error.$ === 3) {
				var status = error.a;
				return status === 401;
			} else {
				return false;
			}
		}();
		return mustReLogin ? A2($author$project$Route$replaceUrl, key, $author$project$Route$Logout) : $elm$core$Platform$Cmd$none;
	});
var $author$project$Page$Categories$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Home));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Categories$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Categories$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Categories$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Categories$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Categories$List$delete, slug, cred, $author$project$Page$Categories$List$CompletedDeleteItem));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Categories$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
		}
	});
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		var session = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aA: session}),
			A2(
				$author$project$Route$replaceUrl,
				$author$project$Session$navKey(session),
				$author$project$Route$Login));
	});
var $mikaxyz$elm_cropper$Cropper$Helper$zoomImage = F2(
	function (model, zoom) {
		return _Utils_update(
			model,
			{
				hl: A3($elm$core$Basics$clamp, 0.0, 1.0, zoom)
			});
	});
var $mikaxyz$elm_cropper$Cropper$zoom = $mikaxyz$elm_cropper$Cropper$Helper$zoomImage;
var $author$project$Page$Images$Crop$update = F2(
	function (msg, model) {
		var zoom = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					as: A2(
						$mikaxyz$elm_cropper$Cropper$zoom,
						model.as,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toFloat(zoom)))
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Images$Form$Done = {$: 2};
var $author$project$Page$Images$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Images$Form$Fail = {$: 3};
var $author$project$Page$Images$Form$GotFile = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Images$Form$GotPreview = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Images$Form$Loaded = function (a) {
	return {$: 1, a: a};
};
var $pablen$toasty$Toasty$Defaults$Success = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Images$Form$ToastyMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Images$Form$Uploaded = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Images$Form$Uploading = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Images$Form$myConfig = A2($pablen$toasty$Toasty$delay, 5000, $pablen$toasty$Toasty$Defaults$config);
var $author$project$Page$Images$Form$addToast = F2(
	function (toast, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A4(
			$pablen$toasty$Toasty$addToast,
			$author$project$Page$Images$Form$myConfig,
			$author$project$Page$Images$Form$ToastyMsg,
			toast,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$Image$UploadInfo = F2(
	function (path_full, path_tn) {
		return {dp: path_full, dq: path_tn};
	});
var $author$project$Image$nameListDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'path_tn',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'path_full',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Image$UploadInfo)));
var $author$project$Page$Images$Form$CompletedCreate = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Images$Form$CompletedEdit = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Images$Form$Creating = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Images$Form$Saving = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Page$Images$Form$create = F3(
	function (_v0, cred, toMsg) {
		var form = _v0;
		var article = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(form.g5)),
					_Utils_Tuple2(
					'path_full',
					$elm$json$Json$Encode$string(form.dp)),
					_Utils_Tuple2(
					'path_tn',
					$elm$json$Json$Encode$string(form.dq)),
					_Utils_Tuple2(
					'category_id',
					$elm$json$Json$Encode$int(form.eC))
				]));
		var body = $elm$http$Http$jsonBody(article);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$images(_List_Nil),
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$author$project$Image$fullDecoder);
	});
var $author$project$Page$Images$Form$edit = F4(
	function (articleSlug, _v0, cred, toMsg) {
		var form = _v0;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'title',
						$elm$json$Json$Encode$string(form.g5)),
						_Utils_Tuple2(
						'category_id',
						$elm$json$Json$Encode$int(form.eC)),
						_Utils_Tuple2(
						'path_tn',
						$elm$json$Json$Encode$string(form.dq)),
						_Utils_Tuple2(
						'path_full',
						$elm$json$Json$Encode$string(form.dp))
					])));
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$item(articleSlug),
			cred,
			body,
			toMsg,
			$author$project$Item$fullDecoder);
	});
var $author$project$Page$Images$Form$Category = 1;
var $author$project$Page$Images$Form$ImagePath = 2;
var $author$project$Page$Images$Form$Title = 0;
var $author$project$Page$Images$Form$fieldsToValidate = _List_fromArray(
	[0, 1, 2]);
var $author$project$Page$Images$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Images$Form$trimFields = function (form) {
	return {
		eC: form.eC,
		dp: $elm$core$String$trim(form.dp),
		dq: $elm$core$String$trim(form.dq),
		g5: $elm$core$String$trim(form.g5)
	};
};
var $author$project$Page$Images$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Images$Form$validateField = F3(
	function (model, _v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Images$Form$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return $elm$core$String$isEmpty(form.g5) ? _List_fromArray(
							['title can\'t be blank.']) : _List_Nil;
					case 1:
						return (!form.eC) ? _List_fromArray(
							['Please select Category']) : _List_Nil;
					default:
						var _v2 = model.bl;
						if (!_v2.$) {
							return _List_fromArray(
								['Please select Image']);
						} else {
							return _List_Nil;
						}
				}
			}());
	});
var $author$project$Page$Images$Form$validate = F2(
	function (form, model) {
		var trimmedForm = $author$project$Page$Images$Form$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A2($author$project$Page$Images$Form$validateField, model, trimmedForm),
			$author$project$Page$Images$Form$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$Images$Form$save = F3(
	function (model, cred, status) {
		switch (status.$) {
			case 4:
				var slug = status.a;
				var form = status.c;
				var _v1 = A2($author$project$Page$Images$Form$validate, form, model);
				if (!_v1.$) {
					var validForm = _v1.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Images$Form$Saving, slug, form),
						A4($author$project$Page$Images$Form$edit, slug, validForm, cred, $author$project$Page$Images$Form$CompletedEdit));
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						A3($author$project$Page$Images$Form$Editing, slug, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var form = status.b;
				var _v2 = A2($author$project$Page$Images$Form$validate, form, model);
				if (!_v2.$) {
					var validForm = _v2.a;
					return _Utils_Tuple2(
						$author$project$Page$Images$Form$Creating(form),
						A3($author$project$Page$Images$Form$create, validForm, cred, $author$project$Page$Images$Form$CompletedCreate));
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Images$Form$EditingNew, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(status, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Images$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Images$Form$savingError = F2(
	function (error, status) {
		var problems = _List_fromArray(
			[
				$author$project$Page$Images$Form$ServerError('Error saving article')
			]);
		switch (status.$) {
			case 3:
				var slug = status.a;
				var form = status.b;
				return A3($author$project$Page$Images$Form$Editing, slug, problems, form);
			case 6:
				var form = status.a;
				return A2($author$project$Page$Images$Form$EditingNew, problems, form);
			default:
				return status;
		}
	});
var $author$project$Item$slug = function (item) {
	return $author$project$Items$Slug$idToSlug(item.cY);
};
var $author$project$Page$Images$Form$updateForm = F2(
	function (transform, model) {
		var newModel = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return model;
				case 1:
					return model;
				case 2:
					return model;
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Images$Form$Saving,
								slug,
								transform(form))
						});
				case 4:
					var slug = _v0.a;
					var errors = _v0.b;
					var form = _v0.c;
					return _Utils_update(
						model,
						{
							dL: A3(
								$author$project$Page$Images$Form$Editing,
								slug,
								errors,
								transform(form))
						});
				case 5:
					var errors = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Images$Form$EditingNew,
								errors,
								transform(form))
						});
				default:
					var form = _v0.a;
					return _Utils_update(
						model,
						{
							dL: $author$project$Page$Images$Form$Creating(
								transform(form))
						});
			}
		}();
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Images$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 10:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['image/*']),
						$author$project$Page$Images$Form$GotFile));
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bl: $author$project$Page$Images$Form$LoadingImage, ah: ''}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var file = msg.a;
				var _v1 = model.dL;
				if (_v1.$ === 5) {
					var problems = _v1.a;
					var form = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bl: $author$project$Page$Images$Form$Loaded(file),
								dL: A2($author$project$Page$Images$Form$EditingNew, _List_Nil, form)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Task$perform,
									$author$project$Page$Images$Form$GotPreview,
									$elm$file$File$toUrl(file))
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 13:
				var url = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ah: url}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var progress = msg.a;
				if (!progress.$) {
					var p = progress.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								am: $author$project$Page$Images$Form$Uploading(
									$elm$http$Http$fractionSent(p))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 2:
				var title = msg.a;
				return A2(
					$author$project$Page$Images$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{g5: title});
					},
					model);
			case 3:
				var category_id_str = msg.a;
				var _v3 = $elm$core$String$toInt(category_id_str);
				if (!_v3.$) {
					var category_id = _v3.a;
					return A2(
						$author$project$Page$Images$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{eC: category_id});
						},
						model);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var cred = msg.a;
				var _v4 = _Utils_Tuple2(model.bl, model.dL);
				if (_v4.b.$ === 5) {
					if (_v4.a.$ === 1) {
						var file = _v4.a.a;
						var _v5 = _v4.b;
						var form = _v5.b;
						var _v6 = A2($author$project$Page$Images$Form$validate, form, model);
						if (!_v6.$) {
							var validForm = _v6.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										am: $author$project$Page$Images$Form$Uploading(0)
									}),
								$elm$http$Http$request(
									{
										a7: $elm$http$Http$multipartBody(
											_List_fromArray(
												[
													A2($elm$http$Http$filePart, 'files[]', file)
												])),
										bk: A2($elm$http$Http$expectJson, $author$project$Page$Images$Form$Uploaded, $author$project$Image$nameListDecoder),
										bn: _List_Nil,
										bp: 'POST',
										bJ: $elm$core$Maybe$Nothing,
										g9: $elm$core$Maybe$Just('upload'),
										bL: $author$project$Api$Endpoint$unwrap($author$project$Api$Endpoint$upload)
									}));
						} else {
							var problems = _v6.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										dL: A2($author$project$Page$Images$Form$EditingNew, problems, form)
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						var _v7 = _v4.b;
						var form = _v7.b;
						var _v8 = A2($author$project$Page$Images$Form$validate, form, model);
						if (_v8.$ === 1) {
							var problems = _v8.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										dL: A2($author$project$Page$Images$Form$EditingNew, problems, form)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							var validForm = _v8.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										dL: A2($author$project$Page$Images$Form$EditingNew, _List_Nil, form)
									}),
								$elm$core$Platform$Cmd$none);
						}
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 14:
				if (!msg.a.$) {
					var listStr = msg.a.a;
					var _v9 = A2(
						$author$project$Page$Images$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{dp: listStr.dp, dq: listStr.dq});
						},
						_Utils_update(
							model,
							{am: $author$project$Page$Images$Form$Done}));
					var newModel = _v9.a;
					var newCmd = _v9.b;
					var _v10 = $author$project$Session$cred(model.aA);
					if (!_v10.$) {
						var cred = _v10.a;
						return A2(
							$elm$core$Tuple$mapFirst,
							function (status) {
								return _Utils_update(
									newModel,
									{dL: status});
							},
							A3($author$project$Page$Images$Form$save, newModel, cred, newModel.dL));
					} else {
						return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					}
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{am: $author$project$Page$Images$Form$Fail}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Images$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return model._ ? A2(
						$author$project$Page$Images$Form$addToast,
						A2(
							$pablen$toasty$Toasty$Defaults$Success,
							'Save Success',
							'Item ID: ' + $elm$core$String$fromInt(item.cY)),
						A2(
							$author$project$Page$Images$Form$updateForm,
							function (form) {
								return _Utils_update(
									form,
									{g5: ''});
							},
							_Utils_update(
								model,
								{
									dL: A2(
										$author$project$Page$Images$Form$EditingNew,
										_List_Nil,
										{eC: item.eC, dp: '', dq: '', g5: ''})
								}))) : _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$ImageView(
								$author$project$Items$Slug$idToSlug(item.cY))));
				}
			case 4:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Images$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Item(
								$author$project$Item$slug(item))));
				}
			case 6:
				if (!msg.a.$) {
					var item = msg.a.a;
					var status = A3(
						$author$project$Page$Images$Form$Editing,
						$author$project$Item$slug(item),
						_List_Nil,
						{eC: item.eC, dp: '', dq: '', g5: item.g5});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dL: status}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				if (!msg.a.$) {
					var itemList = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aK: itemList}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{_: !model._}),
					$elm$core$Platform$Cmd$none);
			default:
				var subMsg = msg.a;
				return A4($pablen$toasty$Toasty$update, $author$project$Page$Images$Form$myConfig, $author$project$Page$Images$Form$ToastyMsg, subMsg, model);
		}
	});
var $author$project$Page$Images$List$Failed = {$: 3};
var $author$project$Page$Images$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Images$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Images$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Images$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aK: $author$project$Page$Images$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aK: $author$project$Page$Images$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A4($author$project$Page$Images$List$fetchFeed, model.aA, page, model.ai, model.aZ));
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1, aR: $author$project$Page$Images$List$Loading}),
					A4($author$project$Page$Images$List$fetchFeed, model.aA, 1, model.ai, model.aZ));
			case 6:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			default:
				var val = msg.a;
				var maybeInt = $elm$core$String$toInt(val);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aR: $author$project$Page$Images$List$Loaded(
								A2($author$project$PaginatedList$fromList, 0, _List_Nil)),
							aZ: maybeInt
						}),
					A4($author$project$Page$Images$List$fetchFeed, model.aA, 1, model.ai, maybeInt));
		}
	});
var $author$project$Page$Images$View$CompletedDeleteItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Images$View$Failed = {$: 3};
var $author$project$Page$Images$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Images$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$image(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Log$error = $elm$core$Platform$Cmd$none;
var $author$project$Page$Images$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Images$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Images$View$Failed}),
						$author$project$Log$error);
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$ImageList));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 3:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: true}),
					$elm$core$Platform$Cmd$none);
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3($author$project$Page$Images$View$delete, model.bG, cred, $author$project$Page$Images$View$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Imports$Form$CompletedImportItem = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Imports$Form$Failed = {$: 3};
var $author$project$Page$Imports$Form$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Imports$Form$LoadedLocation = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Imports$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Imports$Form$ToastyMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Imports$Form$myConfig = A2($pablen$toasty$Toasty$delay, 5000, $pablen$toasty$Toasty$Defaults$config);
var $author$project$Page$Imports$Form$addToast = F2(
	function (toast, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A4(
			$pablen$toasty$Toasty$addToast,
			$author$project$Page$Imports$Form$myConfig,
			$author$project$Page$Imports$Form$ToastyMsg,
			toast,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$Page$Imports$Form$CompletedLoadItems = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Imports$Form$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchAll, 20, maybeCred, params, $author$project$Page$Imports$Form$CompletedLoadItems);
	});
var $author$project$Page$Imports$Form$CompletedLoadLocations = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Imports$Form$fetchFeedLocation = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Location$fetchAll, maybeCred, params, $author$project$Page$Imports$Form$CompletedLoadLocations);
	});
var $author$project$Page$Imports$Form$CompletedLoadGuideLocation = function (a) {
	return {$: 16, a: a};
};
var $author$project$Api$Endpoint$locationGuide = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'items',
				$author$project$Items$Slug$toString(slug),
				'guide_location'
			]),
		_List_Nil);
};
var $author$project$Location$fetchGuideLocation = F3(
	function (maybeCred, locationSlug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$locationGuide(locationSlug),
			maybeCred,
			expectMsg,
			$elm$json$Json$Decode$list($author$project$Location$fullDecoder));
	});
var $author$project$Page$Imports$Form$fetchGuideLocation = F3(
	function (session, slug, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			model,
			A3($author$project$Location$fetchGuideLocation, maybeCred, slug, $author$project$Page$Imports$Form$CompletedLoadGuideLocation));
	});
var $author$project$Page$Imports$Form$itemImport = F3(
	function (maybeCred, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(form.gd)),
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(form.fB)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(form.P))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$movementImports(_List_Nil),
			maybeCred,
			body,
			toMsg,
			$author$project$Stock$fullDecoder);
	});
var $author$project$Page$Imports$Form$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Imports$Form$ItemID = 2;
var $author$project$Page$Imports$Form$LocationID = 1;
var $author$project$Page$Imports$Form$Quantity = 0;
var $author$project$Page$Imports$Form$fieldsToValidate = _List_fromArray(
	[1, 2, 0]);
var $author$project$Page$Imports$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Imports$Form$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		fB: form.fB,
		P: form.P,
		gd: form.gd
	};
};
var $author$project$Page$Imports$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Imports$Form$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Imports$Form$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return (form.gd <= 0) ? _List_fromArray(
							['Quantity <= 0']) : _List_Nil;
					case 2:
						return (form.fB <= 0) ? _List_fromArray(
							['Please select Item']) : _List_Nil;
					default:
						return (form.P <= 0) ? _List_fromArray(
							['Please select Location']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Imports$Form$validate = function (form) {
	var trimmedForm = $author$project$Page$Imports$Form$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Imports$Form$validateField(trimmedForm),
		$author$project$Page$Imports$Form$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Imports$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: search}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Imports$Form$fetchFeed, model.aA, 1, model.bE));
			case 5:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Imports$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Imports$Form$fetchFeedLocation, model.aA, 1, model.bF));
			case 7:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bo: $author$project$Page$Imports$Form$LoadedLocation(locations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 12:
				var comments = msg.a;
				return A2(
					$author$project$Page$Imports$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model);
			case 3:
				var quantity = msg.a;
				var quantityInt = function () {
					if (!quantity.$) {
						var i = quantity.a;
						return i;
					} else {
						return 0;
					}
				}();
				return A2(
					$author$project$Page$Imports$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{gd: quantityInt});
					},
					model);
			case 8:
				var itemID = msg.a;
				return A3(
					$author$project$Page$Imports$Form$fetchGuideLocation,
					model.aA,
					$author$project$Items$Slug$idToSlug(itemID),
					A2(
						$author$project$Page$Imports$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{fB: itemID});
						},
						model));
			case 9:
				var locationID = msg.a;
				return A2(
					$author$project$Page$Imports$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{P: locationID});
					},
					model);
			case 10:
				var _v2 = $author$project$Page$Imports$Form$validate(model.v);
				if (!_v2.$) {
					var validForm = _v2.a;
					var _v3 = $author$project$Session$cred(model.aA);
					if (!_v3.$) {
						var cred = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil, aE: true}),
							A3(
								$author$project$Page$Imports$Form$itemImport,
								$author$project$Session$cred(model.aA),
								validForm,
								$author$project$Page$Imports$Form$CompletedImportItem));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{_: !model._}),
					$elm$core$Platform$Cmd$none);
			case 11:
				if (!msg.a.$) {
					var stock = msg.a.a;
					return model._ ? A2(
						$author$project$Page$Imports$Form$addToast,
						A2(
							$pablen$toasty$Toasty$Defaults$Success,
							'Save Success',
							'LD_ID: ' + $elm$core$String$fromInt(stock.fG)),
						A2(
							$author$project$Page$Imports$Form$updateForm,
							function (form) {
								return _Utils_update(
									form,
									{eL: model.v.eL, fB: 0, gd: 0});
							},
							_Utils_update(
								model,
								{aE: false}))) : _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$ImportList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Imports$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Imports$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			case 14:
				var subMsg = msg.a;
				return A4($pablen$toasty$Toasty$update, $author$project$Page$Imports$Form$myConfig, $author$project$Page$Imports$Form$ToastyMsg, subMsg, model);
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{C: !model.C}),
					$elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aM: $author$project$Page$Imports$Form$Loaded(locations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aM: $author$project$Page$Imports$Form$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
		}
	});
var $author$project$Page$Imports$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Imports$List$Failed = {$: 3};
var $author$project$Page$Imports$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Endpoint$movementImport = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'movements',
				'import',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Page$Imports$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$movementImport(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Imports$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Imports$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Imports$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Imports$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Imports$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Y: 0, aB: false}),
						A3(
							$author$project$Page$Imports$List$delete,
							$author$project$Items$Slug$idToSlug(model.Y),
							cred,
							$author$project$Page$Imports$List$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Y: 0, aB: false}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Imports$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
		}
	});
var $author$project$Page$Imports$Po$CompletedImportItem = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Imports$Po$Failed = {$: 2};
var $author$project$Page$Imports$Po$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Imports$Po$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Imports$Po$ToastyMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Imports$Po$myConfig = A2($pablen$toasty$Toasty$delay, 5000, $pablen$toasty$Toasty$Defaults$config);
var $author$project$Page$Imports$Po$addToast = F2(
	function (toast, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A4(
			$pablen$toasty$Toasty$addToast,
			$author$project$Page$Imports$Po$myConfig,
			$author$project$Page$Imports$Po$ToastyMsg,
			toast,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$Page$Imports$Po$CompletedLoadLocations = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Imports$Po$fetchFeedLocation = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Location$fetchAll, maybeCred, params, $author$project$Page$Imports$Po$CompletedLoadLocations);
	});
var $author$project$Page$Imports$Po$CompletedLoadGuideLocation = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Imports$Po$fetchGuideLocation = F3(
	function (session, slug, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			model,
			A3($author$project$Location$fetchGuideLocation, maybeCred, slug, $author$project$Page$Imports$Po$CompletedLoadGuideLocation));
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$Endpoint$poImport = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'import'
			]),
		_List_Nil);
};
var $author$project$Page$Imports$Po$itemImport = F4(
	function (maybeCred, slug, _v0, toMsg) {
		var form = _v0;
		var quantityPerContainer = function () {
			var _v1 = form.I;
			if (!_v1.a.$) {
				var q = _v1.a.a;
				return (q <= 0) ? form.gd : q;
			} else {
				return form.gd;
			}
		}();
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(form.gd)),
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(form.fB)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(form.P)),
					_Utils_Tuple2(
					'ship_id',
					$elm$json$Json$Encode$int(form.aj)),
					_Utils_Tuple2(
					'quantity_per_container',
					$elm$json$Json$Encode$int(quantityPerContainer))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$poImport(slug),
			maybeCred,
			body,
			toMsg,
			$author$project$Stock$decodeFullList);
	});
var $author$project$Page$Imports$Po$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Imports$Po$ItemID = 2;
var $author$project$Page$Imports$Po$LocationID = 1;
var $author$project$Page$Imports$Po$PerContainer = 3;
var $author$project$Page$Imports$Po$Quantity = 0;
var $author$project$Page$Imports$Po$fieldsToValidate = _List_fromArray(
	[1, 2, 0, 3]);
var $author$project$Page$Imports$Po$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Imports$Po$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		fB: form.fB,
		P: form.P,
		gc: form.gc,
		gd: form.gd,
		I: form.I,
		aj: form.aj
	};
};
var $author$project$Page$Imports$Po$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Imports$Po$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Imports$Po$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return (form.gd <= 0) ? _List_fromArray(
							['Quantity <= 0']) : _List_Nil;
					case 2:
						return (form.fB <= 0) ? _List_fromArray(
							['Please select Item']) : _List_Nil;
					case 1:
						return (form.P <= 0) ? _List_fromArray(
							['Please select Location']) : _List_Nil;
					default:
						var _v2 = form.I;
						if (!_v2.a.$) {
							var q = _v2.a.a;
							return (q <= 0) ? _List_fromArray(
								['Please input Per LD_ID']) : _List_Nil;
						} else {
							return _List_fromArray(
								['Invalid Per LD_ID']);
						}
				}
			}());
	});
var $author$project$Page$Imports$Po$validate = function (form) {
	var trimmedForm = $author$project$Page$Imports$Po$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Imports$Po$validateField(trimmedForm),
		$author$project$Page$Imports$Po$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Imports$Po$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: search}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 6:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Imports$Po$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 7:
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Imports$Po$fetchFeedLocation, model.aA, 1, model.bF));
			case 8:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bo: $author$project$Page$Imports$Po$Loaded(locations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 13:
				var comments = msg.a;
				return A2(
					$author$project$Page$Imports$Po$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model);
			case 3:
				var str = msg.a;
				return A2(
					$author$project$Page$Imports$Po$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{
								I: A2(
									$author$project$Page$Imports$Po$PriceField,
									$elm$core$String$toInt(str),
									str)
							});
					},
					model);
			case 4:
				var quantity = msg.a;
				var quantityInt = function () {
					if (!quantity.$) {
						var i = quantity.a;
						return i;
					} else {
						return 0;
					}
				}();
				return A2(
					$author$project$Page$Imports$Po$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{gd: quantityInt});
					},
					model);
			case 9:
				var itemID = msg.a;
				var purchaseOrderID = msg.b;
				var shipID = msg.c;
				var _v2 = model.aR;
				if (!_v2.$) {
					var items = _v2.a;
					var _v3 = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (i) {
								return _Utils_eq(i.fB, itemID) && (_Utils_eq(i.gc, purchaseOrderID) && _Utils_eq(i.gf, shipID));
							},
							items));
					if (!_v3.$) {
						var item = _v3.a;
						return A3(
							$author$project$Page$Imports$Po$fetchGuideLocation,
							model.aA,
							$author$project$Items$Slug$idToSlug(itemID),
							A2(
								$author$project$Page$Imports$Po$updateForm,
								function (form) {
									return _Utils_update(
										form,
										{
											fB: itemID,
											gc: item.gc,
											gd: item.gd,
											I: A2(
												$author$project$Page$Imports$Po$PriceField,
												$elm$core$Maybe$Just(item.gd),
												$elm$core$String$fromInt(item.gd)),
											aj: item.gf
										});
								},
								model));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 10:
				var locationID = msg.a;
				return A2(
					$author$project$Page$Imports$Po$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{P: locationID});
					},
					model);
			case 11:
				var _v4 = $author$project$Page$Imports$Po$validate(model.v);
				if (!_v4.$) {
					var validForm = _v4.a;
					var _v5 = $author$project$Session$cred(model.aA);
					if (!_v5.$) {
						var cred = _v5.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil, aE: true}),
							A4(
								$author$project$Page$Imports$Po$itemImport,
								$author$project$Session$cred(model.aA),
								$author$project$Items$Slug$idToSlug(model.v.gc),
								validForm,
								$author$project$Page$Imports$Po$CompletedImportItem));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v4.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{_: !model._}),
					$elm$core$Platform$Cmd$none);
			case 12:
				if (!msg.a.$) {
					var stocks = msg.a.a;
					var newList = function () {
						var _v6 = model.aR;
						if (!_v6.$) {
							var items = _v6.a;
							return $author$project$Page$Imports$Po$Loaded(
								A2(
									$elm$core$List$filter,
									function (i) {
										return !(_Utils_eq(i.fB, model.v.fB) && _Utils_eq(i.gc, model.v.gc));
									},
									items));
						} else {
							return model.aR;
						}
					}();
					var ldMessages = 'LD_ID: ' + A2(
						$elm$core$String$join,
						', ',
						A2(
							$elm$core$List$map,
							function (s) {
								return $elm$core$String$fromInt(s.fG);
							},
							stocks));
					return model._ ? A2(
						$author$project$Page$Imports$Po$addToast,
						A2($pablen$toasty$Toasty$Defaults$Success, 'Save Success', ldMessages),
						A2(
							$author$project$Page$Imports$Po$updateForm,
							function (form) {
								return _Utils_update(
									form,
									{eL: model.v.eL, fB: 0, gd: 0});
							},
							_Utils_update(
								model,
								{aR: newList, aE: false}))) : _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$ImportList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Imports$Po$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Imports$Po$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList, aE: false}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			case 15:
				var subMsg = msg.a;
				return A4($pablen$toasty$Toasty$update, $author$project$Page$Imports$Po$myConfig, $author$project$Page$Imports$Po$ToastyMsg, subMsg, model);
			case 17:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{C: !model.C}),
					$elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aM: $author$project$Page$Imports$Po$Loaded(locations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aM: $author$project$Page$Imports$Po$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
		}
	});
var $author$project$Page$Items$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Items$Form$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Items$Form$ToastyMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Items$Form$myConfig = A2($pablen$toasty$Toasty$delay, 5000, $pablen$toasty$Toasty$Defaults$config);
var $author$project$Page$Items$Form$addToast = F2(
	function (toast, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A4(
			$pablen$toasty$Toasty$addToast,
			$author$project$Page$Items$Form$myConfig,
			$author$project$Page$Items$Form$ToastyMsg,
			toast,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$Page$Items$Form$CompletedLoadImages = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Items$Form$fetchFeed = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Image$fetchAll, 20, maybeCred, params, $author$project$Page$Items$Form$CompletedLoadImages);
	});
var $author$project$Page$Items$Form$CompletedCreate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Items$Form$CompletedEdit = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Items$Form$Creating = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Items$Form$Saving = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Items$Form$cartToVecInt = function (carts) {
	return A2(
		$elm$core$List$map,
		function (cart) {
			return cart.cY;
		},
		carts);
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Page$Items$Form$create = F4(
	function (_v0, cred, toMsg, cart) {
		var form = _v0;
		var article = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'title',
					$elm$json$Json$Encode$string(form.g5)),
					_Utils_Tuple2(
					'body',
					$elm$json$Json$Encode$string(form.a7)),
					_Utils_Tuple2(
					'unit_id',
					$elm$json$Json$Encode$int(1)),
					_Utils_Tuple2(
					'category_id',
					$elm$json$Json$Encode$int(form.eC)),
					_Utils_Tuple2(
					'stock',
					$elm$json$Json$Encode$int(0)),
					_Utils_Tuple2(
					'price',
					$elm$json$Json$Encode$int(0)),
					_Utils_Tuple2(
					'images',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$int,
						$author$project$Page$Items$Form$cartToVecInt(cart)))
				]));
		var body = $elm$http$Http$jsonBody(article);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$items(_List_Nil),
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$author$project$Item$fullDecoder);
	});
var $author$project$Page$Items$Form$edit = F5(
	function (articleSlug, _v0, cred, toMsg, cart) {
		var form = _v0;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'title',
						$elm$json$Json$Encode$string(form.g5)),
						_Utils_Tuple2(
						'body',
						$elm$json$Json$Encode$string(form.a7)),
						_Utils_Tuple2(
						'category_id',
						$elm$json$Json$Encode$int(form.eC)),
						_Utils_Tuple2(
						'images',
						A2(
							$elm$json$Json$Encode$list,
							$elm$json$Json$Encode$int,
							$author$project$Page$Items$Form$cartToVecInt(cart)))
					])));
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$item(articleSlug),
			cred,
			body,
			toMsg,
			$author$project$Item$fullDecoder);
	});
var $author$project$Page$Items$Form$Body = 1;
var $author$project$Page$Items$Form$Title = 0;
var $author$project$Page$Items$Form$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$Items$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Items$Form$trimFields = function (form) {
	return {
		a7: $elm$core$String$trim(form.a7),
		eC: form.eC,
		g5: $elm$core$String$trim(form.g5)
	};
};
var $author$project$Page$Items$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Items$Form$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Items$Form$InvalidEntry(field),
			function () {
				if (!field) {
					return $elm$core$String$isEmpty(form.g5) ? _List_fromArray(
						['title can\'t be blank.']) : _List_Nil;
				} else {
					return $elm$core$String$isEmpty(form.a7) ? _List_fromArray(
						['body can\'t be blank.']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Items$Form$validate = function (form) {
	var trimmedForm = $author$project$Page$Items$Form$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Items$Form$validateField(trimmedForm),
		$author$project$Page$Items$Form$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Items$Form$save = F3(
	function (model, cred, status) {
		switch (status.$) {
			case 4:
				var slug = status.a;
				var form = status.c;
				var _v1 = $author$project$Page$Items$Form$validate(form);
				if (!_v1.$) {
					var validForm = _v1.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Items$Form$Saving, slug, form),
						A5($author$project$Page$Items$Form$edit, slug, validForm, cred, $author$project$Page$Items$Form$CompletedEdit, model.o));
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						A3($author$project$Page$Items$Form$Editing, slug, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var form = status.b;
				var _v2 = $author$project$Page$Items$Form$validate(form);
				if (!_v2.$) {
					var validForm = _v2.a;
					return _Utils_Tuple2(
						$author$project$Page$Items$Form$Creating(form),
						A4($author$project$Page$Items$Form$create, validForm, cred, $author$project$Page$Items$Form$CompletedCreate, model.o));
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Items$Form$EditingNew, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(status, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Items$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Items$Form$savingError = F2(
	function (error, status) {
		var problems = _List_fromArray(
			[
				$author$project$Page$Items$Form$ServerError('Error saving article')
			]);
		switch (status.$) {
			case 3:
				var slug = status.a;
				var form = status.b;
				return A3($author$project$Page$Items$Form$Editing, slug, problems, form);
			case 6:
				var form = status.a;
				return A2($author$project$Page$Items$Form$EditingNew, problems, form);
			default:
				return status;
		}
	});
var $author$project$Page$Items$Form$updateForm = F2(
	function (transform, model) {
		var newModel = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return model;
				case 1:
					return model;
				case 2:
					return model;
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Items$Form$Saving,
								slug,
								transform(form))
						});
				case 4:
					var slug = _v0.a;
					var errors = _v0.b;
					var form = _v0.c;
					return _Utils_update(
						model,
						{
							dL: A3(
								$author$project$Page$Items$Form$Editing,
								slug,
								errors,
								transform(form))
						});
				case 5:
					var errors = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Items$Form$EditingNew,
								errors,
								transform(form))
						});
				default:
					var form = _v0.a;
					return _Utils_update(
						model,
						{
							dL: $author$project$Page$Items$Form$Creating(
								transform(form))
						});
			}
		}();
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Items$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 2:
				var title = msg.a;
				return A2(
					$author$project$Page$Items$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{g5: title});
					},
					model);
			case 3:
				var body = msg.a;
				return A2(
					$author$project$Page$Items$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{a7: body});
					},
					model);
			case 12:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a_: search}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Items$Form$fetchFeed, model.aA, 1, model.a_));
			case 4:
				var category_id_str = msg.a;
				var _v1 = $elm$core$String$toInt(category_id_str);
				if (!_v1.$) {
					var category_id = _v1.a;
					return A2(
						$author$project$Page$Items$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{eC: category_id});
						},
						model);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var cred = msg.a;
				return A2(
					$elm$core$Tuple$mapFirst,
					function (status) {
						return _Utils_update(
							model,
							{dL: status});
					},
					A3($author$project$Page$Items$Form$save, model, cred, model.dL));
			case 6:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Items$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return model._ ? A2(
						$author$project$Page$Items$Form$addToast,
						A2(
							$pablen$toasty$Toasty$Defaults$Success,
							'Save Success',
							'Item ID: ' + $elm$core$String$fromInt(item.cY)),
						A2(
							$author$project$Page$Items$Form$updateForm,
							function (form) {
								return _Utils_update(
									form,
									{g5: ''});
							},
							_Utils_update(
								model,
								{
									dL: A2(
										$author$project$Page$Items$Form$EditingNew,
										_List_Nil,
										{a7: item.a7, eC: item.eC, g5: ''})
								}))) : _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Item(
								$author$project$Item$slug(item))));
				}
			case 5:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Items$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Item(
								$author$project$Item$slug(item))));
				}
			case 16:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a$: $author$project$Page$Items$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 9:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 7:
				if (!msg.a.$) {
					var item = msg.a.a;
					var status = A3(
						$author$project$Page$Items$Form$Editing,
						$author$project$Item$slug(item),
						_List_Nil,
						{a7: item.a7, eC: item.eC, g5: item.g5});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dL: status}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				if (!msg.a.$) {
					var itemList = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aK: itemList}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{_: !model._}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var subMsg = msg.a;
				return A4($pablen$toasty$Toasty$update, $author$project$Page$Items$Form$myConfig, $author$project$Page$Items$Form$ToastyMsg, subMsg, model);
			case 14:
				var stock = msg.a;
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return _Utils_eq(cart.cY, stock.cY);
					},
					model.o);
				var carts = (!$elm$core$List$length(filteredCart)) ? A2(
					$elm$core$List$append,
					model.o,
					_List_fromArray(
						[stock])) : model.o;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: carts}),
					$elm$core$Platform$Cmd$none);
			default:
				var stock = msg.a;
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return !_Utils_eq(cart.cY, stock.cY);
					},
					model.o);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: filteredCart}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Items$List$Failed = {$: 3};
var $author$project$Page$Items$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Page$Items$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Items$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Items$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aK: $author$project$Page$Items$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aK: $author$project$Page$Items$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A4($author$project$Page$Items$List$fetchFeed, model.aA, page, model.ai, model.aZ));
			case 5:
				var searchQueryParameter = function () {
					var _v1 = $elm$core$String$length(model.ai);
					if (!_v1) {
						return $elm$core$Maybe$Nothing;
					} else {
						return $elm$core$Maybe$Just(model.ai);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1, aR: $author$project$Page$Items$List$Loading}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$author$project$Route$pushUrl,
								$author$project$Session$navKey(model.aA),
								$author$project$Route$Items(searchQueryParameter))
							])));
			case 6:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			default:
				var val = msg.a;
				var maybeInt = $elm$core$String$toInt(val);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aR: $author$project$Page$Items$List$Loaded(
								A2($author$project$PaginatedList$fromList, 0, _List_Nil)),
							aZ: maybeInt
						}),
					A4($author$project$Page$Items$List$fetchFeed, model.aA, 1, model.ai, maybeInt));
		}
	});
var $author$project$Page$Items$View$CompletedDeleteItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Items$View$Failed = {$: 3};
var $author$project$Page$Items$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Items$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$item(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Items$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Items$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Items$View$Failed}),
						$author$project$Log$error);
				}
			case 12:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Items($elm$core$Maybe$Nothing)));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 5:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $author$project$Page$Items$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 6:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bx: $author$project$Page$Items$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 10:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bB: $author$project$Page$Items$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 7:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: page}),
					A3($author$project$Page$Items$View$fetchMovements, model.aA, page, model.bG));
			case 8:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{by: page}),
					A3($author$project$Page$Items$View$fetchPositiveMovements, model.aA, page, model.bG));
			case 9:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bA: page}),
					A3($author$project$Page$Items$View$fetchRemainLot, model.aA, page, model.bG));
			case 3:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: true}),
					$elm$core$Platform$Cmd$none);
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3($author$project$Page$Items$View$delete, model.bG, cred, $author$project$Page$Items$View$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Locations$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Locations$Form$CompletedCreate = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Locations$Form$CompletedEdit = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Locations$Form$Creating = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Locations$Form$Saving = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Locations$Form$create = F3(
	function (_v0, cred, toMsg) {
		var form = _v0;
		var article = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(form.g5))
				]));
		var body = $elm$http$Http$jsonBody(article);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$locations(_List_Nil),
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$author$project$Location$fullDecoder);
	});
var $author$project$Page$Locations$Form$edit = F4(
	function (articleSlug, _v0, cred, toMsg) {
		var form = _v0;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(form.g5))
					])));
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$location(articleSlug),
			cred,
			body,
			toMsg,
			$author$project$Location$fullDecoder);
	});
var $author$project$Page$Locations$Form$Title = 0;
var $author$project$Page$Locations$Form$fieldsToValidate = _List_fromArray(
	[0]);
var $author$project$Page$Locations$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Locations$Form$trimFields = function (form) {
	return {
		g5: $elm$core$String$trim(form.g5)
	};
};
var $author$project$Page$Locations$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Locations$Form$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Locations$Form$InvalidEntry(field),
			$elm$core$String$isEmpty(form.g5) ? _List_fromArray(
				['title can\'t be blank.']) : _List_Nil);
	});
var $author$project$Page$Locations$Form$validate = function (form) {
	var trimmedForm = $author$project$Page$Locations$Form$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Locations$Form$validateField(trimmedForm),
		$author$project$Page$Locations$Form$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Locations$Form$save = F2(
	function (cred, status) {
		switch (status.$) {
			case 4:
				var slug = status.a;
				var form = status.c;
				var _v1 = $author$project$Page$Locations$Form$validate(form);
				if (!_v1.$) {
					var validForm = _v1.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Locations$Form$Saving, slug, form),
						A4($author$project$Page$Locations$Form$edit, slug, validForm, cred, $author$project$Page$Locations$Form$CompletedEdit));
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						A3($author$project$Page$Locations$Form$Editing, slug, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var form = status.b;
				var _v2 = $author$project$Page$Locations$Form$validate(form);
				if (!_v2.$) {
					var validForm = _v2.a;
					return _Utils_Tuple2(
						$author$project$Page$Locations$Form$Creating(form),
						A3($author$project$Page$Locations$Form$create, validForm, cred, $author$project$Page$Locations$Form$CompletedCreate));
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Locations$Form$EditingNew, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(status, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Locations$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Locations$Form$savingError = F2(
	function (error, status) {
		var problems = _List_fromArray(
			[
				$author$project$Page$Locations$Form$ServerError('Error saving article')
			]);
		switch (status.$) {
			case 3:
				var slug = status.a;
				var form = status.b;
				return A3($author$project$Page$Locations$Form$Editing, slug, problems, form);
			case 6:
				var form = status.a;
				return A2($author$project$Page$Locations$Form$EditingNew, problems, form);
			default:
				return status;
		}
	});
var $author$project$Location$slug = function (item) {
	return $author$project$Items$Slug$idToSlug(item.cY);
};
var $author$project$Page$Locations$Form$updateForm = F2(
	function (transform, model) {
		var newModel = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return model;
				case 1:
					return model;
				case 2:
					return model;
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Locations$Form$Saving,
								slug,
								transform(form))
						});
				case 4:
					var slug = _v0.a;
					var errors = _v0.b;
					var form = _v0.c;
					return _Utils_update(
						model,
						{
							dL: A3(
								$author$project$Page$Locations$Form$Editing,
								slug,
								errors,
								transform(form))
						});
				case 5:
					var errors = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Locations$Form$EditingNew,
								errors,
								transform(form))
						});
				default:
					var form = _v0.a;
					return _Utils_update(
						model,
						{
							dL: $author$project$Page$Locations$Form$Creating(
								transform(form))
						});
			}
		}();
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Locations$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 2:
				var title = msg.a;
				return A2(
					$author$project$Page$Locations$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{g5: title});
					},
					model);
			case 1:
				var cred = msg.a;
				return A2(
					$elm$core$Tuple$mapFirst,
					function (status) {
						return _Utils_update(
							model,
							{dL: status});
					},
					A2($author$project$Page$Locations$Form$save, cred, model.dL));
			case 4:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Locations$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Locations));
				}
			case 3:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Locations$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Locations));
				}
			default:
				if (!msg.a.$) {
					var item = msg.a.a;
					var status = A3(
						$author$project$Page$Locations$Form$Editing,
						$author$project$Location$slug(item),
						_List_Nil,
						{g5: item.fU});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dL: status}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Locations$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Locations$List$Failed = {$: 3};
var $author$project$Page$Locations$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Locations$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$location(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Locations$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Locations$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Locations$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Locations$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Locations$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Locations$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3(
							$author$project$Page$Locations$List$delete,
							$author$project$Items$Slug$idToSlug(model.Y),
							cred,
							$author$project$Page$Locations$List$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Locations$View$CompletedDeleteItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Locations$View$Failed = {$: 3};
var $author$project$Page$Locations$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Locations$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$location(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Locations$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Locations$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Locations$View$Failed}),
						$author$project$Log$error);
				}
			case 3:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aD: $author$project$Page$Locations$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aD: $author$project$Page$Locations$View$Failed}),
						$author$project$Log$error);
				}
			case 4:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Locations$View$delete, slug, cred, $author$project$Page$Locations$View$CompletedDeleteItem));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Home));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
		}
	});
var $author$project$Page$Login$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$fromPair = function (_v0) {
	var field = _v0.a;
	var errors = _v0.b;
	return A2(
		$elm$core$List$map,
		function (error) {
			return field + (' ' + error);
		},
		errors);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $author$project$Api$errorsDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$List$concatMap($author$project$Api$fromPair),
	$elm$json$Json$Decode$keyValuePairs(
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Api$decodeErrors = function (error) {
	switch (error.$) {
		case 3:
			var status = error.a;
			return _List_fromArray(
				[
					'Bad Status Error' + $elm$core$String$fromInt(status)
				]);
		case 4:
			var response = error.a;
			return A2(
				$elm$core$Result$withDefault,
				_List_fromArray(
					['Server error']),
				A2(
					$elm$json$Json$Decode$decodeString,
					A2($elm$json$Json$Decode$field, 'errors', $author$project$Api$errorsDecoder),
					response));
		default:
			var err = error;
			return _List_fromArray(
				['Server error']);
	}
};
var $author$project$Page$Login$CompletedLogin = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Endpoint$login = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['auth']),
	_List_Nil);
var $author$project$Api$login = F3(
	function (body, expectMsg, decoder) {
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$login,
			$elm$core$Maybe$Nothing,
			body,
			expectMsg,
			A2(
				$elm$json$Json$Decode$field,
				'user',
				$author$project$Api$decoderFromCred(decoder)));
	});
var $author$project$Page$Login$login = function (_v0) {
	var form = _v0;
	var user = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(form.O)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(form.R))
			]));
	var body = $elm$http$Http$jsonBody(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('user', user)
				])));
	return A3($author$project$Api$login, body, $author$project$Page$Login$CompletedLogin, $author$project$Viewer$decoder);
};
var $author$project$Avatar$encode = function (_v0) {
	var maybeUrl = _v0;
	if (!maybeUrl.$) {
		var url = maybeUrl.a;
		return $elm$json$Json$Encode$string(url);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Username$encode = function (_v0) {
	var username = _v0;
	return $elm$json$Json$Encode$string(username);
};
var $author$project$Api$storeCredWith = F3(
	function (_v0, user_type, avatar) {
		var uname = _v0.a;
		var token = _v0.b;
		var json = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'user',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'username',
								$author$project$Username$encode(uname)),
								_Utils_Tuple2(
								'token',
								$elm$json$Json$Encode$string(token)),
								_Utils_Tuple2(
								'image',
								$author$project$Avatar$encode(avatar)),
								_Utils_Tuple2(
								'userType',
								$elm$json$Json$Encode$int(user_type))
							])))
				]));
		return $author$project$Api$storeCache(
			$elm$core$Maybe$Just(json));
	});
var $author$project$Viewer$store = function (_v0) {
	var avatarVal = _v0.a;
	var typeVal = _v0.b;
	var credVal = _v0.c;
	return A3($author$project$Api$storeCredWith, credVal, typeVal, avatarVal);
};
var $author$project$Page$Login$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Login$Email = 0;
var $author$project$Page$Login$Password = 1;
var $author$project$Page$Login$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$Login$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Login$trimFields = function (form) {
	return {
		O: $elm$core$String$trim(form.O),
		R: $elm$core$String$trim(form.R)
	};
};
var $author$project$Page$Login$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Login$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Login$InvalidEntry(field),
			function () {
				if (!field) {
					return $elm$core$String$isEmpty(form.O) ? _List_fromArray(
						['email can\'t be blank.']) : _List_Nil;
				} else {
					return $elm$core$String$isEmpty(form.R) ? _List_fromArray(
						['password can\'t be blank.']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Login$validate = function (form) {
	var trimmedForm = $author$project$Page$Login$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Login$validateField(trimmedForm),
		$author$project$Page$Login$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				return A2(
					$author$project$Page$Login$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{O: email});
					},
					model);
			case 1:
				var password = msg.a;
				return A2(
					$author$project$Page$Login$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{R: password});
					},
					model);
			case 2:
				var _v1 = $author$project$Page$Login$validate(model.v);
				if (!_v1.$) {
					var validForm = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aU: true, ax: _List_Nil}),
						$author$project$Page$Login$login(validForm));
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					var serverErrors = A2(
						$elm$core$List$map,
						$author$project$Page$Login$ServerError,
						$author$project$Api$decodeErrors(error));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aU: false,
								ax: A2($elm$core$List$append, model.ax, serverErrors)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var viewer = msg.a.a;
					return _Utils_Tuple2(
						model,
						$author$project$Viewer$store(viewer));
				}
			default:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Home));
		}
	});
var $author$project$Page$Moves$Form$CompletedSaveForm = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Moves$Form$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Moves$Form$LoadedLocation = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$Form$CompletedLoadItems = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Endpoint$stockNotInLocation = F2(
	function (exclude_location_id, params) {
		return A2(
			$author$project$Api$Endpoint$url,
			_List_fromArray(
				[
					'stocks',
					'not_in',
					$elm$core$String$fromInt(exclude_location_id)
				]),
			params);
	});
var $author$project$Stock$fetchNotInLocation = F4(
	function (exclude_location_id, maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			A2($author$project$Api$Endpoint$stockNotInLocation, exclude_location_id, params),
			maybeCred,
			toMsg,
			A2($author$project$Stock$decoder, maybeCred, 10));
	});
var $author$project$Page$Moves$Form$fetchFeed = F4(
	function (form, session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Stock$fetchNotInLocation, form.x, maybeCred, params, $author$project$Page$Moves$Form$CompletedLoadItems);
	});
var $author$project$Page$Moves$Form$CompletedLoadLocations = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Moves$Form$fetchFeedLocation = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Location$fetchAll, maybeCred, params, $author$project$Page$Moves$Form$CompletedLoadLocations);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Page$Moves$Form$encodeStock = function (stocks) {
	var singleEncode = function (stock) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'lot_detail_id',
					$elm$json$Json$Encode$int(stock.fG)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(stock.gd)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(stock.P))
				]));
	};
	return A2($elm$json$Json$Encode$list, singleEncode, stocks);
};
var $author$project$Api$Endpoint$stockMove = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['moves']),
	_List_Nil);
var $author$project$Page$Moves$Form$itemImport = F4(
	function (maybeCred, carts, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					$author$project$Page$Moves$Form$encodeStock(carts)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(form.x))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$stockMove,
			maybeCred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Moves$Form$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Moves$Form$Cart = 1;
var $author$project$Page$Moves$Form$LocationID = 0;
var $author$project$Page$Moves$Form$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$Moves$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Moves$Form$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		x: form.x
	};
};
var $author$project$Page$Moves$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Moves$Form$validateField = F3(
	function (_v0, carts, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Moves$Form$InvalidEntry(field),
			function () {
				if (field === 1) {
					return (!$elm$core$List$length(carts)) ? _List_fromArray(
						['Please Select Item']) : _List_Nil;
				} else {
					return (form.x <= 0) ? _List_fromArray(
						['Please select Location']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Moves$Form$validate = F2(
	function (carts, form) {
		var trimmedForm = $author$project$Page$Moves$Form$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A2($author$project$Page$Moves$Form$validateField, trimmedForm, carts),
			$author$project$Page$Moves$Form$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$Moves$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: search}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					model,
					A4($author$project$Page$Moves$Form$fetchFeed, model.v, model.aA, 1, model.bE));
			case 5:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Moves$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							o: _List_Nil,
							v: {eL: '', x: 0},
							aR: $author$project$Page$Moves$Form$Loading
						}),
					A3($author$project$Page$Moves$Form$fetchFeedLocation, model.aA, 1, model.bF));
			case 7:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bo: $author$project$Page$Moves$Form$LoadedLocation(locations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 12:
				var comments = msg.a;
				return A2(
					$author$project$Page$Moves$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model);
			case 3:
				var stock = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				var updateCartFunc = function (cart) {
					return (_Utils_eq(cart.fG, stock.fG) && _Utils_eq(cart.P, stock.P)) ? ((_Utils_cmp(stock.dx, quantityInt) > -1) ? _Utils_update(
						cart,
						{gd: quantityInt}) : _Utils_update(
						cart,
						{gd: cart.dx})) : cart;
				};
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return cart.gd > 0;
					},
					A2($elm$core$List$map, updateCartFunc, model.o));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var stock = msg.a;
				var amount = msg.b;
				var updateCartFunc = function (cart) {
					return (_Utils_eq(cart.fG, stock.fG) && (_Utils_eq(cart.P, stock.P) && (_Utils_cmp(cart.gd, stock.dx) < 1))) ? ((amount === 1) ? _Utils_update(
						cart,
						{gd: cart.gd + 1}) : _Utils_update(
						cart,
						{gd: amount})) : cart;
				};
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return _Utils_eq(cart.fG, stock.fG) && _Utils_eq(cart.P, stock.P);
					},
					model.o);
				var carts = (!$elm$core$List$length(filteredCart)) ? A2(
					$elm$core$List$append,
					model.o,
					_List_fromArray(
						[
							_Utils_update(
							stock,
							{gd: 0})
						])) : model.o;
				var newCart = A2($elm$core$List$map, updateCartFunc, carts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var locationID = msg.a;
				return A2(
					$author$project$Page$Moves$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{x: locationID});
					},
					model);
			case 10:
				var _v2 = A2($author$project$Page$Moves$Form$validate, model.o, model.v);
				if (!_v2.$) {
					var validForm = _v2.a;
					var _v3 = $author$project$Session$cred(model.aA);
					if (!_v3.$) {
						var cred = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil}),
							A4(
								$author$project$Page$Moves$Form$itemImport,
								$author$project$Session$cred(model.aA),
								model.o,
								validForm,
								$author$project$Page$Moves$Form$CompletedSaveForm));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$MoveList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Moves$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Moves$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
		}
	});
var $author$project$Page$Moves$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Moves$List$Failed = {$: 3};
var $author$project$Page$Moves$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Moves$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$movementMove(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Moves$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Moves$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Moves$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Moves$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Moves$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Moves$List$delete, slug, cred, $author$project$Page$Moves$List$CompletedDeleteItem));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Moves$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
		}
	});
var $author$project$Page$Moves$Original$CompletedSaveForm = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Moves$Original$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Moves$Original$LoadedLocation = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$Original$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$Original$CompletedLoadItems = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Endpoint$stockInLocationGetOriginalLocation = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'stocks',
				'in-original',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Stock$fetchInLocationGetOriginal = F3(
	function (maybeCred, slug, expectMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$stockInLocationGetOriginalLocation(slug),
			maybeCred,
			expectMsg,
			A2($author$project$Stock$decoder, maybeCred, 100));
	});
var $author$project$Page$Moves$Original$fetchFeed = F5(
	function (form, session, page, query, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			model,
			A3(
				$author$project$Stock$fetchInLocationGetOriginal,
				maybeCred,
				$author$project$Location$slug(model.v.p),
				$author$project$Page$Moves$Original$CompletedLoadItems));
	});
var $author$project$Page$Moves$Original$CompletedLoadLocations = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Moves$Original$fetchFeedLocation = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Location$fetchAll, maybeCred, params, $author$project$Page$Moves$Original$CompletedLoadLocations);
	});
var $author$project$Page$Moves$Original$encodeStock = function (stocks) {
	var singleEncode = function (stock) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'lot_detail_id',
					$elm$json$Json$Encode$int(stock.fG)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(stock.gd)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(stock.P))
				]));
	};
	return A2($elm$json$Json$Encode$list, singleEncode, stocks);
};
var $author$project$Api$Endpoint$stockMoveBack = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['moves', 'back']),
	_List_Nil);
var $author$project$Page$Moves$Original$itemImport = F4(
	function (maybeCred, carts, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					$author$project$Page$Moves$Original$encodeStock(carts)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(form.p.cY))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$stockMoveBack,
			maybeCred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Moves$Original$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Moves$Original$Cart = 1;
var $author$project$Page$Moves$Original$LocationID = 0;
var $author$project$Page$Moves$Original$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$Moves$Original$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Moves$Original$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		p: form.p
	};
};
var $author$project$Page$Moves$Original$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Moves$Original$validateField = F3(
	function (_v0, carts, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Moves$Original$InvalidEntry(field),
			function () {
				if (field === 1) {
					return (!$elm$core$List$length(carts)) ? _List_fromArray(
						['Please Select Item']) : _List_Nil;
				} else {
					return (form.p.cY <= 0) ? _List_fromArray(
						['Please select Location']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Moves$Original$validate = F2(
	function (carts, form) {
		var trimmedForm = $author$project$Page$Moves$Original$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A2($author$project$Page$Moves$Original$validateField, trimmedForm, carts),
			$author$project$Page$Moves$Original$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$PaginatedList$values = function (_v0) {
	var info = _v0;
	return info.bM;
};
var $author$project$Page$Moves$Original$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: search}),
					$elm$core$Platform$Cmd$none);
			case 4:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Moves$Original$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							o: _List_Nil,
							v: {
								eL: '',
								p: {e2: false, cY: 0, fU: ''}
							},
							aR: $author$project$Page$Moves$Original$Loading
						}),
					A3($author$project$Page$Moves$Original$fetchFeedLocation, model.aA, 1, model.bF));
			case 6:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bo: $author$project$Page$Moves$Original$LoadedLocation(locations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 11:
				var comments = msg.a;
				return A2(
					$author$project$Page$Moves$Original$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model);
			case 3:
				var stock = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return (_Utils_cmp(stock.dx, quantity) > -1) ? quantity : stock.gd;
					} else {
						return 0;
					}
				}();
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fG, stock.fG) ? _Utils_update(
						cart,
						{gd: quantityInt}) : cart;
				};
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return cart.gd > 0;
					},
					A2($elm$core$List$map, updateCartFunc, model.o));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var stock = msg.a;
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return _Utils_eq(cart.fG, stock.fG);
					},
					model.o);
				var newCart = (!$elm$core$List$length(filteredCart)) ? A2(
					$elm$core$List$append,
					model.o,
					_List_fromArray(
						[
							_Utils_update(
							stock,
							{dx: stock.gd})
						])) : model.o;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var locationID = msg.a;
				var newLocation = function () {
					var _v2 = model.bo;
					if (_v2.$ === 1) {
						var locations = _v2.a;
						var _v3 = $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (i) {
									return _Utils_eq(i.cY, locationID);
								},
								$author$project$PaginatedList$values(locations)));
						if (!_v3.$) {
							var location = _v3.a;
							return location;
						} else {
							return {e2: false, cY: 0, fU: ''};
						}
					} else {
						return {e2: false, cY: 0, fU: ''};
					}
				}();
				return A5(
					$author$project$Page$Moves$Original$fetchFeed,
					model.v,
					model.aA,
					1,
					model.bE,
					A2(
						$author$project$Page$Moves$Original$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{p: newLocation});
						},
						model));
			case 9:
				var _v4 = A2($author$project$Page$Moves$Original$validate, model.o, model.v);
				if (!_v4.$) {
					var validForm = _v4.a;
					var _v5 = $author$project$Session$cred(model.aA);
					if (!_v5.$) {
						var cred = _v5.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil, bD: true}),
							A4(
								$author$project$Page$Moves$Original$itemImport,
								$author$project$Session$cred(model.aA),
								model.o,
								validForm,
								$author$project$Page$Moves$Original$CompletedSaveForm));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v4.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$MoveList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Moves$Original$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Moves$Original$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
		}
	});
var $author$project$Page$Moves$View$CompletedDeleteItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Moves$View$Failed = {$: 3};
var $author$project$Page$Moves$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Moves$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$item(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Moves$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Moves$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Moves$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $author$project$Page$Moves$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{br: $author$project$Page$Moves$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Home));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Moves$View$delete, slug, cred, $author$project$Page$Moves$View$CompletedDeleteItem));
		}
	});
var $author$project$Page$Packings$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Packings$Form$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Packings$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $author$project$Page$Packings$Form$CompletedCreate = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Packings$Form$CompletedEdit = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Packings$Form$Creating = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Packings$Form$Saving = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Packings$Form$encodeStock = function (stocks) {
	var singleEncode = function (stock) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(stock.fB)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(stock.gd)),
					_Utils_Tuple2(
					'supplier_id',
					$elm$json$Json$Encode$int(stock.gc))
				]));
	};
	return A2($elm$json$Json$Encode$list, singleEncode, stocks);
};
var $author$project$Page$Packings$Form$create = F4(
	function (_v0, cred, carts, toMsg) {
		var form = _v0;
		var article = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cart',
					$author$project$Page$Packings$Form$encodeStock(carts))
				]));
		var body = $elm$http$Http$jsonBody(article);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$purchaseOrderDetailReadyToShip,
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$author$project$Models$PurchaseOrder$fullShippingDecoder);
	});
var $author$project$Page$Packings$Form$priceFieldToString = function (priceField) {
	if (priceField.a.$ === 1) {
		var _v1 = priceField.a;
		var price = priceField.b;
		return price;
	} else {
		var price = priceField.b;
		return price;
	}
};
var $author$project$Page$Packings$Form$edit = F5(
	function (articleSlug, _v0, cred, carts, toMsg) {
		var form = _v0;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'comments',
						$elm$json$Json$Encode$string(form.eL)),
						_Utils_Tuple2(
						'shipper',
						$elm$json$Json$Encode$string(form.gv)),
						_Utils_Tuple2(
						'tracking',
						$elm$json$Json$Encode$string(form.ha)),
						_Utils_Tuple2(
						'ship_fee',
						$elm$json$Json$Encode$string(
							$author$project$Page$Packings$Form$priceFieldToString(form.dI))),
						_Utils_Tuple2(
						'cart',
						$author$project$Page$Packings$Form$encodeStock(carts))
					])));
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$purchaseOrderShippedCart(articleSlug),
			cred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Packings$Form$Quantity = 0;
var $author$project$Page$Packings$Form$ShipFee = 3;
var $author$project$Page$Packings$Form$Shipper = 1;
var $author$project$Page$Packings$Form$Tracking = 2;
var $author$project$Page$Packings$Form$fieldsToValidate = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Page$Packings$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Packings$Form$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		dI: form.dI,
		gv: $elm$core$String$trim(form.gv),
		ha: $elm$core$String$trim(form.ha)
	};
};
var $author$project$Page$Packings$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Packings$Form$validateField = F4(
	function (_v0, isUpdate, cart, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Packings$Form$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return ($elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (i) {
									return _Utils_cmp(i.gd, i.gf) > 0;
								},
								cart)) > 0) ? _List_fromArray(
							['Quantity more than allow']) : _List_Nil;
					case 1:
						return (isUpdate && (!$elm$core$String$length(form.gv))) ? _List_fromArray(
							['Please input shipper']) : _List_Nil;
					case 2:
						return (isUpdate && (!$elm$core$String$length(form.gv))) ? _List_fromArray(
							['Please input tracking']) : _List_Nil;
					default:
						return (isUpdate && (!$elm$core$String$length(form.gv))) ? _List_fromArray(
							['Please input ship fee']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Packings$Form$validate = F3(
	function (form, isUpdate, carts) {
		var trimmedForm = $author$project$Page$Packings$Form$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A3($author$project$Page$Packings$Form$validateField, trimmedForm, isUpdate, carts),
			$author$project$Page$Packings$Form$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$Packings$Form$save = F3(
	function (cred, status, carts) {
		switch (status.$) {
			case 4:
				var slug = status.a;
				var form = status.c;
				var _v1 = A3($author$project$Page$Packings$Form$validate, form, true, carts);
				if (!_v1.$) {
					var validForm = _v1.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Packings$Form$Saving, slug, form),
						A5($author$project$Page$Packings$Form$edit, slug, validForm, cred, carts, $author$project$Page$Packings$Form$CompletedEdit));
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						A3($author$project$Page$Packings$Form$Editing, slug, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var form = status.b;
				var _v2 = A3($author$project$Page$Packings$Form$validate, form, false, carts);
				if (!_v2.$) {
					var validForm = _v2.a;
					return _Utils_Tuple2(
						$author$project$Page$Packings$Form$Creating(form),
						A4($author$project$Page$Packings$Form$create, validForm, cred, carts, $author$project$Page$Packings$Form$CompletedCreate));
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Packings$Form$EditingNew, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(status, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Packings$Form$savingError = F2(
	function (error, status) {
		var problems = _List_fromArray(
			[
				$author$project$Page$Packings$Form$ServerError('Error saving tracking')
			]);
		switch (status.$) {
			case 3:
				var slug = status.a;
				var form = status.b;
				return A3($author$project$Page$Packings$Form$Editing, slug, problems, form);
			case 6:
				var form = status.a;
				return A2($author$project$Page$Packings$Form$EditingNew, problems, form);
			default:
				return status;
		}
	});
var $author$project$Page$Packings$Form$updateForm = F2(
	function (transform, model) {
		var newModel = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return model;
				case 1:
					return model;
				case 2:
					return model;
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Packings$Form$Saving,
								slug,
								transform(form))
						});
				case 4:
					var slug = _v0.a;
					var errors = _v0.b;
					var form = _v0.c;
					return _Utils_update(
						model,
						{
							dL: A3(
								$author$project$Page$Packings$Form$Editing,
								slug,
								errors,
								transform(form))
						});
				case 5:
					var errors = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Packings$Form$EditingNew,
								errors,
								transform(form))
						});
				default:
					var form = _v0.a;
					return _Utils_update(
						model,
						{
							dL: $author$project$Page$Packings$Form$Creating(
								transform(form))
						});
			}
		}();
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Packings$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 7:
				var query = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 10:
				var name = msg.a;
				return A2(
					$author$project$Page$Packings$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{gv: name});
					},
					model);
			case 13:
				var email = msg.a;
				return A2(
					$author$project$Page$Packings$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: email});
					},
					model);
			case 11:
				var email = msg.a;
				return A2(
					$author$project$Page$Packings$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{ha: email});
					},
					model);
			case 12:
				var price = msg.a;
				if (A2($elm$core$String$right, 1, price) === '.') {
					return A2(
						$author$project$Page$Packings$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									dI: A2($author$project$Page$Packings$Form$PriceField, $elm$core$Maybe$Nothing, price)
								});
						},
						model);
				} else {
					var maybePrice = $elm$core$String$toFloat(price);
					var newPrice = function () {
						if (maybePrice.$ === 1) {
							return A2($author$project$Page$Packings$Form$PriceField, $elm$core$Maybe$Nothing, price);
						} else {
							var p = maybePrice.a;
							return A2(
								$author$project$Page$Packings$Form$PriceField,
								$elm$core$Maybe$Just(p),
								price);
						}
					}();
					return A2(
						$author$project$Page$Packings$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{dI: newPrice});
						},
						model);
				}
			case 1:
				var _v2 = $author$project$Session$cred(model.aA);
				if (!_v2.$) {
					var cred = _v2.a;
					return A2(
						$elm$core$Tuple$mapFirst,
						function (status) {
							return _Utils_update(
								model,
								{dL: status});
						},
						A3($author$project$Page$Packings$Form$save, cred, model.dL, model.o));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Packings$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$PackingList));
				}
			case 2:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Packings$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$PackingList));
				}
			case 4:
				if (!msg.a.$) {
					var item = msg.a.a;
					var status = A3(
						$author$project$Page$Packings$Form$Editing,
						$author$project$Items$Slug$idToSlug(item.cY),
						_List_Nil,
						{
							eL: item.eL,
							dI: A2(
								$author$project$Page$Packings$Form$PriceField,
								$elm$core$String$toFloat(item.dI),
								item.dI),
							gv: item.gv,
							ha: item.ha
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dL: status}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aQ: $author$project$Page$Packings$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Packings$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Packings$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			case 6:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Packings$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Packings$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			case 8:
				var item = msg.a;
				var cartExist = $elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (c) {
							return _Utils_eq(c.fB, item.fB);
						},
						model.o)) > 0;
				var newCart = cartExist ? model.o : A2(
					$elm$core$List$append,
					model.o,
					_List_fromArray(
						[
							_Utils_update(
							item,
							{gf: item.gd})
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			default:
				var item = msg.a;
				var maybeInt = msg.b;
				var newCart = A2(
					$elm$core$List$filter,
					function (i) {
						return i.gd > 0;
					},
					A2(
						$elm$core$List$map,
						function (i) {
							return _Utils_eq(item.fB, i.fB) ? _Utils_update(
								i,
								{
									gd: A2($elm$core$Maybe$withDefault, 0, maybeInt)
								}) : i;
						},
						model.o));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Packings$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Packings$List$Failed = {$: 3};
var $author$project$Page$Packings$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Packings$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$purchaseOrder(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Packings$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Packings$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Packings$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Packings$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Packings$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Packings$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3(
							$author$project$Page$Packings$List$delete,
							$author$project$Items$Slug$idToSlug(model.Y),
							cred,
							$author$project$Page$Packings$List$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Packings$View$CompletedDeleteItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Packings$View$Failed = {$: 3};
var $author$project$Page$Packings$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Packings$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$location(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Packings$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Packings$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Packings$View$Failed}),
						$author$project$Log$error);
				}
			case 3:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aD: $author$project$Page$Packings$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aD: $author$project$Page$Packings$View$Failed}),
						$author$project$Log$error);
				}
			case 4:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Packings$View$delete, slug, cred, $author$project$Page$Packings$View$CompletedDeleteItem));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Home));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
		}
	});
var $author$project$Page$Payments$Form$CompletedSaveForm = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Payments$Form$Loaded = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Page$Payments$Form$priceFieldToString = function (priceField) {
	if (priceField.a.$ === 1) {
		var _v1 = priceField.a;
		var price = priceField.b;
		return price;
	} else {
		var price = priceField.b;
		return price;
	}
};
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Page$Payments$Form$confirmTemplate = F4(
	function (cred, model, form, toMsg) {
		var totalRMB = function () {
			var _v0 = model.Z;
			if (!_v0.$) {
				var details = _v0.a;
				return $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function (d) {
							return A2(
								$elm$core$Maybe$withDefault,
								0.0,
								$elm$core$String$toFloat(d.gb));
						},
						details));
			} else {
				return 0.0;
			}
		}();
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'conversion_rate',
					$elm$json$Json$Encode$string(
						$author$project$Page$Payments$Form$priceFieldToString(form.eR))),
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string('RMB')),
					_Utils_Tuple2(
					'create_id',
					$elm$json$Json$Encode$int(0)),
					_Utils_Tuple2(
					'amount',
					$elm$json$Json$Encode$string(
						$elm$core$String$fromFloat(totalRMB)))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$poReadyToPay,
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Payments$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Payments$Form$trimFields = function (form) {
	return {ei: form.ei, eR: form.eR};
};
var $author$project$Page$Payments$Form$validateField = function (_v0) {
	var form = _v0;
	var _v1 = form.eR;
	if (!_v1.a.$) {
		var q = _v1.a.a;
		return (q <= 0) ? _List_fromArray(
			['Please input Conversion Rate']) : ((form.ei === '0') ? _List_fromArray(
			['Error Empty Amount']) : _List_Nil);
	} else {
		return _List_fromArray(
			['Invalid Conversion']);
	}
};
var $author$project$Page$Payments$Form$validate = function (form) {
	var trimmedForm = $author$project$Page$Payments$Form$trimFields(form);
	var _v0 = $author$project$Page$Payments$Form$validateField(trimmedForm);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Payments$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: function (f) {
								return _Utils_update(
									f,
									{
										eR: A2(
											$author$project$Page$Payments$Form$PriceField,
											$elm$core$String$toFloat(search),
											search)
									});
							}(model.v)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				if (!msg.a.$) {
					var items = msg.a.a;
					var newAmount = $elm$core$String$fromFloat(
						$elm$core$List$sum(
							A2(
								$elm$core$List$map,
								function (i) {
									return A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toFloat(i.gb));
								},
								items)));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Z: $author$project$Page$Payments$Form$Loaded(items),
								v: function (f) {
									return _Utils_update(
										f,
										{ei: newAmount});
								}(model.v)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				var _v1 = $author$project$Page$Payments$Form$validate(model.v);
				if (!_v1.$) {
					var validForm = _v1.a;
					var _v2 = $author$project$Session$cred(model.aA);
					if (!_v2.$) {
						var cred = _v2.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bD: true}),
							A4($author$project$Page$Payments$Form$confirmTemplate, cred, model, model.v, $author$project$Page$Payments$Form$CompletedSaveForm));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$PaymentList));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ax: _List_fromArray(
									['Server Error']),
								bD: false
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
		}
	});
var $author$project$Page$Payments$List$Failed = {$: 3};
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.ci, posixMinutes) < 0) {
					return posixMinutes + era.b9;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		cK: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		c8: month,
		d4: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cK;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).c8;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).d4;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $author$project$Page$Payments$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b9: A2($justinmimbs$time_extra$Time$Extra$toOffset, tz, model.aG),
							ck: tz
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aG: time}),
					$elm$core$Platform$Cmd$none);
			case 3:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Payments$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Payments$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Payments$List$fetchFeed, model.aA, page, model.ai));
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Payments$List$fetchFeed, model.aA, 1, model.ai));
			case 6:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 8:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Payments$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Payments$View$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Payments$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					var newAmount = $elm$core$String$fromFloat(
						$elm$core$List$sum(
							A2(
								$elm$core$List$map,
								function (i) {
									return A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toFloat(i.gb));
								},
								items)));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Z: $author$project$Page$Payments$View$Loaded(items),
								v: function (f) {
									return _Utils_update(
										f,
										{ei: newAmount});
								}(model.v)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			default:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								v: function (f) {
									return _Utils_update(
										f,
										{
											ei: item.ei,
											eR: A2(
												$author$project$Page$Payments$View$PriceField,
												$elm$core$String$toFloat(item.eR),
												item.eR)
										});
								}(model.v)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
		}
	});
var $author$project$Page$PurchaseOrders$Confirm$CompletedSaveForm = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$Loading = {$: 1};
var $author$project$Page$PurchaseOrders$Confirm$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$CompletedLoadItemImage = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Confirm$fetchItem = F3(
	function (session, articleSlug, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var item_id = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toInt(
				$author$project$Items$Slug$toString(articleSlug)));
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Item$fetch,
						$author$project$Session$cred(session),
						articleSlug,
						$author$project$Page$PurchaseOrders$Confirm$CompletedLoadItem),
						A3(
						$author$project$Image$fetchImages,
						$author$project$Session$cred(session),
						articleSlug,
						$author$project$Page$PurchaseOrders$Confirm$CompletedLoadItemImage(item_id))
					])));
	});
var $author$project$Api$Endpoint$purchaseOrderDetailCannotFind = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'cannot-find'
			]),
		_List_Nil);
};
var $author$project$Api$Endpoint$purchaseOrderDetailConfirmPrice = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'confirm-price'
			]),
		_List_Nil);
};
var $author$project$Api$Endpoint$purchaseOrderRevisedRequest = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'revise'
			]),
		_List_Nil);
};
var $author$project$Page$PurchaseOrders$Confirm$sendSaveStatus = F5(
	function (status, session, form, slug, toMsg) {
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(form.fB)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(form.gd)),
					_Utils_Tuple2(
					'supplier_id',
					$elm$json$Json$Encode$int(0))
				]));
		var endPoint = (status === 1) ? $author$project$Api$Endpoint$purchaseOrderRevisedRequest(slug) : ((!status) ? $author$project$Api$Endpoint$purchaseOrderDetailCannotFind(slug) : ((status === 3) ? $author$project$Api$Endpoint$purchaseOrderDetailConfirmPrice(slug) : $author$project$Api$Endpoint$purchaseOrderDetailConfirmPrice(slug)));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			endPoint,
			$author$project$Session$cred(session),
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$Confirm$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v),
					ax: _List_Nil
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$PurchaseOrders$Confirm$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					var newLoadedItem = A2(
						$elm$core$List$map,
						function (_v1) {
							var id = _v1.a;
							var itemLocal = _v1.b;
							var images = _v1.c;
							return _Utils_eq(id, item.cY) ? _Utils_Tuple3(
								id,
								$author$project$Page$PurchaseOrders$Confirm$Loaded(item),
								images) : _Utils_Tuple3(id, itemLocal, images);
						},
						model.u);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{u: newLoadedItem}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				if (!msg.b.$) {
					var item_id = msg.a;
					var items = msg.b.a;
					var newLoadedItem = A2(
						$elm$core$List$map,
						function (_v2) {
							var id = _v2.a;
							var itemLocal = _v2.b;
							var images = _v2.c;
							return _Utils_eq(id, item_id) ? _Utils_Tuple3(id, itemLocal, items) : _Utils_Tuple3(id, itemLocal, images);
						},
						model.u);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{u: newLoadedItem}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.b.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				if (!msg.a.$) {
					var items = msg.a.a;
					var newCart = A2(
						$elm$core$List$map,
						function (i) {
							return {
								fB: i.fB,
								fC: i.fC,
								fD: i.fD,
								gb: A2(
									$author$project$Page$PurchaseOrders$Confirm$PriceField,
									$elm$core$String$toFloat(i.gb),
									i.gb),
								gd: i.gd,
								dL: i.dL
							};
						},
						items);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: newCart}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				var stock = msg.a;
				var mode = msg.b;
				var itemExist = $elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (_v3) {
							var i = _v3.a;
							return _Utils_eq(i, stock.fB);
						},
						model.u)) > 0;
				var newLoadedItems = itemExist ? model.u : A2(
					$elm$core$List$append,
					model.u,
					_List_fromArray(
						[
							_Utils_Tuple3(stock.fB, $author$project$Page$PurchaseOrders$Confirm$Loading, _List_Nil)
						]));
				return itemExist ? A2(
					$author$project$Page$PurchaseOrders$Confirm$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{fB: stock.fB, gb: stock.gb, gd: stock.gd});
					},
					_Utils_update(
						model,
						{u: newLoadedItems, an: mode})) : A3(
					$author$project$Page$PurchaseOrders$Confirm$fetchItem,
					model.aA,
					$author$project$Items$Slug$idToSlug(stock.fB),
					A2(
						$author$project$Page$PurchaseOrders$Confirm$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{fB: stock.fB, gb: stock.gb, gd: stock.gd});
						},
						_Utils_update(
							model,
							{u: newLoadedItems, an: mode})));
			case 8:
				var str = msg.a;
				var _v4 = $elm$core$String$toInt(str);
				if (!_v4.$) {
					var quantity = _v4.a;
					return A2(
						$author$project$Page$PurchaseOrders$Confirm$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{gd: quantity});
						},
						model);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var status = msg.a;
				var newCart = A2(
					$elm$core$List$map,
					function (i) {
						return _Utils_eq(i.fB, model.v.fB) ? _Utils_update(
							i,
							{gd: model.v.gd, dL: status}) : i;
					},
					model.o);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart, bD: true, an: 10}),
					A5($author$project$Page$PurchaseOrders$Confirm$sendSaveStatus, status, model.aA, model.v, model.bG, $author$project$Page$PurchaseOrders$Confirm$CompletedSaveForm));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bD: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Confirm$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Confirm$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList, bD: false}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
		}
	});
var $author$project$Page$PurchaseOrders$Form$CompletedSaveForm = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$PurchaseOrders$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$PurchaseOrders$Form$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Endpoint$purchaseOrderConfirm = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'confirm'
			]),
		_List_Nil);
};
var $author$project$Page$PurchaseOrders$Form$confirmTemplate = F3(
	function (cred, slug, toMsg) {
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$purchaseOrderConfirm(slug),
			$elm$core$Maybe$Just(cred),
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$Form$encodeStock = F2(
	function (stocks, supplier) {
		var singleEncode = function (stock) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'item_id',
						$elm$json$Json$Encode$int(stock.fB)),
						_Utils_Tuple2(
						'quantity',
						$elm$json$Json$Encode$int(stock.gd)),
						_Utils_Tuple2(
						'supplier_id',
						$elm$json$Json$Encode$int(supplier.cY))
					]));
		};
		return A2($elm$json$Json$Encode$list, singleEncode, stocks);
	});
var $author$project$Page$PurchaseOrders$Form$editTemplate = F5(
	function (cred, carts, _v0, slug, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					A2($author$project$Page$PurchaseOrders$Form$encodeStock, carts, form.n)),
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string('RMB')),
					_Utils_Tuple2(
					'pay_to',
					$elm$json$Json$Encode$int(1)),
					_Utils_Tuple2(
					'flow_type',
					$elm$json$Json$Encode$int(1))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$purchaseOrder(slug),
			cred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$Form$CompletedLoadItems = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Endpoint$itemsNotTemplate = function (params) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			['items', 'not-template']),
		params);
};
var $author$project$Item$fetchAllNotTemplate = F4(
	function (pageSize, maybeCred, params, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$itemsNotTemplate(params),
			maybeCred,
			toMsg,
			A2($author$project$Item$decoder, maybeCred, pageSize));
	});
var $author$project$Page$PurchaseOrders$Form$fetchFeed = F4(
	function (form, session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchAllNotTemplate, 20, maybeCred, params, $author$project$Page$PurchaseOrders$Form$CompletedLoadItems);
	});
var $author$project$Page$PurchaseOrders$Form$saveTemplate = F4(
	function (maybeCred, carts, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					A2($author$project$Page$PurchaseOrders$Form$encodeStock, carts, form.n)),
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string('RMB')),
					_Utils_Tuple2(
					'pay_to',
					$elm$json$Json$Encode$int(1)),
					_Utils_Tuple2(
					'flow_type',
					$elm$json$Json$Encode$int(1))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$purchaseOrders(_List_Nil),
			maybeCred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Username$toString = function (_v0) {
	var username = _v0;
	return username;
};
var $author$project$Page$PurchaseOrders$Form$updateForm = F3(
	function (transform, model, carts) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					o: carts,
					v: transform(model.v),
					ax: _List_Nil
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Api$username = function (_v0) {
	var val = _v0.a;
	return val;
};
var $author$project$Page$PurchaseOrders$Form$Cart = 0;
var $author$project$Page$PurchaseOrders$Form$Comments = 2;
var $author$project$Page$PurchaseOrders$Form$Supplier = 1;
var $author$project$Page$PurchaseOrders$Form$fieldsToValidate = _List_fromArray(
	[0, 1, 2]);
var $author$project$Page$PurchaseOrders$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$PurchaseOrders$Form$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		n: form.n
	};
};
var $author$project$Page$PurchaseOrders$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Form$validateField = F4(
	function (_v0, model, carts, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$PurchaseOrders$Form$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return (!$elm$core$List$length(carts)) ? _List_fromArray(
							['Please Select Item']) : _List_Nil;
					case 1:
						return (!model.v.n.cY) ? _List_fromArray(
							['Please Select Supplier']) : _List_Nil;
					default:
						return (model.v.eL === '') ? _List_fromArray(
							['Please Add Comments']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$PurchaseOrders$Form$validate = F3(
	function (model, carts, form) {
		var trimmedForm = $author$project$Page$PurchaseOrders$Form$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A3($author$project$Page$PurchaseOrders$Form$validateField, trimmedForm, model, carts),
			$author$project$Page$PurchaseOrders$Form$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$PurchaseOrders$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					model,
					A4($author$project$Page$PurchaseOrders$Form$fetchFeed, model.v, model.aA, 1, model.bE));
			case 4:
				if (!msg.a.$) {
					var item = msg.a.a;
					var username = function () {
						var _v1 = $author$project$Session$cred(model.aA);
						if (!_v1.$) {
							var cred = _v1.a;
							return $author$project$Username$toString(
								$author$project$Api$username(cred));
						} else {
							return '';
						}
					}();
					return ((item.fb === 1) && _Utils_eq(item.eW, username)) ? A3(
						$author$project$Page$PurchaseOrders$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									eL: item.eL,
									n: {cJ: 'THB', e2: false, O: '', cY: item.gK, fU: item.gL}
								});
						},
						_Utils_update(
							model,
							{
								K: A3(
									$author$project$Page$PurchaseOrders$Form$Editing,
									$author$project$Items$Slug$idToSlug(item.cY),
									_List_Nil,
									{
										eL: item.eL,
										n: {cJ: 'THB', e2: false, O: '', cY: item.gK, fU: item.gL}
									})
							}),
						model.o) : _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$PoList));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$PurchaseOrders$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 7:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aF: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 13:
				var comments = msg.a;
				return A3(
					$author$project$Page$PurchaseOrders$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model,
					model.o);
			case 2:
				var stock = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fB, stock.fB) ? _Utils_update(
						cart,
						{gd: quantityInt}) : cart;
				};
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return cart.gd > 0;
					},
					A2($elm$core$List$map, updateCartFunc, model.o));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var stock = msg.a;
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fB, stock.cY) ? _Utils_update(
						cart,
						{gd: 1}) : cart;
				};
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return _Utils_eq(cart.fB, stock.cY);
					},
					model.o);
				var carts = ((!$elm$core$List$length(filteredCart)) && (!_Utils_eq(stock.cY, model.v.n.cY))) ? A2(
					$elm$core$List$append,
					model.o,
					_List_fromArray(
						[
							{fB: stock.cY, fC: stock.g5, fD: '', gb: '', gc: 0, gd: 1, gf: 0, dL: 1}
						])) : model.o;
				var newCart = A2($elm$core$List$map, updateCartFunc, carts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var stock = msg.a;
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return !_Utils_eq(cart.fB, stock.cY);
					},
					model.o);
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 10:
				var _v3 = A3($author$project$Page$PurchaseOrders$Form$validate, model, model.o, model.v);
				if (!_v3.$) {
					var validForm = _v3.a;
					var _v4 = _Utils_Tuple2(
						$author$project$Session$cred(model.aA),
						model.K);
					if (!_v4.a.$) {
						if (_v4.b.$ === 4) {
							var cred = _v4.a.a;
							var _v5 = _v4.b;
							var slug = _v5.a;
							var form = _v5.c;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ax: _List_Nil}),
								A5($author$project$Page$PurchaseOrders$Form$editTemplate, cred, model.o, validForm, slug, $author$project$Page$PurchaseOrders$Form$CompletedSaveForm));
						} else {
							var cred = _v4.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ax: _List_Nil}),
								A4(
									$author$project$Page$PurchaseOrders$Form$saveTemplate,
									$author$project$Session$cred(model.aA),
									model.o,
									validForm,
									$author$project$Page$PurchaseOrders$Form$CompletedSaveForm));
						}
					} else {
						var _v6 = _v4.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var _v7 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					model.K);
				if ((!_v7.a.$) && (_v7.b.$ === 4)) {
					var cred = _v7.a.a;
					var _v8 = _v7.b;
					var slug = _v8.a;
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$PurchaseOrders$Form$confirmTemplate, cred, slug, $author$project$Page$PurchaseOrders$Form$CompletedSaveForm));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 12:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$PoList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			default:
				var val = msg.a;
				var locationIdInt = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(val));
				var maybeSupplier = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (l) {
							return _Utils_eq(l.cY, locationIdInt);
						},
						model.aF));
				if (!maybeSupplier.$) {
					var supplier = maybeSupplier.a;
					return A3(
						$author$project$Page$PurchaseOrders$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{n: supplier});
						},
						model,
						model.o);
				} else {
					return A3(
						$author$project$Page$PurchaseOrders$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
								});
						},
						model,
						model.o);
				}
		}
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedSaveForm = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$priceFieldToString = function (priceField) {
	if (priceField.a.$ === 1) {
		var _v1 = priceField.a;
		var price = priceField.b;
		return price;
	} else {
		var price = priceField.b;
		return price;
	}
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$encodeStock = F2(
	function (stocks, supplier) {
		var singleEncode = function (stock) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'item_id',
						$elm$json$Json$Encode$int(stock.fB)),
						_Utils_Tuple2(
						'quantity',
						$elm$json$Json$Encode$int(stock.gd)),
						_Utils_Tuple2(
						'supplier_id',
						$elm$json$Json$Encode$int(supplier.cY)),
						_Utils_Tuple2(
						'price_total',
						$elm$json$Json$Encode$string(
							$author$project$Page$PurchaseOrders$KnownPriceForm$priceFieldToString(stock.gb)))
					]));
		};
		return A2($elm$json$Json$Encode$list, singleEncode, stocks);
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$editTemplate = F5(
	function (cred, carts, _v0, slug, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					A2($author$project$Page$PurchaseOrders$KnownPriceForm$encodeStock, carts, form.n)),
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string(form.cJ)),
					_Utils_Tuple2(
					'pay_to',
					$elm$json$Json$Encode$int(
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(form.f4)))),
					_Utils_Tuple2(
					'flow_type',
					$elm$json$Json$Encode$int(0)),
					_Utils_Tuple2(
					'ship_fee',
					$elm$json$Json$Encode$string(
						$author$project$Page$PurchaseOrders$KnownPriceForm$priceFieldToString(form.dI)))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$purchaseOrder(slug),
			cred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadItems = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$fetchFeed = F4(
	function (form, session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchAllNotTemplate, 20, maybeCred, params, $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedLoadItems);
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$saveTemplate = F4(
	function (maybeCred, carts, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					A2($author$project$Page$PurchaseOrders$KnownPriceForm$encodeStock, carts, form.n)),
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string(form.cJ)),
					_Utils_Tuple2(
					'pay_to',
					$elm$json$Json$Encode$int(
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(form.f4)))),
					_Utils_Tuple2(
					'flow_type',
					$elm$json$Json$Encode$int(0)),
					_Utils_Tuple2(
					'ship_fee',
					$elm$json$Json$Encode$string(
						$author$project$Page$PurchaseOrders$KnownPriceForm$priceFieldToString(form.dI)))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$purchaseOrders(_List_Nil),
			maybeCred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$updateForm = F3(
	function (transform, model, carts) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					o: carts,
					v: transform(model.v),
					ax: _List_Nil
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$Cart = 0;
var $author$project$Page$PurchaseOrders$KnownPriceForm$Comments = 2;
var $author$project$Page$PurchaseOrders$KnownPriceForm$ShipFee = 3;
var $author$project$Page$PurchaseOrders$KnownPriceForm$Supplier = 1;
var $author$project$Page$PurchaseOrders$KnownPriceForm$fieldsToValidate = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Page$PurchaseOrders$KnownPriceForm$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$PurchaseOrders$KnownPriceForm$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		cJ: form.cJ,
		f4: form.f4,
		dI: form.dI,
		n: form.n
	};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$validateField = F4(
	function (_v0, model, carts, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$PurchaseOrders$KnownPriceForm$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return (!$elm$core$List$length(carts)) ? _List_fromArray(
							['Please Select Item']) : (($elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (i) {
									var _v2 = i.gb;
									if (!_v2.a.$) {
										return false;
									} else {
										return true;
									}
								},
								carts)) > 0) ? _List_fromArray(
							['Please Enter Price']) : _List_Nil);
					case 3:
						var _v3 = model.v.dI;
						if (!_v3.a.$) {
							return _List_Nil;
						} else {
							return _List_fromArray(
								['Please Fix Ship Fee']);
						}
					case 1:
						return (!model.v.n.cY) ? _List_fromArray(
							['Please Select Supplier']) : _List_Nil;
					default:
						return (model.v.eL === '') ? _List_fromArray(
							['Please Add Comments']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$validate = F3(
	function (model, carts, form) {
		var trimmedForm = $author$project$Page$PurchaseOrders$KnownPriceForm$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A3($author$project$Page$PurchaseOrders$KnownPriceForm$validateField, trimmedForm, model, carts),
			$author$project$Page$PurchaseOrders$KnownPriceForm$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					model,
					A4($author$project$Page$PurchaseOrders$KnownPriceForm$fetchFeed, model.v, model.aA, 1, model.bE));
			case 5:
				if (!msg.a.$) {
					var item = msg.a.a;
					var newSupplier = {cJ: 'THB', e2: false, O: '', cY: item.gK, fU: item.gL};
					return A3(
						$author$project$Page$PurchaseOrders$KnownPriceForm$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									eL: item.eL,
									dI: A2(
										$author$project$Page$PurchaseOrders$KnownPriceForm$PriceField,
										$elm$core$String$toFloat(item.dI),
										item.dI),
									n: newSupplier
								});
						},
						_Utils_update(
							model,
							{
								K: A3(
									$author$project$Page$PurchaseOrders$KnownPriceForm$Editing,
									$author$project$Items$Slug$idToSlug(item.cY),
									_List_Nil,
									{
										eL: item.eL,
										cJ: 'THB',
										f4: '0',
										dI: A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, ''),
										n: newSupplier
									})
							}),
						model.o);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$PurchaseOrders$KnownPriceForm$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 8:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aF: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 7:
				if (!msg.a.$) {
					var items = msg.a.a;
					var newCart = A2(
						$elm$core$List$map,
						function (i) {
							return {
								fB: i.fB,
								fC: i.fC,
								fD: i.fD,
								gb: A2(
									$author$project$Page$PurchaseOrders$KnownPriceForm$PriceField,
									$elm$core$String$toFloat(i.gb),
									i.gb),
								gd: i.gd
							};
						},
						items);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: newCart}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 13:
				var comments = msg.a;
				return A3(
					$author$project$Page$PurchaseOrders$KnownPriceForm$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model,
					model.o);
			case 2:
				var stock = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fB, stock.fB) ? _Utils_update(
						cart,
						{gd: quantityInt}) : cart;
				};
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return cart.gd > 0;
					},
					A2($elm$core$List$map, updateCartFunc, model.o));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var stock = msg.a;
				var priceStr = msg.b;
				var newPrice = function () {
					if (A2($elm$core$String$right, 1, priceStr) === '.') {
						return A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, priceStr);
					} else {
						var _v2 = $elm$core$String$toFloat(priceStr);
						if (_v2.$ === 1) {
							return A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, priceStr);
						} else {
							var p = _v2.a;
							return A2(
								$author$project$Page$PurchaseOrders$KnownPriceForm$PriceField,
								$elm$core$Maybe$Just(p),
								priceStr);
						}
					}
				}();
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fB, stock.fB) ? _Utils_update(
						cart,
						{gb: newPrice}) : cart;
				};
				var newCart = A2($elm$core$List$map, updateCartFunc, model.o);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var price = msg.a;
				if (A2($elm$core$String$right, 1, price) === '.') {
					return A3(
						$author$project$Page$PurchaseOrders$KnownPriceForm$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									dI: A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, price)
								});
						},
						model,
						model.o);
				} else {
					var maybePrice = $elm$core$String$toFloat(price);
					var newPrice = function () {
						if (maybePrice.$ === 1) {
							return A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, price);
						} else {
							var p = maybePrice.a;
							return A2(
								$author$project$Page$PurchaseOrders$KnownPriceForm$PriceField,
								$elm$core$Maybe$Just(p),
								price);
						}
					}();
					return A3(
						$author$project$Page$PurchaseOrders$KnownPriceForm$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{dI: newPrice});
						},
						model,
						model.o);
				}
			case 9:
				var stock = msg.a;
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fB, stock.cY) ? _Utils_update(
						cart,
						{gd: 1}) : cart;
				};
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return _Utils_eq(cart.fB, stock.cY);
					},
					model.o);
				var carts = ((!$elm$core$List$length(filteredCart)) && (!_Utils_eq(stock.cY, model.v.n.cY))) ? A2(
					$elm$core$List$append,
					model.o,
					_List_fromArray(
						[
							{
							fB: stock.cY,
							fC: stock.g5,
							fD: '',
							gb: A2($author$project$Page$PurchaseOrders$KnownPriceForm$PriceField, $elm$core$Maybe$Nothing, ''),
							gd: 1
						}
						])) : model.o;
				var newCart = A2($elm$core$List$map, updateCartFunc, carts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var val = msg.a;
				return A3(
					$author$project$Page$PurchaseOrders$KnownPriceForm$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{f4: val});
					},
					model,
					model.o);
			case 11:
				var _v4 = A3($author$project$Page$PurchaseOrders$KnownPriceForm$validate, model, model.o, model.v);
				if (!_v4.$) {
					var validForm = _v4.a;
					var _v5 = _Utils_Tuple2(
						$author$project$Session$cred(model.aA),
						model.K);
					if (!_v5.a.$) {
						if (_v5.b.$ === 4) {
							var cred = _v5.a.a;
							var _v6 = _v5.b;
							var slug = _v6.a;
							var form = _v6.c;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ax: _List_Nil}),
								A5($author$project$Page$PurchaseOrders$KnownPriceForm$editTemplate, cred, model.o, validForm, slug, $author$project$Page$PurchaseOrders$KnownPriceForm$CompletedSaveForm));
						} else {
							var cred = _v5.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ax: _List_Nil}),
								A4(
									$author$project$Page$PurchaseOrders$KnownPriceForm$saveTemplate,
									$author$project$Session$cred(model.aA),
									model.o,
									validForm,
									$author$project$Page$PurchaseOrders$KnownPriceForm$CompletedSaveForm));
						}
					} else {
						var _v7 = _v5.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v4.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 12:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$PoList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$KnownPriceForm$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$KnownPriceForm$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			default:
				var val = msg.a;
				var locationIdInt = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(val));
				var maybeSupplier = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (l) {
							return _Utils_eq(l.cY, locationIdInt);
						},
						model.aF));
				if (!maybeSupplier.$) {
					var supplier = maybeSupplier.a;
					return A3(
						$author$project$Page$PurchaseOrders$KnownPriceForm$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{n: supplier});
						},
						model,
						model.o);
				} else {
					return A3(
						$author$project$Page$PurchaseOrders$KnownPriceForm$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
								});
						},
						model,
						model.o);
				}
		}
	});
var $author$project$Page$PurchaseOrders$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PurchaseOrders$List$Failed = {$: 3};
var $author$project$Page$PurchaseOrders$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$purchaseOrder(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$PurchaseOrders$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$PurchaseOrders$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$PurchaseOrders$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$PurchaseOrders$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$PurchaseOrders$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3(
							$author$project$Page$PurchaseOrders$List$delete,
							$author$project$Items$Slug$idToSlug(model.Y),
							cred,
							$author$project$Page$PurchaseOrders$List$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$PurchaseOrders$Receive$CompletedImportItem = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$Failed = {$: 3};
var $author$project$Page$PurchaseOrders$Receive$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$ToastyMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$myConfig = A2($pablen$toasty$Toasty$delay, 5000, $pablen$toasty$Toasty$Defaults$config);
var $author$project$Page$PurchaseOrders$Receive$addToast = F2(
	function (toast, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A4(
			$pablen$toasty$Toasty$addToast,
			$author$project$Page$PurchaseOrders$Receive$myConfig,
			$author$project$Page$PurchaseOrders$Receive$ToastyMsg,
			toast,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$Page$PurchaseOrders$Receive$CompletedLoadLocations = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$fetchFeedLocation = F3(
	function (session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Location$fetchAll, maybeCred, params, $author$project$Page$PurchaseOrders$Receive$CompletedLoadLocations);
	});
var $author$project$Page$PurchaseOrders$Receive$CompletedLoadGuideLocation = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$fetchGuideLocation = F3(
	function (session, slug, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			model,
			A3($author$project$Location$fetchGuideLocation, maybeCred, slug, $author$project$Page$PurchaseOrders$Receive$CompletedLoadGuideLocation));
	});
var $author$project$Page$PurchaseOrders$Receive$itemImport = F4(
	function (maybeCred, slug, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(form.gd)),
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(form.fB)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(form.P))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$poImport(slug),
			maybeCred,
			body,
			toMsg,
			$author$project$Stock$decodeFullList);
	});
var $author$project$Page$PurchaseOrders$Receive$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$PurchaseOrders$Receive$ItemID = 1;
var $author$project$Page$PurchaseOrders$Receive$LocationID = 0;
var $author$project$Page$PurchaseOrders$Receive$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$PurchaseOrders$Receive$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$PurchaseOrders$Receive$trimFields = function (form) {
	return {eL: form.eL, fB: form.fB, P: form.P, gd: form.gd};
};
var $author$project$Page$PurchaseOrders$Receive$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Receive$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$PurchaseOrders$Receive$InvalidEntry(field),
			function () {
				if (field === 1) {
					return (form.fB <= 0) ? _List_fromArray(
						['Please select Item']) : _List_Nil;
				} else {
					return (form.P <= 0) ? _List_fromArray(
						['Please select Location']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$PurchaseOrders$Receive$validate = function (form) {
	var trimmedForm = $author$project$Page$PurchaseOrders$Receive$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$PurchaseOrders$Receive$validateField(trimmedForm),
		$author$project$Page$PurchaseOrders$Receive$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$PurchaseOrders$Receive$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$PurchaseOrders$Receive$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$PurchaseOrders$Receive$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Z: $author$project$Page$PurchaseOrders$Receive$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Z: $author$project$Page$PurchaseOrders$Receive$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 11:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aM: locations}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Z: $author$project$Page$PurchaseOrders$Receive$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 15:
				var itemID = msg.a;
				var details = $elm$core$List$head(
					function () {
						var _v2 = model.Z;
						if (_v2.$ === 2) {
							var detail = _v2.a;
							return A2(
								$elm$core$List$filter,
								function (s) {
									return _Utils_eq(s.fB, itemID);
								},
								detail);
						} else {
							return _List_Nil;
						}
					}());
				var localQuantity = function () {
					if (!details.$) {
						var d = details.a;
						return d.gd;
					} else {
						return 0;
					}
				}();
				return A3(
					$author$project$Page$PurchaseOrders$Receive$fetchGuideLocation,
					model.aA,
					$author$project$Items$Slug$idToSlug(itemID),
					A2(
						$author$project$Page$PurchaseOrders$Receive$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{fB: itemID, gd: localQuantity});
						},
						model));
			case 7:
				var quantity = msg.a;
				var quantityInt = function () {
					if (!quantity.$) {
						var i = quantity.a;
						return i;
					} else {
						return 0;
					}
				}();
				return A2(
					$author$project$Page$PurchaseOrders$Receive$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{gd: quantityInt});
					},
					model);
			case 8:
				var _v4 = $author$project$Page$PurchaseOrders$Receive$validate(model.v);
				if (!_v4.$) {
					var validForm = _v4.a;
					var _v5 = $author$project$Session$cred(model.aA);
					if (!_v5.$) {
						var cred = _v5.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil, aE: true}),
							A4(
								$author$project$Page$PurchaseOrders$Receive$itemImport,
								$author$project$Session$cred(model.aA),
								model.bG,
								validForm,
								$author$project$Page$PurchaseOrders$Receive$CompletedImportItem));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v4.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{_: !model._}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{C: !model.C}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var subMsg = msg.a;
				return A4($pablen$toasty$Toasty$update, $author$project$Page$PurchaseOrders$Receive$myConfig, $author$project$Page$PurchaseOrders$Receive$ToastyMsg, subMsg, model);
			case 9:
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$PurchaseOrders$Receive$fetchFeedLocation, model.aA, 1, model.bF));
			case 10:
				if (!msg.a.$) {
					var locations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bo: $author$project$Page$PurchaseOrders$Receive$Loaded(locations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 16:
				if (!msg.a.$) {
					var stocks = msg.a.a;
					var _v6 = $elm$core$List$head(stocks);
					if (!_v6.$) {
						var stock = _v6.a;
						var newDetails = function () {
							var _v7 = model.Z;
							if (_v7.$ === 2) {
								var details = _v7.a;
								return $author$project$Page$PurchaseOrders$Receive$Loaded(
									A2(
										$elm$core$List$map,
										function (d) {
											return _Utils_eq(d.fB, model.v.fB) ? _Utils_update(
												d,
												{gf: model.v.gd}) : d;
										},
										details));
							} else {
								return $author$project$Page$PurchaseOrders$Receive$Loading;
							}
						}();
						return A2(
							$author$project$Page$PurchaseOrders$Receive$addToast,
							A2(
								$pablen$toasty$Toasty$Defaults$Success,
								'Save Success',
								'LD_ID: ' + $elm$core$String$fromInt(stock.fG)),
							A2(
								$author$project$Page$PurchaseOrders$Receive$updateForm,
								function (form) {
									return _Utils_update(
										form,
										{fB: 0, P: 0, gd: 0});
								},
								_Utils_update(
									model,
									{Z: newDetails, aM: _List_Nil, T: stock.fG, aE: false})));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aE: false}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 17:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{T: 0}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var locationID = msg.a;
				return A2(
					$author$project$Page$PurchaseOrders$Receive$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{P: locationID});
					},
					model);
			case 12:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: search}),
					$elm$core$Platform$Cmd$none);
			default:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$PurchaseOrders$Supplier$CompletedSaveForm = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$Loading = {$: 1};
var $author$project$Page$PurchaseOrders$Supplier$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$priceFieldToString = function (priceField) {
	if (priceField.a.$ === 1) {
		var _v1 = priceField.a;
		var price = priceField.b;
		return price;
	} else {
		var price = priceField.b;
		return price;
	}
};
var $author$project$Api$Endpoint$purchaseOrderDetailSavePrice = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'save-price'
			]),
		_List_Nil);
};
var $author$project$Page$PurchaseOrders$Supplier$editTemplate = F4(
	function (cred, _v0, slug, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(form.fB)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(0)),
					_Utils_Tuple2(
					'price_total',
					$elm$json$Json$Encode$string(
						$author$project$Page$PurchaseOrders$Supplier$priceFieldToString(form.gb))),
					_Utils_Tuple2(
					'supplier_id',
					$elm$json$Json$Encode$int(0))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$purchaseOrderDetailSavePrice(slug),
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$Supplier$CompletedLoadItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$CompletedLoadItemImage = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Supplier$fetchItem = F3(
	function (session, articleSlug, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var item_id = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toInt(
				$author$project$Items$Slug$toString(articleSlug)));
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3(
						$author$project$Item$fetch,
						$author$project$Session$cred(session),
						articleSlug,
						$author$project$Page$PurchaseOrders$Supplier$CompletedLoadItem),
						A3(
						$author$project$Image$fetchImages,
						$author$project$Session$cred(session),
						articleSlug,
						$author$project$Page$PurchaseOrders$Supplier$CompletedLoadItemImage(item_id))
					])));
	});
var $author$project$Api$Endpoint$purchaseOrderDetailOrdered = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'ordered'
			]),
		_List_Nil);
};
var $author$project$Api$Endpoint$purchaseOrderDetailReceived = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'po',
				$author$project$Items$Slug$toString(slug),
				'received'
			]),
		_List_Nil);
};
var $author$project$Page$PurchaseOrders$Supplier$sendSaveStatus = F5(
	function (status, session, form, slug, toMsg) {
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(form.fB)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(0)),
					_Utils_Tuple2(
					'supplier_id',
					$elm$json$Json$Encode$int(0))
				]));
		var finalEndPoint = (!status) ? $author$project$Api$Endpoint$purchaseOrderDetailCannotFind(slug) : ((status === 4) ? $author$project$Api$Endpoint$purchaseOrderDetailOrdered(slug) : ((status === 5) ? $author$project$Api$Endpoint$purchaseOrderDetailReceived(slug) : $author$project$Api$Endpoint$purchaseOrderDetailCannotFind(slug)));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			finalEndPoint,
			$author$project$Session$cred(session),
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$Supplier$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v),
					ax: _List_Nil
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$PurchaseOrders$Supplier$ItemID = 1;
var $author$project$Page$PurchaseOrders$Supplier$PriceTotal = 0;
var $author$project$Page$PurchaseOrders$Supplier$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$PurchaseOrders$Supplier$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$PurchaseOrders$Supplier$trimFields = function (form) {
	return {fB: form.fB, gb: form.gb};
};
var $author$project$Page$PurchaseOrders$Supplier$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Supplier$validateField = F3(
	function (_v0, model, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$PurchaseOrders$Supplier$InvalidEntry(field),
			function () {
				if (field === 1) {
					return (!form.fB) ? _List_fromArray(
						['Please select Item']) : _List_Nil;
				} else {
					var _v2 = form.gb;
					if (!_v2.a.$) {
						return _List_Nil;
					} else {
						return _List_fromArray(
							['Please Input Price']);
					}
				}
			}());
	});
var $author$project$Page$PurchaseOrders$Supplier$validate = F3(
	function (model, carts, form) {
		var trimmedForm = $author$project$Page$PurchaseOrders$Supplier$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A2($author$project$Page$PurchaseOrders$Supplier$validateField, trimmedForm, model),
			$author$project$Page$PurchaseOrders$Supplier$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$PurchaseOrders$Supplier$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					var newLoadedItem = A2(
						$elm$core$List$map,
						function (_v1) {
							var id = _v1.a;
							var itemLocal = _v1.b;
							var images = _v1.c;
							return _Utils_eq(id, item.cY) ? _Utils_Tuple3(
								id,
								$author$project$Page$PurchaseOrders$Supplier$Loaded(item),
								images) : _Utils_Tuple3(id, itemLocal, images);
						},
						model.u);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{u: newLoadedItem}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 7:
				if (!msg.b.$) {
					var item_id = msg.a;
					var items = msg.b.a;
					var newLoadedItem = A2(
						$elm$core$List$map,
						function (_v2) {
							var id = _v2.a;
							var itemLocal = _v2.b;
							var images = _v2.c;
							return _Utils_eq(id, item_id) ? _Utils_Tuple3(id, itemLocal, items) : _Utils_Tuple3(id, itemLocal, images);
						},
						model.u);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{u: newLoadedItem}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.b.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				if (!msg.a.$) {
					var items = msg.a.a;
					var newCart = A2(
						$elm$core$List$map,
						function (i) {
							return {
								fB: i.fB,
								fC: i.fC,
								fD: i.fD,
								gb: A2(
									$author$project$Page$PurchaseOrders$Supplier$PriceField,
									$elm$core$String$toFloat(i.gb),
									i.gb),
								gd: i.gd,
								dL: i.dL
							};
						},
						items);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: newCart}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				var priceStr = msg.a;
				var newPrice = function () {
					if (A2($elm$core$String$right, 1, priceStr) === '.') {
						return A2($author$project$Page$PurchaseOrders$Supplier$PriceField, $elm$core$Maybe$Nothing, priceStr);
					} else {
						var _v3 = $elm$core$String$toFloat(priceStr);
						if (_v3.$ === 1) {
							return A2($author$project$Page$PurchaseOrders$Supplier$PriceField, $elm$core$Maybe$Nothing, priceStr);
						} else {
							var p = _v3.a;
							return A2(
								$author$project$Page$PurchaseOrders$Supplier$PriceField,
								$elm$core$Maybe$Just(p),
								priceStr);
						}
					}
				}();
				return A2(
					$author$project$Page$PurchaseOrders$Supplier$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{gb: newPrice});
					},
					model);
			case 4:
				var stock = msg.a;
				var mode = msg.b;
				var itemExist = $elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (_v4) {
							var i = _v4.a;
							return _Utils_eq(i, stock.fB);
						},
						model.u)) > 0;
				var newLoadedItems = itemExist ? model.u : A2(
					$elm$core$List$append,
					model.u,
					_List_fromArray(
						[
							_Utils_Tuple3(stock.fB, $author$project$Page$PurchaseOrders$Supplier$Loading, _List_Nil)
						]));
				return itemExist ? A2(
					$author$project$Page$PurchaseOrders$Supplier$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{fB: stock.fB, gb: stock.gb});
					},
					_Utils_update(
						model,
						{u: newLoadedItems, an: mode})) : A3(
					$author$project$Page$PurchaseOrders$Supplier$fetchItem,
					model.aA,
					$author$project$Items$Slug$idToSlug(stock.fB),
					A2(
						$author$project$Page$PurchaseOrders$Supplier$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{fB: stock.fB, gb: stock.gb});
						},
						_Utils_update(
							model,
							{u: newLoadedItems, an: mode})));
			case 8:
				var _v5 = A3($author$project$Page$PurchaseOrders$Supplier$validate, model, model.o, model.v);
				if (!_v5.$) {
					var validForm = _v5.a;
					var _v6 = $author$project$Session$cred(model.aA);
					if (!_v6.$) {
						var cred = _v6.a;
						var newCart = A2(
							$elm$core$List$map,
							function (i) {
								return _Utils_eq(i.fB, model.v.fB) ? _Utils_update(
									i,
									{gb: model.v.gb, dL: 2}) : i;
							},
							model.o);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{o: newCart, ax: _List_Nil, bD: true, an: 10}),
							A4($author$project$Page$PurchaseOrders$Supplier$editTemplate, cred, validForm, model.bG, $author$project$Page$PurchaseOrders$Supplier$CompletedSaveForm));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v5.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bD: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Supplier$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Supplier$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList, bD: false}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			default:
				var status = msg.a;
				var newCart = A2(
					$elm$core$List$map,
					function (i) {
						return _Utils_eq(i.fB, model.v.fB) ? _Utils_update(
							i,
							{dL: status}) : i;
					},
					model.o);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart, bD: true, an: 10}),
					A5($author$project$Page$PurchaseOrders$Supplier$sendSaveStatus, status, model.aA, model.v, model.bG, $author$project$Page$PurchaseOrders$Supplier$CompletedSaveForm));
		}
	});
var $author$project$Page$PurchaseOrders$View$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PurchaseOrders$View$CompletedSaveForm = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PurchaseOrders$View$Failed = {$: 3};
var $author$project$Page$PurchaseOrders$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PurchaseOrders$View$delete = F4(
	function (cost, slug, cred, toMsg) {
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cost',
					$elm$json$Json$Encode$string(cost.eS)),
					_Utils_Tuple2(
					'cost_type',
					$elm$json$Json$Encode$string(cost.eT)),
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(cost.eL)),
					_Utils_Tuple2(
					'purchase_order_id',
					$elm$json$Json$Encode$int(cost.cY))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$poOtherCost(slug),
			cred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$PurchaseOrders$View$saveOtherCost = F4(
	function (maybeCred, articleSlug, _v0, toMsg) {
		var form = _v0;
		var cost = function () {
			var _v1 = form.eS;
			if (!_v1.a.$) {
				var str = _v1.b;
				return str;
			} else {
				return '';
			}
		}();
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'cost',
					$elm$json$Json$Encode$string(cost)),
					_Utils_Tuple2(
					'cost_type',
					$elm$json$Json$Encode$string(form.eT)),
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'purchase_order_id',
					$elm$json$Json$Encode$int(form.gc))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$poOtherCost(articleSlug),
			maybeCred,
			body,
			toMsg,
			$author$project$Models$PoOtherCost$fullDecoder);
	});
var $author$project$Page$PurchaseOrders$View$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$PurchaseOrders$View$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		eS: form.eS,
		eT: form.eT,
		gc: form.gc
	};
};
var $author$project$Page$PurchaseOrders$View$validateField = function (_v0) {
	var form = _v0;
	var _v1 = form.eS;
	if (!_v1.a.$) {
		return _List_Nil;
	} else {
		return _List_fromArray(
			['Please Fix Cost']);
	}
};
var $author$project$Page$PurchaseOrders$View$validate = F2(
	function (model, form) {
		var trimmedForm = $author$project$Page$PurchaseOrders$View$trimFields(form);
		var problems = $author$project$Page$PurchaseOrders$View$validateField(trimmedForm);
		return ($elm$core$List$length(problems) > 0) ? $elm$core$Result$Err(problems) : $elm$core$Result$Ok(trimmedForm);
	});
var $author$project$Page$PurchaseOrders$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$PurchaseOrders$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$PurchaseOrders$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Z: $author$project$Page$PurchaseOrders$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{Z: $author$project$Page$PurchaseOrders$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $author$project$Page$PurchaseOrders$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 5:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								w: $author$project$Page$PurchaseOrders$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				if (!msg.a.$) {
					var cost = msg.a.a;
					var newCost = function () {
						var _v1 = model.w;
						if (_v1.$ === 2) {
							var costs = _v1.a;
							return $author$project$Page$PurchaseOrders$View$Loaded(
								A2(
									$elm$core$List$append,
									_List_fromArray(
										[cost]),
									costs));
						} else {
							return model.w;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{w: newCost}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 8:
				var _v2 = A2($author$project$Page$PurchaseOrders$View$validate, model, model.v);
				if (!_v2.$) {
					var validForm = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: _List_Nil}),
						A4(
							$author$project$Page$PurchaseOrders$View$saveOtherCost,
							$author$project$Session$cred(model.aA),
							model.bG,
							validForm,
							$author$project$Page$PurchaseOrders$View$CompletedSaveForm));
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bj: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: function (f) {
								return _Utils_update(
									f,
									{eT: str});
							}(model.v)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							v: function (f) {
								return _Utils_update(
									f,
									{
										eS: A2(
											$author$project$Page$PurchaseOrders$View$PriceField,
											$elm$core$String$toFloat(str),
											str)
									});
							}(model.v)
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var id = msg.a;
				var newOtherCost = function () {
					var _v5 = model.w;
					if (_v5.$ === 2) {
						var costs = _v5.a;
						return $author$project$Page$PurchaseOrders$View$Loaded(
							A2(
								$elm$core$List$filter,
								function (c) {
									return !_Utils_eq(c.cY, id);
								},
								costs));
					} else {
						return model.w;
					}
				}();
				var costEdit = function () {
					var _v4 = model.w;
					if (_v4.$ === 2) {
						var costs = _v4.a;
						return $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (c) {
									return _Utils_eq(c.cY, id);
								},
								costs));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var _v3 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					costEdit);
				if ((!_v3.a.$) && (!_v3.b.$)) {
					var cred = _v3.a.a;
					var c = _v3.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{w: newOtherCost}),
						A4($author$project$Page$PurchaseOrders$View$delete, c, model.bG, cred, $author$project$Page$PurchaseOrders$View$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Suppliers$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Suppliers$Form$CompletedCreate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Suppliers$Form$CompletedEdit = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Suppliers$Form$Creating = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Suppliers$Form$Saving = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Suppliers$Form$create = F3(
	function (_v0, cred, toMsg) {
		var form = _v0;
		var article = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(form.fU)),
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(form.O)),
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string(form.cJ))
				]));
		var body = $elm$http$Http$jsonBody(article);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$suppliers(_List_Nil),
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$author$project$Supplier$fullDecoder);
	});
var $author$project$Page$Suppliers$Form$edit = F4(
	function (articleSlug, _v0, cred, toMsg) {
		var form = _v0;
		var body = $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(form.fU)),
						_Utils_Tuple2(
						'email',
						$elm$json$Json$Encode$string(form.O)),
						_Utils_Tuple2(
						'currency',
						$elm$json$Json$Encode$string(form.cJ))
					])));
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$supplier(articleSlug),
			cred,
			body,
			toMsg,
			$author$project$Supplier$fullDecoder);
	});
var $author$project$Page$Suppliers$Form$Currency = 1;
var $author$project$Page$Suppliers$Form$Title = 0;
var $author$project$Page$Suppliers$Form$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$Suppliers$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Suppliers$Form$trimFields = function (form) {
	return {
		cJ: $elm$core$String$trim(form.cJ),
		O: $elm$core$String$trim(form.O),
		fU: $elm$core$String$trim(form.fU)
	};
};
var $author$project$Page$Suppliers$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Suppliers$Form$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Suppliers$Form$InvalidEntry(field),
			function () {
				if (!field) {
					return $elm$core$String$isEmpty(form.fU) ? _List_fromArray(
						['title can\'t be blank.']) : _List_Nil;
				} else {
					return ((form.cJ !== 'CNY') && ((form.cJ !== 'THB') && (form.cJ !== 'USD'))) ? _List_fromArray(
						['Error Currency is required']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Suppliers$Form$validate = function (form) {
	var trimmedForm = $author$project$Page$Suppliers$Form$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Suppliers$Form$validateField(trimmedForm),
		$author$project$Page$Suppliers$Form$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Suppliers$Form$save = F2(
	function (cred, status) {
		switch (status.$) {
			case 4:
				var slug = status.a;
				var form = status.c;
				var _v1 = $author$project$Page$Suppliers$Form$validate(form);
				if (!_v1.$) {
					var validForm = _v1.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Suppliers$Form$Saving, slug, form),
						A4($author$project$Page$Suppliers$Form$edit, slug, validForm, cred, $author$project$Page$Suppliers$Form$CompletedEdit));
				} else {
					var problems = _v1.a;
					return _Utils_Tuple2(
						A3($author$project$Page$Suppliers$Form$Editing, slug, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var form = status.b;
				var _v2 = $author$project$Page$Suppliers$Form$validate(form);
				if (!_v2.$) {
					var validForm = _v2.a;
					return _Utils_Tuple2(
						$author$project$Page$Suppliers$Form$Creating(form),
						A3($author$project$Page$Suppliers$Form$create, validForm, cred, $author$project$Page$Suppliers$Form$CompletedCreate));
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Suppliers$Form$EditingNew, problems, form),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(status, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Suppliers$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Suppliers$Form$savingError = F2(
	function (error, status) {
		var problems = _List_fromArray(
			[
				$author$project$Page$Suppliers$Form$ServerError('Error saving article')
			]);
		switch (status.$) {
			case 3:
				var slug = status.a;
				var form = status.b;
				return A3($author$project$Page$Suppliers$Form$Editing, slug, problems, form);
			case 6:
				var form = status.a;
				return A2($author$project$Page$Suppliers$Form$EditingNew, problems, form);
			default:
				return status;
		}
	});
var $author$project$Supplier$slug = function (item) {
	return $author$project$Items$Slug$idToSlug(item.cY);
};
var $author$project$Page$Suppliers$Form$updateForm = F2(
	function (transform, model) {
		var newModel = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return model;
				case 1:
					return model;
				case 2:
					return model;
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Suppliers$Form$Saving,
								slug,
								transform(form))
						});
				case 4:
					var slug = _v0.a;
					var errors = _v0.b;
					var form = _v0.c;
					return _Utils_update(
						model,
						{
							dL: A3(
								$author$project$Page$Suppliers$Form$Editing,
								slug,
								errors,
								transform(form))
						});
				case 5:
					var errors = _v0.a;
					var form = _v0.b;
					return _Utils_update(
						model,
						{
							dL: A2(
								$author$project$Page$Suppliers$Form$EditingNew,
								errors,
								transform(form))
						});
				default:
					var form = _v0.a;
					return _Utils_update(
						model,
						{
							dL: $author$project$Page$Suppliers$Form$Creating(
								transform(form))
						});
			}
		}();
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Suppliers$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 2:
				var name = msg.a;
				return A2(
					$author$project$Page$Suppliers$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{fU: name});
					},
					model);
			case 3:
				var email = msg.a;
				return A2(
					$author$project$Page$Suppliers$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{O: email});
					},
					model);
			case 4:
				var email = msg.a;
				return A2(
					$author$project$Page$Suppliers$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{cJ: email});
					},
					model);
			case 1:
				var cred = msg.a;
				return A2(
					$elm$core$Tuple$mapFirst,
					function (status) {
						return _Utils_update(
							model,
							{dL: status});
					},
					A2($author$project$Page$Suppliers$Form$save, cred, model.dL));
			case 6:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Suppliers$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Suppliers));
				}
			case 5:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dL: A2($author$project$Page$Suppliers$Form$savingError, error, model.dL)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var item = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Suppliers));
				}
			default:
				if (!msg.a.$) {
					var item = msg.a.a;
					var status = A3(
						$author$project$Page$Suppliers$Form$Editing,
						$author$project$Supplier$slug(item),
						_List_Nil,
						{cJ: item.cJ, O: item.O, fU: item.fU});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dL: status}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Suppliers$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Suppliers$List$Failed = {$: 3};
var $author$project$Page$Suppliers$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Suppliers$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$location(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Suppliers$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Suppliers$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Suppliers$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Suppliers$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Suppliers$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Suppliers$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3(
							$author$project$Page$Suppliers$List$delete,
							$author$project$Items$Slug$idToSlug(model.Y),
							cred,
							$author$project$Page$Suppliers$List$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Suppliers$View$CompletedDeleteItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Suppliers$View$Failed = {$: 3};
var $author$project$Page$Suppliers$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Suppliers$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$location(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Suppliers$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Suppliers$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Suppliers$View$Failed}),
						$author$project$Log$error);
				}
			case 3:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aD: $author$project$Page$Suppliers$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aD: $author$project$Page$Suppliers$View$Failed}),
						$author$project$Log$error);
				}
			case 4:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Suppliers$View$delete, slug, cred, $author$project$Page$Suppliers$View$CompletedDeleteItem));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Home));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
		}
	});
var $author$project$Page$Templates$Cal$CompletedLoadItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Templates$Cal$CompletedSaveForm = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Templates$Cal$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Templates$Cal$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Templates$Cal$CompletedLoadItems = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Templates$Cal$fetchFeed = F4(
	function (form, session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Template$fetchList, maybeCred, params, $author$project$Page$Templates$Cal$CompletedLoadItems);
	});
var $author$project$Page$Templates$Cal$CompletedLoadIngredients = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Api$Endpoint$wipIngredientsRecursive = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'templates',
				'ingredients-recursive',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Wip$fetchIngredientsRecursive = F3(
	function (maybeCred, slug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$wipIngredientsRecursive(slug),
			maybeCred,
			toMsg,
			$author$project$Wip$ingredientsListDecoder);
	});
var $author$project$Page$Templates$Cal$fetchIngredients = F2(
	function (session, stock) {
		var slug = $author$project$Items$Slug$idToSlug(stock.cY);
		var maybeCred = $author$project$Session$cred(session);
		return A3(
			$author$project$Wip$fetchIngredientsRecursive,
			maybeCred,
			slug,
			$author$project$Page$Templates$Cal$CompletedLoadIngredients(stock));
	});
var $author$project$Page$Templates$Cal$CompletedLoadInt = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Templates$Cal$encodeTemplateList = function (stocks) {
	var singleEncode = function (stock) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(stock.cY))
				]));
	};
	return A2($elm$json$Json$Encode$list, singleEncode, stocks);
};
var $author$project$Api$Endpoint$wipIngredientsFromTemplates = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['templates', 'ingredients-from-template']),
	_List_Nil);
var $author$project$Page$Templates$Cal$fetchIntIngredients = F2(
	function (session, ingredients) {
		var wipList = A2(
			$elm$core$List$map,
			function (_v0) {
				var t = _v0.a;
				var i = _v0.b;
				return t;
			},
			ingredients);
		var maybeCred = $author$project$Session$cred(session);
		var body = $elm$http$Http$jsonBody(
			$author$project$Page$Templates$Cal$encodeTemplateList(wipList));
		return A5($author$project$Api$post, $author$project$Api$Endpoint$wipIngredientsFromTemplates, maybeCred, body, $author$project$Page$Templates$Cal$CompletedLoadInt, $author$project$Wip$ingredientsListDecoder);
	});
var $author$project$Page$Templates$Cal$CompletedLoadWipList = function (a) {
	return {$: 17, a: a};
};
var $author$project$Api$Endpoint$calWipList = A2(
	$author$project$Api$Endpoint$url,
	_List_fromArray(
		['cal', 'wip-list']),
	_List_Nil);
var $author$project$Page$Templates$Cal$fetchWipIngredients = F2(
	function (session, ingredients) {
		var wipList = A2(
			$elm$core$List$map,
			function (_v0) {
				var t = _v0.a;
				var i = _v0.b;
				return t;
			},
			ingredients);
		var maybeCred = $author$project$Session$cred(session);
		var body = $elm$http$Http$jsonBody(
			$author$project$Page$Templates$Cal$encodeTemplateList(wipList));
		return A5($author$project$Api$post, $author$project$Api$Endpoint$calWipList, maybeCred, body, $author$project$Page$Templates$Cal$CompletedLoadWipList, $author$project$Wip$ingredientsListDecoder);
	});
var $author$project$Page$Templates$Cal$flatten2D = function (list) {
	return A3($elm$core$List$foldr, $elm$core$Basics$append, _List_Nil, list);
};
var $author$project$Page$Templates$Cal$mapFunc = F5(
	function (singleItemIngredients, newIngredients, main_supplier_id, model, item_id) {
		var _v0 = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (i) {
					return _Utils_eq(i.cY, item_id);
				},
				singleItemIngredients));
		if (_v0.$ === 1) {
			return {cY: 0, fC: '', fD: '', gd: 0, dx: main_supplier_id};
		} else {
			var ingredient = _v0.a;
			var wipQuantity = function () {
				var _v9 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (i) {
							return _Utils_eq(i.cY, ingredient.cY);
						},
						model.a6));
				if (!_v9.$) {
					var w = _v9.a;
					return w.gd;
				} else {
					return 0;
				}
			}();
			var tmpQuantityList = A2(
				$elm$core$List$map,
				function (_v7) {
					var w = _v7.a;
					var wl = _v7.b;
					var _v8 = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (t) {
								return _Utils_eq(t.cY, ingredient.cY);
							},
							wl));
					if (_v8.$ === 1) {
						return _Utils_Tuple3(w.gd, 0, 0);
					} else {
						var st = _v8.a;
						return _Utils_Tuple3(w.gd, st.gd, st.dx);
					}
				},
				newIngredients);
			var totalDemand = $elm$core$List$sum(
				A2(
					$elm$core$List$map,
					function (_v6) {
						var d = _v6.a;
						var q = _v6.b;
						return d * q;
					},
					tmpQuantityList));
			var qoh = function () {
				var _v3 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v4) {
							var d = _v4.a;
							var q = _v4.b;
							var r = _v4.c;
							return r > 0;
						},
						tmpQuantityList));
				if (!_v3.$) {
					var _v5 = _v3.a;
					var r = _v5.c;
					return r;
				} else {
					return 0;
				}
			}();
			var poQuantity = function () {
				var _v2 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (i) {
							return _Utils_eq(i.cY, ingredient.cY);
						},
						model.aX));
				if (!_v2.$) {
					var w = _v2.a;
					return w.gd;
				} else {
					return 0;
				}
			}();
			var intQuantity = function () {
				var _v1 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (i) {
							return _Utils_eq(i.cY, ingredient.cY);
						},
						model.aN));
				if (!_v1.$) {
					var w = _v1.a;
					return w.gd;
				} else {
					return 0;
				}
			}();
			var totalSupplier = ((qoh + wipQuantity) + poQuantity) + intQuantity;
			var orderAmount = (_Utils_cmp(totalDemand, totalSupplier) > 0) ? (totalDemand - totalSupplier) : 0;
			return _Utils_update(
				ingredient,
				{gd: orderAmount, dx: main_supplier_id});
		}
	});
var $author$project$Page$Templates$Cal$encodeStock = function (stocks) {
	var singleEncode = function (stock) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(stock.cY)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(stock.gd)),
					_Utils_Tuple2(
					'supplier_id',
					$elm$json$Json$Encode$int(stock.dx))
				]));
	};
	return A2($elm$json$Json$Encode$list, singleEncode, stocks);
};
var $author$project$Page$Templates$Cal$save = F4(
	function (maybeCred, carts, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'flow_type',
					$elm$json$Json$Encode$int(1)),
					_Utils_Tuple2(
					'currency',
					$elm$json$Json$Encode$string('RMB')),
					_Utils_Tuple2(
					'pay_to',
					$elm$json$Json$Encode$int(1)),
					_Utils_Tuple2(
					'cart',
					$author$project$Page$Templates$Cal$encodeStock(carts))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$purchaseOrders(_List_Nil),
			maybeCred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $elm$core$List$sortBy = _List_sortBy;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $author$project$Page$Templates$Cal$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v),
					ax: _List_Nil
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Templates$Cal$Comments = 3;
var $author$project$Page$Templates$Cal$Ingredient = 2;
var $author$project$Page$Templates$Cal$Quantity = 1;
var $author$project$Page$Templates$Cal$TargetItemField = 0;
var $author$project$Page$Templates$Cal$fieldsToValidate = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Page$Templates$Cal$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Templates$Cal$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		n: form.n
	};
};
var $author$project$Page$Templates$Cal$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Templates$Cal$validateField = F3(
	function (_v0, model, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Templates$Cal$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return ((!model.v.n.cY) || ($elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (i) {
									return !i.dx;
								},
								model.m)) > 0)) ? _List_fromArray(
							['Please Select Supplier']) : _List_Nil;
					case 1:
						return ($elm$core$List$sum(
							A2(
								$elm$core$List$map,
								function (i) {
									return i.gd;
								},
								model.m)) <= 0) ? _List_fromArray(
							['Please Input Quantity']) : _List_Nil;
					case 3:
						return (form.eL === '') ? _List_fromArray(
							['Please Add Comments']) : _List_Nil;
					default:
						return (!$elm$core$List$length(model.m)) ? _List_fromArray(
							['Please Select Template']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Templates$Cal$validate = F2(
	function (model, form) {
		var trimmedForm = $author$project$Page$Templates$Cal$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A2($author$project$Page$Templates$Cal$validateField, trimmedForm, model),
			$author$project$Page$Templates$Cal$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$Templates$Cal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					model,
					A4($author$project$Page$Templates$Cal$fetchFeed, model.v, model.aA, 1, model.bE));
			case 6:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Templates$Cal$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				if (!msg.a.$) {
					var item = msg.a.a;
					var newIngredients = A2(
						$elm$core$List$map,
						function (_v1) {
							var w = _v1.a;
							var wl = _v1.b;
							return _Utils_eq(w.cY, item.cY) ? _Utils_Tuple2(
								_Utils_update(
									w,
									{dx: item.dx}),
								wl) : _Utils_Tuple2(w, wl);
						},
						model.i);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{i: newIngredients}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 15:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aN: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 16:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aX: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 17:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a6: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 7:
				if (!msg.b.$) {
					var template = msg.a;
					var items = msg.b.a;
					var newIngredients = A2(
						$elm$core$List$map,
						function (_v3) {
							var w = _v3.a;
							var wl = _v3.b;
							return _Utils_eq(w.cY, template.a1) ? _Utils_Tuple2(w, items) : _Utils_Tuple2(w, wl);
						},
						model.i);
					var singleItemIngredients = $author$project$Page$Templates$Cal$flatten2D(
						A2(
							$elm$core$List$map,
							function (_v2) {
								var t = _v2.a;
								var i = _v2.b;
								return i;
							},
							newIngredients));
					var uniqueList = $elm_community$list_extra$List$Extra$unique(
						A2(
							$elm$core$List$map,
							function (i) {
								return i.cY;
							},
							singleItemIngredients));
					var newList = A2(
						$elm$core$List$map,
						A4($author$project$Page$Templates$Cal$mapFunc, singleItemIngredients, newIngredients, model.v.n.cY, model),
						uniqueList);
					var sortedList = A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.cY;
						},
						newList);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{m: sortedList, i: newIngredients}),
						$elm$core$Platform$Cmd$none);
				} else {
					var template = msg.a;
					var error = msg.b.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 8:
				if (!msg.a.$) {
					var suppliers = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aF: $author$project$Page$Templates$Cal$Loaded(suppliers)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 12:
				var comments = msg.a;
				return A2(
					$author$project$Page$Templates$Cal$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model);
			case 3:
				var item = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				var newList = A2(
					$elm$core$List$map,
					function (w) {
						return _Utils_eq(w.cY, item.cY) ? _Utils_update(
							w,
							{gd: quantityInt}) : w;
					},
					model.m);
				var sortedList = A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.cY;
					},
					newList);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: sortedList}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var item = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				var updateCartFunc = function (_v7) {
					var template = _v7.a;
					var ingredients = _v7.b;
					return _Utils_eq(template.cY, item.cY) ? _Utils_Tuple2(
						_Utils_update(
							template,
							{gd: quantityInt}),
						ingredients) : _Utils_Tuple2(template, ingredients);
				};
				var newIngredients = A2(
					$elm$core$List$filter,
					function (_v6) {
						var w = _v6.a;
						var lw = _v6.b;
						return w.gd > 0;
					},
					A2($elm$core$List$map, updateCartFunc, model.i));
				var singleItemIngredients = $author$project$Page$Templates$Cal$flatten2D(
					A2(
						$elm$core$List$map,
						function (_v5) {
							var t = _v5.a;
							var i = _v5.b;
							return i;
						},
						newIngredients));
				var uniqueList = $elm_community$list_extra$List$Extra$unique(
					A2(
						$elm$core$List$map,
						function (i) {
							return i.cY;
						},
						singleItemIngredients));
				var newList = A2(
					$elm$core$List$map,
					A4($author$project$Page$Templates$Cal$mapFunc, singleItemIngredients, newIngredients, model.v.n.cY, model),
					uniqueList);
				var sortedList = A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.cY;
					},
					newList);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: sortedList, i: newIngredients}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var template = msg.a;
				var filteredCart = A2(
					$elm$core$List$filter,
					function (_v9) {
						var w = _v9.a;
						var wl = _v9.b;
						return _Utils_eq(w.cY, template.a1);
					},
					model.i);
				var newIngredients = (!$elm$core$List$length(filteredCart)) ? A2(
					$elm$core$List$append,
					model.i,
					_List_fromArray(
						[
							_Utils_Tuple2(
							{cY: template.a1, fC: template.dQ, fD: template.gO, gd: 1, dx: 0},
							_List_Nil)
						])) : model.i;
				return ($elm$core$List$length(filteredCart) > 0) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{i: newIngredients}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$Page$Templates$Cal$fetchIngredients, model.aA, template),
								A2($author$project$Page$Templates$Cal$fetchIntIngredients, model.aA, newIngredients),
								A2($author$project$Page$Templates$Cal$fetchWipIngredients, model.aA, newIngredients),
								A3(
								$author$project$Item$fetch,
								$author$project$Session$cred(model.aA),
								$author$project$Items$Slug$idToSlug(template.a1),
								$author$project$Page$Templates$Cal$CompletedLoadItem)
							])));
			case 10:
				var _v10 = A2($author$project$Page$Templates$Cal$validate, model, model.v);
				if (!_v10.$) {
					var validForm = _v10.a;
					var _v11 = $author$project$Session$cred(model.aA);
					if (!_v11.$) {
						var cred = _v11.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil}),
							A4(
								$author$project$Page$Templates$Cal$save,
								$author$project$Session$cred(model.aA),
								model.m,
								validForm,
								$author$project$Page$Templates$Cal$CompletedSaveForm));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v10.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$PoList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Templates$Cal$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Templates$Cal$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			case 14:
				var wi = msg.a;
				var val = msg.b;
				var supplierID = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(val));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							m: A2(
								$elm$core$List$map,
								function (i) {
									return _Utils_eq(wi.cY, i.cY) ? _Utils_update(
										i,
										{dx: supplierID}) : i;
								},
								model.m)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var val = msg.a;
				var locationIdInt = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(val));
				var localLocations = function () {
					var _v14 = model.aF;
					if (!_v14.$) {
						var suppliers = _v14.a;
						return $author$project$PaginatedList$values(suppliers);
					} else {
						return _List_Nil;
					}
				}();
				var maybeSupplier = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (l) {
							return _Utils_eq(l.cY, locationIdInt);
						},
						localLocations));
				if (!maybeSupplier.$) {
					var supplier = maybeSupplier.a;
					return A2(
						$author$project$Page$Templates$Cal$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{n: supplier});
						},
						_Utils_update(
							model,
							{
								m: A2(
									$elm$core$List$map,
									function (i) {
										return _Utils_update(
											i,
											{dx: supplier.cY});
									},
									model.m)
							}));
				} else {
					return A2(
						$author$project$Page$Templates$Cal$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									n: {cJ: 'THB', e2: false, O: '', cY: 0, fU: ''}
								});
						},
						model);
				}
		}
	});
var $author$project$Page$Templates$Form$CompletedSaveForm = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Templates$Form$Editing = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Templates$Form$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Templates$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Templates$Form$encodeStock = function (stocks) {
	var singleEncode = function (stock) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'item_id',
					$elm$json$Json$Encode$int(stock.fB)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(stock.gd))
				]));
	};
	return A2($elm$json$Json$Encode$list, singleEncode, stocks);
};
var $author$project$Page$Templates$Form$editTemplate = F5(
	function (cred, carts, _v0, slug, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					$author$project$Page$Templates$Form$encodeStock(carts)),
					_Utils_Tuple2(
					'target_item_id',
					$elm$json$Json$Encode$int(form.l.cY))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$template(slug),
			cred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Templates$Form$CompletedLoadItems = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Templates$Form$fetchFeed = F4(
	function (form, session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Item$fetchAll, 20, maybeCred, params, $author$project$Page$Templates$Form$CompletedLoadItems);
	});
var $author$project$Page$Templates$Form$saveTemplate = F4(
	function (maybeCred, carts, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					$author$project$Page$Templates$Form$encodeStock(carts)),
					_Utils_Tuple2(
					'target_item_id',
					$elm$json$Json$Encode$int(form.l.cY))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$templates(_List_Nil),
			maybeCred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Templates$Form$updateForm = F3(
	function (transform, model, carts) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					o: carts,
					v: transform(model.v),
					ax: _List_Nil
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Templates$Form$Cart = 0;
var $author$project$Page$Templates$Form$TargetItemField = 1;
var $author$project$Page$Templates$Form$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$Templates$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Templates$Form$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		l: form.l
	};
};
var $author$project$Page$Templates$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Templates$Form$validateField = F4(
	function (_v0, model, carts, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Templates$Form$InvalidEntry(field),
			function () {
				if (!field) {
					return (!$elm$core$List$length(carts)) ? _List_fromArray(
						['Please Select Item']) : _List_Nil;
				} else {
					return (!model.v.l.cY) ? _List_fromArray(
						['Please Select Target Item']) : _List_Nil;
				}
			}());
	});
var $author$project$Page$Templates$Form$validate = F3(
	function (model, carts, form) {
		var trimmedForm = $author$project$Page$Templates$Form$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A3($author$project$Page$Templates$Form$validateField, trimmedForm, model, carts),
			$author$project$Page$Templates$Form$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$Templates$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					model,
					A4($author$project$Page$Templates$Form$fetchFeed, model.v, model.aA, 1, model.bE));
			case 4:
				if (!msg.a.$) {
					var item = msg.a.a;
					return A3(
						$author$project$Page$Templates$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									eL: item.eL,
									l: {cY: item.a1, g5: item.dQ}
								});
						},
						_Utils_update(
							model,
							{
								K: A3(
									$author$project$Page$Templates$Form$Editing,
									$author$project$Items$Slug$idToSlug(item.cY),
									_List_Nil,
									{
										eL: item.eL,
										l: {cY: item.a1, g5: item.dQ}
									})
							}),
						model.o);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Templates$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: items}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 11:
				var comments = msg.a;
				return A3(
					$author$project$Page$Templates$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model,
					model.o);
			case 2:
				var stock = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fB, stock.fB) ? _Utils_update(
						cart,
						{gd: quantityInt}) : cart;
				};
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return cart.gd > 0;
					},
					A2($elm$core$List$map, updateCartFunc, model.o));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var stock = msg.a;
				var updateCartFunc = function (cart) {
					return _Utils_eq(cart.fB, stock.cY) ? _Utils_update(
						cart,
						{gd: 1}) : cart;
				};
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return _Utils_eq(cart.fB, stock.cY);
					},
					model.o);
				var carts = ((!$elm$core$List$length(filteredCart)) && (!_Utils_eq(stock.cY, model.v.l.cY))) ? A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							{fB: stock.cY, fC: stock.g5, fD: '', gd: 1}
						]),
					model.o) : model.o;
				var newCart = A2($elm$core$List$map, updateCartFunc, carts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var stock = msg.a;
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return !_Utils_eq(cart.fB, stock.cY);
					},
					model.o);
				return A3(
					$author$project$Page$Templates$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{
								l: {cY: stock.cY, g5: stock.g5}
							});
					},
					model,
					newCart);
			case 9:
				var _v2 = A3($author$project$Page$Templates$Form$validate, model, model.o, model.v);
				if (!_v2.$) {
					var validForm = _v2.a;
					var _v3 = _Utils_Tuple2(
						$author$project$Session$cred(model.aA),
						model.K);
					if (!_v3.a.$) {
						if (_v3.b.$ === 4) {
							var cred = _v3.a.a;
							var _v4 = _v3.b;
							var slug = _v4.a;
							var form = _v4.c;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ax: _List_Nil}),
								A5($author$project$Page$Templates$Form$editTemplate, cred, model.o, validForm, slug, $author$project$Page$Templates$Form$CompletedSaveForm));
						} else {
							var cred = _v3.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{ax: _List_Nil}),
								A4(
									$author$project$Page$Templates$Form$saveTemplate,
									$author$project$Session$cred(model.aA),
									model.o,
									validForm,
									$author$project$Page$Templates$Form$CompletedSaveForm));
						}
					} else {
						var _v5 = _v3.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$TemplateList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Templates$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Templates$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
		}
	});
var $author$project$Page$Templates$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Templates$List$Failed = {$: 3};
var $author$project$Page$Templates$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Templates$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$template(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Templates$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Templates$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Templates$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Templates$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Templates$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Templates$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3(
							$author$project$Page$Templates$List$delete,
							$author$project$Items$Slug$idToSlug(model.Y),
							cred,
							$author$project$Page$Templates$List$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Templates$View$CompletedDeleteItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Templates$View$Failed = {$: 3};
var $author$project$Page$Templates$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Templates$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$item(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Templates$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Templates$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Templates$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $author$project$Page$Templates$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{br: $author$project$Page$Templates$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Home));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Templates$View$delete, slug, cred, $author$project$Page$Templates$View$CompletedDeleteItem));
		}
	});
var $author$project$Page$Wips$Form$CompletedSaveForm = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Wips$Form$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Wips$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wips$Form$CompletedLoadItems = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Wips$Form$fetchFeed = F4(
	function (form, session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A3($author$project$Template$fetchList, maybeCred, params, $author$project$Page$Wips$Form$CompletedLoadItems);
	});
var $author$project$Page$Wips$Form$CompletedLoadIngredients = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Endpoint$wipIngredients = function (slug) {
	return A2(
		$author$project$Api$Endpoint$url,
		_List_fromArray(
			[
				'templates',
				'ingredients',
				$author$project$Items$Slug$toString(slug)
			]),
		_List_Nil);
};
var $author$project$Wip$fetchIngredients = F3(
	function (maybeCred, slug, toMsg) {
		return A4(
			$author$project$Api$get,
			$author$project$Api$Endpoint$wipIngredients(slug),
			maybeCred,
			toMsg,
			$author$project$Wip$ingredientsListDecoder);
	});
var $author$project$Page$Wips$Form$fetchIngredients = F3(
	function (session, stock, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var slug = $author$project$Items$Slug$idToSlug(stock.cY);
		var maybeCred = $author$project$Session$cred(session);
		return _Utils_Tuple2(
			model,
			A3($author$project$Wip$fetchIngredients, maybeCred, slug, $author$project$Page$Wips$Form$CompletedLoadIngredients));
	});
var $author$project$Page$Wips$Form$saveTemplate = F3(
	function (maybeCred, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(form.gd)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(form.p.cY)),
					_Utils_Tuple2(
					'template_id',
					$elm$json$Json$Encode$int(form.l.cY))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$wips(_List_Nil),
			maybeCred,
			body,
			toMsg,
			$author$project$Wip$fullDecoder);
	});
var $author$project$Page$Wips$Form$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v),
					ax: _List_Nil
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Wips$Form$Ingredient = 2;
var $author$project$Page$Wips$Form$Location = 3;
var $author$project$Page$Wips$Form$Quantity = 1;
var $author$project$Page$Wips$Form$TargetItemField = 0;
var $author$project$Page$Wips$Form$fieldsToValidate = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Page$Wips$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Wips$Form$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		p: form.p,
		gd: form.gd,
		l: form.l
	};
};
var $author$project$Page$Wips$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Wips$Form$validateField = F3(
	function (_v0, model, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Wips$Form$InvalidEntry(field),
			function () {
				switch (field) {
					case 0:
						return (!model.v.l.cY) ? _List_fromArray(
							['Please Select Target Item']) : _List_Nil;
					case 1:
						return (!model.v.gd) ? _List_fromArray(
							['Please Input Quantity']) : _List_Nil;
					case 3:
						return (!model.v.p.cY) ? _List_fromArray(
							['Please Input Location']) : _List_Nil;
					default:
						var _v2 = model.i;
						if (!_v2.$) {
							var ingredients = _v2.a;
							var filterQuantity = function (i) {
								return _Utils_cmp(i.gd * model.v.gd, i.dx) > 0;
							};
							var filteredProblem = A2($elm$core$List$filter, filterQuantity, ingredients);
							var problemStrList = A2(
								$elm$core$List$map,
								function (p) {
									return p.fC + 'Not enough';
								},
								filteredProblem);
							return problemStrList;
						} else {
							return _List_Nil;
						}
				}
			}());
	});
var $author$project$Page$Wips$Form$validate = F2(
	function (model, form) {
		var trimmedForm = $author$project$Page$Wips$Form$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A2($author$project$Page$Wips$Form$validateField, trimmedForm, model),
			$author$project$Page$Wips$Form$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$Wips$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					model,
					A4($author$project$Page$Wips$Form$fetchFeed, model.v, model.aA, 1, model.bE));
			case 4:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Wips$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								i: $author$project$Page$Wips$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bo: $author$project$Page$Wips$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 11:
				var comments = msg.a;
				return A2(
					$author$project$Page$Wips$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model);
			case 2:
				var maybeQuantity = msg.a;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				return A2(
					$author$project$Page$Wips$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{gd: quantityInt});
					},
					model);
			case 7:
				var stock = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 8:
				var stock = msg.a;
				return A3(
					$author$project$Page$Wips$Form$fetchIngredients,
					model.aA,
					stock,
					A2(
						$author$project$Page$Wips$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									l: {cY: stock.cY, g5: stock.dQ}
								});
						},
						_Utils_update(
							model,
							{i: $author$project$Page$Wips$Form$Loading})));
			case 9:
				var _v2 = A2($author$project$Page$Wips$Form$validate, model, model.v);
				if (!_v2.$) {
					var validForm = _v2.a;
					var _v3 = $author$project$Session$cred(model.aA);
					if (!_v3.$) {
						var cred = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil, bD: true}),
							A3(
								$author$project$Page$Wips$Form$saveTemplate,
								$author$project$Session$cred(model.aA),
								validForm,
								$author$project$Page$Wips$Form$CompletedSaveForm));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			case 10:
				if (!msg.a.$) {
					var wip = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$WipView(
								$author$project$Items$Slug$idToSlug(wip.cY))));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Wips$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Wips$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList, bD: false}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
			default:
				var val = msg.a;
				var locationIdInt = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(val));
				var localLocations = function () {
					var _v6 = model.bo;
					if (!_v6.$) {
						var locations = _v6.a;
						return $author$project$PaginatedList$values(locations);
					} else {
						return _List_Nil;
					}
				}();
				var maybeLocation = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (l) {
							return _Utils_eq(l.cY, locationIdInt);
						},
						localLocations));
				if (!maybeLocation.$) {
					var location = maybeLocation.a;
					return A2(
						$author$project$Page$Wips$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{p: location});
						},
						model);
				} else {
					return A2(
						$author$project$Page$Wips$Form$updateForm,
						function (form) {
							return _Utils_update(
								form,
								{
									p: {e2: false, cY: 0, fU: ''}
								});
						},
						model);
				}
		}
	});
var $author$project$Page$Wips$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Wips$List$Failed = {$: 3};
var $author$project$Page$Wips$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Wips$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$wip(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Wips$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Wips$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Wips$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Wips$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Wips$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Y: slug, aB: true}),
					$elm$core$Platform$Cmd$none);
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Wips$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var confirmed = msg.a;
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					confirmed);
				if ((!_v1.a.$) && _v1.b) {
					var cred = _v1.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						A3(
							$author$project$Page$Wips$List$delete,
							$author$project$Items$Slug$idToSlug(model.Y),
							cred,
							$author$project$Page$Wips$List$CompletedDeleteItem));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aB: false}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Wips$View$CompletedDeleteItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Wips$View$CompletedProceedItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Wips$View$Failed = {$: 3};
var $author$project$Page$Wips$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Wips$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$wip(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Wips$View$proceed = F3(
	function (articleSlug, cred, toMsg) {
		return A5(
			$author$project$Api$put,
			$author$project$Api$Endpoint$wip(articleSlug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Wips$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Wips$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Wips$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 6:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $author$project$Page$Wips$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{br: $author$project$Page$Wips$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 7:
				if (!msg.a.$) {
					var pickList = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aV: $author$project$Page$Wips$View$Loaded(pickList)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aV: $author$project$Page$Wips$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$WipList));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 5:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$WipList));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			case 3:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ay: true}),
					A3($author$project$Page$Wips$View$delete, slug, cred, $author$project$Page$Wips$View$CompletedDeleteItem));
			default:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ay: true}),
					A3($author$project$Page$Wips$View$proceed, slug, cred, $author$project$Page$Wips$View$CompletedProceedItem));
		}
	});
var $author$project$Page$Withdraws$Borrow$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Withdraws$Borrow$Failed = {$: 3};
var $author$project$Page$Withdraws$Borrow$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Withdraws$Borrow$delete = F4(
	function (withdrawID, ld_id, cred, toMsg) {
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'lot_detail_id',
					$elm$json$Json$Encode$int(ld_id)),
					_Utils_Tuple2(
					'withdraw_id',
					$elm$json$Json$Encode$int(withdrawID))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$borrows(_List_Nil),
			$elm$core$Maybe$Just(cred),
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$PaginatedList$total = function (_v0) {
	var info = _v0;
	return info.dU;
};
var $author$project$Page$Withdraws$Borrow$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Withdraws$Borrow$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Withdraws$Borrow$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Withdraws$Borrow$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Withdraws$Borrow$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var withdrawID = msg.b;
				var ld_id = msg.c;
				var newList = function () {
					var _v1 = model.aR;
					if (_v1.$ === 2) {
						var items = _v1.a;
						return $author$project$Page$Withdraws$Borrow$Loaded(
							A2(
								$author$project$PaginatedList$fromList,
								$author$project$PaginatedList$total(items),
								A2(
									$elm$core$List$filter,
									function (i) {
										return !(_Utils_eq(i.dx, withdrawID) && _Utils_eq(i.fG, ld_id));
									},
									$author$project$PaginatedList$values(items))));
					} else {
						return model.aR;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aR: newList}),
					A4($author$project$Page$Withdraws$Borrow$delete, withdrawID, ld_id, cred, $author$project$Page$Withdraws$Borrow$CompletedDeleteItem));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Withdraws$Borrow$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
		}
	});
var $author$project$Page$Withdraws$Form$CompletedSaveForm = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Withdraws$Form$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Withdraws$Form$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Withdraws$Form$CompletedLoadItems = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Withdraws$Form$fetchFeed = F4(
	function (form, session, page, query) {
		var params = $author$project$PaginatedList$params(
			{f1: page, ge: query, gh: 20});
		var maybeCred = $author$project$Session$cred(session);
		return A4($author$project$Stock$fetchNotInLocation, 0, maybeCred, params, $author$project$Page$Withdraws$Form$CompletedLoadItems);
	});
var $author$project$Page$Withdraws$Form$encodeStock = function (stocks) {
	var singleEncode = function (stock) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'lot_detail_id',
					$elm$json$Json$Encode$int(stock.fG)),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(stock.gd)),
					_Utils_Tuple2(
					'location_id',
					$elm$json$Json$Encode$int(stock.P))
				]));
	};
	return A2($elm$json$Json$Encode$list, singleEncode, stocks);
};
var $author$project$Page$Withdraws$Form$itemImport = F4(
	function (maybeCred, carts, _v0, toMsg) {
		var form = _v0;
		var user = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments',
					$elm$json$Json$Encode$string(form.eL)),
					_Utils_Tuple2(
					'cart',
					$author$project$Page$Withdraws$Form$encodeStock(carts)),
					_Utils_Tuple2(
					'withdraw_type',
					$elm$json$Json$Encode$int(form.hf))
				]));
		var body = $elm$http$Http$jsonBody(user);
		return A5(
			$author$project$Api$post,
			$author$project$Api$Endpoint$movementWithdraws(_List_Nil),
			maybeCred,
			body,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Withdraws$Form$updateForm = F2(
	function (transform, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					v: transform(model.v)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Withdraws$Form$Cart = 1;
var $author$project$Page$Withdraws$Form$WithdrawType = 0;
var $author$project$Page$Withdraws$Form$fieldsToValidate = _List_fromArray(
	[0, 1]);
var $author$project$Page$Withdraws$Form$Trimmed = $elm$core$Basics$identity;
var $author$project$Page$Withdraws$Form$trimFields = function (form) {
	return {
		eL: $elm$core$String$trim(form.eL),
		hf: form.hf
	};
};
var $author$project$Page$Withdraws$Form$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{j: nodeList, f: nodeListSize, h: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $author$project$Page$Withdraws$Form$validateField = F4(
	function (_v0, model, carts, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Withdraws$Form$InvalidEntry(field),
			function () {
				if (field === 1) {
					return (!$elm$core$List$length(carts)) ? _List_fromArray(
						['Please Select Item']) : _List_Nil;
				} else {
					var optionsArray = $elm$core$Array$fromList(model.bN);
					var _v2 = A2($elm$core$Array$get, form.hf, optionsArray);
					if (!_v2.$) {
						var s = _v2.a;
						return _List_Nil;
					} else {
						return _List_fromArray(
							['Please select Type']);
					}
				}
			}());
	});
var $author$project$Page$Withdraws$Form$validate = F3(
	function (model, carts, form) {
		var trimmedForm = $author$project$Page$Withdraws$Form$trimFields(form);
		var _v0 = A2(
			$elm$core$List$concatMap,
			A3($author$project$Page$Withdraws$Form$validateField, trimmedForm, model, carts),
			$author$project$Page$Withdraws$Form$fieldsToValidate);
		if (!_v0.b) {
			return $elm$core$Result$Ok(trimmedForm);
		} else {
			var problems = _v0;
			return $elm$core$Result$Err(problems);
		}
	});
var $author$project$Page$Withdraws$Form$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bE: search}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					model,
					A4($author$project$Page$Withdraws$Form$fetchFeed, model.v, model.aA, 1, model.bE));
			case 4:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Withdraws$Form$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 9:
				var comments = msg.a;
				return A2(
					$author$project$Page$Withdraws$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{eL: comments});
					},
					model);
			case 2:
				var stock = msg.a;
				var maybeQuantity = msg.b;
				var quantityInt = function () {
					if (!maybeQuantity.$) {
						var quantity = maybeQuantity.a;
						return quantity;
					} else {
						return 0;
					}
				}();
				var updateCartFunc = function (cart) {
					return (_Utils_eq(cart.fG, stock.fG) && _Utils_eq(cart.P, stock.P)) ? _Utils_update(
						cart,
						{gd: quantityInt}) : cart;
				};
				var newCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return cart.gd > 0;
					},
					A2($elm$core$List$map, updateCartFunc, model.o));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var stock = msg.a;
				var updateCartFunc = function (cart) {
					return (_Utils_eq(cart.fG, stock.fG) && (_Utils_eq(cart.P, stock.P) && (_Utils_cmp(cart.gd, stock.dx) < 0))) ? _Utils_update(
						cart,
						{gd: cart.gd + 1}) : cart;
				};
				var filteredCart = A2(
					$elm$core$List$filter,
					function (cart) {
						return _Utils_eq(cart.fG, stock.fG) && _Utils_eq(cart.P, stock.P);
					},
					model.o);
				var carts = (!$elm$core$List$length(filteredCart)) ? A2(
					$elm$core$List$append,
					model.o,
					_List_fromArray(
						[stock])) : model.o;
				var newCart = A2($elm$core$List$map, updateCartFunc, carts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newCart}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var typeKey = msg.a;
				return A2(
					$author$project$Page$Withdraws$Form$updateForm,
					function (form) {
						return _Utils_update(
							form,
							{
								hf: A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$String$toInt(typeKey))
							});
					},
					model);
			case 7:
				var _v2 = A3($author$project$Page$Withdraws$Form$validate, model, model.o, model.v);
				if (!_v2.$) {
					var validForm = _v2.a;
					var _v3 = $author$project$Session$cred(model.aA);
					if (!_v3.$) {
						var cred = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: _List_Nil}),
							A4(
								$author$project$Page$Withdraws$Form$itemImport,
								$author$project$Session$cred(model.aA),
								model.o,
								validForm,
								$author$project$Page$Withdraws$Form$CompletedSaveForm));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var problems = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: problems}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$WithdrawList));
				} else {
					var error = msg.a.a;
					var errorList = function () {
						if (error.$ === 4) {
							var errors = error.a;
							return _List_fromArray(
								[
									$author$project$Page$Withdraws$Form$ServerError(errors)
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Page$Withdraws$Form$ServerError('Unknow')
								]);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ax: errorList}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$author$project$Api$intercept401,
									$author$project$Session$navKey(model.aA),
									error)
								])));
				}
		}
	});
var $author$project$Page$Withdraws$List$CompletedDeleteItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Withdraws$List$Failed = {$: 3};
var $author$project$Page$Withdraws$List$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Withdraws$List$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$movementWithdraw(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Withdraws$List$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var items = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Withdraws$List$Loaded(items)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aR: $author$project$Page$Withdraws$List$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 3:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: page}),
					A3($author$project$Page$Withdraws$List$fetchFeed, model.aA, page, model.ai));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aP: 1}),
					A3($author$project$Page$Withdraws$List$fetchFeed, model.aA, 1, model.ai));
			case 5:
				var search = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ai: search}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Withdraws$List$delete, slug, cred, $author$project$Page$Withdraws$List$CompletedDeleteItem));
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Page$Withdraws$List$fetchFeed, model.aA, 1, model.ai));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
		}
	});
var $author$project$Page$Withdraws$View$CompletedDeleteItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Withdraws$View$Failed = {$: 3};
var $author$project$Page$Withdraws$View$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Withdraws$View$delete = F3(
	function (slug, cred, toMsg) {
		return A5(
			$author$project$Api$delete,
			$author$project$Api$Endpoint$item(slug),
			cred,
			$elm$http$Http$emptyBody,
			toMsg,
			$elm$json$Json$Decode$succeed(0));
	});
var $author$project$Page$Withdraws$View$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: session}),
					A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login));
			case 1:
				var tz = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ck: tz}),
					$elm$core$Platform$Cmd$none);
			case 2:
				if (!msg.a.$) {
					var item = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: $author$project$Page$Withdraws$View$Loaded(item)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aO: $author$project$Page$Withdraws$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 5:
				if (!msg.a.$) {
					var movements = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $author$project$Page$Withdraws$View$Loaded(movements)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{br: $author$project$Page$Withdraws$View$Failed}),
						A2(
							$author$project$Api$intercept401,
							$author$project$Session$navKey(model.aA),
							error));
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Route$replaceUrl,
							$author$project$Session$navKey(model.aA),
							$author$project$Route$Home));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bj: $author$project$Api$addServerError(model.bj)
							}),
						$author$project$Log$error);
				}
			default:
				var cred = msg.a;
				var slug = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Page$Withdraws$View$delete, slug, cred, $author$project$Page$Withdraws$View$CompletedDeleteItem));
		}
	});
var $author$project$Session$viewer = function (session) {
	if (!session.$) {
		var val = session.b;
		return $elm$core$Maybe$Just(val);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$52:
		while (true) {
			switch (_v0.a.$) {
				case 1:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						var _v2 = url.ff;
						if (!_v2.$) {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								model,
								A2(
									$elm$browser$Browser$Navigation$pushUrl,
									$author$project$Session$navKey(
										$author$project$Main$toSession(model)),
									$elm$url$Url$toString(url)));
						}
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 0:
					var url = _v0.a.a;
					var isSecuredRoute = $author$project$Route$isSecuredRoute(url);
					var isLoggedIn = function () {
						var _v3 = $author$project$Session$viewer(
							$author$project$Main$toSession(model));
						if (!_v3.$) {
							return true;
						} else {
							return false;
						}
					}();
					return (isSecuredRoute && (!isLoggedIn)) ? A2(
						$author$project$Main$changeRouteTo,
						$elm$core$Maybe$Just($author$project$Route$Login),
						model) : A2(
						$author$project$Main$changeRouteTo,
						$author$project$Route$fromUrl(url),
						model);
				case 2:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var home = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Home,
							$author$project$Main$GotHomeMsg,
							model,
							A2($author$project$Page$Home$update, subMsg, home));
					} else {
						break _v0$52;
					}
				case 3:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var login = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$Login,
							$author$project$Main$GotLoginMsg,
							model,
							A2($author$project$Page$Login$update, subMsg, login));
					} else {
						break _v0$52;
					}
				case 4:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var about = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$About,
							$author$project$Main$GotAboutMsg,
							model,
							A2($author$project$Page$About$update, subMsg, about));
					} else {
						break _v0$52;
					}
				case 5:
					if (!_v0.b.$) {
						var session = _v0.a.a;
						return _Utils_Tuple2(
							$author$project$Main$Redirect(session),
							A2(
								$author$project$Route$replaceUrl,
								$author$project$Session$navKey(session),
								$author$project$Route$Home));
					} else {
						break _v0$52;
					}
				case 6:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var _v4 = _v0.b;
						var slug = _v4.a;
						var item = _v4.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ItemForm(slug),
							$author$project$Main$GotItemFormMsg,
							model,
							A2($author$project$Page$Items$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 9:
					if (_v0.b.$ === 8) {
						var subMsg = _v0.a.a;
						var _v5 = _v0.b;
						var slug = _v5.a;
						var item = _v5.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ImageForm(slug),
							$author$project$Main$GotImageFormMsg,
							model,
							A2($author$project$Page$Images$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 10:
					if (_v0.b.$ === 9) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ImageView,
							$author$project$Main$GotImageViewMsg,
							model,
							A2($author$project$Page$Images$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 11:
					if (_v0.b.$ === 10) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ImageList,
							$author$project$Main$GotImageListMsg,
							model,
							A2($author$project$Page$Images$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 12:
					if (_v0.b.$ === 11) {
						var subMsg = _v0.a.a;
						var _v6 = _v0.b;
						var slug = _v6.a;
						var item = _v6.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PoForm(slug),
							$author$project$Main$GotPoFormMsg,
							model,
							A2($author$project$Page$PurchaseOrders$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 13:
					if (_v0.b.$ === 12) {
						var subMsg = _v0.a.a;
						var _v7 = _v0.b;
						var slug = _v7.a;
						var item = _v7.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PoKnownPriceForm(slug),
							$author$project$Main$GotPoKnownPriceFormMsg,
							model,
							A2($author$project$Page$PurchaseOrders$KnownPriceForm$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 14:
					if (_v0.b.$ === 13) {
						var subMsg = _v0.a.a;
						var _v8 = _v0.b;
						var slug = _v8.a;
						var item = _v8.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PoSupplierUpdate(slug),
							$author$project$Main$GotPoSupplierUpdateMsg,
							model,
							A2($author$project$Page$PurchaseOrders$Supplier$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 15:
					if (_v0.b.$ === 14) {
						var subMsg = _v0.a.a;
						var _v9 = _v0.b;
						var slug = _v9.a;
						var item = _v9.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PoConfirmAndChat(slug),
							$author$project$Main$GotPoConfirmAndChatMsg,
							model,
							A2($author$project$Page$PurchaseOrders$Confirm$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 16:
					if (_v0.b.$ === 15) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PoView,
							$author$project$Main$GotPoViewMsg,
							model,
							A2($author$project$Page$PurchaseOrders$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 17:
					if (_v0.b.$ === 16) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PoReceive,
							$author$project$Main$GotPoReceiveMsg,
							model,
							A2($author$project$Page$PurchaseOrders$Receive$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 18:
					if (_v0.b.$ === 17) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PoList,
							$author$project$Main$GotPoListMsg,
							model,
							A2($author$project$Page$PurchaseOrders$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 47:
					if (_v0.b.$ === 46) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$BorrowList,
							$author$project$Main$GotBorrowListMsg,
							model,
							A2($author$project$Page$Withdraws$Borrow$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 20:
					if (_v0.b.$ === 19) {
						var subMsg = _v0.a.a;
						var _v10 = _v0.b;
						var slug = _v10.a;
						var item = _v10.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$LocationForm(slug),
							$author$project$Main$GotLocationFormMsg,
							model,
							A2($author$project$Page$Locations$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 21:
					if (_v0.b.$ === 20) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$LocationView,
							$author$project$Main$GotLocationMsg,
							model,
							A2($author$project$Page$Locations$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 22:
					if (_v0.b.$ === 21) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$LocationList,
							$author$project$Main$GotLocationListMsg,
							model,
							A2($author$project$Page$Locations$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 26:
					if (_v0.b.$ === 25) {
						var subMsg = _v0.a.a;
						var _v11 = _v0.b;
						var slug = _v11.a;
						var item = _v11.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$SupplierForm(slug),
							$author$project$Main$GotSupplierFormMsg,
							model,
							A2($author$project$Page$Suppliers$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 27:
					if (_v0.b.$ === 26) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$SupplierView,
							$author$project$Main$GotSupplierMsg,
							model,
							A2($author$project$Page$Suppliers$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 28:
					if (_v0.b.$ === 27) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$SupplierList,
							$author$project$Main$GotSupplierListMsg,
							model,
							A2($author$project$Page$Suppliers$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 23:
					if (_v0.b.$ === 22) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PaymentForm,
							$author$project$Main$GotPaymentFormMsg,
							model,
							A2($author$project$Page$Payments$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 24:
					if (_v0.b.$ === 23) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PaymentView,
							$author$project$Main$GotPaymentMsg,
							model,
							A2($author$project$Page$Payments$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 25:
					if (_v0.b.$ === 24) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PaymentList,
							$author$project$Main$GotPaymentListMsg,
							model,
							A2($author$project$Page$Payments$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 29:
					if (_v0.b.$ === 28) {
						var subMsg = _v0.a.a;
						var _v12 = _v0.b;
						var slug = _v12.a;
						var item = _v12.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PackingForm(slug),
							$author$project$Main$GotPackingFormMsg,
							model,
							A2($author$project$Page$Packings$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 30:
					if (_v0.b.$ === 29) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PackingView,
							$author$project$Main$GotPackingViewMsg,
							model,
							A2($author$project$Page$Packings$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 31:
					if (_v0.b.$ === 30) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$PackingList,
							$author$project$Main$GotPackingListMsg,
							model,
							A2($author$project$Page$Packings$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 32:
					if (_v0.b.$ === 31) {
						var subMsg = _v0.a.a;
						var _v13 = _v0.b;
						var slug = _v13.a;
						var item = _v13.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$CategoryForm(slug),
							$author$project$Main$GotCategoryFormMsg,
							model,
							A2($author$project$Page$Categories$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 33:
					if (_v0.b.$ === 32) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$CategoryList,
							$author$project$Main$GotCategoryListMsg,
							model,
							A2($author$project$Page$Categories$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 34:
					if (_v0.b.$ === 33) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ImportNew,
							$author$project$Main$GotImportNewMsg,
							model,
							A2($author$project$Page$Imports$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 35:
					if (_v0.b.$ === 34) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ImportPo,
							$author$project$Main$GotImportPoMsg,
							model,
							A2($author$project$Page$Imports$Po$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 36:
					if (_v0.b.$ === 35) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ImportList,
							$author$project$Main$GotImportListMsg,
							model,
							A2($author$project$Page$Imports$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 39:
					if (_v0.b.$ === 38) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$MoveNew,
							$author$project$Main$GotMoveNewMsg,
							model,
							A2($author$project$Page$Moves$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 40:
					if (_v0.b.$ === 39) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$MoveToOriginal,
							$author$project$Main$GotMoveToOriginalMsg,
							model,
							A2($author$project$Page$Moves$Original$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 41:
					if (_v0.b.$ === 40) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$WithdrawNew,
							$author$project$Main$GotWithdrawNewMsg,
							model,
							A2($author$project$Page$Withdraws$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 45:
					if (_v0.b.$ === 44) {
						var subMsg = _v0.a.a;
						var _v14 = _v0.b;
						var slug = _v14.a;
						var item = _v14.b;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$TemplateForm(slug),
							$author$project$Main$GotTemplateFormMsg,
							model,
							A2($author$project$Page$Templates$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 49:
					if (_v0.b.$ === 48) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$WipNew,
							$author$project$Main$GotWipNewMsg,
							model,
							A2($author$project$Page$Wips$Form$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 37:
					if (_v0.b.$ === 36) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$MoveList,
							$author$project$Main$GotMoveListMsg,
							model,
							A2($author$project$Page$Moves$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 38:
					if (_v0.b.$ === 37) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$WithdrawList,
							$author$project$Main$GotWithdrawListMsg,
							model,
							A2($author$project$Page$Withdraws$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 44:
					if (_v0.b.$ === 43) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$TemplateList,
							$author$project$Main$GotTemplateListMsg,
							model,
							A2($author$project$Page$Templates$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 48:
					if (_v0.b.$ === 47) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$WipList,
							$author$project$Main$GotWipListMsg,
							model,
							A2($author$project$Page$Wips$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 8:
					if (_v0.b.$ === 7) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ItemList,
							$author$project$Main$GotItemListMsg,
							model,
							A2($author$project$Page$Items$List$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 7:
					if (_v0.b.$ === 6) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ItemView,
							$author$project$Main$GotItemMsg,
							model,
							A2($author$project$Page$Items$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 42:
					if (_v0.b.$ === 41) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$MoveView,
							$author$project$Main$GotMoveViewMsg,
							model,
							A2($author$project$Page$Moves$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 43:
					if (_v0.b.$ === 42) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$WithdrawView,
							$author$project$Main$GotWithdrawViewMsg,
							model,
							A2($author$project$Page$Withdraws$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 46:
					if (_v0.b.$ === 45) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$TemplateView,
							$author$project$Main$GotTemplateViewMsg,
							model,
							A2($author$project$Page$Templates$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 50:
					if (_v0.b.$ === 49) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$WipView,
							$author$project$Main$GotWipViewMsg,
							model,
							A2($author$project$Page$Wips$View$update, subMsg, item));
					} else {
						break _v0$52;
					}
				case 51:
					if (_v0.b.$ === 50) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$TemplateCal,
							$author$project$Main$GotTemplateCalMsg,
							model,
							A2($author$project$Page$Templates$Cal$update, subMsg, item));
					} else {
						break _v0$52;
					}
				default:
					if (_v0.b.$ === 18) {
						var subMsg = _v0.a.a;
						var item = _v0.b.a;
						return A4(
							$author$project$Main$updateWith,
							$author$project$Main$ImageCrop,
							$author$project$Main$GotImageCropMsg,
							model,
							A2($author$project$Page$Images$Crop$update, subMsg, item));
					} else {
						break _v0$52;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$About = 2;
var $author$project$Page$Categories = 8;
var $author$project$Page$Home = 0;
var $author$project$Page$Items = 6;
var $author$project$Page$Locations = 7;
var $author$project$Page$Login = 1;
var $author$project$Page$Move = 11;
var $author$project$Page$Movement = 9;
var $author$project$Page$NewItem = 5;
var $author$project$Page$Other = 3;
var $author$project$Page$Report = 10;
var $author$project$Page$Template = 13;
var $author$project$Page$Wip = 14;
var $author$project$Page$Withdraw = 12;
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $author$project$Page$viewFooter = A2($elm$html$Html$footer, _List_Nil, _List_Nil);
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Route$href = function (targetRoute) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$routeToString(targetRoute));
};
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Page$isActive = F2(
	function (page, route) {
		var _v0 = _Utils_Tuple2(page, route);
		_v0$12:
		while (true) {
			switch (_v0.a) {
				case 0:
					if (!_v0.b.$) {
						var _v1 = _v0.a;
						var _v2 = _v0.b;
						return true;
					} else {
						break _v0$12;
					}
				case 1:
					if (_v0.b.$ === 2) {
						var _v3 = _v0.a;
						var _v4 = _v0.b;
						return true;
					} else {
						break _v0$12;
					}
				case 2:
					if (_v0.b.$ === 4) {
						var _v5 = _v0.a;
						var _v6 = _v0.b;
						return true;
					} else {
						break _v0$12;
					}
				case 4:
					if (_v0.b.$ === 5) {
						var _v7 = _v0.a;
						var _v8 = _v0.b;
						return true;
					} else {
						break _v0$12;
					}
				case 5:
					if (_v0.b.$ === 6) {
						var _v9 = _v0.a;
						var _v10 = _v0.b;
						return true;
					} else {
						break _v0$12;
					}
				case 6:
					if (_v0.b.$ === 8) {
						var _v11 = _v0.a;
						var query = _v0.b.a;
						return true;
					} else {
						break _v0$12;
					}
				case 7:
					if (_v0.b.$ === 10) {
						var _v12 = _v0.a;
						var _v13 = _v0.b;
						return true;
					} else {
						break _v0$12;
					}
				case 8:
					if (_v0.b.$ === 14) {
						var _v14 = _v0.a;
						var _v15 = _v0.b;
						return true;
					} else {
						break _v0$12;
					}
				case 9:
					var _v16 = _v0.a;
					return true;
				case 11:
					var _v17 = _v0.a;
					return true;
				case 12:
					var _v18 = _v0.a;
					return true;
				case 13:
					var _v19 = _v0.a;
					return true;
				default:
					break _v0$12;
			}
		}
		return false;
	});
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Page$navbarLink = F3(
	function (page, route, linkContent) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('nav-item', true),
							_Utils_Tuple2(
							'active',
							A2($author$project$Page$isActive, page, route))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav-link'),
							$author$project$Route$href(route)
						]),
					linkContent)
				]));
	});
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Viewer$avatar = function (_v0) {
	var val = _v0.a;
	return val;
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$hidden = $elm$html$Html$Attributes$boolProperty('hidden');
var $author$project$Viewer$username = function (_v0) {
	var val = _v0.c;
	return $author$project$Api$username(val);
};
var $author$project$Viewer$viewerType = function (_v0) {
	var val = _v0.b;
	return val;
};
var $author$project$Page$viewMenu = F2(
	function (page, maybeViewer) {
		var linkTo = $author$project$Page$navbarLink(page);
		if (!maybeViewer.$) {
			var viewer = maybeViewer.a;
			var username = $author$project$Viewer$username(viewer);
			var usernameString = $author$project$Username$toString(username);
			var userType = $author$project$Viewer$viewerType(viewer);
			var avatar = $author$project$Viewer$avatar(viewer);
			if (!userType) {
				return _List_fromArray(
					[
						A2(
						linkTo,
						$author$project$Route$Items($elm$core$Maybe$Nothing),
						_List_fromArray(
							[
								$elm$html$Html$text('Items')
							])),
						A2(
						linkTo,
						$author$project$Route$TemplateList,
						_List_fromArray(
							[
								$elm$html$Html$text('Templates')
							])),
						A2(
						linkTo,
						$author$project$Route$TemplateCal,
						_List_fromArray(
							[
								$elm$html$Html$text('Cal')
							])),
						A2(
						linkTo,
						$author$project$Route$WipList,
						_List_fromArray(
							[
								$elm$html$Html$text('WIP')
							])),
						A2(
						linkTo,
						$author$project$Route$ImportList,
						_List_fromArray(
							[
								$elm$html$Html$text('Import')
							])),
						A2(
						linkTo,
						$author$project$Route$WithdrawList,
						_List_fromArray(
							[
								$elm$html$Html$text('Withdraw')
							])),
						A2(
						linkTo,
						$author$project$Route$PoList,
						_List_fromArray(
							[
								$elm$html$Html$text('PO')
							])),
						A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('nav-item', true),
										_Utils_Tuple2(
										'active',
										A2($author$project$Page$isActive, page, $author$project$Route$PaymentList))
									])),
								$elm$html$Html$Attributes$hidden((usernameString !== 'futuremlm42@gmail.com') && ((usernameString !== 'aon.u.1978@gmail.com') && (usernameString !== 'nextgensoft.apichart@gmail.com')))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('nav-link'),
										$author$project$Route$href($author$project$Route$PaymentList)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Payments')
									]))
							])),
						A2(
						linkTo,
						$author$project$Route$MoveList,
						_List_fromArray(
							[
								$elm$html$Html$text('Move')
							])),
						A2(
						linkTo,
						$author$project$Route$BorrowList,
						_List_fromArray(
							[
								$elm$html$Html$text('Borrow')
							])),
						A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('nav-item dropdown')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('nav-link dropdown-toggle'),
										$elm$html$Html$Attributes$href('#'),
										A2($elm$html$Html$Attributes$attribute, 'data-toggle', 'dropdown'),
										A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', 'navbarDropdown')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Settings')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('dropdown-menu')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('dropdown-item'),
												$author$project$Route$href($author$project$Route$Categories)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Category')
											])),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('dropdown-item'),
												$author$project$Route$href($author$project$Route$Locations)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Location')
											])),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('dropdown-item'),
												$author$project$Route$href($author$project$Route$Suppliers)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Supplier')
											])),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('dropdown-item'),
												$author$project$Route$href($author$project$Route$ImageList)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											]))
									]))
							])),
						A2(
						linkTo,
						$author$project$Route$Logout,
						_List_fromArray(
							[
								$elm$html$Html$text('Sign out')
							]))
					]);
			} else {
				return _List_fromArray(
					[
						A2(
						linkTo,
						$author$project$Route$PoList,
						_List_fromArray(
							[
								$elm$html$Html$text('PO')
							])),
						A2(
						linkTo,
						$author$project$Route$PackingList,
						_List_fromArray(
							[
								$elm$html$Html$text('Packing')
							])),
						A2(
						linkTo,
						$author$project$Route$PaymentList,
						_List_fromArray(
							[
								$elm$html$Html$text('Payments')
							])),
						A2(
						linkTo,
						$author$project$Route$Logout,
						_List_fromArray(
							[
								$elm$html$Html$text('Sign out')
							]))
					]);
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$Page$viewHeader = F2(
	function (page, maybeViewer) {
		if (!maybeViewer.$) {
			var viewer = maybeViewer.a;
			return A2(
				$elm$html$Html$nav,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('navbar navbar-expand-lg navbar-light')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navbar-brand'),
								$author$project$Route$href($author$project$Route$Home)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('conduit')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navbar-collapse')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$ul,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navbar-nav pull-xs-right')
									]),
								A2(
									$elm$core$List$cons,
									A3(
										$author$project$Page$navbarLink,
										page,
										$author$project$Route$Home,
										_List_fromArray(
											[
												$elm$html$Html$text('Home')
											])),
									A2($author$project$Page$viewMenu, page, maybeViewer)))
							]))
					]));
		} else {
			return A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Conduit')
					]));
		}
	});
var $author$project$Page$view = F3(
	function (maybeViewer, page, _v0) {
		var title = _v0.g5;
		var content = _v0.eO;
		return {
			a7: A2(
				$elm$core$List$cons,
				A2($author$project$Page$viewHeader, page, maybeViewer),
				A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[content])),
					_List_fromArray(
						[$author$project$Page$viewFooter]))),
			g5: title + ' - Conduit'
		};
	});
var $author$project$Page$About$BtnClicked = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$About$CloseDialog = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$About$Increment = {$: 1};
var $author$project$Page$About$Upload = {$: 18};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $pablen$toasty$Toasty$itemContainer = F4(
	function (_v0, tagger, _v1, toastView) {
		var cfg = _v0;
		var id = _v1.a;
		var status = _v1.b;
		var toast = _v1.c;
		var attrs = function () {
			if (!status) {
				return cfg.cm;
			} else {
				return cfg.cn;
			}
		}();
		return _Utils_Tuple2(
			$elm$core$String$fromInt(id),
			A2(
				$elm$html$Html$li,
				_Utils_ap(
					cfg.b3,
					_Utils_ap(
						attrs,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								tagger(
									$pablen$toasty$Toasty$TransitionOut(id)))
							]))),
				_List_fromArray(
					[
						toastView(toast)
					])));
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$Keyed$ol = $elm$html$Html$Keyed$node('ol');
var $pablen$toasty$Toasty$view = F4(
	function (cfg, toastView, tagger, _v0) {
		var toasts = _v0.a;
		var seed = _v0.b;
		var _v1 = cfg;
		var c = _v1;
		return $elm$core$List$isEmpty(toasts) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$Keyed$ol,
			c.bT,
			A2(
				$elm$core$List$map,
				function (toast) {
					return A4($pablen$toasty$Toasty$itemContainer, cfg, tagger, toast, toastView);
				},
				toasts));
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $pablen$toasty$Toasty$Defaults$genericToast = F3(
	function (variantClass, title, message) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('toasty-container'),
					$elm$html$Html$Attributes$class(variantClass)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('toasty-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					$elm$core$String$isEmpty(message) ? $elm$html$Html$text('') : A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('toasty-message')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(message)
						]))
				]));
	});
var $pablen$toasty$Toasty$Defaults$view = function (toast) {
	switch (toast.$) {
		case 0:
			var title = toast.a;
			var message = toast.b;
			return A3($pablen$toasty$Toasty$Defaults$genericToast, 'toasty-success', title, message);
		case 1:
			var title = toast.a;
			var message = toast.b;
			return A3($pablen$toasty$Toasty$Defaults$genericToast, 'toasty-warning', title, message);
		default:
			var title = toast.a;
			var message = toast.b;
			return A3($pablen$toasty$Toasty$Defaults$genericToast, 'toasty-error', title, message);
	}
};
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX(1);
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY(1);
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $mdgriffith$elm_ui$Internal$Model$Right = 2;
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX(2);
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var $author$project$Components$DeleteButton$black = A3($mdgriffith$elm_ui$Element$rgb, 0, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$classes = {d5: 'a', bO: 'atv', d7: 'ab', d8: 'cx', d9: 'cy', ea: 'acb', eb: 'accx', ec: 'accy', ed: 'acr', cw: 'al', cx: 'ar', ee: 'at', bP: 'ah', bQ: 'av', ek: 's', eo: 'bh', ep: 'b', er: 'w7', et: 'bd', eu: 'bdt', a8: 'bn', ev: 'bs', ba: 'cpe', eH: 'cp', eI: 'cpx', eJ: 'cpy', M: 'c', bc: 'ctr', bd: 'cb', be: 'ccx', N: 'ccy', aL: 'cl', bf: 'cr', eP: 'ct', eZ: 'cptr', e_: 'ctxt', fc: 'fcs', cR: 'focus-within', fg: 'fs', fi: 'g', bZ: 'hbh', b_: 'hc', cT: 'he', b$: 'hf', cU: 'hfp', ac: 'hv', fp: 'ic', fr: 'fr', ft: 'iml', fu: 'imlf', fv: 'imlp', fw: 'implw', fx: 'it', fA: 'i', c5: 'lnk', av: 'nb', da: 'notxt', fY: 'ol', fZ: 'or', af: 'oq', f0: 'oh', f1: 'pg', dn: 'p', f3: 'ppe', gj: 'ui', dE: 'r', gn: 'sb', go: 'sbx', gp: 'sby', gq: 'sbt', gw: 'e', gy: 'cap', gA: 'sev', gH: 'sk', dS: 't', gP: 'tc', gQ: 'w8', gR: 'w2', gS: 'w9', gT: 'tj', bI: 'tja', gU: 'tl', gV: 'w3', gW: 'w5', gX: 'w4', gY: 'tr', gZ: 'w6', g_: 'w1', g$: 'tun', dW: 'ts', al: 'clr', hc: 'u', cs: 'wc', d2: 'we', ct: 'wf', d3: 'wfp', cu: 'wrp'};
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.er);
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var $mdgriffith$elm_ui$Internal$Model$asEl = 2;
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.M);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.fi);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f1);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dn);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dE);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gw);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var $mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.gk)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.D)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gB.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gB.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.dE) + ('-' + ($elm$core$String$fromInt(pos.cF) + ('-' + ($elm$core$String$fromInt(pos.he) + ('-' + $elm$core$String$fromInt(pos.fl)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.c$ ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.b9.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.b9.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.aq) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.gx) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.ar))
				])));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cR) + ':focus-within',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.es),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.em),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										aq: shadow.aq,
										ar: shadow.ar,
										c$: false,
										b9: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.b9)),
										gx: shadow.gx
									}));
						},
						focus.gs),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					]))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek) + (':focus .focusable, ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek) + '.focusable:focus')),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.es),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.em),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										aq: shadow.aq,
										ar: shadow.ar,
										c$: false,
										b9: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.b9)),
										gx: shadow.gx
									}));
						},
						focus.gs),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					])))
		]);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = 3;
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = 2;
var $mdgriffith$elm_ui$Internal$Style$Self = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var $mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var $mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var $mdgriffith$elm_ui$Internal$Style$Top = 0;
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eP);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bd);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bf);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aL);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.be);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.N);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ee);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d7);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cx);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cw);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d8);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d9);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bZ),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eo),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gq),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dS),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ct),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b_),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ct),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d3),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cs),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = 0;
var $mdgriffith$elm_ui$Internal$Style$Behind = 5;
var $mdgriffith$elm_ui$Internal$Style$Below = 1;
var $mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var $mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var $mdgriffith$elm_ui$Internal$Style$Within = 4;
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _v0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gw),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fp))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gj),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fr),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.av),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.av),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gw),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d5),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ct),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ep),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fZ),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fY),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fr),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eo),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.da),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eZ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e_),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ba),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.al),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.af),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.ac, $mdgriffith$elm_ui$Internal$Style$classes.al)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.ac, $mdgriffith$elm_ui$Internal$Style$classes.af)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.fc, $mdgriffith$elm_ui$Internal$Style$classes.al)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.fc, $mdgriffith$elm_ui$Internal$Style$classes.af)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.bO, $mdgriffith$elm_ui$Internal$Style$classes.al)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.bO, $mdgriffith$elm_ui$Internal$Style$classes.af)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dW),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gn),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.go),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dE),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gp),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.M),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gw),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eH),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eI),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eJ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cs),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.a8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.et),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ev),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dS),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fx),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gw),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dE),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d2),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c5),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cU),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ct),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.ed,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eb,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eb,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eb,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d9),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.eb + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.ed + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.eb)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gA),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.M),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cT),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.M),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ct),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d3),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cs),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.ea,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.ec,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d9),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.ec,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d9),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.ec,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d9),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.ec + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.ea + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.ec)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bc),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gA),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fi),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.ek + ($mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.ek))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.ek + ($mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.ek))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ft),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background-color', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fw),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gw),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fu),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'transparent')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dn),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bZ),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eo),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dS),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gw),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fr),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eo),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d5),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ep),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fZ),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dS),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gw),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Internal$Style$Child,
										$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dS),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
											]))
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dE),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.M),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fi),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g_),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gR),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gV),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gX),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gW),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gZ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.er),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gQ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gS),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fA),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gH),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hc),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hc),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gH)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g$),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gT),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bI),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gP),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gU),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.ek + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.ek + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$inputTextReset = '\ninput[type="search"],\ninput[type="search"]::-webkit-search-decoration,\ninput[type="search"]::-webkit-search-cancel-button,\ninput[type="search"]::-webkit-search-results-button,\ninput[type="search"]::-webkit-search-results-decoration {\n  -webkit-appearance:none;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$sliderReset = '\ninput[type=range] {\n  -webkit-appearance: none; \n  background: transparent;\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$thumbReset = '\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$trackReset = '\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dE) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dE) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ek) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bc) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$inputTextReset + ($mdgriffith$elm_ui$Internal$Style$sliderReset + ($mdgriffith$elm_ui$Internal$Style$trackReset + ($mdgriffith$elm_ui$Internal$Style$thumbReset + $mdgriffith$elm_ui$Internal$Style$explainer)))))))))))))));
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $mdgriffith$elm_ui$Internal$Style$Intermediate = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {bb: closing, q: _List_Nil, S: _List_Nil, J: selector};
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								S: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.S)
							});
					case 2:
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								q: A2(
									$elm$core$List$cons,
									{bb: '\n}', q: _List_Nil, S: props, J: '@supports (' + (prop + (':' + (value + (') {' + parent.J))))},
									rendered.q)
							});
					case 4:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.J + (' + ' + selector), ''),
										adjRules),
									rendered.q)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.J + (' > ' + child), ''),
										childRules),
									rendered.q)
							});
					case 3:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.J, descriptor),
											''),
										descriptorRules),
									rendered.q)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								q: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.J, ''),
										batched),
									rendered.q)
							});
				}
			});
		return A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.S;
		if (!_v2.b) {
			return '';
		} else {
			return rule.J + ('{' + (renderValues(rule.S) + (rule.bb + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.q)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $mdgriffith$elm_ui$Internal$Model$staticRoot = function (opts) {
	var _v0 = opts.fQ;
	switch (_v0) {
		case 0:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'div',
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$virtual_dom$VirtualDom$node,
						'style',
						_List_Nil,
						_List_fromArray(
							[
								$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
							]))
					]));
		case 1:
			return $elm$virtual_dom$VirtualDom$text('');
		default:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'elm-ui-static-rules',
				_List_fromArray(
					[
						A2(
						$elm$virtual_dom$VirtualDom$property,
						'rules',
						$elm$json$Json$Encode$string($mdgriffith$elm_ui$Internal$Style$rules))
					]),
				_List_Nil);
	}
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.fU;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.dY);
	} else {
		return false;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$renderStyle = F4(
	function (options, maybePseudo, selector, props) {
		if (maybePseudo.$ === 1) {
			return _List_fromArray(
				[
					selector + ('{' + (A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$renderProps(false),
					'',
					props) + '\n}'))
				]);
		} else {
			var pseudo = maybePseudo.a;
			switch (pseudo) {
				case 1:
					var _v2 = options.ac;
					switch (_v2) {
						case 0:
							return _List_Nil;
						case 2:
							return _List_fromArray(
								[
									selector + ('-hv {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(true),
									'',
									props) + '\n}'))
								]);
						default:
							return _List_fromArray(
								[
									selector + ('-hv:hover {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(false),
									'',
									props) + '\n}'))
								]);
					}
				case 0:
					var renderedProps = A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props);
					return _List_fromArray(
						[selector + ('-fs:focus {' + (renderedProps + '\n}')), '.' + ($mdgriffith$elm_ui$Internal$Style$classes.ek + (':focus ~ ' + (selector + ('-fs:not(.focus)  {' + (renderedProps + '\n}'))))), '.' + ($mdgriffith$elm_ui$Internal$Style$classes.ek + (':focus ' + (selector + ('-fs  {' + (renderedProps + '\n}'))))), selector + ('-fs:focus-within {' + (renderedProps + '\n}')), '.focusable-parent:focus ~ ' + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + (selector + ('-fs {' + (renderedProps + '\n}'))))))]);
				default:
					return _List_fromArray(
						[
							selector + ('-act:active {' + (A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$renderProps(false),
							'',
							props) + '\n}'))
						]);
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.dY)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderStyleRule = F3(
	function (options, rule, maybePseudo) {
		switch (rule.$) {
			case 0:
				var selector = rule.a;
				var props = rule.b;
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, selector, props);
			case 13:
				var name = rule.a;
				var prop = rule.b;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
						]));
			case 12:
				var name = rule.a;
				var transparency = rule.b;
				var opacity = A2(
					$elm$core$Basics$max,
					0,
					A2($elm$core$Basics$min, 1, 1 - transparency));
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'opacity',
							$elm$core$String$fromFloat(opacity))
						]));
			case 2:
				var i = rule.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			case 1:
				var name = rule.a;
				var typefaces = rule.b;
				var features = A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
				var families = _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-family',
						A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-variant',
						A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
					]);
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, '.' + name, families);
			case 3:
				var _class = rule.a;
				var prop = rule.b;
				var val = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
						]));
			case 4:
				var _class = rule.a;
				var prop = rule.b;
				var color = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							prop,
							$mdgriffith$elm_ui$Internal$Model$formatColor(color))
						]));
			case 5:
				var cls = rule.a;
				var x = rule.b;
				var y = rule.c;
				var yPx = $elm$core$String$fromInt(y) + 'px';
				var xPx = $elm$core$String$fromInt(x) + 'px';
				var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gw;
				var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.dE;
				var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.cu + row);
				var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.cx;
				var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.dn;
				var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.f1;
				var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.cw;
				var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
				var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
				var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.M;
				var _class = '.' + cls;
				var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.ek;
				return $elm$core$List$concat(
					_List_fromArray(
						[
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (row + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (wrappedRow + (' > ' + any)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (column + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_Utils_ap(_class, paragraph),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							'textarea' + (any + _class),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)')),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'height',
									'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::after'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-top',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::before'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-bottom',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								]))
						]));
			case 7:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'padding',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 6:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-width',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 8:
				var template = rule.a;
				var toGridLengthHelper = F3(
					function (minimum, maximum, x) {
						toGridLengthHelper:
						while (true) {
							switch (x.$) {
								case 0:
									var px = x.a;
									return $elm$core$String$fromInt(px) + 'px';
								case 1:
									var _v2 = _Utils_Tuple2(minimum, maximum);
									if (_v2.a.$ === 1) {
										if (_v2.b.$ === 1) {
											var _v3 = _v2.a;
											var _v4 = _v2.b;
											return 'max-content';
										} else {
											var _v6 = _v2.a;
											var maxSize = _v2.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v2.b.$ === 1) {
											var minSize = _v2.a.a;
											var _v5 = _v2.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
										} else {
											var minSize = _v2.a.a;
											var maxSize = _v2.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 2:
									var i = x.a;
									var _v7 = _Utils_Tuple2(minimum, maximum);
									if (_v7.a.$ === 1) {
										if (_v7.b.$ === 1) {
											var _v8 = _v7.a;
											var _v9 = _v7.b;
											return $elm$core$String$fromInt(i) + 'fr';
										} else {
											var _v11 = _v7.a;
											var maxSize = _v7.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v7.b.$ === 1) {
											var minSize = _v7.a.a;
											var _v10 = _v7.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
										} else {
											var minSize = _v7.a.a;
											var maxSize = _v7.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 3:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = $elm$core$Maybe$Just(m),
										$temp$maximum = maximum,
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
								default:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = minimum,
										$temp$maximum = $elm$core$Maybe$Just(m),
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
							}
						}
					});
				var toGridLength = function (x) {
					return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
				};
				var xSpacing = toGridLength(template.gB.a);
				var ySpacing = toGridLength(template.gB.b);
				var rows = function (x) {
					return 'grid-template-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.gk)));
				var msRows = function (x) {
					return '-ms-grid-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.D)));
				var msColumns = function (x) {
					return '-ms-grid-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.D)));
				var gapY = 'grid-row-gap:' + (toGridLength(template.gB.b) + ';');
				var gapX = 'grid-column-gap:' + (toGridLength(template.gB.a) + ';');
				var columns = function (x) {
					return 'grid-template-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.D)));
				var _class = '.grid-rows-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.gk)) + ('-cols-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.D)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gB.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.gB.b)))))));
				var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msColumns + (msRows + '}')));
				return _List_fromArray(
					[base, supports]);
			case 9:
				var position = rule.a;
				var msPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'-ms-grid-row: ' + ($elm$core$String$fromInt(position.dE) + ';'),
							'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.fl) + ';'),
							'-ms-grid-column: ' + ($elm$core$String$fromInt(position.cF) + ';'),
							'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.he) + ';')
						]));
				var modernPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'grid-row: ' + ($elm$core$String$fromInt(position.dE) + (' / ' + ($elm$core$String$fromInt(position.dE + position.fl) + ';'))),
							'grid-column: ' + ($elm$core$String$fromInt(position.cF) + (' / ' + ($elm$core$String$fromInt(position.cF + position.he) + ';')))
						]));
				var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.dE) + ('-' + ($elm$core$String$fromInt(position.cF) + ('-' + ($elm$core$String$fromInt(position.he) + ('-' + $elm$core$String$fromInt(position.fl)))))));
				var modernGrid = _class + ('{' + (modernPosition + '}'));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msPosition + '}'));
				return _List_fromArray(
					[base, supports]);
			case 11:
				var _class = rule.a;
				var styles = rule.b;
				var renderPseudoRule = function (style) {
					return A3(
						$mdgriffith$elm_ui$Internal$Model$renderStyleRule,
						options,
						style,
						$elm$core$Maybe$Just(_class));
				};
				return A2($elm$core$List$concatMap, renderPseudoRule, styles);
			default:
				var transform = rule.a;
				var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
				var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				var _v12 = _Utils_Tuple2(_class, val);
				if ((!_v12.a.$) && (!_v12.b.$)) {
					var cls = _v12.a.a;
					var v = _v12.b.a;
					return A4(
						$mdgriffith$elm_ui$Internal$Model$renderStyle,
						options,
						maybePseudo,
						'.' + cls,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
							]));
				} else {
					return _List_Nil;
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$encodeStyles = F2(
	function (options, stylesheet) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (style) {
					var styled = A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing);
					return _Utils_Tuple2(
						$mdgriffith$elm_ui$Internal$Model$getStyleName(style),
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, styled));
				},
				stylesheet));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.dS + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.dS)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.gy, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.fg, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.gy + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.gy))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.gy + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.dS + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.gy + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.dS)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {fl: height / size, gx: size, dZ: vertical};
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.eA, adjustment.en, adjustment.e5, adjustment.fI]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.e5,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.en,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.eA,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		eA: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		cS: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.fl)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.dZ) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.gx) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _v2 = _with.d6;
						if (_v2.$ === 1) {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.cS;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.eA;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 1) {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var combine = F2(
			function (style, rendered) {
				return {
					bC: _Utils_ap(
						rendered.bC,
						A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing)),
					a3: function () {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v1.$ === 1) {
							return rendered.a3;
						} else {
							var topLevel = _v1.a;
							return A2($elm$core$List$cons, topLevel, rendered.a3);
						}
					}()
				};
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			combine,
			{bC: _List_Nil, a3: _List_Nil},
			stylesheet);
		var topLevel = _v0.a3;
		var rules = _v0.bC;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			$elm$core$String$concat(rules));
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		var _v0 = options.fQ;
		switch (_v0) {
			case 0:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			case 1:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			default:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'elm-ui-rules',
					_List_fromArray(
						[
							A2(
							$elm$virtual_dom$VirtualDom$property,
							'rules',
							A2($mdgriffith$elm_ui$Internal$Model$encodeStyles, options, styleSheet))
						]),
					_List_Nil);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.fc)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'static-stylesheet',
				$mdgriffith$elm_ui$Internal$Model$staticRoot(opts)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.fc)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot(opts),
			A2($elm$core$List$cons, dynamicStyleSheet, children)) : A2($elm$core$List$cons, dynamicStyleSheet, children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gw))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.ek, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.bc, $mdgriffith$elm_ui$Internal$Style$classes.N, $mdgriffith$elm_ui$Internal$Style$classes.ed])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.ek, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.bc, $mdgriffith$elm_ui$Internal$Style$classes.N, $mdgriffith$elm_ui$Internal$Style$classes.eb])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.ek, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.bc, $mdgriffith$elm_ui$Internal$Style$classes.ec])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.ek, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.bc, $mdgriffith$elm_ui$Internal$Style$classes.ea])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $mdgriffith$elm_ui$Internal$Model$textElementClasses = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dS + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.cs + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.b_)))));
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFillClasses = $mdgriffith$elm_ui$Internal$Style$classes.ek + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dS + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.ct + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.b$)))));
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementFillClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.fn, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.gI : _Utils_ap(styled.gI, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.fn, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.gI : _Utils_ap(styled.gI, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.fn, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.gI : _Utils_ap(styled.gI, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.fn, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.gI : _Utils_ap(styled.gI, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.gI : _Utils_ap(rendered.gI, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.ab,
						rendered.ae,
						rendered.V,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.W)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						fn: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.ab,
							rendered.ae,
							rendered.V,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.W))),
						gI: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.gI : _Utils_ap(rendered.gI, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.ab,
						rendered.ae,
						rendered.V,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.W)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						fn: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.ab,
							rendered.ae,
							rendered.V,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.W))),
						gI: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.av, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.d5]));
							case 1:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.av, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.ep]));
							case 2:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.av, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.fZ]));
							case 3:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.av, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.fY]));
							case 4:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.av, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.fr]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.av, $mdgriffith$elm_ui$Internal$Style$classes.gw, $mdgriffith$elm_ui$Internal$Style$classes.eo]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return $elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.fn, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.bP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cw);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.bP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cx);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.bP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.d8);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.bQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ee);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.bQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.d7);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.bQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.d9);
	}
};
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.cT + (' ' + name),
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.b_,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.b$,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.cU + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.ek + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.M + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.d2 + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.cs,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.ct,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.d3 + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.ek + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.dE + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 1) {
					return {
						V: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						W: children,
						ab: has,
						ae: node,
						gI: styles
					};
				} else {
					var _class = _v1.a;
					return {
						V: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						W: children,
						ab: has,
						ae: node,
						gI: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.d2 + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cs),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ct),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.d3 + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.ek + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.dE + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.cT + (' ' + (name + (' ' + classes))),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.b_ + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.b$ + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.cU + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.ek + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.M + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.gI);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $mdgriffith$elm_ui$Element$Input$onKey = F2(
	function (desiredCode, msg) {
		var decode = function (code) {
			return _Utils_eq(code, desiredCode) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('Not the enter key');
		};
		var isKey = A2(
			$elm$json$Json$Decode$andThen,
			decode,
			A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
		return $mdgriffith$elm_ui$Internal$Model$Attr(
			A2(
				$elm$html$Html$Events$preventDefaultOn,
				'keyup',
				A2(
					$elm$json$Json$Decode$map,
					function (fired) {
						return _Utils_Tuple2(fired, true);
					},
					isKey)));
	});
var $mdgriffith$elm_ui$Element$Input$onEnter = function (msg) {
	return A2($mdgriffith$elm_ui$Element$Input$onKey, $mdgriffith$elm_ui$Element$Input$enter, msg);
};
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.eZ);
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.df;
		var label = _v0.c3;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.be + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.N + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.gq + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.da)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onEnter(msg),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $mdgriffith$elm_ui$Internal$Model$Hover = 1;
var $mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$hover = $mdgriffith$elm_ui$Internal$Flag$flag(33);
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 1:
				var styled = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						fn: F2(
							function (add, context) {
								return A2(
									$elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.fn, add, context));
							}),
						gI: styled.gI
					});
			case 0:
				var html = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						$elm$core$Basics$composeL,
						$elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 2:
				var str = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return $mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2($mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, $elm$core$Basics$never, style);
};
var $mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _v0) {
		var styles = _v0.a;
		var trans = _v0.b;
		var _v1 = $mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_v1.$) {
			case 4:
				var style = _v1.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, style, styles),
					trans);
			case 10:
				var flag = _v1.a;
				var component = _v1.b;
				return _Utils_Tuple2(
					styles,
					A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _v0 = A3(
		$elm$core$List$foldl,
		$mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, $mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _v0.a;
	var transform = _v0.b;
	return A2(
		$elm$core$List$cons,
		$mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var $mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			1,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$padding = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			x,
			x,
			x,
			x));
};
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var $mdgriffith$elm_ui$Internal$Model$asRow = 0;
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.aL + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.N)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $author$project$Components$DeleteButton$white = A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $author$project$Components$DeleteButton$footerButtons = function (toMsg) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$padding(2),
				$mdgriffith$elm_ui$Element$spacing(5)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Components$DeleteButton$white),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Components$DeleteButton$black),
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$Border$rounded(2),
						$mdgriffith$elm_ui$Element$Border$width(1),
						$mdgriffith$elm_ui$Element$padding(1),
						$mdgriffith$elm_ui$Element$alignRight,
						$mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$Components$DeleteButton$black),
								$mdgriffith$elm_ui$Element$Font$color($author$project$Components$DeleteButton$white)
							]))
					]),
				{
					c3: $mdgriffith$elm_ui$Element$text('Delete'),
					df: $elm$core$Maybe$Just(
						toMsg(true))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Components$DeleteButton$white),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Components$DeleteButton$black),
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$Border$rounded(2),
						$mdgriffith$elm_ui$Element$Border$width(1),
						$mdgriffith$elm_ui$Element$padding(1),
						$mdgriffith$elm_ui$Element$alignRight,
						$mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$Components$DeleteButton$black),
								$mdgriffith$elm_ui$Element$Font$color($author$project$Components$DeleteButton$white)
							]))
					]),
				{
					c3: $mdgriffith$elm_ui$Element$text('Cancel'),
					df: $elm$core$Maybe$Just(
						toMsg(false))
				})
			]));
};
var $author$project$Components$DeleteButton$gray = A3($mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5);
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $author$project$Components$DeleteButton$red = A3($mdgriffith$elm_ui$Element$rgb, 1, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $author$project$Components$DeleteButton$config = F2(
	function (idString, toMsg) {
		return {
			a7: $elm$core$Maybe$Nothing,
			eq: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Components$DeleteButton$gray),
					$mdgriffith$elm_ui$Element$padding(20)
				]),
			eK: $elm$core$Maybe$Just(
				toMsg(false)),
			eN: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Components$DeleteButton$white),
					$mdgriffith$elm_ui$Element$Border$rounded(5),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(400))
				]),
			fd: $elm$core$Maybe$Just(
				$author$project$Components$DeleteButton$footerButtons(toMsg)),
			fe: _List_Nil,
			fj: $elm$core$Maybe$Just(
				$mdgriffith$elm_ui$Element$text('Delete Confirmation: ' + idString)),
			fk: _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$size(24),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Components$DeleteButton$red),
					$mdgriffith$elm_ui$Element$padding(1)
				]),
			fJ: _List_Nil
		};
	});
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $mdgriffith$elm_ui$Internal$Model$InFront = 4;
var $mdgriffith$elm_ui$Element$createNearby = F2(
	function (loc, element) {
		if (element.$ === 3) {
			return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
		} else {
			return A2($mdgriffith$elm_ui$Internal$Model$Nearby, loc, element);
		}
	});
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 4, element);
};
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var $mdgriffith$elm_ui$Internal$Model$Layout = 0;
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	em: $elm$core$Maybe$Nothing,
	es: $elm$core$Maybe$Nothing,
	gs: $elm$core$Maybe$Just(
		{
			aq: 0,
			ar: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			b9: _Utils_Tuple2(0, 0),
			gx: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _v4 = record.ac;
					if (_v4.$ === 1) {
						return _Utils_update(
							record,
							{
								ac: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _v5 = record.fc;
					if (_v5.$ === 1) {
						return _Utils_update(
							record,
							{
								fc: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.fQ;
					if (_v6.$ === 1) {
						return _Utils_update(
							record,
							{
								fQ: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			fc: function () {
				var _v0 = record.fc;
				if (_v0.$ === 1) {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			ac: function () {
				var _v1 = record.ac;
				if (_v1.$ === 1) {
					return 1;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			fQ: function () {
				var _v2 = record.fQ;
				if (_v2.$ === 1) {
					return 0;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{fc: $elm$core$Maybe$Nothing, ac: $elm$core$Maybe$Nothing, fQ: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.gI;
				var html = el.a.fn;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.fQ;
			if (_v0 === 1) {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $elm$core$String$toLower = _String_toLower;
var $elm$core$String$words = _String_words;
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.fU;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.dj;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.gj, $mdgriffith$elm_ui$Internal$Style$classes.ek, $mdgriffith$elm_ui$Internal$Style$classes.gw]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $mdgriffith$elm_ui$Element$layout = $mdgriffith$elm_ui$Element$layoutWith(
	{dj: _List_Nil});
var $mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.eP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.aL)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $PaackEng$elm_ui_dialog$Dialog$dialogMask = A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.3);
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $PaackEng$elm_ui_dialog$Dialog$wrapBody = function (_v0) {
	var body = _v0.a7;
	var bodyAttributes = _v0.eq;
	if (body.$ === 1) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		var body_ = body.a;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(1)
					]),
				bodyAttributes),
			body_);
	}
};
var $PaackEng$elm_ui_dialog$Dialog$wrapFooter = function (_v0) {
	var footer = _v0.fd;
	var footerAttributes = _v0.fe;
	if (footer.$ === 1) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		var footer_ = footer.a;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(1)
					]),
				footerAttributes),
			footer_);
	}
};
var $mdgriffith$elm_ui$Internal$Model$Left = 0;
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX(0);
var $PaackEng$elm_ui_dialog$Dialog$closeButton = function (closeMessage) {
	return A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$padding(1),
				$mdgriffith$elm_ui$Element$Font$size(16)
			]),
		{
			c3: $mdgriffith$elm_ui$Element$text('x'),
			df: $elm$core$Maybe$Just(closeMessage)
		});
};
var $PaackEng$elm_ui_dialog$Dialog$maybe = F3(
	function (_default, f, value) {
		if (!value.$) {
			var value_ = value.a;
			return f(value_);
		} else {
			return _default;
		}
	});
var $PaackEng$elm_ui_dialog$Dialog$wrapHeader = function (_v0) {
	var header = _v0.fj;
	var headerAttributes = _v0.fk;
	var closeMessage = _v0.eK;
	return (_Utils_eq(header, $elm$core$Maybe$Nothing) && _Utils_eq(closeMessage, $elm$core$Maybe$Nothing)) ? $mdgriffith$elm_ui$Element$none : A2(
		$mdgriffith$elm_ui$Element$row,
		_Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(2)
				]),
			headerAttributes),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignLeft]),
				A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$none, header)),
				A3($PaackEng$elm_ui_dialog$Dialog$maybe, $mdgriffith$elm_ui$Element$none, $PaackEng$elm_ui_dialog$Dialog$closeButton, closeMessage)
			]));
};
var $PaackEng$elm_ui_dialog$Dialog$view = function (maybeConfig) {
	if (maybeConfig.$ === 1) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		var config = maybeConfig.a;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($PaackEng$elm_ui_dialog$Dialog$dialogMask),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				config.fJ),
			A2(
				$mdgriffith$elm_ui$Element$column,
				config.eN,
				_List_fromArray(
					[
						$PaackEng$elm_ui_dialog$Dialog$wrapHeader(config),
						$PaackEng$elm_ui_dialog$Dialog$wrapBody(config),
						$PaackEng$elm_ui_dialog$Dialog$wrapFooter(config)
					])));
	}
};
var $author$project$Components$DeleteButton$viewDialog = F3(
	function (showDialog, idString, closeMsg) {
		var dialogConfig = showDialog ? $elm$core$Maybe$Just(
			A2($author$project$Components$DeleteButton$config, idString, closeMsg)) : $elm$core$Maybe$Nothing;
		return A2(
			$mdgriffith$elm_ui$Element$layout,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$inFront(
					$PaackEng$elm_ui_dialog$Dialog$view(dialogConfig))
				]),
			$mdgriffith$elm_ui$Element$html(
				A2($elm$html$Html$div, _List_Nil, _List_Nil)));
	});
var $author$project$Page$About$DragEnter = {$: 12};
var $author$project$Page$About$DragLeave = {$: 13};
var $author$project$Page$About$Pick = {$: 11};
var $author$project$Page$About$GotFiles = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$file$File$decoder = _File_decoder;
var $elm$json$Json$Decode$oneOrMoreHelp = F2(
	function (toValue, xs) {
		if (!xs.b) {
			return $elm$json$Json$Decode$fail('a ARRAY with at least ONE element');
		} else {
			var y = xs.a;
			var ys = xs.b;
			return $elm$json$Json$Decode$succeed(
				A2(toValue, y, ys));
		}
	});
var $elm$json$Json$Decode$oneOrMore = F2(
	function (toValue, decoder) {
		return A2(
			$elm$json$Json$Decode$andThen,
			$elm$json$Json$Decode$oneOrMoreHelp(toValue),
			$elm$json$Json$Decode$list(decoder));
	});
var $author$project$Page$About$dropDecoder = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['dataTransfer', 'files']),
	A2($elm$json$Json$Decode$oneOrMore, $author$project$Page$About$GotFiles, $elm$file$File$decoder));
var $author$project$Page$About$hijack = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $author$project$Page$About$hijackOn = F2(
	function (event, decoder) {
		return A2(
			$elm$html$Html$Events$preventDefaultOn,
			event,
			A2($elm$json$Json$Decode$map, $author$project$Page$About$hijack, decoder));
	});
var $author$project$Page$About$viewPreview = function (url) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '500px'),
				A2($elm$html$Html$Attributes$style, 'height', '500px'),
				A2($elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (url + '\')')),
				A2($elm$html$Html$Attributes$style, 'background-position', 'center'),
				A2($elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
				A2($elm$html$Html$Attributes$style, 'background-size', 'contain')
			]),
		_List_Nil);
};
var $author$project$Page$About$viewUploadBox = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$style,
				'border',
				model.ac ? '6px dashed purple' : '6px dashed #ccc'),
				A2($elm$html$Html$Attributes$style, 'border-radius', '20px'),
				A2($elm$html$Html$Attributes$style, 'width', '600px'),
				A2($elm$html$Html$Attributes$style, 'margin', '100px auto'),
				A2($elm$html$Html$Attributes$style, 'padding', '40px'),
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'flex-direction', 'column'),
				A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
				A2($elm$html$Html$Attributes$style, 'align-items', 'center'),
				A2(
				$author$project$Page$About$hijackOn,
				'dragenter',
				$elm$json$Json$Decode$succeed($author$project$Page$About$DragEnter)),
				A2(
				$author$project$Page$About$hijackOn,
				'dragover',
				$elm$json$Json$Decode$succeed($author$project$Page$About$DragEnter)),
				A2(
				$author$project$Page$About$hijackOn,
				'dragleave',
				$elm$json$Json$Decode$succeed($author$project$Page$About$DragLeave)),
				A2($author$project$Page$About$hijackOn, 'drop', $author$project$Page$About$dropDecoder)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick($author$project$Page$About$Pick)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Upload Images')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'flex'),
						A2($elm$html$Html$Attributes$style, 'align-items', 'center'),
						A2($elm$html$Html$Attributes$style, 'height', '60px'),
						A2($elm$html$Html$Attributes$style, 'padding', '20px')
					]),
				A2($elm$core$List$map, $author$project$Page$About$viewPreview, model.aY))
			]));
};
var $author$project$Page$About$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('About Page')
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info'),
									$elm$html$Html$Events$onClick($author$project$Page$About$Increment)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Increment')
								])),
							A3($author$project$Components$DeleteButton$viewDialog, model.ak, '123', $author$project$Page$About$CloseDialog),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Page$About$BtnClicked('error'))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Post import notification')
								])),
							A4($pablen$toasty$Toasty$view, $author$project$Page$About$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$About$ToastyMsg, model.cl),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$About$Upload)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Upload')
								])),
							$author$project$Page$About$viewUploadBox(model)
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Blank$view = {
	eO: $elm$html$Html$text(''),
	g5: ''
};
var $author$project$Page$Categories$Form$saveArticleButton = F2(
	function (caption, extraAttrs) {
		return A2(
			$elm$html$Html$button,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('btn btn-lg pull-xs-right btn-primary'),
				extraAttrs),
			_List_fromArray(
				[
					$elm$html$Html$text(caption)
				]));
	});
var $author$project$Page$Categories$Form$editArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Categories$Form$saveArticleButton, 'Update Category', extraAttrs);
};
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Asset$Image = $elm$core$Basics$identity;
var $author$project$Asset$image = function (filename) {
	return '/assets/images/' + filename;
};
var $author$project$Asset$loading = $author$project$Asset$image('loading.svg');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Asset$src = function (_v0) {
	var url = _v0;
	return $elm$html$Html$Attributes$src(url);
};
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Loading$icon = A2(
	$elm$html$Html$img,
	_List_fromArray(
		[
			$author$project$Asset$src($author$project$Asset$loading),
			$elm$html$Html$Attributes$width(64),
			$elm$html$Html$Attributes$height(64),
			$elm$html$Html$Attributes$alt('Loading...')
		]),
	_List_Nil);
var $author$project$Page$Categories$Form$newArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Categories$Form$saveArticleButton, 'Save Category', extraAttrs);
};
var $author$project$Page$Categories$Form$ClickedSave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Categories$Form$EnteredTitle = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Page$Categories$Form$viewForm = F3(
	function (cred, fields, saveButton) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit(
					$author$project$Page$Categories$Form$ClickedSave(cred))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$fieldset,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control form-control-lg'),
											$elm$html$Html$Attributes$placeholder('Category Title'),
											$elm$html$Html$Events$onInput($author$project$Page$Categories$Form$EnteredTitle),
											$elm$html$Html$Attributes$value(fields.g5)
										]),
									_List_Nil)
								])),
							saveButton
						]))
				]));
	});
var $author$project$Page$Categories$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var message = problem.b;
			return message;
		} else {
			var message = problem.a;
			return message;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Categories$Form$viewProblems = function (problems) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-messages')
			]),
		A2($elm$core$List$map, $author$project$Page$Categories$Form$viewProblem, problems));
};
var $author$project$Page$Categories$Form$viewAuthenticated = F2(
	function (cred, model) {
		var formHtml = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return _List_Nil;
				case 1:
					return _List_fromArray(
						[$author$project$Loading$icon]);
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							A3(
							$author$project$Page$Categories$Form$viewForm,
							cred,
							form,
							$author$project$Page$Categories$Form$editArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 6:
					var form = _v0.a;
					return _List_fromArray(
						[
							A3(
							$author$project$Page$Categories$Form$viewForm,
							cred,
							form,
							$author$project$Page$Categories$Form$newArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 4:
					var slug = _v0.a;
					var problems = _v0.b;
					var form = _v0.c;
					return _List_fromArray(
						[
							$author$project$Page$Categories$Form$viewProblems(problems),
							A3(
							$author$project$Page$Categories$Form$viewForm,
							cred,
							form,
							$author$project$Page$Categories$Form$editArticleSaveButton(_List_Nil))
						]);
				case 5:
					var problems = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							$author$project$Page$Categories$Form$viewProblems(problems),
							A3(
							$author$project$Page$Categories$Form$viewForm,
							cred,
							form,
							$author$project$Page$Categories$Form$newArticleSaveButton(_List_Nil))
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$text('Article failed to load.')
						]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('editor-page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container page')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-10 offset-md-1 col-xs-12')
										]),
									formHtml)
								]))
						]))
				]));
	});
var $author$project$Page$Categories$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2($author$project$Page$Categories$Form$viewAuthenticated, cred, model);
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Categories$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Loading$error = function (str) {
	return $elm$html$Html$text('Error loading ' + (str + '.'));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Page$Categories$List$DeleteItem = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$Page$Categories$List$viewItem = F2(
	function (cred, item) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm mr-10'),
									$author$project$Route$href(
									$author$project$Route$EditCategory(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-edit')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-danger btn-sm'),
									$elm$html$Html$Attributes$hidden(!item.e2),
									$elm$html$Html$Events$onClick(
									A2(
										$author$project$Page$Categories$List$DeleteItem,
										cred,
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-trash-a')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Categories$List$viewItems = F2(
	function (cred, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '85%')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$Page$Categories$List$viewItem(cred),
						items))
				]));
	});
var $author$project$PaginatedList$pageLink = F3(
	function (toMsg, targetPage, isActive) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('page-item', true),
							_Utils_Tuple2('active', isActive)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('page-link'),
							$elm$html$Html$Events$onClick(
							toMsg(targetPage)),
							$elm$html$Html$Attributes$href(
							'#page=' + $elm$core$String$fromInt(targetPage))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(targetPage))
						]))
				]));
	});
var $author$project$PaginatedList$viewPagination = F3(
	function (toMsg, activePage, totalPages) {
		var viewPageLink = function (currentPage) {
			return A3(
				$author$project$PaginatedList$pageLink,
				toMsg,
				currentPage,
				_Utils_eq(currentPage, activePage));
		};
		var displayAmount = 10;
		var beginPage = (_Utils_cmp(activePage, displayAmount) > -1) ? (activePage - A2($elm$core$Basics$modBy, displayAmount, activePage)) : 1;
		var endPage = (_Utils_cmp(beginPage + displayAmount, totalPages) < 0) ? ((beginPage + displayAmount) - 1) : totalPages;
		var options = _Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('page-item'),
							$elm$html$Html$Attributes$hidden(
							_Utils_cmp(beginPage, displayAmount) < 0)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('page-link'),
									$elm$html$Html$Events$onClick(
									toMsg(beginPage - 1)),
									$elm$html$Html$Attributes$href(
									'#page=' + $elm$core$String$fromInt(beginPage - 1))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('<')
								]))
						]))
				]),
			_Utils_ap(
				A2(
					$elm$core$List$map,
					viewPageLink,
					A2($elm$core$List$range, beginPage, endPage)),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('page-item'),
								$elm$html$Html$Attributes$hidden(
								_Utils_cmp(endPage + 1, totalPages) > 0)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('page-link'),
										$elm$html$Html$Events$onClick(
										toMsg(endPage + 1)),
										$elm$html$Html$Attributes$href(
										'#page=' + $elm$core$String$fromInt(endPage + 1))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('>')
									]))
							]))
					])));
		return (totalPages > 1) ? A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('pagination')
				]),
			options) : $elm$html$Html$text('');
	});
var $author$project$Page$Categories$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Categories$List$SubmittedForm = {$: 4};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Page$Categories$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Categories$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Categories$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Categories$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Categories$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Categories$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Category List Page'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$NewCategory)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Categories$List$viewSearch(model),
									A2(
									$author$project$Page$Categories$List$viewItems,
									cred,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Categories$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Home$view = function (model) {
	return {
		eO: A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('home-page')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Home Page')
				])),
		g5: 'Conduit'
	};
};
var $author$project$Page$Images$Crop$Zoom = $elm$core$Basics$identity;
var $elm$html$Html$code = _VirtualDom_node('code');
var $mikaxyz$elm_cropper$Cropper$Types$Point = F2(
	function (x, y) {
		return {hh: x, hi: y};
	});
var $mikaxyz$elm_cropper$Cropper$Types$Rect = F2(
	function (width, height) {
		return {fl: height, he: width};
	});
var $mikaxyz$elm_cropper$Cropper$Helper$imageRatio = function (_v0) {
	var image = _v0.au;
	var crop = _v0.cI;
	return A2($mikaxyz$elm_cropper$Cropper$Types$Vector, image.he / crop.he, image.fl / crop.fl);
};
var $mikaxyz$elm_cropper$Cropper$Helper$imageSize = function (_v0) {
	var image = _v0.au;
	var crop = _v0.cI;
	var zoom = _v0.hl;
	var ratio = $mikaxyz$elm_cropper$Cropper$Helper$imageRatio(
		{cI: crop, au: image});
	var ratioMin = A2($elm$core$Basics$min, ratio.hh, ratio.hi);
	var minWidth = crop.he * (ratio.hh / ratioMin);
	var width = minWidth + ((image.he - minWidth) * zoom);
	var minHeight = crop.fl * (ratio.hi / ratioMin);
	var height = minHeight + ((image.fl - minHeight) * zoom);
	return A2($mikaxyz$elm_cropper$Cropper$Types$Vector, width, height);
};
var $mikaxyz$elm_cropper$Cropper$Helper$cropOrigin = function (_v0) {
	var crop = _v0.cI;
	var pivot = _v0.aW;
	var zoom = _v0.hl;
	var image = _v0.au;
	var size = $mikaxyz$elm_cropper$Cropper$Helper$imageSize(
		{cI: crop, au: image, hl: zoom});
	var x = pivot.hh * (size.hh - crop.he);
	var y = pivot.hi * (size.hi - crop.fl);
	return A2($mikaxyz$elm_cropper$Cropper$Types$Vector, x, y);
};
var $mikaxyz$elm_cropper$Cropper$Helper$cropData = function (model) {
	var _v0 = model.au;
	if (_v0.$ === 1) {
		return {
			cI: model.cI,
			f$: A2($mikaxyz$elm_cropper$Cropper$Types$Point, 0, 0),
			gg: A2($mikaxyz$elm_cropper$Cropper$Types$Rect, 0, 0),
			gx: A2($mikaxyz$elm_cropper$Cropper$Types$Rect, 0, 0),
			bL: model.bL
		};
	} else {
		var image = _v0.a;
		var size = $mikaxyz$elm_cropper$Cropper$Helper$imageSize(
			{cI: model.cI, au: image, hl: model.hl});
		var origin = $mikaxyz$elm_cropper$Cropper$Helper$cropOrigin(
			{cI: model.cI, au: image, aW: model.aW, hl: model.hl});
		return {
			cI: model.cI,
			f$: {
				hh: $elm$core$Basics$round(origin.hh),
				hi: $elm$core$Basics$round(origin.hi)
			},
			gg: {
				fl: $elm$core$Basics$round(size.hi),
				he: $elm$core$Basics$round(size.hh)
			},
			gx: {fl: image.fl, he: image.he},
			bL: image.a
		};
	}
};
var $mikaxyz$elm_cropper$Cropper$cropData = $mikaxyz$elm_cropper$Cropper$Helper$cropData;
var $elm$html$Html$dd = _VirtualDom_node('dd');
var $elm$html$Html$dl = _VirtualDom_node('dl');
var $elm$html$Html$dt = _VirtualDom_node('dt');
var $author$project$Page$Images$Crop$cropDataDump = function (data) {
	var rectDebug = function (rect) {
		return $elm$core$String$fromInt(rect.he) + ('x' + $elm$core$String$fromInt(rect.fl));
	};
	var pointDebug = function (point) {
		return $elm$core$String$fromInt(point.hh) + ('|' + $elm$core$String$fromInt(point.hi));
	};
	return A2(
		$elm$html$Html$dl,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$dt,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('url')
					])),
				A2(
				$elm$html$Html$dd,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(data.bL)
					])),
				A2(
				$elm$html$Html$dt,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('size')
					])),
				A2(
				$elm$html$Html$dd,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						rectDebug(data.gx))
					])),
				A2(
				$elm$html$Html$dt,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('crop')
					])),
				A2(
				$elm$html$Html$dd,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						rectDebug(data.cI))
					])),
				A2(
				$elm$html$Html$dt,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('resized')
					])),
				A2(
				$elm$html$Html$dd,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						rectDebug(data.gg))
					])),
				A2(
				$elm$html$Html$dt,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('origin')
					])),
				A2(
				$elm$html$Html$dd,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						pointDebug(data.f$))
					]))
			]));
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Page$Images$Crop$view = function (model) {
	var content = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('simple.html')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Elm Cropper Simple Example')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('controls')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('controls__row')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Z')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput($elm$core$Basics$identity),
										$elm$html$Html$Attributes$type_('range'),
										$elm$html$Html$Attributes$min('0'),
										$elm$html$Html$Attributes$max('1'),
										$elm$html$Html$Attributes$step('0.0001'),
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromFloat(model.as.hl))
									]),
								_List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$code,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('code')
					]),
				_List_fromArray(
					[
						$author$project$Page$Images$Crop$cropDataDump(
						$mikaxyz$elm_cropper$Cropper$cropData(model.as))
					]))
			]));
	return {eO: content, g5: 'Conduit'};
};
var $author$project$Page$Images$Form$saveArticleButton = F2(
	function (caption, extraAttrs) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('actions')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('btn btn-block btn-primary'),
						extraAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(caption)
						]))
				]));
	});
var $author$project$Page$Images$Form$editArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Images$Form$saveArticleButton, 'Update Image', extraAttrs);
};
var $author$project$Page$Images$Form$newArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Images$Form$saveArticleButton, 'Save Image', extraAttrs);
};
var $author$project$Page$Images$Form$ClickedSave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Images$Form$EnteredTitle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Images$Form$Pick = {$: 10};
var $author$project$Page$Images$Form$SelectedCategory = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Images$Form$ToggleNotChangePage = {$: 8};
var $author$project$Page$Images$Form$Unpick = {$: 11};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Page$Images$Form$viewCategoryOption = F2(
	function (selectedID, category) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(category.cY)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(category.cY, selectedID))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(category.fU)
				]));
	});
var $author$project$Page$Images$Form$viewPreview = function (url) {
	var finalUrl = (url === '') ? 'http://placehold.it/500x500' : url;
	var color = (finalUrl === 'http://placehold.it/500x500') ? 'none' : 'green';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '500px'),
				A2($elm$html$Html$Attributes$style, 'height', '500px'),
				A2($elm$html$Html$Attributes$style, 'border', '5px dotted black'),
				A2($elm$html$Html$Attributes$style, 'background-image', 'url(\'' + (finalUrl + '\')')),
				A2($elm$html$Html$Attributes$style, 'background-repeat', 'no-repeat'),
				A2($elm$html$Html$Attributes$style, 'background-size', 'contain'),
				A2($elm$html$Html$Attributes$style, 'background-color', color)
			]),
		_List_Nil);
};
var $author$project$Page$Images$Form$viewForm = F4(
	function (model, cred, fields, saveButton) {
		var _v0 = model.am;
		if (_v0.$ === 1) {
			var i = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('row')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-md-6')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('btn btn-block btn-primary'),
												$elm$html$Html$Attributes$disabled(true)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												'Uploading: ' + $elm$core$String$fromFloat(i))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('col-md-6')
									]),
								_List_fromArray(
									[
										$author$project$Page$Images$Form$viewPreview(model.ah)
									]))
							]))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit(
								$author$project$Page$Images$Form$ClickedSave(cred))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col-md-6')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$fieldset,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$fieldset,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('form-group')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('form-control form-control-lg'),
																		$elm$html$Html$Attributes$placeholder('Image Title'),
																		$elm$html$Html$Events$onInput($author$project$Page$Images$Form$EnteredTitle),
																		$elm$html$Html$Attributes$value(fields.g5)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$fieldset,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('form-group')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$select,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('form-control'),
																		$elm$html$Html$Events$onInput($author$project$Page$Images$Form$SelectedCategory)
																	]),
																A2(
																	$elm$core$List$map,
																	$author$project$Page$Images$Form$viewCategoryOption(fields.eC),
																	model.aK))
															])),
														A2(
														$elm$html$Html$fieldset,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('form-group')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('form-checkbox')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$type_('checkbox'),
																				$elm$html$Html$Attributes$class('form-check-input'),
																				$elm$html$Html$Attributes$checked(model._),
																				$elm$html$Html$Events$onClick($author$project$Page$Images$Form$ToggleNotChangePage)
																			]),
																		_List_Nil),
																		A2(
																		$elm$html$Html$label,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('form-check-label')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Do not change page')
																			]))
																	]))
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Events$onClick($author$project$Page$Images$Form$Pick),
																$elm$html$Html$Attributes$class('btn btn-info'),
																A2($elm$html$Html$Attributes$style, 'margin-bottom', '15px'),
																$elm$html$Html$Attributes$type_('button'),
																$elm$html$Html$Attributes$hidden(
																function () {
																	var _v1 = model.bl;
																	if (_v1.$ === 1) {
																		var f = _v1.a;
																		return true;
																	} else {
																		return false;
																	}
																}())
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Upload Images')
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Events$onClick($author$project$Page$Images$Form$Unpick),
																$elm$html$Html$Attributes$class('btn btn-warning'),
																A2($elm$html$Html$Attributes$style, 'margin-bottom', '15px'),
																$elm$html$Html$Attributes$type_('button'),
																$elm$html$Html$Attributes$hidden(
																function () {
																	var _v2 = model.bl;
																	if (_v2.$ === 1) {
																		var f = _v2.a;
																		return false;
																	} else {
																		return true;
																	}
																}())
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Restart')
															])),
														saveButton
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('col-md-6')
											]),
										_List_fromArray(
											[
												$author$project$Page$Images$Form$viewPreview(model.ah)
											]))
									]))
							]))
					]));
		}
	});
var $author$project$Page$Images$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var message = problem.b;
			return message;
		} else {
			var message = problem.a;
			return message;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Images$Form$viewProblems = function (problems) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-messages')
			]),
		A2($elm$core$List$map, $author$project$Page$Images$Form$viewProblem, problems));
};
var $author$project$Page$Images$Form$viewAuthenticated = F2(
	function (cred, model) {
		var formHtml = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return _List_Nil;
				case 1:
					return _List_fromArray(
						[$author$project$Loading$icon]);
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							A4(
							$author$project$Page$Images$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Images$Form$editArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 6:
					var form = _v0.a;
					return _List_fromArray(
						[
							A4(
							$author$project$Page$Images$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Images$Form$newArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 4:
					var slug = _v0.a;
					var problems = _v0.b;
					var form = _v0.c;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Updating Image: ' + $author$project$Items$Slug$toString(slug))
								])),
							$author$project$Page$Images$Form$viewProblems(problems),
							A4(
							$author$project$Page$Images$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Images$Form$editArticleSaveButton(_List_Nil)),
							A4($pablen$toasty$Toasty$view, $author$project$Page$Images$Form$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$Images$Form$ToastyMsg, model.cl)
						]);
				case 5:
					var problems = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Create Image')
								])),
							$author$project$Page$Images$Form$viewProblems(problems),
							A4(
							$author$project$Page$Images$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Images$Form$newArticleSaveButton(_List_Nil)),
							A4($pablen$toasty$Toasty$view, $author$project$Page$Images$Form$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$Images$Form$ToastyMsg, model.cl)
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$text('Article failed to load.')
						]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('editor-page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container page')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-12')
										]),
									formHtml)
								]))
						]))
				]));
	});
var $author$project$Page$Images$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2($author$project$Page$Images$Form$viewAuthenticated, cred, model);
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Images$List$ClickedFeedPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Image$baseUrl = '/image/';
var $author$project$Page$Images$List$viewItem = function (item) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$author$project$Route$href(
						$author$project$Route$ImageView(
							$author$project$Items$Slug$idToSlug(item.cY)))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item-img')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(
										_Utils_ap($author$project$Image$baseUrl, item.dq))
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item-title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(item.fU)
							]))
					]))
			]));
};
var $author$project$Page$Images$List$viewItems = function (items) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		A2($elm$core$List$map, $author$project$Page$Images$List$viewItem, items));
};
var $author$project$Page$Images$List$EnteredSearch = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Images$List$SelectCategory = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Images$List$SubmittedForm = {$: 5};
var $author$project$Page$Images$List$viewCategoryOption = function (category) {
	return A2(
		$elm$html$Html$option,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(category.cY))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(category.fU)
			]));
};
var $author$project$Page$Images$List$viewCategoryOptions = function (categories) {
	return A2($elm$core$List$map, $author$project$Page$Images$List$viewCategoryOption, categories);
};
var $author$project$Page$Images$List$viewForm = function (model) {
	var options = function () {
		var _v0 = model.aK;
		if (_v0.$ === 2) {
			var categories = _v0.a;
			return A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('All Category')
						])),
				$author$project$Page$Images$List$viewCategoryOptions(categories));
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('All Category')
						]))
				]);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Images$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$select,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('custom-select w-25'),
														$elm$html$Html$Events$onInput($author$project$Page$Images$List$SelectCategory)
													]),
												options),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Images$List$EnteredSearch),
														$elm$html$Html$Attributes$value(model.ai)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Images$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Images$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Images$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Images List Page'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$ImageNew)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Images$List$viewSearch(model),
									$author$project$Page$Images$List$viewItems(
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Images$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Images'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Images - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Images'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Images - Error'
			};
	}
};
var $author$project$Page$Images$View$AcknowledgeDelete = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Images$View$DeleteItem = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Session$toStringUsername = function (session) {
	var _v0 = $author$project$Session$viewer(session);
	if (!_v0.$) {
		var v = _v0.a;
		return $author$project$Username$toString(
			$author$project$Viewer$username(v));
	} else {
		return '';
	}
};
var $author$project$Page$Images$View$viewItemDescriptionTable = function (item) {
	return A2(
		$elm$html$Html$table,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Name')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.fU)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Category')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.eD)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Creator')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.eW)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Full')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.dp)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Thumbnail')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.dq)
									]))
							]))
					]))
			]));
};
var $author$project$Page$Images$View$viewItem = F3(
	function (item, cred, model) {
		var username = $author$project$Session$toStringUsername(model.aA);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-5')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(
											_Utils_ap($author$project$Image$baseUrl, item.dp))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-7')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-danger'),
															$elm$html$Html$Events$onClick(
															A2(
																$author$project$Page$Images$View$DeleteItem,
																cred,
																$author$project$Items$Slug$idToSlug(item.cY))),
															$elm$html$Html$Attributes$hidden(
															(!item.e2) || (!_Utils_eq(item.eW, username)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Delete')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													$author$project$Page$Images$View$viewItemDescriptionTable(item)
												]))
										])),
									A3(
									$author$project$Components$DeleteButton$viewDialog,
									model.aB,
									$elm$core$String$fromInt(item.cY),
									$author$project$Page$Images$View$AcknowledgeDelete)
								]))
						]))
				]));
	});
var $author$project$Page$Images$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Images$View$viewItem, item, cred, model);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + ($elm$core$String$fromInt(item.cY) + (' - ' + item.fU))
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$Imports$Form$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Imports$Form$SubmittedFormItem = {$: 4};
var $author$project$Page$Imports$Form$SelectItem = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Imports$Form$viewItem = F2(
	function (form, item) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.g2) ? item.g2 : _Utils_ap($author$project$Image$baseUrl, item.g2);
		var checked = _Utils_eq(item.cY, form.fB) ? true : false;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '150px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.g5)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Imports$Form$SelectItem(item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Imports$Form$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Imports$Form$viewItem(model.v),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Imports$Form$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Imports$Form$SubmittedFormItem)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Search Item')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Search Item'),
								$elm$html$Html$Events$onInput($author$project$Page$Imports$Form$EnteredSearchItem)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table-responsive')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'width', '150px')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Image')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Item ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('#')
													]))
											]))
									])),
								$author$project$Page$Imports$Form$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Imports$Form$EnteredSearchLocation = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Imports$Form$SubmittedFormLocation = {$: 6};
var $author$project$Page$Imports$Form$ToggleChangeUseGuided = {$: 15};
var $author$project$Page$Imports$Form$SelectLocation = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Imports$Form$viewLocation = F3(
	function (form, useGuidedLocation, item) {
		var typeName = item.e2 ? 'Category' : 'Item';
		var checked = _Utils_eq(item.cY, form.P);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$hidden(!useGuidedLocation)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(typeName)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Imports$Form$SelectLocation(item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Imports$Form$viewLocationSearchResult = function (model) {
	if (model.C) {
		var _v0 = model.aM;
		if (!_v0.$) {
			var locations = _v0.a;
			return A2(
				$elm$html$Html$tbody,
				_List_Nil,
				A2(
					$elm$core$List$map,
					A2($author$project$Page$Imports$Form$viewLocation, model.v, model.C),
					locations));
		} else {
			return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
		}
	} else {
		var _v1 = model.bo;
		if (_v1.$ === 1) {
			var items = _v1.a;
			return A2(
				$elm$html$Html$tbody,
				_List_Nil,
				A2(
					$elm$core$List$map,
					A2($author$project$Page$Imports$Form$viewLocation, model.v, model.C),
					$author$project$PaginatedList$values(items)));
		} else {
			return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
		}
	}
};
var $author$project$Page$Imports$Form$viewLocationPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-check')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check-input'),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Events$onClick($author$project$Page$Imports$Form$ToggleChangeUseGuided),
								$elm$html$Html$Attributes$checked(model.C)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check-label')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Use Guided Location')
							]))
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Imports$Form$SubmittedFormLocation),
						$elm$html$Html$Attributes$hidden(model.C)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Search Location')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Search Item'),
								$elm$html$Html$Events$onInput($author$project$Page$Imports$Form$EnteredSearchLocation)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table-responsive')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$hidden(!model.C)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Type')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('#')
													]))
											]))
									])),
								$author$project$Page$Imports$Form$viewLocationSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Imports$Form$EnteredComment = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Imports$Form$SubmittedForm = {$: 10};
var $author$project$Page$Imports$Form$ToggleChangePage = {$: 13};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $abadi199$elm_input_extra$Input$Number$exceedMaxValue = F2(
	function (maxValue, maybeNumber) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (max, number) {
						return _Utils_cmp(number, max) > 0;
					}),
				maxValue,
				maybeNumber));
	});
var $abadi199$elm_input_extra$Input$Number$lessThanMinValue = F2(
	function (minValue, maybeNumber) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (min, number) {
						return _Utils_cmp(number, min) < 0;
					}),
				minValue,
				maybeNumber));
	});
var $abadi199$elm_input_extra$Input$Number$onChange = function (options) {
	var checkWithMinValue = function (number) {
		return A2($abadi199$elm_input_extra$Input$Number$lessThanMinValue, options.fP, number) ? options.fP : number;
	};
	var checkWithMaxValue = function (number) {
		return A2($abadi199$elm_input_extra$Input$Number$exceedMaxValue, options.fM, number) ? options.fM : number;
	};
	var toInt = function (string) {
		return checkWithMaxValue(
			checkWithMinValue(
				$elm$core$String$toInt(string)));
	};
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2(
			$elm$json$Json$Decode$map,
			A2($elm$core$Basics$composeR, toInt, options.G),
			$elm$html$Html$Events$targetValue));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $abadi199$elm_input_extra$Input$KeyCode$alt = 18;
var $abadi199$elm_input_extra$Input$KeyCode$backspace = 8;
var $abadi199$elm_input_extra$Input$KeyCode$ctrl = 17;
var $abadi199$elm_input_extra$Input$KeyCode$delete = 46;
var $abadi199$elm_input_extra$Input$KeyCode$downArrow = 40;
var $abadi199$elm_input_extra$Input$KeyCode$enter = 13;
var $abadi199$elm_input_extra$Input$KeyCode$leftArrow = 37;
var $abadi199$elm_input_extra$Input$KeyCode$rightArrow = 39;
var $abadi199$elm_input_extra$Input$KeyCode$shift = 16;
var $abadi199$elm_input_extra$Input$KeyCode$tab = 9;
var $abadi199$elm_input_extra$Input$KeyCode$upArrow = 38;
var $abadi199$elm_input_extra$Input$KeyCode$allowedKeyCodes = _List_fromArray(
	[$abadi199$elm_input_extra$Input$KeyCode$leftArrow, $abadi199$elm_input_extra$Input$KeyCode$upArrow, $abadi199$elm_input_extra$Input$KeyCode$rightArrow, $abadi199$elm_input_extra$Input$KeyCode$downArrow, $abadi199$elm_input_extra$Input$KeyCode$backspace, $abadi199$elm_input_extra$Input$KeyCode$ctrl, $abadi199$elm_input_extra$Input$KeyCode$alt, $abadi199$elm_input_extra$Input$KeyCode$delete, $abadi199$elm_input_extra$Input$KeyCode$tab, $abadi199$elm_input_extra$Input$KeyCode$enter, $abadi199$elm_input_extra$Input$KeyCode$shift]);
var $abadi199$elm_input_extra$Input$Decoder$Event = F5(
	function (keyCode, ctrlKey, altKey, metaKey, shiftKey) {
		return {eh: altKey, eY: ctrlKey, aS: keyCode, fN: metaKey, gu: shiftKey};
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $abadi199$elm_input_extra$Input$Decoder$eventDecoder = A6(
	$elm$json$Json$Decode$map5,
	$abadi199$elm_input_extra$Input$Decoder$Event,
	A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'metaKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $abadi199$elm_input_extra$Input$Number$exceedMaxLength = F2(
	function (maybeMaxLength, value) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$not,
				A2(
					$elm$core$Maybe$map,
					function (maxLength) {
						return _Utils_cmp(
							maxLength,
							$elm$core$String$length(value)) > -1;
					},
					maybeMaxLength)));
	});
var $abadi199$elm_input_extra$Input$Number$isValid = F2(
	function (newValue, options) {
		var updatedNumber = $elm$core$String$toInt(newValue);
		return (!A2($abadi199$elm_input_extra$Input$Number$exceedMaxLength, options.fL, newValue)) && (!A2($abadi199$elm_input_extra$Input$Number$exceedMaxValue, options.fM, updatedNumber));
	});
var $abadi199$elm_input_extra$Input$Number$onKeyDown = F2(
	function (options, currentValue) {
		var newValue = function (keyCode) {
			return _Utils_ap(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, currentValue)),
				$elm$core$String$fromChar(
					$elm$core$Char$fromCode(keyCode)));
		};
		var isNumber = function (keyCode) {
			return (keyCode >= 48) && (keyCode <= 57);
		};
		var isNumPad = function (keyCode) {
			return (keyCode >= 96) && (keyCode <= 105);
		};
		var filterKey = function (event) {
			return (event.eY || (event.eh || event.fN)) ? _Utils_Tuple2(
				options.G(currentValue),
				false) : (event.gu ? _Utils_Tuple2(
				options.G(currentValue),
				true) : (A2(
				$elm$core$List$any,
				$elm$core$Basics$eq(event.aS),
				$abadi199$elm_input_extra$Input$KeyCode$allowedKeyCodes) ? _Utils_Tuple2(
				options.G(currentValue),
				false) : (((isNumber(event.aS) || isNumPad(event.aS)) && A2(
				$abadi199$elm_input_extra$Input$Number$isValid,
				newValue(event.aS),
				options)) ? _Utils_Tuple2(
				options.G(
					$elm$core$String$toInt(
						newValue(event.aS))),
				false) : _Utils_Tuple2(
				options.G(currentValue),
				true))));
		};
		var decoder = A2($elm$json$Json$Decode$map, filterKey, $abadi199$elm_input_extra$Input$Decoder$eventDecoder);
		return A2($elm$html$Html$Events$preventDefaultOn, 'keydown', decoder);
	});
var $abadi199$elm_input_extra$Input$Number$input = F3(
	function (options, attributes, currentValue) {
		var toArray = function (a) {
			return A2($elm$core$List$cons, a, _List_Nil);
		};
		var onFocusAttribute = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				toArray,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Events$onFocus,
					A2(
						$elm$core$Maybe$map,
						function (f) {
							return f(true);
						},
						options.bm))));
		var onBlurAttribute = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				toArray,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Events$onBlur,
					A2(
						$elm$core$Maybe$map,
						function (f) {
							return f(false);
						},
						options.bm))));
		var minAttribute = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				toArray,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Attributes$min,
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, options.fP))));
		var maxAttribute = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				toArray,
				A2(
					$elm$core$Maybe$map,
					$elm$html$Html$Attributes$max,
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, options.fM))));
		return A2(
			$elm$html$Html$input,
			A2(
				$elm$core$List$append,
				minAttribute,
				A2(
					$elm$core$List$append,
					maxAttribute,
					A2(
						$elm$core$List$append,
						onBlurAttribute,
						A2(
							$elm$core$List$append,
							onFocusAttribute,
							A2(
								$elm$core$List$append,
								attributes,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2($elm$core$Maybe$map, $elm$core$String$fromInt, currentValue))),
										A2($abadi199$elm_input_extra$Input$Number$onKeyDown, options, currentValue),
										$elm$html$Html$Events$onInput(
										A2($elm$core$Basics$composeR, $elm$core$String$toInt, options.G)),
										$abadi199$elm_input_extra$Input$Number$onChange(options),
										$elm$html$Html$Attributes$type_('number')
									])))))),
			_List_Nil);
	});
var $author$project$Page$Imports$Form$EnteredQuantity = function (a) {
	return {$: 3, a: a};
};
var $abadi199$elm_input_extra$Input$Number$defaultOptions = function (onInput) {
	return {bm: $elm$core$Maybe$Nothing, fL: $elm$core$Maybe$Nothing, fM: $elm$core$Maybe$Nothing, fP: $elm$core$Maybe$Nothing, G: onInput};
};
var $author$project$Page$Imports$Form$inputOptions = function () {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions($author$project$Page$Imports$Form$EnteredQuantity);
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
}();
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Page$Imports$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Imports$Form$viewQuantityComment = function (model) {
	var saveWord = model.aE ? 'Saving' : 'Save';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Imports$Form$viewProblem, model.ax)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Quantity')
							])),
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$Imports$Form$inputOptions,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(model.v.gd))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Imports$Form$EnteredComment),
								$elm$html$Html$Attributes$value(model.v.eL)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-input'),
										$elm$html$Html$Attributes$type_('checkbox'),
										$elm$html$Html$Events$onClick($author$project$Page$Imports$Form$ToggleChangePage),
										$elm$html$Html$Attributes$checked(model._)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-label')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Do not change Page')
									]))
							]))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Imports$Form$SubmittedForm),
						$elm$html$Html$Attributes$disabled(model.aE)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(saveWord)
					]))
			]));
};
var $author$project$Page$Imports$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Import Page')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Imports$Form$viewItemPicker(model),
									$author$project$Page$Imports$Form$viewLocationPicker(model),
									$author$project$Page$Imports$Form$viewQuantityComment(model)
								])),
							A4($pablen$toasty$Toasty$view, $author$project$Page$Imports$Form$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$Imports$Form$ToastyMsg, model.cl)
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Imports$List$AcknowledgeDelete = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Imports$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Imports$List$DeleteItem = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Timestamp$format = F2(
	function (zone, time) {
		var year = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
		var month = function () {
			var _v0 = A2($elm$time$Time$toMonth, zone, time);
			switch (_v0) {
				case 0:
					return 'January';
				case 1:
					return 'February';
				case 2:
					return 'March';
				case 3:
					return 'April';
				case 4:
					return 'May';
				case 5:
					return 'June';
				case 6:
					return 'July';
				case 7:
					return 'August';
				case 8:
					return 'September';
				case 9:
					return 'October';
				case 10:
					return 'November';
				default:
					return 'December';
			}
		}();
		var minute = $elm$core$String$fromInt(
			A2($elm$time$Time$toMinute, zone, time));
		var hour = $elm$core$String$fromInt(
			A2($elm$time$Time$toHour, zone, time));
		var day = $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, zone, time));
		return month + (' ' + (day + (', ' + (year + (' ' + (hour + (':' + minute)))))));
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Timestamp$view = F2(
	function (timeZone, timestamp) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('date')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Timestamp$format, timeZone, timestamp))
				]));
	});
var $author$project$Page$Imports$List$viewItem = F3(
	function (cred, timeZone, item) {
		var username = $author$project$Username$toString(
			$author$project$Api$username(cred));
		var typeName = 'Manual';
		var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(typeName)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-danger btn-sm'),
									$elm$html$Html$Attributes$hidden(
									(!item.e2) || (!_Utils_eq(item.eW, username))),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$Imports$List$DeleteItem, cred, item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-trash-a')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Imports$List$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('LD_ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Image')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Item')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Type')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Location')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Quantity')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Imports$List$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$Imports$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Imports$List$SubmittedForm = {$: 4};
var $author$project$Page$Imports$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container-fluid')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Imports$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Imports$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Imports$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Imports$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Imports$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Imports'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$ImportNew)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Import Init')
												])),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$ImportPo)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Import PO')
												]))
										])),
									$author$project$Page$Imports$List$viewSearch(model),
									A3(
									$author$project$Page$Imports$List$viewItems,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Imports$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items)),
									A3(
									$author$project$Components$DeleteButton$viewDialog,
									model.aB,
									$elm$core$String$fromInt(model.Y),
									$author$project$Page$Imports$List$AcknowledgeDelete)
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Imports$Po$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Imports$Po$SubmittedFormItem = {$: 5};
var $author$project$Page$Imports$Po$SelectItem = F3(
	function (a, b, c) {
		return {$: 9, a: a, b: b, c: c};
	});
var $author$project$Page$Imports$Po$viewItem = F2(
	function (form, item) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
		var checked = (_Utils_eq(item.fB, form.fB) && (_Utils_eq(item.gc, form.gc) && _Utils_eq(item.gf, form.aj))) ? true : false;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '150px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.fB))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gc))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gf))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									A3($author$project$Page$Imports$Po$SelectItem, item.fB, item.gc, item.gf))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Imports$Po$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Imports$Po$viewItem(model.v),
				items));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Imports$Po$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Imports$Po$SubmittedFormItem)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Search Item')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Search Item'),
								$elm$html$Html$Events$onInput($author$project$Page$Imports$Po$EnteredSearchItem)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table-responsive')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'width', '150px')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Image')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Item ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('PO_ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('SHIP_ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Quantity')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('#')
													]))
											]))
									])),
								$author$project$Page$Imports$Po$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Imports$Po$EnteredSearchLocation = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Imports$Po$SubmittedFormLocation = {$: 7};
var $author$project$Page$Imports$Po$ToggleChangeUseGuided = {$: 17};
var $author$project$Page$Imports$Po$SelectLocation = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Imports$Po$viewLocation = F3(
	function (form, useGuidedLocation, item) {
		var typeName = item.e2 ? 'Category' : 'Item';
		var checked = _Utils_eq(item.cY, form.P);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$hidden(!useGuidedLocation)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(typeName)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Imports$Po$SelectLocation(item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Imports$Po$viewLocationSearchResult = function (model) {
	if (model.C) {
		var _v0 = model.aM;
		if (!_v0.$) {
			var locations = _v0.a;
			return A2(
				$elm$html$Html$tbody,
				_List_Nil,
				A2(
					$elm$core$List$map,
					A2($author$project$Page$Imports$Po$viewLocation, model.v, model.C),
					locations));
		} else {
			return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
		}
	} else {
		var _v1 = model.bo;
		if (!_v1.$) {
			var items = _v1.a;
			return A2(
				$elm$html$Html$tbody,
				_List_Nil,
				A2(
					$elm$core$List$map,
					A2($author$project$Page$Imports$Po$viewLocation, model.v, model.C),
					$author$project$PaginatedList$values(items)));
		} else {
			return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
		}
	}
};
var $author$project$Page$Imports$Po$viewLocationPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-check')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check-input'),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Events$onClick($author$project$Page$Imports$Po$ToggleChangeUseGuided),
								$elm$html$Html$Attributes$checked(model.C)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check-label')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Use Guided Location')
							]))
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Imports$Po$SubmittedFormLocation),
						$elm$html$Html$Attributes$hidden(model.C)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Search Location')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Search Item'),
								$elm$html$Html$Events$onInput($author$project$Page$Imports$Po$EnteredSearchLocation)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table-responsive')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$hidden(!model.C)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Type')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('#')
													]))
											]))
									])),
								$author$project$Page$Imports$Po$viewLocationSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Imports$Po$EnteredComment = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Imports$Po$EnteredQuantityInContainer = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Imports$Po$SubmittedForm = {$: 11};
var $author$project$Page$Imports$Po$ToggleChangePage = {$: 14};
var $author$project$Page$Imports$Po$priceFieldToString = function (priceField) {
	if (priceField.a.$ === 1) {
		var _v1 = priceField.a;
		var price = priceField.b;
		return price;
	} else {
		var price = priceField.b;
		return price;
	}
};
var $author$project$Page$Imports$Po$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Imports$Po$viewQuantityComment = function (model) {
	var saveWord = model.aE ? 'Saving' : 'Save';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Imports$Po$viewProblem, model.ax)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Quantity')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Quantity'),
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromInt(model.v.gd))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Quantity Per LD_ID')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Quantity'),
								$elm$html$Html$Events$onInput($author$project$Page$Imports$Po$EnteredQuantityInContainer),
								$elm$html$Html$Attributes$value(
								$author$project$Page$Imports$Po$priceFieldToString(model.v.I))
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-muted')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('เช่น Reel ละ 5000 ให้ใส่เลข 5000 ผลลัพธ์ที่เป็นเศษ จะปัดไป LD สุดท้าย')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Imports$Po$EnteredComment),
								$elm$html$Html$Attributes$value(model.v.eL)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-input'),
										$elm$html$Html$Attributes$type_('checkbox'),
										$elm$html$Html$Events$onClick($author$project$Page$Imports$Po$ToggleChangePage),
										$elm$html$Html$Attributes$checked(model._)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-label')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Do not change Page')
									]))
							]))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Imports$Po$SubmittedForm),
						$elm$html$Html$Attributes$disabled(model.aE)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(saveWord)
					]))
			]));
};
var $author$project$Page$Imports$Po$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Import PO Page')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Imports$Po$viewItemPicker(model),
									$author$project$Page$Imports$Po$viewLocationPicker(model),
									$author$project$Page$Imports$Po$viewQuantityComment(model)
								])),
							A4($pablen$toasty$Toasty$view, $author$project$Page$Imports$Po$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$Imports$Po$ToastyMsg, model.cl)
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Items$Form$saveArticleButton = F2(
	function (caption, extraAttrs) {
		return A2(
			$elm$html$Html$button,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('btn btn-lg pull-xs-right btn-primary'),
				extraAttrs),
			_List_fromArray(
				[
					$elm$html$Html$text(caption)
				]));
	});
var $author$project$Page$Items$Form$editArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Items$Form$saveArticleButton, 'Update Item', extraAttrs);
};
var $author$project$Page$Items$Form$newArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Items$Form$saveArticleButton, 'Save Item', extraAttrs);
};
var $author$project$Page$Items$Form$ClickedSave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Items$Form$EnteredBody = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Items$Form$EnteredTitle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Items$Form$SelectedCategory = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Items$Form$ToggleNotChangePage = {$: 10};
var $author$project$Page$Items$Form$viewCategoryOption = F2(
	function (selectedID, category) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(category.cY)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(category.cY, selectedID))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(category.fU)
				]));
	});
var $author$project$Page$Items$Form$viewForm = F4(
	function (model, cred, fields, saveButton) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit(
					$author$project$Page$Items$Form$ClickedSave(cred))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$fieldset,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control form-control-lg'),
											$elm$html$Html$Attributes$placeholder('Item Title'),
											$elm$html$Html$Events$onInput($author$project$Page$Items$Form$EnteredTitle),
											$elm$html$Html$Attributes$value(fields.g5)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$textarea,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control'),
											$elm$html$Html$Attributes$placeholder('Write your item description (in markdown)'),
											A2($elm$html$Html$Attributes$attribute, 'rows', '8'),
											$elm$html$Html$Events$onInput($author$project$Page$Items$Form$EnteredBody),
											$elm$html$Html$Attributes$value(fields.a7)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control'),
											$elm$html$Html$Events$onInput($author$project$Page$Items$Form$SelectedCategory)
										]),
									A2(
										$elm$core$List$map,
										$author$project$Page$Items$Form$viewCategoryOption(fields.eC),
										model.aK))
								])),
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-checkbox')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('checkbox'),
													$elm$html$Html$Attributes$class('form-check-input'),
													$elm$html$Html$Attributes$checked(model._),
													$elm$html$Html$Events$onClick($author$project$Page$Items$Form$ToggleNotChangePage)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('form-check-label')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Do not change page')
												]))
										]))
								])),
							saveButton
						]))
				]));
	});
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $author$project$Page$Items$Form$RemoveItem = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Items$Form$viewCartItem = function (item) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(
								_Utils_ap($author$project$Image$baseUrl, item.dq)),
								$elm$html$Html$Attributes$width(150),
								$elm$html$Html$Attributes$width(150),
								A2($elm$html$Html$Attributes$style, 'margin', '0')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(item.fU)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('btn btn-sm btn-danger', true)
									])),
								$elm$html$Html$Events$onClick(
								$author$project$Page$Items$Form$RemoveItem(item))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-trash-a')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Page$Items$Form$viewCartResult = function (model) {
	return A2(
		$elm$html$Html$tbody,
		_List_Nil,
		A2($elm$core$List$map, $author$project$Page$Items$Form$viewCartItem, model.o));
};
var $author$project$Page$Items$Form$viewImageCart = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h5,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Selected Images')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('-')
											]))
									]))
							])),
						$author$project$Page$Items$Form$viewCartResult(model)
					]))
			]));
};
var $author$project$Page$Items$Form$EnteredSearchItem = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Items$Form$SubmittedFormItem = {$: 13};
var $author$project$Page$Items$Form$SelectItem = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Items$Form$viewItem = F2(
	function (carts, item) {
		var filteredCart = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.cY, item.cY);
			},
			carts);
		var checked = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$hidden(checked)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(
									_Utils_ap($author$project$Image$baseUrl, item.dq)),
									$elm$html$Html$Attributes$width(150),
									$elm$html$Html$Attributes$width(150),
									A2($elm$html$Html$Attributes$style, 'margin', '0')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Items$Form$SelectItem(item))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-plus')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Items$Form$viewItemSearchResult = function (model) {
	var _v0 = model.a$;
	if (_v0.$ === 1) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Items$Form$viewItem(model.o),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Items$Form$viewImageSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Items$Form$SubmittedFormItem)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Search Image')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm'),
								$elm$html$Html$Attributes$placeholder('Search Image'),
								$elm$html$Html$Events$onInput($author$project$Page$Items$Form$EnteredSearchItem)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('+')
											]))
									]))
							])),
						$author$project$Page$Items$Form$viewItemSearchResult(model)
					]))
			]));
};
var $author$project$Page$Items$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var message = problem.b;
			return message;
		} else {
			var message = problem.a;
			return message;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Items$Form$viewProblems = function (problems) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-messages')
			]),
		A2($elm$core$List$map, $author$project$Page$Items$Form$viewProblem, problems));
};
var $author$project$Page$Items$Form$viewAuthenticated = F2(
	function (cred, model) {
		var formHtml = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return _List_Nil;
				case 1:
					return _List_fromArray(
						[$author$project$Loading$icon]);
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							A4(
							$author$project$Page$Items$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Items$Form$editArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 6:
					var form = _v0.a;
					return _List_fromArray(
						[
							A4(
							$author$project$Page$Items$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Items$Form$newArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 4:
					var slug = _v0.a;
					var problems = _v0.b;
					var form = _v0.c;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Updating Item: ' + $author$project$Items$Slug$toString(slug))
								])),
							$author$project$Page$Items$Form$viewProblems(problems),
							A4(
							$author$project$Page$Items$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Items$Form$editArticleSaveButton(_List_Nil)),
							A4($pablen$toasty$Toasty$view, $author$project$Page$Items$Form$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$Items$Form$ToastyMsg, model.cl)
						]);
				case 5:
					var problems = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Create Item')
								])),
							$author$project$Page$Items$Form$viewProblems(problems),
							A4(
							$author$project$Page$Items$Form$viewForm,
							model,
							cred,
							form,
							$author$project$Page$Items$Form$newArticleSaveButton(_List_Nil)),
							A4($pablen$toasty$Toasty$view, $author$project$Page$Items$Form$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$Items$Form$ToastyMsg, model.cl)
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$text('Article failed to load.')
						]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('editor-page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid page')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-4')
										]),
									_List_fromArray(
										[
											$author$project$Page$Items$Form$viewImageSearch(model)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-4')
										]),
									_List_fromArray(
										[
											$author$project$Page$Items$Form$viewImageCart(model)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-4')
										]),
									formHtml)
								]))
						]))
				]));
	});
var $author$project$Page$Items$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2($author$project$Page$Items$Form$viewAuthenticated, cred, model);
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Items$List$ClickedFeedPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Items$List$viewItem = function (item) {
	var imageUrl = A2($elm$core$String$contains, 'http', item.g2) ? item.g2 : _Utils_ap($author$project$Image$baseUrl, item.g2);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$author$project$Route$href(
						$author$project$Route$Item(
							$author$project$Items$Slug$idToSlug(item.cY)))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item-img')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(imageUrl)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('item-title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(item.g5)
							]))
					]))
			]));
};
var $author$project$Page$Items$List$viewItems = function (items) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		A2($elm$core$List$map, $author$project$Page$Items$List$viewItem, items));
};
var $author$project$Page$Items$List$EnteredSearch = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Items$List$SelectCategory = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Items$List$SubmittedForm = {$: 5};
var $author$project$Page$Items$List$viewCategoryOption = function (category) {
	return A2(
		$elm$html$Html$option,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(category.cY))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(category.fU)
			]));
};
var $author$project$Page$Items$List$viewCategoryOptions = function (categories) {
	return A2($elm$core$List$map, $author$project$Page$Items$List$viewCategoryOption, categories);
};
var $author$project$Page$Items$List$viewForm = function (model) {
	var options = function () {
		var _v0 = model.aK;
		if (_v0.$ === 2) {
			var categories = _v0.a;
			return A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('All Category')
						])),
				$author$project$Page$Items$List$viewCategoryOptions(categories));
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('All Category')
						]))
				]);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Items$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$select,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('custom-select w-25'),
														$elm$html$Html$Events$onInput($author$project$Page$Items$List$SelectCategory)
													]),
												options),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Items$List$EnteredSearch),
														$elm$html$Html$Attributes$value(model.ai)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Items$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Items$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Items$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Items List Page'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$NewItem)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Items$List$viewSearch(model),
									$author$project$Page$Items$List$viewItems(
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Items$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Items List Page'),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-primary'),
											$author$project$Route$href($author$project$Route$NewItem)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('+')
										]))
								])),
							$author$project$Page$Items$List$viewSearch(model)
						])),
				g5: 'Items - Loading'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Items$View$AcknowledgeDelete = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Items$View$DeleteItem = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Items$View$viewItemDescriptionTable = function (item) {
	return A2(
		$elm$html$Html$table,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Title')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.g5)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Description')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.a7)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Category')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(item.eD)
									]))
							])),
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Remaining')
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$elm$core$String$fromInt(item.dx))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Items$View$ClickedPositiveMovementPage = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Items$View$viewPositiveMovementTr = F2(
	function (timeZone, movement) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, movement.cH)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.hb))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.fS)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.eL)
						]))
				]));
	});
var $author$project$Page$Items$View$viewMovePlus = function (model) {
	var _v0 = model.bx;
	if (_v0.$ === 2) {
		var movements = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Created At')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('LotID')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Type')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Quantity')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('By')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Remaining')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Comments')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A2(
								$elm$core$List$map,
								$author$project$Page$Items$View$viewPositiveMovementTr(model.ck),
								$author$project$PaginatedList$values(movements)))
						])),
					A3(
					$author$project$PaginatedList$viewPagination,
					$author$project$Page$Items$View$ClickedPositiveMovementPage,
					model.by,
					$author$project$PaginatedList$total(movements))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Items$View$ClickedMovementPage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Items$View$viewMovementTr = F2(
	function (timeZone, movement) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, movement.cH)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.hb))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.fS)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.dx))
						]))
				]));
	});
var $author$project$Page$Items$View$viewMovements = function (model) {
	var _v0 = model.br;
	if (_v0.$ === 2) {
		var movements = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Created At')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('TransactionID')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Comments')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Type')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('LD_ID')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Location')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Quantity')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('By')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Remaining')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A2(
								$elm$core$List$map,
								$author$project$Page$Items$View$viewMovementTr(model.ck),
								$author$project$PaginatedList$values(movements)))
						])),
					A3(
					$author$project$PaginatedList$viewPagination,
					$author$project$Page$Items$View$ClickedMovementPage,
					model.bs,
					$author$project$PaginatedList$total(movements))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Items$View$ClickedRemainLotPage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Items$View$viewRemainingDetailTr = F2(
	function (timeZone, movement) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(movement.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(movement.dx))
						]))
				]));
	});
var $author$project$Page$Items$View$viewRemainingDetail = function (model) {
	var _v0 = model.bB;
	if (_v0.$ === 2) {
		var movements = _v0.a;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('LD_ID')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Location')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Remaining')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A2(
								$elm$core$List$map,
								$author$project$Page$Items$View$viewRemainingDetailTr(model.ck),
								$author$project$PaginatedList$values(movements)))
						])),
					A3(
					$author$project$PaginatedList$viewPagination,
					$author$project$Page$Items$View$ClickedRemainLotPage,
					model.bA,
					$author$project$PaginatedList$total(movements))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Items$View$viewThumb = function (tn) {
	return A2(
		$elm$html$Html$img,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$src(tn),
				$elm$html$Html$Attributes$width(100),
				$elm$html$Html$Attributes$height(100)
			]),
		_List_Nil);
};
var $author$project$Page$Items$View$viewItem = F3(
	function (item, cred, model) {
		var listOfThumbnail = A2(
			$elm$core$List$map,
			function (cart) {
				return _Utils_ap($author$project$Image$baseUrl, cart.dq);
			},
			model.o);
		var bigImage = A2(
			$elm$core$Maybe$withDefault,
			'https://via.placeholder.com/500',
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (cart) {
						return _Utils_ap($author$project$Image$baseUrl, cart.dp);
					},
					model.o)));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container-fluid')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-5')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(bigImage),
											$elm$html$Html$Attributes$width(500),
											$elm$html$Html$Attributes$height(500)
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									A2($elm$core$List$map, $author$project$Page$Items$View$viewThumb, listOfThumbnail))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-7')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary'),
															$author$project$Route$href(
															$author$project$Route$EditItem(
																$author$project$Items$Slug$idToSlug(item.cY)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Update')
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-danger'),
															$elm$html$Html$Events$onClick(
															A2(
																$author$project$Page$Items$View$DeleteItem,
																cred,
																$author$project$Items$Slug$idToSlug(item.cY))),
															$elm$html$Html$Attributes$hidden(!item.e2)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Delete')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													$author$project$Page$Items$View$viewItemDescriptionTable(item)
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-5')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Remaining Details')
										])),
									$author$project$Page$Items$View$viewRemainingDetail(model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-7')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Import / Buy / Transform')
										])),
									$author$project$Page$Items$View$viewMovePlus(model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-12')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Movements')
										])),
									$author$project$Page$Items$View$viewMovements(model)
								]))
						])),
					A3(
					$author$project$Components$DeleteButton$viewDialog,
					model.aB,
					$elm$core$String$fromInt(item.cY),
					$author$project$Page$Items$View$AcknowledgeDelete)
				]));
	});
var $author$project$Page$Items$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Items$View$viewItem, item, cred, model);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + ($elm$core$String$fromInt(item.cY) + (' - ' + item.g5))
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$Locations$Form$saveArticleButton = F2(
	function (caption, extraAttrs) {
		return A2(
			$elm$html$Html$button,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('btn btn-lg pull-xs-right btn-primary'),
				extraAttrs),
			_List_fromArray(
				[
					$elm$html$Html$text(caption)
				]));
	});
var $author$project$Page$Locations$Form$editArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Locations$Form$saveArticleButton, 'Update Location', extraAttrs);
};
var $author$project$Page$Locations$Form$newArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Locations$Form$saveArticleButton, 'Save Location', extraAttrs);
};
var $author$project$Page$Locations$Form$ClickedSave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Locations$Form$EnteredTitle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Locations$Form$viewForm = F3(
	function (cred, fields, saveButton) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit(
					$author$project$Page$Locations$Form$ClickedSave(cred))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$fieldset,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control form-control-lg'),
											$elm$html$Html$Attributes$placeholder('Location Title'),
											$elm$html$Html$Events$onInput($author$project$Page$Locations$Form$EnteredTitle),
											$elm$html$Html$Attributes$value(fields.g5)
										]),
									_List_Nil)
								])),
							saveButton
						]))
				]));
	});
var $author$project$Page$Locations$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var message = problem.b;
			return message;
		} else {
			var message = problem.a;
			return message;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Locations$Form$viewProblems = function (problems) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-messages')
			]),
		A2($elm$core$List$map, $author$project$Page$Locations$Form$viewProblem, problems));
};
var $author$project$Page$Locations$Form$viewAuthenticated = F2(
	function (cred, model) {
		var formHtml = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return _List_Nil;
				case 1:
					return _List_fromArray(
						[$author$project$Loading$icon]);
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							A3(
							$author$project$Page$Locations$Form$viewForm,
							cred,
							form,
							$author$project$Page$Locations$Form$editArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 6:
					var form = _v0.a;
					return _List_fromArray(
						[
							A3(
							$author$project$Page$Locations$Form$viewForm,
							cred,
							form,
							$author$project$Page$Locations$Form$newArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 4:
					var slug = _v0.a;
					var problems = _v0.b;
					var form = _v0.c;
					return _List_fromArray(
						[
							$author$project$Page$Locations$Form$viewProblems(problems),
							A3(
							$author$project$Page$Locations$Form$viewForm,
							cred,
							form,
							$author$project$Page$Locations$Form$editArticleSaveButton(_List_Nil))
						]);
				case 5:
					var problems = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							$author$project$Page$Locations$Form$viewProblems(problems),
							A3(
							$author$project$Page$Locations$Form$viewForm,
							cred,
							form,
							$author$project$Page$Locations$Form$newArticleSaveButton(_List_Nil))
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$text('Article failed to load.')
						]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('editor-page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container page')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-10 offset-md-1 col-xs-12')
										]),
									formHtml)
								]))
						]))
				]));
	});
var $author$project$Page$Locations$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2($author$project$Page$Locations$Form$viewAuthenticated, cred, model);
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Locations$List$AcknowledgeDelete = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Locations$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Locations$List$DeleteItem = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$Locations$List$viewItem = F2(
	function (cred, item) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm mr-10'),
									$author$project$Route$href(
									$author$project$Route$Location(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm mr-10'),
									$author$project$Route$href(
									$author$project$Route$EditLocation(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-edit')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-danger btn-sm'),
									$elm$html$Html$Attributes$hidden(!item.e2),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$Locations$List$DeleteItem, cred, item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-trash-a')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Locations$List$viewItems = F2(
	function (cred, items) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table-responsive')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'width', '85%')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Name')
												])),
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$width(100)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Actions')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A2(
								$elm$core$List$map,
								$author$project$Page$Locations$List$viewItem(cred),
								items))
						]))
				]));
	});
var $author$project$Page$Locations$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Locations$List$SubmittedForm = {$: 4};
var $author$project$Page$Locations$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Locations$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Locations$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Locations$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Locations$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Locations$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Location List Page'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$NewLocation)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Locations$List$viewSearch(model),
									A2(
									$author$project$Page$Locations$List$viewItems,
									cred,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Locations$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items)),
									A3(
									$author$project$Components$DeleteButton$viewDialog,
									model.aB,
									$elm$core$String$fromInt(model.Y),
									$author$project$Page$Locations$List$AcknowledgeDelete)
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Locations$View$DeleteItem = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $author$project$Page$Locations$View$viewDescriptionTable = F2(
	function (model, item) {
		var totalItems = function () {
			var _v0 = model.aD;
			if (_v0.$ === 2) {
				var stocks = _v0.a;
				return $elm$core$List$length(
					$author$project$PaginatedList$values(stocks));
			} else {
				return 0;
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('ID')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(item.cY))
										]))
								])),
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(item.fU)
										]))
								])),
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Total LD In Location')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(totalItems))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Locations$View$viewTrLd = function (stock) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.dx))
					]))
			]));
};
var $author$project$Page$Locations$View$viewLdInLocation = function (stocks) {
	if (stocks.$ === 2) {
		var items = stocks.a;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Locations$View$viewTrLd,
			$author$project$PaginatedList$values(items));
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Locations$View$viewItem = F3(
	function (model, item, cred) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pl-20')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary'),
															$author$project$Route$href(
															$author$project$Route$EditLocation(
																$author$project$Items$Slug$idToSlug(item.cY)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Update')
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-danger'),
															$elm$html$Html$Events$onClick(
															A2(
																$author$project$Page$Locations$View$DeleteItem,
																cred,
																$author$project$Items$Slug$idToSlug(item.cY))),
															$elm$html$Html$Attributes$hidden(!item.e2)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Delete')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2($author$project$Page$Locations$View$viewDescriptionTable, model, item)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-9')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('LD In locations')
										])),
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('LD ID')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item Name')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Remaining')
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											$author$project$Page$Locations$View$viewLdInLocation(model.aD))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Locations$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Locations$View$viewItem, model, item, cred);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + ($elm$core$String$fromInt(item.cY) + (' - ' + item.fU))
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + model.ch
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$Login$EnteredEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$EnteredPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$SubmittedForm = {$: 2};
var $author$project$Page$Login$viewForm = F2(
	function (form, model) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit($author$project$Page$Login$SubmittedForm)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$fieldset,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-group')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control form-control-lg'),
									$elm$html$Html$Attributes$placeholder('Email'),
									$elm$html$Html$Events$onInput($author$project$Page$Login$EnteredEmail),
									$elm$html$Html$Attributes$value(form.O)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$fieldset,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-group')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control form-control-lg'),
									$elm$html$Html$Attributes$type_('password'),
									$elm$html$Html$Attributes$placeholder('Password'),
									$elm$html$Html$Events$onInput($author$project$Page$Login$EnteredPassword),
									$elm$html$Html$Attributes$value(form.R)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn btn-lg btn-primary pull-xs-right'),
							$elm$html$Html$Attributes$disabled(model.aU)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in')
						]))
				]));
	});
var $author$project$Page$Login$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Login$view = function (model) {
	return {
		eO: A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('cred-page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container page')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-6 offset-md-3 col-xs-12')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-xs-center')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Sign in')
												])),
											A2(
											$elm$html$Html$ul,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('error-messages')
												]),
											A2($elm$core$List$map, $author$project$Page$Login$viewProblem, model.ax)),
											A2($author$project$Page$Login$viewForm, model.v, model)
										]))
								]))
						]))
				])),
		g5: 'Login'
	};
};
var $author$project$Page$Moves$Form$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$Form$SubmittedFormItem = {$: 4};
var $author$project$Page$Moves$Form$SelectItem = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Page$Moves$Form$viewItem = F2(
	function (carts, stock) {
		var filteredCartMaxQuantity = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fG, stock.fG) && (_Utils_eq(cart.P, stock.P) && _Utils_eq(cart.gd, stock.dx));
			},
			carts);
		var reachMaxChecked = $elm$core$List$length(filteredCartMaxQuantity) > 0;
		var filteredCart = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fG, stock.fG) && _Utils_eq(cart.P, stock.P);
			},
			carts);
		var checked = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Attributes$hidden(reachMaxChecked),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$Moves$Form$SelectItem, stock, 1))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-plus')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', reachMaxChecked),
											_Utils_Tuple2('btn-light', !reachMaxChecked)
										])),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$Moves$Form$SelectItem, stock, stock.dx))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-ios-briefcase')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Moves$Form$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Moves$Form$viewItem(model.o),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Moves$Form$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$hidden(!model.v.x)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$Moves$Form$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Item')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control'),
										$elm$html$Html$Attributes$placeholder('Search Item'),
										$elm$html$Html$Events$onInput($author$project$Page$Moves$Form$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table-responsive')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$table,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$thead,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$tr,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Name')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('LD_ID')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Location')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Remain')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('+1 / All')
															]))
													]))
											])),
										$author$project$Page$Moves$Form$viewItemSearchResult(model)
									]))
							]))
					]))
			]));
};
var $author$project$Page$Moves$Form$EnteredSearchLocation = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Moves$Form$SubmittedFormLocation = {$: 6};
var $author$project$Page$Moves$Form$SelectLocation = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Moves$Form$viewLocation = F2(
	function (form, item) {
		var checked = _Utils_eq(item.cY, form.x);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Moves$Form$SelectLocation(item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Moves$Form$viewLocationSearchResult = function (model) {
	var _v0 = model.bo;
	if (_v0.$ === 1) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Moves$Form$viewLocation(model.v),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Moves$Form$viewLocationPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Moves$Form$SubmittedFormLocation)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Destination Location')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Search Location'),
								$elm$html$Html$Events$onInput($author$project$Page$Moves$Form$EnteredSearchLocation)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('#')
											]))
									]))
							])),
						$author$project$Page$Moves$Form$viewLocationSearchResult(model)
					]))
			]));
};
var $author$project$Page$Moves$Form$EnteredComment = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Moves$Form$SubmittedForm = {$: 10};
var $author$project$Page$Moves$Form$EnteredQuantity = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Moves$Form$inputOptions = function (stock) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$Moves$Form$EnteredQuantity(stock));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$Moves$Form$viewCart = function (stock) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fF)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$Moves$Form$inputOptions(stock),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(stock.gd))
					]))
			]));
};
var $author$project$Page$Moves$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Moves$Form$viewQuantityComment = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Moves$Form$viewProblem, model.ax)),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('LotID')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('FromLocation')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$Moves$Form$viewCart, model.o))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Moves$Form$EnteredComment)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Moves$Form$SubmittedForm)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]))
			]));
};
var $author$project$Page$Moves$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Move Page')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Moves$Form$viewLocationPicker(model),
									$author$project$Page$Moves$Form$viewItemPicker(model),
									$author$project$Page$Moves$Form$viewQuantityComment(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Moves$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Moves$List$viewItem = F3(
	function (cred, timeZone, item) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$MoveView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Moves$List$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('MoveID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Moves$List$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$Moves$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Moves'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
													$author$project$Route$href($author$project$Route$MoveNew),
													A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Choose Destination')
												])),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
													$author$project$Route$href($author$project$Route$MoveToOriginal),
													A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('To Original Location')
												]))
										])),
									A3(
									$author$project$Page$Moves$List$viewItems,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Moves$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Moves$Original$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Moves$Original$SelectItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Moves$Original$viewItem = F2(
	function (carts, stock) {
		var filteredCartMaxQuantity = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fG, stock.fG);
			},
			carts);
		var reachMaxChecked = $elm$core$List$length(filteredCartMaxQuantity) > 0;
		var filteredCart = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fG, stock.fG);
			},
			carts);
		var checked = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', reachMaxChecked),
											_Utils_Tuple2('btn-light', !reachMaxChecked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Moves$Original$SelectItem(stock))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-ios-briefcase')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Moves$Original$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		var itemsTmp = $author$project$PaginatedList$values(items);
		var finalItems = (model.bE === '') ? itemsTmp : A2(
			$elm$core$List$filter,
			function (i) {
				return A2($elm$core$String$contains, model.bE, i.fC);
			},
			itemsTmp);
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Moves$Original$viewItem(model.o),
				finalItems));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Moves$Original$viewItemPicker = function (model) {
	var colClass = (!model.v.p.cY) ? 'col-md-5' : 'col-md-7';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(colClass),
				$elm$html$Html$Attributes$hidden(!model.v.p.cY)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fieldset')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Item')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control'),
										$elm$html$Html$Attributes$placeholder('Search Item'),
										$elm$html$Html$Events$onInput($author$project$Page$Moves$Original$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table-responsive')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$table,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$thead,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$tr,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Name')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('LD_ID')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Location')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Quantity')
															])),
														A2(
														$elm$html$Html$th,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('MoveAll')
															]))
													]))
											])),
										$author$project$Page$Moves$Original$viewItemSearchResult(model)
									]))
							]))
					]))
			]));
};
var $author$project$Page$Moves$Original$EnteredSearchLocation = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Moves$Original$SubmittedFormLocation = {$: 5};
var $author$project$Page$Moves$Original$SelectLocation = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Moves$Original$viewLocation = F2(
	function (form, item) {
		var checked = _Utils_eq(item.cY, form.p.cY);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Moves$Original$SelectLocation(item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Moves$Original$viewLocationSearchResult = function (model) {
	var _v0 = model.bo;
	if (_v0.$ === 1) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Moves$Original$viewLocation(model.v),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Moves$Original$viewLocationPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-7'),
				$elm$html$Html$Attributes$hidden(!(!model.v.p.cY))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Moves$Original$SubmittedFormLocation)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('From Location')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Search Location'),
								$elm$html$Html$Events$onInput($author$project$Page$Moves$Original$EnteredSearchLocation)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('#')
											]))
									]))
							])),
						$author$project$Page$Moves$Original$viewLocationSearchResult(model)
					]))
			]));
};
var $author$project$Page$Moves$Original$EnteredComment = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Moves$Original$SubmittedForm = {$: 9};
var $author$project$Page$Moves$Original$EnteredQuantity = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Moves$Original$inputOptions = function (stock) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$Moves$Original$EnteredQuantity(stock));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$Moves$Original$viewCart = function (stock) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fF)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('input-group input-group-sm')
							]),
						_List_fromArray(
							[
								A3(
								$abadi199$elm_input_extra$Input$Number$input,
								$author$project$Page$Moves$Original$inputOptions(stock),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm')
									]),
								$elm$core$Maybe$Just(stock.gd)),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('input-group-append')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												'/' + $elm$core$String$fromInt(stock.dx))
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.dx - stock.gd))
					]))
			]));
};
var $author$project$Page$Moves$Original$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Moves$Original$viewQuantityComment = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-5')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Moves$Original$viewProblem, model.ax)),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('LD_ID')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('To Location')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Lost')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$Moves$Original$viewCart, model.o))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Moves$Original$EnteredComment)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Moves$Original$SubmittedForm),
						$elm$html$Html$Attributes$disabled(model.bD)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]))
			]));
};
var $author$project$Page$Moves$Original$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Move Page: ' + model.v.p.fU)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Moves$Original$viewLocationPicker(model),
									$author$project$Page$Moves$Original$viewItemPicker(model),
									$author$project$Page$Moves$Original$viewQuantityComment(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Page$Moves$View$viewMetaDetailTable = F2(
	function (model, item) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-md-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table-responsive')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('ID')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Items$Slug$toString(model.bG))
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Comments')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eL)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Creator')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eW)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Created At')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															A2($author$project$Timestamp$view, model.ck, item.eV)
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Moves$View$viewTr = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fF)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gd))
					]))
			]));
};
var $author$project$Page$Moves$View$viewMoveDetailTr = function (stockStatus) {
	if (stockStatus.$ === 2) {
		var stocks = stockStatus.a;
		return A2($elm$core$List$map, $author$project$Page$Moves$View$viewTr, stocks);
	} else {
		return _List_fromArray(
			[
				A2($elm$html$Html$tr, _List_Nil, _List_Nil)
			]);
	}
};
var $author$project$Page$Moves$View$viewItem = F3(
	function (item, cred, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'MoveID: ' + $elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Moves$View$viewMetaDetailTable, model, item),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Location')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('LD_ID')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Image')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item Name')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Quantity')
																]))
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											$author$project$Page$Moves$View$viewMoveDetailTr(model.br))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Moves$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Moves$View$viewItem, item, cred, model);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + $elm$core$String$fromInt(item.cY)
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Asset$error = $author$project$Asset$image('error.jpg');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $author$project$Page$NotFound$view = {
	eO: A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('content'),
				$elm$html$Html$Attributes$class('container'),
				$elm$html$Html$Attributes$tabindex(-1)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Not Found')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$author$project$Asset$src($author$project$Asset$error)
							]),
						_List_Nil)
					]))
			])),
	g5: 'Page Not Found'
};
var $author$project$Page$Packings$Form$EnteredQuantity = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$Packings$Form$inputOptions = function (item) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$Packings$Form$EnteredQuantity(item));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$Packings$Form$viewCartTr = F2(
	function (isUpdate, item) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
		var checked = false;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'width', '100px')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gc))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$hidden(!isUpdate)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$hidden(isUpdate)
						]),
					_List_fromArray(
						[
							A3(
							$abadi199$elm_input_extra$Input$Number$input,
							$author$project$Page$Packings$Form$inputOptions(item),
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control form-control-sm')
								]),
							$elm$core$Maybe$Just(item.gd))
						]))
				]));
	});
var $author$project$Page$Packings$Form$viewCart = F2(
	function (isUpdate, items) {
		var className = isUpdate ? 'col-md-8' : 'col-md-4';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(className)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h5,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Selected Item')
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Image')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Name')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('PO_ID')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Quantity')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A2(
								$elm$core$List$map,
								$author$project$Page$Packings$Form$viewCartTr(isUpdate),
								items))
						]))
				]));
	});
var $author$project$Page$Packings$Form$ClickedSave = {$: 1};
var $author$project$Page$Packings$Form$EnteredComment = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Packings$Form$EnteredShipFee = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Packings$Form$EnteredShipper = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Packings$Form$EnteredTracking = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Packings$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var message = problem.b;
			return message;
		} else {
			var message = problem.a;
			return message;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Packings$Form$viewProblems = function (problems) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-messages')
			]),
		A2($elm$core$List$map, $author$project$Page$Packings$Form$viewProblem, problems));
};
var $author$project$Page$Packings$Form$viewDetailAndSave = F3(
	function (isUpdate, form, problems) {
		var btnWords = isUpdate ? 'Update' : 'Save';
		var btnClassName = 'btn btn-block ' + (isUpdate ? 'btn-success' : 'btn-primary');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-md-4')
				]),
			_List_fromArray(
				[
					$author$project$Page$Packings$Form$viewProblems(problems),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$hidden(isUpdate)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Be careful you cannot edit item and Quantity after save')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-group'),
							$elm$html$Html$Attributes$hidden(!isUpdate)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Shipper')
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Attributes$value(form.gv),
									$elm$html$Html$Events$onInput($author$project$Page$Packings$Form$EnteredShipper)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-group'),
							$elm$html$Html$Attributes$hidden(!isUpdate)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Tracking')
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Attributes$value(form.ha),
									$elm$html$Html$Events$onInput($author$project$Page$Packings$Form$EnteredTracking)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-group'),
							$elm$html$Html$Attributes$hidden(!isUpdate)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Ship Fee (CNY)')
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Events$onInput($author$project$Page$Packings$Form$EnteredShipFee),
									$elm$html$Html$Attributes$value(
									$author$project$Page$Packings$Form$priceFieldToString(form.dI))
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-group'),
							$elm$html$Html$Attributes$hidden(!isUpdate)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Comments')
								])),
							A2(
							$elm$html$Html$textarea,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Events$onInput($author$project$Page$Packings$Form$EnteredComment)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(form.eL)
								]))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(btnClassName),
							$elm$html$Html$Events$onClick($author$project$Page$Packings$Form$ClickedSave)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(btnWords)
						]))
				]));
	});
var $author$project$Page$Packings$Form$EnteredSearchItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Packings$Form$SelectItem = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Packings$Form$viewPickListTr = function (item) {
	var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
	var checked = false;
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(item.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.gc))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('btn btn-sm', true),
										_Utils_Tuple2('btn-primary', checked),
										_Utils_Tuple2('btn-light', !checked)
									])),
								$elm$html$Html$Events$onClick(
								$author$project$Page$Packings$Form$SelectItem(item))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-plus')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Page$Packings$Form$viewPickList = function (status) {
	if (status.$ === 1) {
		var items = status.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-md-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Search Item from Received List')
						])),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-control form-control-sm'),
							$elm$html$Html$Attributes$placeholder('Search Item'),
							$elm$html$Html$Events$onInput($author$project$Page$Packings$Form$EnteredSearchItem)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped table-scrollable')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Image')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Name')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('PO_ID')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Quantity')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										]))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							A2($elm$core$List$map, $author$project$Page$Packings$Form$viewPickListTr, items))
						]))
				]));
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Packings$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = _Utils_Tuple2(
				$author$project$Session$cred(model.aA),
				model.dL);
			if (!_v0.a.$) {
				switch (_v0.b.$) {
					case 0:
						var cred = _v0.a.a;
						var slug = _v0.b.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'Packing Update: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Loading')
										]))
								]));
					case 4:
						var cred = _v0.a.a;
						var _v1 = _v0.b;
						var slug = _v1.a;
						var problems = _v1.b;
						var form = _v1.c;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'Packing Update: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2($author$project$Page$Packings$Form$viewCart, true, model.o),
											A3($author$project$Page$Packings$Form$viewDetailAndSave, true, form, problems)
										]))
								]));
					case 5:
						var cred = _v0.a.a;
						var _v2 = _v0.b;
						var problems = _v2.a;
						var form = _v2.b;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Create Packing List')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$Packings$Form$viewPickList(model.aQ),
											A2($author$project$Page$Packings$Form$viewCart, false, model.o),
											A3($author$project$Page$Packings$Form$viewDetailAndSave, false, form, problems)
										]))
								]));
					default:
						var cred = _v0.a.a;
						return A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Error Load Item')
								]));
				}
			} else {
				var _v3 = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Packings$List$AcknowledgeDelete = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Packings$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Packings$List$viewItem = F3(
	function (cred, timeZone, item) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.cH)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.gv)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.ha)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.dI)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
									$author$project$Route$href(
									$author$project$Route$PackingUpdate(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-edit')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Packings$List$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('PO ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Shipper')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Tracking')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Ship Fee')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Packings$List$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$Packings$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Packings$List$SubmittedForm = {$: 4};
var $author$project$Page$Packings$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Packings$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Packings$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Packings$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Packings$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Packings$List$view = function (model) {
	var _v0 = model.aR;
	if (_v0.$ === 2) {
		var items = _v0.a;
		return {
			eO: function () {
				var _v1 = $author$project$Session$cred(model.aA);
				if (!_v1.$) {
					var cred = _v1.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('container-fluid')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Packing List'),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
												$author$project$Route$href($author$project$Route$PackingNew),
												A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('+ Packing')
											]))
									])),
								$author$project$Page$Packings$List$viewSearch(model),
								A3(
								$author$project$Page$Packings$List$viewItems,
								cred,
								model.ck,
								$author$project$PaginatedList$values(items)),
								A3(
								$author$project$PaginatedList$viewPagination,
								$author$project$Page$Packings$List$ClickedFeedPage,
								model.aP,
								$author$project$PaginatedList$total(items)),
								A3(
								$author$project$Components$DeleteButton$viewDialog,
								model.aB,
								$elm$core$String$fromInt(model.Y),
								$author$project$Page$Packings$List$AcknowledgeDelete)
							]));
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('home-page')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Sign in Please')
							]));
				}
			}(),
			g5: 'Items'
		};
	} else {
		return {
			eO: A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('home-page')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('')
					])),
			g5: 'Items'
		};
	}
};
var $author$project$Page$Packings$View$DeleteItem = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$Packings$View$viewDescriptionTable = F2(
	function (model, item) {
		var totalItems = function () {
			var _v0 = model.aD;
			if (_v0.$ === 2) {
				var stocks = _v0.a;
				return $elm$core$List$length(
					$author$project$PaginatedList$values(stocks));
			} else {
				return 0;
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('ID')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(item.cY))
										]))
								])),
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(item.fU)
										]))
								])),
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Total LD In Location')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(totalItems))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Packings$View$viewTrLd = function (stock) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.dx))
					]))
			]));
};
var $author$project$Page$Packings$View$viewLdInLocation = function (stocks) {
	if (stocks.$ === 2) {
		var items = stocks.a;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Packings$View$viewTrLd,
			$author$project$PaginatedList$values(items));
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Packings$View$viewItem = F3(
	function (model, item, cred) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pl-20')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary'),
															$author$project$Route$href(
															$author$project$Route$EditLocation(
																$author$project$Items$Slug$idToSlug(item.cY)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Update')
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-danger'),
															$elm$html$Html$Events$onClick(
															A2(
																$author$project$Page$Packings$View$DeleteItem,
																cred,
																$author$project$Items$Slug$idToSlug(item.cY))),
															$elm$html$Html$Attributes$hidden(!item.e2)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Delete')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2($author$project$Page$Packings$View$viewDescriptionTable, model, item)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-9')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('LD In locations')
										])),
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('LD ID')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item Name')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Remaining')
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											$author$project$Page$Packings$View$viewLdInLocation(model.aD))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Packings$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Packings$View$viewItem, model, item, cred);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + ($elm$core$String$fromInt(item.cY) + (' - ' + item.fU))
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + model.ch
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$Payments$Form$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Payments$Form$SubmittedFormItem = {$: 2};
var $author$project$Page$Payments$Form$viewItem = function (item) {
	var pricePerPcs = function () {
		var _v0 = $elm$core$String$toFloat(item.gb);
		if (!_v0.$) {
			var total = _v0.a;
			return $elm$core$String$fromFloat(
				$elm$core$Basics$round((total / item.gd) * 100.0) / 100);
		} else {
			return '0';
		}
	}();
	var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(item.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.gc))
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(pricePerPcs)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(item.gb)
					]))
			]));
};
var $author$project$Page$Payments$Form$viewItemSearchResult = function (model) {
	var _v0 = model.Z;
	if (!_v0.$) {
		var details = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Page$Payments$Form$viewItem, details));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Payments$Form$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-10')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$Payments$Form$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Item')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm'),
										$elm$html$Html$Attributes$placeholder('Search Item'),
										$elm$html$Html$Events$onInput($author$project$Page$Payments$Form$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Img')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Item ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('PO ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Quantity')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Price / Pcs')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Price Total')
													]))
											]))
									])),
								$author$project$Page$Payments$Form$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Payments$Form$EditConversionRate = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Payments$Form$SubmittedForm = {$: 5};
var $author$project$Page$Payments$Form$viewProblem = function (problem) {
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(problem)
			]));
};
var $author$project$Page$Payments$Form$viewQuantityComment = function (model) {
	var totalRMB = function () {
		var _v1 = model.Z;
		if (!_v1.$) {
			var details = _v1.a;
			return $elm$core$List$sum(
				A2(
					$elm$core$List$map,
					function (d) {
						return A2(
							$elm$core$Maybe$withDefault,
							0.0,
							$elm$core$String$toFloat(d.gb));
					},
					details));
		} else {
			return 0.0;
		}
	}();
	var totalTHB = function () {
		var _v0 = model.v.eR;
		if (!_v0.a.$) {
			var c = _v0.a.a;
			return $elm$core$Basics$round((c * totalRMB) * 100) / 100;
		} else {
			return 0.0;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Payments$Form$viewProblem, model.ax)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Total (RMB)')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromFloat(totalRMB))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Conversion Rate : 1 RMB')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Events$onInput($author$project$Page$Payments$Form$EditConversionRate),
								$elm$html$Html$Attributes$value(
								$author$project$Page$Payments$Form$priceFieldToString(model.v.eR))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Total (THB)')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromFloat(totalTHB))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-success btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Payments$Form$SubmittedForm)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Confirm Batch Payments')
					]))
			]));
};
var $author$project$Page$Payments$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Create Payments')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Payments$Form$viewItemPicker(model),
									$author$project$Page$Payments$Form$viewQuantityComment(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Payments$List$AcknowledgeDelete = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Payments$List$ClickedFeedPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Payments$List$viewItem = F4(
	function (cred, model, timeZone, item) {
		var username = $author$project$Username$toString(
			$author$project$Api$username(cred));
		var totalTHB = (item.cJ === 'THB') ? item.ei : $elm$core$String$fromFloat(
			$elm$core$Basics$round(
				(A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toFloat(item.ei)) * A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toFloat(item.eR))) * 100) / 100);
		var hideDelete = (!_Utils_eq(item.eW, username)) || (_Utils_cmp(
			$elm$time$Time$posixToMillis(model.aG) - (((24 * 60) * 60) * 1000),
			$elm$time$Time$posixToMillis(item.eV)) > 0);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.cJ)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eR)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.ei)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(totalTHB)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$PaymentView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Payments$List$viewItems = F4(
	function (cred, model, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Payment ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Currency')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Conversion Rate')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Original Amount')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Total THB')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$width(150)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A3($author$project$Page$Payments$List$viewItem, cred, model, timeZone),
						items))
				]));
	});
var $author$project$Page$Payments$List$viewItemSupplier = F4(
	function (cred, model, timeZone, item) {
		var username = $author$project$Username$toString(
			$author$project$Api$username(cred));
		var hideDelete = (!_Utils_eq(item.eW, username)) || (_Utils_cmp(
			$elm$time$Time$posixToMillis(model.aG) - (((24 * 60) * 60) * 1000),
			$elm$time$Time$posixToMillis(item.eV)) > 0);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.ei)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$PaymentView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Payments$List$viewItemsSupplierType = F4(
	function (cred, model, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Payment ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Amount')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$width(150)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A3($author$project$Page$Payments$List$viewItemSupplier, cred, model, timeZone),
						items))
				]));
	});
var $author$project$Page$Payments$List$EnteredSearch = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Payments$List$SubmittedForm = {$: 5};
var $author$project$Page$Payments$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Payments$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Payments$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Payments$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Payments$List$viewForm(model)
					]))
			]));
};
var $author$project$Session$viewerType = function (session) {
	var _v0 = $author$project$Session$viewer(session);
	if (!_v0.$) {
		var val = _v0.a;
		return $author$project$Viewer$viewerType(val);
	} else {
		return 0;
	}
};
var $author$project$Page$Payments$List$view = function (model) {
	var _v0 = model.aR;
	if (_v0.$ === 2) {
		var items = _v0.a;
		return {
			eO: function () {
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					$author$project$Session$viewerType(model.aA));
				if (!_v1.a.$) {
					if (!_v1.b) {
						var cred = _v1.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Payments'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
													$author$project$Route$href($author$project$Route$PaymentNew),
													A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Payments$List$viewSearch(model),
									A4(
									$author$project$Page$Payments$List$viewItems,
									cred,
									model,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Payments$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items)),
									A3(
									$author$project$Components$DeleteButton$viewDialog,
									model.aB,
									$elm$core$String$fromInt(model.Y),
									$author$project$Page$Payments$List$AcknowledgeDelete)
								]));
					} else {
						var cred = _v1.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Payments')
										])),
									$author$project$Page$Payments$List$viewSearch(model),
									A4(
									$author$project$Page$Payments$List$viewItemsSupplierType,
									cred,
									model,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Payments$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					}
				} else {
					var _v2 = _v1.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('home-page')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Sign in Please')
							]));
				}
			}(),
			g5: 'Items'
		};
	} else {
		return {
			eO: A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('home-page')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('')
					])),
			g5: 'Items'
		};
	}
};
var $author$project$Page$Payments$View$viewItem = function (item) {
	var pricePerPcs = function () {
		var _v0 = $elm$core$String$toFloat(item.gb);
		if (!_v0.$) {
			var total = _v0.a;
			return $elm$core$String$fromFloat(
				$elm$core$Basics$round((total / item.gd) * 100.0) / 100);
		} else {
			return '0';
		}
	}();
	var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(item.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(pricePerPcs)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(item.gb)
					]))
			]));
};
var $author$project$Page$Payments$View$viewItemSearchResult = function (model) {
	var _v0 = model.Z;
	if (!_v0.$) {
		var details = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Page$Payments$View$viewItem, details));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Payments$View$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-10')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Img')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Item ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('PO ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Quantity')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Price / Pcs')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Price Total')
													]))
											]))
									])),
								$author$project$Page$Payments$View$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Payments$View$priceFieldToString = function (priceField) {
	if (priceField.a.$ === 1) {
		var _v1 = priceField.a;
		var price = priceField.b;
		return price;
	} else {
		var price = priceField.b;
		return price;
	}
};
var $author$project$Page$Payments$View$viewQuantityComment = function (model) {
	var totalRMB = function () {
		var _v1 = model.Z;
		if (!_v1.$) {
			var details = _v1.a;
			return $elm$core$List$sum(
				A2(
					$elm$core$List$map,
					function (d) {
						return A2(
							$elm$core$Maybe$withDefault,
							0.0,
							$elm$core$String$toFloat(d.gb));
					},
					details));
		} else {
			return 0.0;
		}
	}();
	var totalTHB = function () {
		var _v0 = model.v.eR;
		if (!_v0.a.$) {
			var c = _v0.a.a;
			return $elm$core$Basics$round((c * totalRMB) * 100) / 100;
		} else {
			return 0.0;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Total (RMB)')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromFloat(totalRMB))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Conversion Rate : 1 RMB')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$value(
								$author$project$Page$Payments$View$priceFieldToString(model.v.eR))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Total (THB)')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromFloat(totalTHB))
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Page$Payments$View$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'View Payments: ' + $author$project$Items$Slug$toString(model.bG))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Payments$View$viewItemPicker(model),
									$author$project$Page$Payments$View$viewQuantityComment(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$PurchaseOrders$Confirm$ChangeStatus = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$SetQuantity = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Page$PurchaseOrders$Confirm$priceFieldToString = function (priceField) {
	if (priceField.a.$ === 1) {
		var _v1 = priceField.a;
		var price = priceField.b;
		return price;
	} else {
		var price = priceField.b;
		return price;
	}
};
var $author$project$Page$PurchaseOrders$Confirm$viewByMode = function (model) {
	var items = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (_v4) {
				var id = _v4.a;
				var itemDetail = _v4.b;
				var itemImages = _v4.c;
				if (!itemDetail.$) {
					return _Utils_eq(id, model.v.fB);
				} else {
					return false;
				}
			},
			model.u));
	var viewMode = function () {
		if (!items.$) {
			var _v1 = items.a;
			var itemDetail = _v1.b;
			var images = _v1.c;
			if (!itemDetail.$) {
				var item = itemDetail.a;
				if (!model.an) {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Description')
										])),
									A2($elm$html$Html$hr, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(item.a7)
										])),
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Images')
										])),
									A2($elm$html$Html$hr, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$ul,
									_List_Nil,
									A2(
										$elm$core$List$map,
										function (im) {
											var imageUrl = A2($elm$core$String$contains, 'http', im.dp) ? im.dp : _Utils_ap($author$project$Image$baseUrl, im.dp);
											return A2(
												$elm$html$Html$li,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$img,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$src(imageUrl),
																A2($elm$html$Html$Attributes$style, 'width', '100%')
															]),
														_List_Nil)
													]));
										},
										images))
								]))
						]);
				} else {
					if (model.an === 1) {
						var _v3 = $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (i) {
									return _Utils_eq(i.fB, model.v.fB);
								},
								model.o));
						if (!_v3.$) {
							var stock = _v3.a;
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-group'),
											$elm$html$Html$Attributes$hidden(!stock.dL)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Price Total')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('form-control'),
													$elm$html$Html$Attributes$disabled(true),
													$elm$html$Html$Attributes$value(
													$author$project$Page$PurchaseOrders$Confirm$priceFieldToString(model.v.gb))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Page$PurchaseOrders$Confirm$priceFieldToString(model.v.gb))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-group'),
											$elm$html$Html$Attributes$hidden(!(!stock.dL))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Adjust Quantity')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('form-control'),
													$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Confirm$SetQuantity),
													$elm$html$Html$Attributes$value(
													$elm$core$String$fromInt(model.v.gd))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-block btn-primary'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$PurchaseOrders$Confirm$ChangeStatus(3)),
											$elm$html$Html$Attributes$disabled(model.bD),
											$elm$html$Html$Attributes$hidden(stock.dL !== 2)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Confirm')
										])),
									A2($elm$html$Html$hr, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-block btn-warning'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$PurchaseOrders$Confirm$ChangeStatus(1)),
											$elm$html$Html$Attributes$disabled(model.bD)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Revert')
										])),
									A2($elm$html$Html$hr, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-block btn-danger'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$PurchaseOrders$Confirm$ChangeStatus(0)),
											$elm$html$Html$Attributes$disabled(model.bD),
											$elm$html$Html$Attributes$hidden((!stock.dL) || (stock.dL >= 4))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Cancel')
										]))
								]);
						} else {
							return _List_Nil;
						}
					} else {
						return _List_Nil;
					}
				}
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		viewMode);
};
var $author$project$Page$PurchaseOrders$Confirm$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$Confirm$SelectItem = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Models$PurchaseOrder$detailStatusToString = function (statusInt) {
	switch (statusInt) {
		case 0:
			return 'Cannot Find / Cancel';
		case 1:
			return 'Wait for Quote';
		case 2:
			return 'Wait for Confirm';
		case 3:
			return 'Confirmed';
		case 4:
			return 'Ordered';
		case 5:
			return 'Supplier Received';
		case 6:
			return 'Supplier Shipped';
		case 7:
			return 'Buyer Received';
		default:
			return 'Unknown';
	}
};
var $author$project$Page$PurchaseOrders$Confirm$viewCart = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Page$PurchaseOrders$Confirm$priceFieldToString(stock.gb))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Models$PurchaseOrder$detailStatusToString(stock.dL))
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$PurchaseOrders$Confirm$SelectItem, stock, 0))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-eye')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$PurchaseOrders$Confirm$SelectItem, stock, 1)),
								$elm$html$Html$Attributes$hidden((stock.dL !== 2) && ((stock.dL !== 3) && (!(!stock.dL))))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-edit')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Confirm$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$PurchaseOrders$Confirm$viewQuantityComment = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$PurchaseOrders$Confirm$viewProblem, model.ax)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm'),
								$elm$html$Html$Attributes$placeholder('Search Item'),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Confirm$EnteredSearchItem)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped table-scrollable')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Price Total (CNY)')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Action')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$PurchaseOrders$Confirm$viewCart, model.o))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Confirm$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Buyer PO Update : ' + $author$project$Items$Slug$toString(model.bG))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Confirm$viewQuantityComment(model),
									$author$project$Page$PurchaseOrders$Confirm$viewByMode(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$PurchaseOrders$Form$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$Form$SubmittedFormItem = {$: 3};
var $author$project$Page$PurchaseOrders$Form$SelectItem = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$PurchaseOrders$Form$viewItem = F3(
	function (carts, targetItem, item) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.g2) ? item.g2 : _Utils_ap($author$project$Image$baseUrl, item.g2);
		var filteredCart = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fB, item.cY);
			},
			carts);
		var checked = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'width', '100px')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.g5)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$PurchaseOrders$Form$SelectItem(item))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-plus')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$Form$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A2($author$project$Page$PurchaseOrders$Form$viewItem, model.o, model.v.n),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$PurchaseOrders$Form$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$PurchaseOrders$Form$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Item')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm'),
										$elm$html$Html$Attributes$placeholder('Search Item'),
										$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Form$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Item ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Image')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Remain')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('+Cart / Output')
													]))
											]))
									])),
								$author$project$Page$PurchaseOrders$Form$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Form$ConfirmedForm = {$: 11};
var $author$project$Page$PurchaseOrders$Form$EnteredComment = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$PurchaseOrders$Form$SubmittedForm = {$: 10};
var $author$project$Page$PurchaseOrders$Form$EnteredQuantity = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Form$inputOptions = function (stock) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$PurchaseOrders$Form$EnteredQuantity(stock));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$PurchaseOrders$Form$viewCart = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$PurchaseOrders$Form$inputOptions(stock),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(stock.gd))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$PurchaseOrders$Form$SelectSupplier = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$PurchaseOrders$Form$viewSupplierOption = F2(
	function (selectedInt, category) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(category.cY)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(category.cY, selectedInt))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(category.fU)
				]));
	});
var $author$project$Page$PurchaseOrders$Form$viewSupplierOptions = F2(
	function (categories, selectedSupplierID) {
		return A2(
			$elm$core$List$map,
			$author$project$Page$PurchaseOrders$Form$viewSupplierOption(selectedSupplierID),
			categories);
	});
var $author$project$Page$PurchaseOrders$Form$viewSupplierInput = function (model) {
	var options = A2(
		$elm$core$List$cons,
		A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value('')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Select Supplier')
				])),
		A2($author$project$Page$PurchaseOrders$Form$viewSupplierOptions, model.aF, model.v.n.cY));
	var mustBeDisable = function () {
		var _v0 = model.K;
		if (_v0.$ === 4) {
			return true;
		} else {
			return false;
		}
	}();
	return A2(
		$elm$html$Html$select,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-control'),
				$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Form$SelectSupplier),
				$elm$html$Html$Attributes$disabled(mustBeDisable)
			]),
		options);
};
var $author$project$Page$PurchaseOrders$Form$viewQuantityComment = function (model) {
	var hideConfirm = function () {
		var _v0 = model.K;
		if (_v0.$ === 4) {
			return false;
		} else {
			return true;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$PurchaseOrders$Form$viewProblem, model.ax)),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('ID')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$PurchaseOrders$Form$viewCart, model.o))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Suppliers')
							])),
						$author$project$Page$PurchaseOrders$Form$viewSupplierInput(model)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Form$EnteredComment),
								$elm$html$Html$Attributes$value(model.v.eL)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$Form$SubmittedForm)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save Draft')
					])),
				A2($elm$html$Html$hr, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-warning btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$Form$ConfirmedForm),
						$elm$html$Html$Attributes$hidden(hideConfirm)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Confirm Draft')
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = _Utils_Tuple2(
				$author$project$Session$cred(model.aA),
				model.K);
			if (!_v0.a.$) {
				switch (_v0.b.$) {
					case 2:
						var cred = _v0.a.a;
						var slug = _v0.b.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'PO Edit: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$PurchaseOrders$Form$viewItemPicker(model),
											$author$project$Page$PurchaseOrders$Form$viewQuantityComment(model)
										]))
								]));
					case 4:
						var cred = _v0.a.a;
						var _v1 = _v0.b;
						var slug = _v1.a;
						var problems = _v1.b;
						var form = _v1.c;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'PO Edit: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$PurchaseOrders$Form$viewItemPicker(model),
											$author$project$Page$PurchaseOrders$Form$viewQuantityComment(model)
										]))
								]));
					default:
						var cred = _v0.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Purchase Order')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$PurchaseOrders$Form$viewItemPicker(model),
											$author$project$Page$PurchaseOrders$Form$viewQuantityComment(model)
										]))
								]));
				}
			} else {
				var _v2 = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$SubmittedFormItem = {$: 4};
var $author$project$Page$PurchaseOrders$KnownPriceForm$SelectItem = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewItem = F3(
	function (carts, targetItem, item) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.g2) ? item.g2 : _Utils_ap($author$project$Image$baseUrl, item.g2);
		var filteredCart = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fB, item.cY);
			},
			carts);
		var checked = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.g5)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$PurchaseOrders$KnownPriceForm$SelectItem(item))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-plus')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A2($author$project$Page$PurchaseOrders$KnownPriceForm$viewItem, model.o, model.v.n),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$PurchaseOrders$KnownPriceForm$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Item')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm'),
										$elm$html$Html$Attributes$placeholder('Search Item'),
										$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$KnownPriceForm$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Item ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Image')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Remain')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('+Cart / Output')
													]))
											]))
									])),
								$author$project$Page$PurchaseOrders$KnownPriceForm$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$EnteredComment = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$EnteredQuantityShipFee = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$SelectPayTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$SubmittedForm = {$: 11};
var $author$project$Page$PurchaseOrders$KnownPriceForm$EnteredPriceTotal = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$EnteredQuantity = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$inputOptions = function (stock) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$PurchaseOrders$KnownPriceForm$EnteredQuantity(stock));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewCart = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$PurchaseOrders$KnownPriceForm$inputOptions(stock),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(stock.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm'),
								$elm$html$Html$Events$onInput(
								$author$project$Page$PurchaseOrders$KnownPriceForm$EnteredPriceTotal(stock)),
								$elm$html$Html$Attributes$value(
								$author$project$Page$PurchaseOrders$KnownPriceForm$priceFieldToString(stock.gb))
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$SelectSupplier = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewSupplierOption = F2(
	function (selectedInt, category) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(category.cY)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(category.cY, selectedInt))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(category.fU)
				]));
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewSupplierOptions = F2(
	function (categories, selectedSupplierID) {
		return A2(
			$elm$core$List$map,
			$author$project$Page$PurchaseOrders$KnownPriceForm$viewSupplierOption(selectedSupplierID),
			categories);
	});
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewSupplierInput = function (model) {
	var options = A2(
		$elm$core$List$cons,
		A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value('')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Select Supplier')
				])),
		A2($author$project$Page$PurchaseOrders$KnownPriceForm$viewSupplierOptions, model.aF, model.v.n.cY));
	var mustBeDisable = function () {
		var _v0 = model.K;
		if (_v0.$ === 4) {
			return true;
		} else {
			return false;
		}
	}();
	return A2(
		$elm$html$Html$select,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-control'),
				$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$KnownPriceForm$SelectSupplier),
				$elm$html$Html$Attributes$disabled(mustBeDisable)
			]),
		options);
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$viewQuantityComment = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$PurchaseOrders$KnownPriceForm$viewProblem, model.ax)),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('ID')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Price Total')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$PurchaseOrders$KnownPriceForm$viewCart, model.o))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Suppliers')
							])),
						$author$project$Page$PurchaseOrders$KnownPriceForm$viewSupplierInput(model)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Pay To')
							])),
						A2(
						$elm$html$Html$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$KnownPriceForm$SelectPayTo)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value('0')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Me')
									])),
								A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value('1')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Supplier')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Currency')
							])),
						A2(
						$elm$html$Html$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$KnownPriceForm$SelectPayTo)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value('THB')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('THB')
									])),
								A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value('USD')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('USD')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Ship Fee')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$value(
								$author$project$Page$PurchaseOrders$KnownPriceForm$priceFieldToString(model.v.dI)),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$KnownPriceForm$EnteredQuantityShipFee)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$KnownPriceForm$EnteredComment),
								$elm$html$Html$Attributes$value(model.v.eL)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$KnownPriceForm$SubmittedForm)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]))
			]));
};
var $author$project$Page$PurchaseOrders$KnownPriceForm$view = function (model) {
	return {
		eO: function () {
			var _v0 = _Utils_Tuple2(
				$author$project$Session$cred(model.aA),
				model.K);
			if (!_v0.a.$) {
				switch (_v0.b.$) {
					case 2:
						var cred = _v0.a.a;
						var slug = _v0.b.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'PO Edit: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$PurchaseOrders$KnownPriceForm$viewItemPicker(model),
											$author$project$Page$PurchaseOrders$KnownPriceForm$viewQuantityComment(model)
										]))
								]));
					case 4:
						var cred = _v0.a.a;
						var _v1 = _v0.b;
						var slug = _v1.a;
						var problems = _v1.b;
						var form = _v1.c;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'PO Edit: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$PurchaseOrders$KnownPriceForm$viewItemPicker(model),
											$author$project$Page$PurchaseOrders$KnownPriceForm$viewQuantityComment(model)
										]))
								]));
					default:
						var cred = _v0.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Purchase Order: Known Price')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$PurchaseOrders$KnownPriceForm$viewItemPicker(model),
											$author$project$Page$PurchaseOrders$KnownPriceForm$viewQuantityComment(model)
										]))
								]));
				}
			} else {
				var _v2 = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$PurchaseOrders$List$AcknowledgeDelete = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$PurchaseOrders$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PurchaseOrders$List$DeleteItem = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Models$PurchaseOrder$statusToString = function (statusInt) {
	switch (statusInt) {
		case 0:
			return 'Canceled';
		case 1:
			return 'Draft';
		case 2:
			return 'Supplier Processing';
		case 3:
			return 'Some Ready to Confirm';
		case 5:
			return 'Waiting for Item';
		case 6:
			return 'Partial Received';
		case 10:
			return 'Completed';
		default:
			return 'Unknown';
	}
};
var $author$project$Page$PurchaseOrders$List$viewItem = F3(
	function (cred, timeZone, item) {
		var username = $author$project$Username$toString(
			$author$project$Api$username(cred));
		var updateRoute = (!item.fb) ? $author$project$Route$PoKnownPriceUpdate(
			$author$project$Items$Slug$idToSlug(item.cY)) : ((item.dL === 3) ? $author$project$Route$PoConfirmAndChat(
			$author$project$Items$Slug$idToSlug(item.cY)) : $author$project$Route$PoUpdate(
			$author$project$Items$Slug$idToSlug(item.cY)));
		var statusStr = $author$project$Models$PurchaseOrder$statusToString(item.dL);
		var payTo = (!item.f4) ? 'Creator' : 'Supplier';
		var paidStatus = item.dm ? 'Yes' : 'No';
		var hideEdit = ((!_Utils_eq(item.eW, username)) || (!item.fb)) ? true : (((item.dL === 1) || (item.dL === 3)) ? false : true);
		var hideDelete = ((item.fb === 1) && (item.dL === 1)) ? false : (((!item.fb) && (item.dL === 5)) ? false : (((item.dL > 5) || (item.dL <= 1)) ? true : true));
		var flowType = (!item.fb) ? 'Known Price' : 'Quotation';
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.gL)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(flowType)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.cJ)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(statusStr)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(paidStatus)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(payTo)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$PoView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
									$author$project$Route$href(updateRoute),
									$elm$html$Html$Attributes$hidden(hideEdit)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-edit')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
									$author$project$Route$href(
									$author$project$Route$PoReceive(
										$author$project$Items$Slug$idToSlug(item.cY))),
									$elm$html$Html$Attributes$hidden(!((!item.fb) && (item.dL === 5)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-fork-repo')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-danger btn-sm'),
									$elm$html$Html$Attributes$hidden(hideDelete),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$PurchaseOrders$List$DeleteItem, cred, item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-trash-a')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$List$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('PO ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Supplier Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Flow Type')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Currency')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Status')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Paid Status')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Pay To')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$width(150)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$PurchaseOrders$List$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$PurchaseOrders$List$viewItemSupplier = F3(
	function (cred, timeZone, item) {
		var updateRoute = (!item.fb) ? $author$project$Route$PoKnownPriceUpdate(
			$author$project$Items$Slug$idToSlug(item.cY)) : ((item.dL === 3) ? $author$project$Route$PoConfirmAndChat(
			$author$project$Items$Slug$idToSlug(item.cY)) : $author$project$Route$PoUpdate(
			$author$project$Items$Slug$idToSlug(item.cY)));
		var statusStr = $author$project$Models$PurchaseOrder$statusToString(item.dL);
		var hideEditSupplier = ((item.fb === 1) && ((item.dL === 2) || (item.dL === 3))) ? false : true;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.cJ)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(statusStr)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$PoView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-warning btn-sm'),
									$author$project$Route$href(
									$author$project$Route$PoSupplierUpdate(
										$author$project$Items$Slug$idToSlug(item.cY))),
									$elm$html$Html$Attributes$hidden(hideEditSupplier)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-edit')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$List$viewItemsSupplierType = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('PO ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Currency')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Status')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$width(150)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$PurchaseOrders$List$viewItemSupplier, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$PurchaseOrders$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PurchaseOrders$List$SubmittedForm = {$: 4};
var $author$project$Page$PurchaseOrders$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$PurchaseOrders$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$PurchaseOrders$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$PurchaseOrders$List$view = function (model) {
	var _v0 = model.aR;
	if (_v0.$ === 2) {
		var items = _v0.a;
		return {
			eO: function () {
				var _v1 = _Utils_Tuple2(
					$author$project$Session$cred(model.aA),
					$author$project$Session$viewerType(model.aA));
				if (!_v1.a.$) {
					if (!_v1.b) {
						var cred = _v1.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Purchase Order'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
													$author$project$Route$href($author$project$Route$PoNew),
													A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+ Ask Quote')
												])),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
													$author$project$Route$href($author$project$Route$PoKnownPrice),
													A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+ Known Price')
												]))
										])),
									$author$project$Page$PurchaseOrders$List$viewSearch(model),
									A3(
									$author$project$Page$PurchaseOrders$List$viewItems,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$PurchaseOrders$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items)),
									A3(
									$author$project$Components$DeleteButton$viewDialog,
									model.aB,
									$elm$core$String$fromInt(model.Y),
									$author$project$Page$PurchaseOrders$List$AcknowledgeDelete)
								]));
					} else {
						var cred = _v1.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Purchase Order')
										])),
									$author$project$Page$PurchaseOrders$List$viewSearch(model),
									A3(
									$author$project$Page$PurchaseOrders$List$viewItemsSupplierType,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$PurchaseOrders$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					}
				} else {
					var _v2 = _v1.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('home-page')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Sign in Please')
							]));
				}
			}(),
			g5: 'Items'
		};
	} else {
		return {
			eO: A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('home-page')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('')
					])),
			g5: 'Items'
		};
	}
};
var $author$project$Page$PurchaseOrders$Receive$EnteredSearchItem = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$SelectItem = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$viewTr = F2(
	function (form, stock) {
		var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
		var checked = _Utils_eq(stock.fB, form.fB) ? true : false;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.fB))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'width', '100px')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.gf))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Attributes$hidden(
									!(_Utils_cmp(stock.gd, stock.gf) > 0)),
									$elm$html$Html$Events$onClick(
									$author$project$Page$PurchaseOrders$Receive$SelectItem(stock.fB))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$Receive$viewMoveDetailTr = F3(
	function (query, form, stockStatus) {
		if (stockStatus.$ === 2) {
			var stocks = stockStatus.a;
			var searchStocks = ($elm$core$String$length(query) > 0) ? A2(
				$elm$core$List$filter,
				function (s) {
					var localID = A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(query));
					return A2($elm$core$String$contains, query, s.fC) || _Utils_eq(s.fB, localID);
				},
				stocks) : stocks;
			return A2(
				$elm$core$List$map,
				$author$project$Page$PurchaseOrders$Receive$viewTr(form),
				searchStocks);
		} else {
			return _List_fromArray(
				[
					A2($elm$html$Html$tr, _List_Nil, _List_Nil)
				]);
		}
	});
var $author$project$Page$PurchaseOrders$Receive$viewItem = F3(
	function (item, cred, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-md-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-group')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Search Item')
								])),
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-control'),
									$elm$html$Html$Attributes$placeholder('Search Item'),
									$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Receive$EnteredSearchItem)
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table-responsive')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item ID')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Image')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item Name')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Quantity')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Received')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Action')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									A3($author$project$Page$PurchaseOrders$Receive$viewMoveDetailTr, model.bE, model.v, model.Z))
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$Receive$EnteredSearchLocation = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$SubmittedFormLocation = {$: 9};
var $author$project$Page$PurchaseOrders$Receive$ToggleChangeUseGuided = {$: 5};
var $author$project$Page$PurchaseOrders$Receive$SelectLocation = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$viewLocation = F3(
	function (form, useGuidedLocation, item) {
		var typeName = item.e2 ? 'Category' : 'Item';
		var checked = _Utils_eq(item.cY, form.P);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$hidden(!useGuidedLocation)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(typeName)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$PurchaseOrders$Receive$SelectLocation(item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-checkmark')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$Receive$viewLocationSearchResult = function (model) {
	if (model.C) {
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A2($author$project$Page$PurchaseOrders$Receive$viewLocation, model.v, model.C),
				model.aM));
	} else {
		var _v0 = model.bo;
		if (_v0.$ === 2) {
			var items = _v0.a;
			return A2(
				$elm$html$Html$tbody,
				_List_Nil,
				A2(
					$elm$core$List$map,
					A2($author$project$Page$PurchaseOrders$Receive$viewLocation, model.v, model.C),
					$author$project$PaginatedList$values(items)));
		} else {
			return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
		}
	}
};
var $author$project$Page$PurchaseOrders$Receive$viewLocationPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-check')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check-input'),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$Receive$ToggleChangeUseGuided),
								$elm$html$Html$Attributes$checked(model.C)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check-label')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Use Guided Location')
							]))
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$PurchaseOrders$Receive$SubmittedFormLocation),
						$elm$html$Html$Attributes$hidden(model.C)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Search Location')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Search Item'),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Receive$EnteredSearchLocation)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table-responsive')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$hidden(!model.C)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Type')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('#')
													]))
											]))
									])),
								$author$project$Page$PurchaseOrders$Receive$viewLocationSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Receive$ResetForm = {$: 17};
var $author$project$Page$PurchaseOrders$Receive$SubmittedForm = {$: 8};
var $author$project$Page$PurchaseOrders$Receive$ToggleChangePage = {$: 4};
var $author$project$Page$PurchaseOrders$Receive$EnteredQuantity = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PurchaseOrders$Receive$inputOptions = function () {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions($author$project$Page$PurchaseOrders$Receive$EnteredQuantity);
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
}();
var $author$project$Page$PurchaseOrders$Receive$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$PurchaseOrders$Receive$viewQuantityComment = function (model) {
	var saveWord = model.aE ? 'Saving' : 'Save';
	var hideQuantity = function () {
		var _v0 = model.aO;
		if (_v0.$ === 2) {
			var item = _v0.a;
			return !item.fb;
		} else {
			return false;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-4')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$PurchaseOrders$Receive$viewProblem, model.ax)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group'),
						$elm$html$Html$Attributes$hidden(hideQuantity)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Quantity')
							])),
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$PurchaseOrders$Receive$inputOptions,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(model.v.gd))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group'),
						$elm$html$Html$Attributes$hidden(hideQuantity)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-check')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-input'),
										$elm$html$Html$Attributes$type_('checkbox'),
										$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$Receive$ToggleChangePage),
										$elm$html$Html$Attributes$checked(model._)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-check-label')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Do not change Page')
									]))
							]))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$Receive$SubmittedForm),
						$elm$html$Html$Attributes$disabled(model.aE),
						$elm$html$Html$Attributes$hidden(!(!model.T))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(saveWord)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$hidden(!model.T)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Result LD ID: ' + $elm$core$String$fromInt(model.T))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-warning btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$Receive$ResetForm),
						$elm$html$Html$Attributes$hidden(!model.T)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Reset')
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Receive$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('PO Received')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A3($author$project$Page$PurchaseOrders$Receive$viewItem, item, cred, model),
											$author$project$Page$PurchaseOrders$Receive$viewLocationPicker(model),
											$author$project$Page$PurchaseOrders$Receive$viewQuantityComment(model)
										])),
									A4($pablen$toasty$Toasty$view, $author$project$Page$PurchaseOrders$Receive$myConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Page$PurchaseOrders$Receive$ToastyMsg, model.cl)
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + $elm$core$String$fromInt(item.cY)
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$PurchaseOrders$Supplier$EnteredPriceTotal = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$SavePriceTotal = {$: 8};
var $author$project$Page$PurchaseOrders$Supplier$SaveStatus = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$viewByMode = function (model) {
	var viewMode = function () {
		if (!model.an) {
			var items = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v3) {
						var id = _v3.a;
						var itemDetail = _v3.b;
						var itemImages = _v3.c;
						if (!itemDetail.$) {
							return _Utils_eq(id, model.v.fB);
						} else {
							return false;
						}
					},
					model.u));
			if (!items.$) {
				var _v1 = items.a;
				var itemDetail = _v1.b;
				var images = _v1.c;
				if (!itemDetail.$) {
					var item = itemDetail.a;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Description')
										])),
									A2($elm$html$Html$hr, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(item.a7)
										])),
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Images')
										])),
									A2($elm$html$Html$hr, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$ul,
									_List_Nil,
									A2(
										$elm$core$List$map,
										function (im) {
											var imageUrl = A2($elm$core$String$contains, 'http', im.dp) ? im.dp : _Utils_ap($author$project$Image$baseUrl, im.dp);
											return A2(
												$elm$html$Html$li,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$img,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$src(imageUrl),
																A2($elm$html$Html$Attributes$style, 'width', '100%')
															]),
														_List_Nil)
													]));
										},
										images))
								]))
						]);
				} else {
					return _List_Nil;
				}
			} else {
				return _List_Nil;
			}
		} else {
			if (model.an === 1) {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-group')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Price Total')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control'),
										$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Supplier$EnteredPriceTotal),
										$elm$html$Html$Attributes$value(
										$author$project$Page$PurchaseOrders$Supplier$priceFieldToString(model.v.gb))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Page$PurchaseOrders$Supplier$priceFieldToString(model.v.gb))
									]))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-block btn-primary'),
								$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$Supplier$SavePriceTotal),
								$elm$html$Html$Attributes$disabled(model.bD)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Save')
							])),
						A2($elm$html$Html$hr, _List_Nil, _List_Nil),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-block btn-warning'),
								$elm$html$Html$Events$onClick(
								$author$project$Page$PurchaseOrders$Supplier$SaveStatus(0)),
								$elm$html$Html$Attributes$disabled(model.bD)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Set cannot Find')
							]))
					]);
			} else {
				if (model.an === 2) {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-block btn-primary'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$PurchaseOrders$Supplier$SaveStatus(4)),
									$elm$html$Html$Attributes$disabled(model.bD)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Set Status Ordered')
								]))
						]);
				} else {
					if (model.an === 3) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('btn btn-block btn-success'),
										$elm$html$Html$Events$onClick(
										$author$project$Page$PurchaseOrders$Supplier$SaveStatus(5)),
										$elm$html$Html$Attributes$disabled(model.bD)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Set Status Received')
									]))
							]);
					} else {
						return _List_Nil;
					}
				}
			}
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		viewMode);
};
var $author$project$Page$PurchaseOrders$Supplier$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PurchaseOrders$Supplier$SelectItem = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$PurchaseOrders$Supplier$viewCart = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Page$PurchaseOrders$Supplier$priceFieldToString(stock.gb))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Models$PurchaseOrder$detailStatusToString(stock.dL))
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$PurchaseOrders$Supplier$SelectItem, stock, 0))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-eye')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$PurchaseOrders$Supplier$SelectItem, stock, 1)),
								$elm$html$Html$Attributes$hidden(stock.dL >= 2)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-edit')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$PurchaseOrders$Supplier$SelectItem, stock, 2)),
								$elm$html$Html$Attributes$hidden(stock.dL !== 3)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-edit')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
								$elm$html$Html$Events$onClick(
								A2($author$project$Page$PurchaseOrders$Supplier$SelectItem, stock, 3)),
								$elm$html$Html$Attributes$hidden(stock.dL !== 4)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('ion-edit')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Supplier$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$PurchaseOrders$Supplier$viewQuantityComment = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$PurchaseOrders$Supplier$viewProblem, model.ax)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm'),
								$elm$html$Html$Attributes$placeholder('Search Item'),
								$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$Supplier$EnteredSearchItem)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped table-scrollable')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Price Total (CNY)')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Status')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$width(100)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Action')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$PurchaseOrders$Supplier$viewCart, model.o))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$Supplier$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									'Supplier PO Update : ' + $author$project$Items$Slug$toString(model.bG))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$PurchaseOrders$Supplier$viewQuantityComment(model),
									$author$project$Page$PurchaseOrders$Supplier$viewByMode(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$PurchaseOrders$View$EnteredCost = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$PurchaseOrders$View$SelectedType = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$PurchaseOrders$View$SubmmitedForm = {$: 8};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Page$PurchaseOrders$View$viewMetaDetailTable = F2(
	function (model, item) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table-responsive')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('ID')
												])),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Items$Slug$toString(model.bG))
												]))
										])),
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Supplier Name')
												])),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(item.gL)
												]))
										])),
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Comments')
												])),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(item.eL)
												]))
										])),
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Creator')
												])),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(item.eW)
												]))
										])),
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Created At')
												])),
											A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													A2($author$project$Timestamp$view, model.ck, item.eV)
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$View$viewTr = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.gb)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gf))
					]))
			]));
};
var $author$project$Page$PurchaseOrders$View$viewMoveDetailTr = function (stockStatus) {
	if (stockStatus.$ === 2) {
		var stocks = stockStatus.a;
		return A2($elm$core$List$map, $author$project$Page$PurchaseOrders$View$viewTr, stocks);
	} else {
		return _List_fromArray(
			[
				A2($elm$html$Html$tr, _List_Nil, _List_Nil)
			]);
	}
};
var $author$project$Page$PurchaseOrders$View$viewMoveTr = F3(
	function (timeZone, details, stock) {
		var _v0 = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (i) {
					return _Utils_eq(i.fB, stock.hb);
				},
				details));
		if (!_v0.$) {
			var item = _v0.a;
			var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
			return A2(
				$elm$html$Html$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$Timestamp$view, timeZone, stock.cH)
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(stock.fG))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src(imageUrl),
										A2($elm$html$Html$Attributes$style, 'margin', '0'),
										A2($elm$html$Html$Attributes$style, 'width', '100px')
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(item.fC)
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(stock.gd))
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(stock.eW)
							])),
						A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(stock.fF)
							]))
					]));
		} else {
			return A2($elm$html$Html$tr, _List_Nil, _List_Nil);
		}
	});
var $author$project$Page$PurchaseOrders$View$viewMovementTr = F3(
	function (timeZone, details, stockStatus) {
		var _v0 = _Utils_Tuple2(stockStatus, details);
		if ((_v0.a.$ === 2) && (_v0.b.$ === 2)) {
			var stocks = _v0.a.a;
			var poDetails = _v0.b.a;
			return A2(
				$elm$core$List$map,
				A2($author$project$Page$PurchaseOrders$View$viewMoveTr, timeZone, poDetails),
				$author$project$PaginatedList$values(stocks));
		} else {
			return _List_fromArray(
				[
					A2($elm$html$Html$tr, _List_Nil, _List_Nil)
				]);
		}
	});
var $author$project$Page$PurchaseOrders$View$DeleteCost = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$PurchaseOrders$View$viewOtherCosts = F2(
	function (status, session) {
		var username = $author$project$Session$toStringUsername(session);
		if (status.$ === 2) {
			var costs = status.a;
			return A2(
				$elm$core$List$map,
				function (c) {
					return A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(c.eT)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(c.eS)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(c.eW)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('btn btn-danger'),
												$elm$html$Html$Attributes$hidden(
												!_Utils_eq(c.eW, username)),
												$elm$html$Html$Events$onClick(
												$author$project$Page$PurchaseOrders$View$DeleteCost(c.cY))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('ion-trash-a')
													]),
												_List_Nil)
											]))
									]))
							]));
				},
				costs);
		} else {
			return _List_Nil;
		}
	});
var $author$project$Page$PurchaseOrders$View$viewProblem = function (problem) {
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(problem)
			]));
};
var $author$project$Page$PurchaseOrders$View$viewProblems = function (problems) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-messages')
			]),
		A2($elm$core$List$map, $author$project$Page$PurchaseOrders$View$viewProblem, problems));
};
var $author$project$Page$PurchaseOrders$View$viewItem = F3(
	function (item, cred, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'PO ID: ' + $elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-4')
								]),
							_List_fromArray(
								[
									A2($author$project$Page$PurchaseOrders$View$viewMetaDetailTable, model, item),
									A2(
									$elm$html$Html$h3,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('PO Details')
										])),
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item ID')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Image')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item Name')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Price')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Quantity')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Received')
																]))
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											$author$project$Page$PurchaseOrders$View$viewMoveDetailTr(model.Z))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Received Log')
										])),
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('CreatedAt')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('LD_ID')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Image')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item Name')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Received')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Receiver')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Store Location')
																]))
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											A3($author$project$Page$PurchaseOrders$View$viewMovementTr, model.ck, model.Z, model.br))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('card')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('card-body')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h5,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('card-title')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Ohter Cost')
														])),
													$author$project$Page$PurchaseOrders$View$viewProblems(model.bj),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('form-group')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Cost')
																])),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('form-control'),
																	$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$View$EnteredCost)
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('form-group')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Cost Type')
																])),
															A2(
															$elm$html$Html$select,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('form-control'),
																	$elm$html$Html$Events$onInput($author$project$Page$PurchaseOrders$View$SelectedType)
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$option,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$value('Tax')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Tax')
																		])),
																	A2(
																	$elm$html$Html$option,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$value('Shipping')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Shipping')
																		]))
																]))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary'),
															$elm$html$Html$Events$onClick($author$project$Page$PurchaseOrders$View$SubmmitedForm)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Save')
														]))
												])),
											A2(
											$elm$html$Html$table,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('table table-bordered table-condensed table-sm')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$thead,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$tr,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Type')
																		])),
																	A2(
																	$elm$html$Html$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Cost')
																		])),
																	A2(
																	$elm$html$Html$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Creator')
																		])),
																	A2(
																	$elm$html$Html$th,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$width(100)
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Actions')
																		]))
																]))
														])),
													A2(
													$elm$html$Html$tbody,
													_List_Nil,
													A2($author$project$Page$PurchaseOrders$View$viewOtherCosts, model.w, model.aA))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$PurchaseOrders$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$PurchaseOrders$View$viewItem, item, cred, model);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + $elm$core$String$fromInt(item.cY)
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$Suppliers$Form$saveArticleButton = F2(
	function (caption, extraAttrs) {
		return A2(
			$elm$html$Html$button,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('btn btn-lg pull-xs-right btn-primary'),
				extraAttrs),
			_List_fromArray(
				[
					$elm$html$Html$text(caption)
				]));
	});
var $author$project$Page$Suppliers$Form$editArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Suppliers$Form$saveArticleButton, 'Update Supplier', extraAttrs);
};
var $author$project$Page$Suppliers$Form$newArticleSaveButton = function (extraAttrs) {
	return A2($author$project$Page$Suppliers$Form$saveArticleButton, 'Save Supplier', extraAttrs);
};
var $author$project$Page$Suppliers$Form$ClickedSave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Suppliers$Form$EnteredCurrency = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Suppliers$Form$EnteredEmail = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Suppliers$Form$EnteredTitle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Suppliers$Form$viewForm = F3(
	function (cred, fields, saveButton) {
		return A2(
			$elm$html$Html$form,
			_List_fromArray(
				[
					$elm$html$Html$Events$onSubmit(
					$author$project$Page$Suppliers$Form$ClickedSave(cred))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$fieldset,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control form-control-lg'),
											$elm$html$Html$Attributes$placeholder('Name'),
											$elm$html$Html$Events$onInput($author$project$Page$Suppliers$Form$EnteredTitle),
											$elm$html$Html$Attributes$value(fields.fU)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control form-control-lg'),
											$elm$html$Html$Attributes$placeholder('Currency'),
											$elm$html$Html$Events$onInput($author$project$Page$Suppliers$Form$EnteredCurrency),
											$elm$html$Html$Attributes$value(fields.cJ)
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$fieldset,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-group')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-control form-control-lg'),
											$elm$html$Html$Attributes$placeholder('Email'),
											$elm$html$Html$Events$onInput($author$project$Page$Suppliers$Form$EnteredEmail),
											$elm$html$Html$Attributes$value(fields.O)
										]),
									_List_Nil)
								])),
							saveButton
						]))
				]));
	});
var $author$project$Page$Suppliers$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var message = problem.b;
			return message;
		} else {
			var message = problem.a;
			return message;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Suppliers$Form$viewProblems = function (problems) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('error-messages')
			]),
		A2($elm$core$List$map, $author$project$Page$Suppliers$Form$viewProblem, problems));
};
var $author$project$Page$Suppliers$Form$viewAuthenticated = F2(
	function (cred, model) {
		var formHtml = function () {
			var _v0 = model.dL;
			switch (_v0.$) {
				case 0:
					return _List_Nil;
				case 1:
					return _List_fromArray(
						[$author$project$Loading$icon]);
				case 3:
					var slug = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							A3(
							$author$project$Page$Suppliers$Form$viewForm,
							cred,
							form,
							$author$project$Page$Suppliers$Form$editArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 6:
					var form = _v0.a;
					return _List_fromArray(
						[
							A3(
							$author$project$Page$Suppliers$Form$viewForm,
							cred,
							form,
							$author$project$Page$Suppliers$Form$newArticleSaveButton(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$disabled(true)
									])))
						]);
				case 4:
					var slug = _v0.a;
					var problems = _v0.b;
					var form = _v0.c;
					return _List_fromArray(
						[
							$author$project$Page$Suppliers$Form$viewProblems(problems),
							A3(
							$author$project$Page$Suppliers$Form$viewForm,
							cred,
							form,
							$author$project$Page$Suppliers$Form$editArticleSaveButton(_List_Nil))
						]);
				case 5:
					var problems = _v0.a;
					var form = _v0.b;
					return _List_fromArray(
						[
							$author$project$Page$Suppliers$Form$viewProblems(problems),
							A3(
							$author$project$Page$Suppliers$Form$viewForm,
							cred,
							form,
							$author$project$Page$Suppliers$Form$newArticleSaveButton(_List_Nil))
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$text('Article failed to load.')
						]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('editor-page')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container page')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-10 offset-md-1 col-xs-12')
										]),
									formHtml)
								]))
						]))
				]));
	});
var $author$project$Page$Suppliers$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2($author$project$Page$Suppliers$Form$viewAuthenticated, cred, model);
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Suppliers$List$AcknowledgeDelete = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Suppliers$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Suppliers$List$DeleteItem = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$Suppliers$List$viewItem = F2(
	function (cred, item) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.O)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.cJ)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm mr-10'),
									$author$project$Route$href(
									$author$project$Route$EditSupplier(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-edit')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-danger btn-sm'),
									$elm$html$Html$Attributes$hidden(!item.e2),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$Suppliers$List$DeleteItem, cred, item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-trash-a')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Suppliers$List$viewItems = F2(
	function (cred, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Email')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Currency')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$Page$Suppliers$List$viewItem(cred),
						items))
				]));
	});
var $author$project$Page$Suppliers$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Suppliers$List$SubmittedForm = {$: 4};
var $author$project$Page$Suppliers$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Suppliers$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Suppliers$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Suppliers$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Suppliers$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Suppliers$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Supplier List Page'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$NewSupplier)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Suppliers$List$viewSearch(model),
									A2(
									$author$project$Page$Suppliers$List$viewItems,
									cred,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Suppliers$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items)),
									A3(
									$author$project$Components$DeleteButton$viewDialog,
									model.aB,
									$elm$core$String$fromInt(model.Y),
									$author$project$Page$Suppliers$List$AcknowledgeDelete)
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Suppliers$View$DeleteItem = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$Suppliers$View$viewDescriptionTable = F2(
	function (model, item) {
		var totalItems = function () {
			var _v0 = model.aD;
			if (_v0.$ === 2) {
				var stocks = _v0.a;
				return $elm$core$List$length(
					$author$project$PaginatedList$values(stocks));
			} else {
				return 0;
			}
		}();
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('ID')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(item.cY))
										]))
								])),
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(item.fU)
										]))
								])),
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Total LD In Location')
										])),
									A2(
									$elm$html$Html$td,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm$core$String$fromInt(totalItems))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Suppliers$View$viewTrLd = function (stock) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.dx))
					]))
			]));
};
var $author$project$Page$Suppliers$View$viewLdInLocation = function (stocks) {
	if (stocks.$ === 2) {
		var items = stocks.a;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Suppliers$View$viewTrLd,
			$author$project$PaginatedList$values(items));
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Suppliers$View$viewItem = F3(
	function (model, item, cred) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('pl-20')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(item.fU)
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-3')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$a,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-primary'),
															$author$project$Route$href(
															$author$project$Route$EditLocation(
																$author$project$Items$Slug$idToSlug(item.cY)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Update')
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('btn btn-danger'),
															$elm$html$Html$Events$onClick(
															A2(
																$author$project$Page$Suppliers$View$DeleteItem,
																cred,
																$author$project$Items$Slug$idToSlug(item.cY))),
															$elm$html$Html$Attributes$hidden(!item.e2)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Delete')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('col-md-12')
												]),
											_List_fromArray(
												[
													A2($author$project$Page$Suppliers$View$viewDescriptionTable, model, item)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-9')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('LD In locations')
										])),
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('LD ID')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item Name')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Remaining')
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											$author$project$Page$Suppliers$View$viewLdInLocation(model.aD))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Suppliers$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Suppliers$View$viewItem, model, item, cred);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + ($elm$core$String$fromInt(item.cY) + (' - ' + item.fU))
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + model.ch
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $elm$html$Html$Attributes$colspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$rowspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rowspan',
		$elm$core$String$fromInt(n));
};
var $cuducos$elm_format_number$FormatNumber$Humanize$RemoveZeros = 1;
var $author$project$Page$Templates$Cal$SelectRowSupplier = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $cuducos$elm_format_number$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {e1: decimals, c1: integers, dl: original, bz: prefix, bH: suffix};
	});
var $cuducos$elm_format_number$Parser$Negative = 2;
var $cuducos$elm_format_number$Parser$Positive = 0;
var $cuducos$elm_format_number$Parser$Zero = 1;
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $cuducos$elm_format_number$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.c1,
				$elm$core$List$singleton(formatted.e1))));
	return onlyZeros ? 1 : ((formatted.dl < 0) ? 2 : 0);
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$String$filter = _String_filter;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $cuducos$elm_format_number$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$Parser$parse = F2(
	function (locale, original) {
		var parts = A2(
			$elm$core$String$split,
			'.',
			A2($myrho$elm_round$Round$round, locale.e1, original));
		var integers = $cuducos$elm_format_number$Parser$splitThousands(
			A2(
				$elm$core$String$filter,
				$elm$core$Char$isDigit,
				A2(
					$elm$core$Maybe$withDefault,
					'0',
					$elm$core$List$head(parts))));
		var decimals = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2($elm$core$List$drop, 1, parts)));
		var partial = A5($cuducos$elm_format_number$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{bz: locale.fV, bH: locale.fW});
			case 0:
				return _Utils_update(
					partial,
					{bz: locale.f8, bH: locale.f9});
			default:
				return _Utils_update(
					partial,
					{bz: locale.hj, bH: locale.hk});
		}
	});
var $cuducos$elm_format_number$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.e0, decimals);
	});
var $cuducos$elm_format_number$Stringfy$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$Stringfy$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$Stringfy$humanizeDecimals = F3(
	function (locale, strategy, decimals) {
		if ((decimals === '') || _Utils_eq(
			A2($elm$core$String$repeat, locale.e1, '0'),
			decimals)) {
			return '';
		} else {
			if (!strategy) {
				return _Utils_ap(locale.e0, decimals);
			} else {
				return A2(
					$cuducos$elm_format_number$Stringfy$formatDecimals,
					locale,
					$cuducos$elm_format_number$Stringfy$removeZeros(decimals));
			}
		}
	});
var $cuducos$elm_format_number$Stringfy$stringfy = F3(
	function (locale, strategy, formatted) {
		var stringfyDecimals = function () {
			if (!strategy.$) {
				var strategy_ = strategy.a;
				return A2($cuducos$elm_format_number$Stringfy$humanizeDecimals, locale, strategy_);
			} else {
				return $cuducos$elm_format_number$Stringfy$formatDecimals(locale);
			}
		}();
		var integers = A2($elm$core$String$join, locale.g0, formatted.c1);
		var decimals = stringfyDecimals(formatted.e1);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.bz, integers, decimals, formatted.bH]));
	});
var $cuducos$elm_format_number$FormatNumber$humanize = F3(
	function (locale, strategy, number_) {
		return A3(
			$cuducos$elm_format_number$Stringfy$stringfy,
			locale,
			$elm$core$Maybe$Just(strategy),
			A2($cuducos$elm_format_number$Parser$parse, locale, number_));
	});
var $author$project$Page$Templates$Cal$EnteredQuantityFinal = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Templates$Cal$inputOptionsFinalQuantity = function (item) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$Templates$Cal$EnteredQuantityFinal(item));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $cuducos$elm_format_number$FormatNumber$Locales$Locale = F9(
	function (decimals, thousandSeparator, decimalSeparator, negativePrefix, negativeSuffix, positivePrefix, positiveSuffix, zeroPrefix, zeroSuffix) {
		return {e0: decimalSeparator, e1: decimals, fV: negativePrefix, fW: negativeSuffix, f8: positivePrefix, f9: positiveSuffix, g0: thousandSeparator, hj: zeroPrefix, hk: zeroSuffix};
	});
var $cuducos$elm_format_number$FormatNumber$Locales$usLocale = A9($cuducos$elm_format_number$FormatNumber$Locales$Locale, 2, ',', '.', '−', '', '', '', '', '');
var $author$project$Page$Templates$Cal$viewSupplierOption = F2(
	function (selectedInt, category) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(category.cY)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(category.cY, selectedInt))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(category.fU)
				]));
	});
var $author$project$Page$Templates$Cal$viewSupplierOptions = F2(
	function (categories, selectedSupplierID) {
		return A2(
			$elm$core$List$map,
			$author$project$Page$Templates$Cal$viewSupplierOption(selectedSupplierID),
			categories);
	});
var $author$project$Page$Templates$Cal$viewIngredientTr = F4(
	function (suppliers, cart, model, ingredient) {
		var wipQuantity = function () {
			var _v9 = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (i) {
						return _Utils_eq(i.cY, ingredient.cY);
					},
					model.a6));
			if (!_v9.$) {
				var w = _v9.a;
				return w.gd;
			} else {
				return 0;
			}
		}();
		var wipTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(wipQuantity))
				]));
		var tmpQuantityList = A2(
			$elm$core$List$map,
			function (_v7) {
				var w = _v7.a;
				var wl = _v7.b;
				var _v8 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (t) {
							return _Utils_eq(t.cY, ingredient.cY);
						},
						wl));
				if (_v8.$ === 1) {
					return _Utils_Tuple3(w.gd, 0, 0);
				} else {
					var st = _v8.a;
					return _Utils_Tuple3(w.gd, st.gd, st.dx);
				}
			},
			cart);
		var totalDemand = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function (_v6) {
					var d = _v6.a;
					var q = _v6.b;
					return d * q;
				},
				tmpQuantityList));
		var totalTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A3($cuducos$elm_format_number$FormatNumber$humanize, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, 1, totalDemand))
				]));
		var qoh = function () {
			var _v3 = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v4) {
						var d = _v4.a;
						var q = _v4.b;
						var r = _v4.c;
						return r > 0;
					},
					tmpQuantityList));
			if (!_v3.$) {
				var _v5 = _v3.a;
				var r = _v5.c;
				return r;
			} else {
				return 0;
			}
		}();
		var qohTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A3($cuducos$elm_format_number$FormatNumber$humanize, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, 1, qoh))
				]));
		var poQuantity = function () {
			var _v2 = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (i) {
						return _Utils_eq(i.cY, ingredient.cY);
					},
					model.aX));
			if (!_v2.$) {
				var w = _v2.a;
				return w.gd;
			} else {
				return 0;
			}
		}();
		var orderedTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(poQuantity))
				]));
		var orderAmountTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right')
				]),
			_List_fromArray(
				[
					A3(
					$abadi199$elm_input_extra$Input$Number$input,
					$author$project$Page$Templates$Cal$inputOptionsFinalQuantity(ingredient),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-control form-control-sm')
						]),
					$elm$core$Maybe$Just(ingredient.gd))
				]));
		var options = A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Select Supplier')
					])),
			A2($author$project$Page$Templates$Cal$viewSupplierOptions, suppliers, ingredient.dx));
		var supplierTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-control'),
							$elm$html$Html$Events$onInput(
							$author$project$Page$Templates$Cal$SelectRowSupplier(ingredient))
						]),
					options)
				]));
		var intQuantity = function () {
			var _v1 = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (i) {
						return _Utils_eq(i.cY, ingredient.cY);
					},
					model.aN));
			if (!_v1.$) {
				var w = _v1.a;
				return w.gd;
			} else {
				return 0;
			}
		}();
		var intTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(intQuantity))
				]));
		var totalSupplier = ((qoh + wipQuantity) + poQuantity) + intQuantity;
		var shortageSurplusTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('text-right', true),
							_Utils_Tuple2('table-danger', (totalSupplier - totalDemand) < 0)
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A3($cuducos$elm_format_number$FormatNumber$humanize, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, 1, totalSupplier - totalDemand))
				]));
		var totalSupplyTd = A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-right')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(totalSupplier))
				]));
		var imageUrl = A2($elm$core$String$contains, 'http', ingredient.fD) ? ingredient.fD : _Utils_ap($author$project$Image$baseUrl, ingredient.fD);
		var cartTd = A2(
			$elm$core$List$map,
			function (_v0) {
				var d = _v0.a;
				var q = _v0.b;
				return A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-right')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A3($cuducos$elm_format_number$FormatNumber$humanize, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, 1, d * q))
						]));
			},
			tmpQuantityList);
		var beforeTd = _List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(ingredient.cY))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-left')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(ingredient.fC)
					]))
			]);
		var trInfo = A2(
			$elm$core$List$append,
			A2($elm$core$List$append, beforeTd, cartTd),
			_List_fromArray(
				[totalTd, qohTd, wipTd, intTd, orderedTd, totalSupplyTd, shortageSurplusTd, supplierTd, orderAmountTd]));
		return A2($elm$html$Html$tr, _List_Nil, trInfo);
	});
var $author$project$Page$Templates$Cal$viewIngredientBody = function (model) {
	var suppliers = function () {
		var _v0 = model.aF;
		if (!_v0.$) {
			var suppliersx = _v0.a;
			return $author$project$PaginatedList$values(suppliersx);
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$core$List$map,
		A3($author$project$Page$Templates$Cal$viewIngredientTr, suppliers, model.i, model),
		model.m);
};
var $author$project$Page$Templates$Cal$viewProductHeader = function (template) {
	return A2(
		$elm$html$Html$th,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-center')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(template.fC)
			]));
};
var $author$project$Page$Templates$Cal$viewIngredientHeader = function (ingredients) {
	return A2(
		$elm$core$List$append,
		A2(
			$elm$core$List$map,
			function (_v0) {
				var t = _v0.a;
				var i = _v0.b;
				return $author$project$Page$Templates$Cal$viewProductHeader(t);
			},
			ingredients),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Total')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('QoH')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Wip')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Int')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Po')
					])),
				A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Total')
					]))
			]));
};
var $author$project$Page$Templates$Cal$viewIngredients = function (model) {
	var numCol = $elm$core$List$length(model.i) + 1;
	var ingredients = _List_Nil;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-12')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Ingredients')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-striped table-sm')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$rowspan(2),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Item ID')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$rowspan(2),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Image')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$rowspan(2),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Item Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(numCol),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Demand')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$colspan(5),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Supply')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$rowspan(2),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Surplus/Shortage')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$rowspan(2),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Supplier')
											])),
										A2(
										$elm$html$Html$th,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$rowspan(2),
												$elm$html$Html$Attributes$class('text-center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('ToOrder')
											]))
									])),
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								$author$project$Page$Templates$Cal$viewIngredientHeader(model.i))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						$author$project$Page$Templates$Cal$viewIngredientBody(model))
					]))
			]));
};
var $author$project$Page$Templates$Cal$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Templates$Cal$SubmittedFormItem = {$: 4};
var $author$project$Page$Templates$Cal$SelectItem = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Templates$Cal$viewItem = F2(
	function (model, item) {
		var filteredCart = A2(
			$elm$core$List$filter,
			function (_v0) {
				var w = _v0.a;
				return _Utils_eq(w.cY, item.a1);
			},
			model.i);
		var checkedOutput = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.dQ)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checkedOutput),
											_Utils_Tuple2('btn-light', !checkedOutput)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Templates$Cal$SelectItem(item))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-plus')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Templates$Cal$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Templates$Cal$viewItem(model),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Templates$Cal$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$Templates$Cal$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Template')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm'),
										$elm$html$Html$Attributes$placeholder('Search Template'),
										$elm$html$Html$Events$onInput($author$project$Page$Templates$Cal$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Template ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Select')
													]))
											]))
									])),
								$author$project$Page$Templates$Cal$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Templates$Cal$EnteredComment = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Templates$Cal$SelectMainSupplier = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Templates$Cal$SubmittedForm = {$: 10};
var $author$project$Page$Templates$Cal$EnteredQuantity = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Templates$Cal$inputOptions = function (item) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$Templates$Cal$EnteredQuantity(item));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$Templates$Cal$viewCart = function (item) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(item.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$Templates$Cal$inputOptions(item),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(item.gd))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(item.dx))
					]))
			]));
};
var $author$project$Page$Templates$Cal$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Templates$Cal$viewQuantityComment = function (model) {
	var options = function () {
		var _v2 = model.aF;
		if (!_v2.$) {
			var suppliers = _v2.a;
			return A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Select Supplier')
						])),
				A2(
					$author$project$Page$Templates$Cal$viewSupplierOptions,
					$author$project$PaginatedList$values(suppliers),
					model.v.n.cY));
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Select Supplier')
						]))
				]);
		}
	}();
	var disabledButton = function () {
		var _v1 = A2($author$project$Page$Templates$Cal$validate, model, model.v);
		if (!_v1.$) {
			return false;
		} else {
			return true;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Templates$Cal$viewProblem, model.ax)),
				A2(
				$elm$html$Html$h5,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Chosen Template')
					])),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Remaining')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (_v0) {
								var w = _v0.a;
								var wl = _v0.b;
								return $author$project$Page$Templates$Cal$viewCart(w);
							},
							model.i))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Supplier')
							])),
						A2(
						$elm$html$Html$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Events$onInput($author$project$Page$Templates$Cal$SelectMainSupplier)
							]),
						options)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Templates$Cal$EnteredComment)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Templates$Cal$SubmittedForm),
						$elm$html$Html$Attributes$disabled(disabledButton)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]))
			]));
};
var $author$project$Page$Templates$Cal$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Cal Page')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Templates$Cal$viewItemPicker(model),
									$author$project$Page$Templates$Cal$viewQuantityComment(model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Templates$Cal$viewIngredients(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Templates$Form$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Templates$Form$SubmittedFormItem = {$: 3};
var $author$project$Page$Templates$Form$SelectItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Templates$Form$SelectOutput = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Templates$Form$viewItem = F3(
	function (carts, targetItem, item) {
		var filteredCart = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fB, item.cY);
			},
			carts);
		var checkedOutput = _Utils_eq(targetItem.cY, item.cY);
		var checked = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.g5)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Attributes$hidden(checkedOutput),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Templates$Form$SelectItem(item))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-plus')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-success', checkedOutput),
											_Utils_Tuple2('btn-light', !checkedOutput)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Templates$Form$SelectOutput(item))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-android-desktop')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Templates$Form$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A2($author$project$Page$Templates$Form$viewItem, model.o, model.v.l),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Templates$Form$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$Templates$Form$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Item')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm'),
										$elm$html$Html$Attributes$placeholder('Search Item'),
										$elm$html$Html$Events$onInput($author$project$Page$Templates$Form$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Item ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Remain')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('+Cart / Output')
													]))
											]))
									])),
								$author$project$Page$Templates$Form$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Templates$Form$EnteredComment = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Templates$Form$SubmittedForm = {$: 9};
var $author$project$Page$Templates$Form$EnteredQuantity = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Templates$Form$inputOptions = function (stock) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$Templates$Form$EnteredQuantity(stock));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$Templates$Form$viewCart = function (stock) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$Templates$Form$inputOptions(stock),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(stock.gd))
					]))
			]));
};
var $author$project$Page$Templates$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Templates$Form$viewQuantityComment = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Templates$Form$viewProblem, model.ax)),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('ID')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$Templates$Form$viewCart, model.o))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Target Output Item')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$disabled(true),
								$elm$html$Html$Attributes$class('form-control forn-control-sm'),
								$elm$html$Html$Attributes$value(
								'ID: ' + ($elm$core$String$fromInt(model.v.l.cY) + (' Title: ' + model.v.l.g5)))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Templates$Form$EnteredComment)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Templates$Form$SubmittedForm)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]))
			]));
};
var $author$project$Page$Templates$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = _Utils_Tuple2(
				$author$project$Session$cred(model.aA),
				model.K);
			if (!_v0.a.$) {
				switch (_v0.b.$) {
					case 2:
						var cred = _v0.a.a;
						var slug = _v0.b.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'Template Edit: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$Templates$Form$viewItemPicker(model),
											$author$project$Page$Templates$Form$viewQuantityComment(model)
										]))
								]));
					case 4:
						var cred = _v0.a.a;
						var _v1 = _v0.b;
						var slug = _v1.a;
						var problems = _v1.b;
						var form = _v1.c;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'Template Edit: ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$Templates$Form$viewItemPicker(model),
											$author$project$Page$Templates$Form$viewQuantityComment(model)
										]))
								]));
					case 3:
						var cred = _v0.a.a;
						var slug = _v0.b.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											'Create Template Fork From : ' + $author$project$Items$Slug$toString(slug))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$Templates$Form$viewItemPicker(model),
											$author$project$Page$Templates$Form$viewQuantityComment(model)
										]))
								]));
					default:
						var cred = _v0.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Template Page')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('row')
										]),
									_List_fromArray(
										[
											$author$project$Page$Templates$Form$viewItemPicker(model),
											$author$project$Page$Templates$Form$viewQuantityComment(model)
										]))
								]));
				}
			} else {
				var _v2 = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Templates$List$AcknowledgeDelete = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Templates$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Templates$List$DeleteItem = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$Templates$List$viewItem = F3(
	function (cred, timeZone, item) {
		var username = $author$project$Username$toString(
			$author$project$Api$username(cred));
		var imageUrl = A2($elm$core$String$contains, 'http', item.cj) ? item.cj : _Utils_ap($author$project$Image$baseUrl, item.cj);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'width', '100px')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.dQ)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$TemplateView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
									$author$project$Route$href(
									$author$project$Route$TemplateUpdate(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-edit')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-warning btn-sm'),
									$author$project$Route$href(
									$author$project$Route$TemplateFork(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-fork-repo')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-danger btn-sm'),
									$elm$html$Html$Attributes$hidden(
									(!item.e2) || (!_Utils_eq(item.eW, username))),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$Templates$List$DeleteItem, cred, item.cY))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-trash-a')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Templates$List$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Template ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Image')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Item Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Templates$List$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$Templates$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Templates$List$SubmittedForm = {$: 4};
var $author$project$Page$Templates$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container-fluid')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Templates$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Templates$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Templates$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Templates$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Templates$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Template'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
													$author$project$Route$href($author$project$Route$TemplateNew),
													A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Templates$List$viewSearch(model),
									A3(
									$author$project$Page$Templates$List$viewItems,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Templates$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items)),
									A3(
									$author$project$Components$DeleteButton$viewDialog,
									model.aB,
									$elm$core$String$fromInt(model.Y),
									$author$project$Page$Templates$List$AcknowledgeDelete)
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Templates$View$viewMetaDetailTable = F2(
	function (model, item) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-md-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table-responsive')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('ID')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Items$Slug$toString(model.bG))
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Target Item Name')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.dQ)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Comments')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eL)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Creator')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eW)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Created At')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															A2($author$project$Timestamp$view, model.ck, item.eV)
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Templates$View$viewTr = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100px')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gd))
					]))
			]));
};
var $author$project$Page$Templates$View$viewMoveDetailTr = function (stockStatus) {
	if (stockStatus.$ === 2) {
		var stocks = stockStatus.a;
		return A2($elm$core$List$map, $author$project$Page$Templates$View$viewTr, stocks);
	} else {
		return _List_fromArray(
			[
				A2($elm$html$Html$tr, _List_Nil, _List_Nil)
			]);
	}
};
var $author$project$Page$Templates$View$viewItem = F3(
	function (item, cred, model) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.gO) ? item.gO : _Utils_ap($author$project$Image$baseUrl, item.gO);
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'TemplateID: ' + $elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Templates$View$viewMetaDetailTable, model, item),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(imageUrl),
											$elm$html$Html$Attributes$width(500),
											$elm$html$Html$Attributes$height(500)
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-12')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Ingredients')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table-responsive')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$table,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$thead,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$tr,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Item ID')
																		])),
																	A2(
																	$elm$html$Html$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Image')
																		])),
																	A2(
																	$elm$html$Html$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Item Name')
																		])),
																	A2(
																	$elm$html$Html$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Quantity')
																		]))
																]))
														])),
													A2(
													$elm$html$Html$tbody,
													_List_Nil,
													$author$project$Page$Templates$View$viewMoveDetailTr(model.br))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Templates$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Templates$View$viewItem, item, cred, model);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + $elm$core$String$fromInt(item.cY)
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$Wips$Form$viewIngredient = F2(
	function (model, ingredient) {
		var imageUrl = A2($elm$core$String$contains, 'http', ingredient.fD) ? ingredient.fD : _Utils_ap($author$project$Image$baseUrl, ingredient.fD);
		var afterQuantity = ingredient.dx - (ingredient.gd * model.v.gd);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-right')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(ingredient.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'width', '100px')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-left')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(ingredient.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-right')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(ingredient.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-right')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(ingredient.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-right')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(ingredient.gd * model.v.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('table-danger', afterQuantity < 0),
									_Utils_Tuple2('text-right', true)
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(afterQuantity))
						]))
				]));
	});
var $author$project$Page$Wips$Form$viewIngredients = function (model) {
	var _v0 = _Utils_Tuple2(model.v.l.cY, model.i);
	_v0$0:
	while (true) {
		if (_v0.b.$ === 1) {
			if (!_v0.a) {
				break _v0$0;
			} else {
				var _v1 = _v0.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-md-12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Ingredients: ' + model.v.l.g5)
								])),
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-bordered table-striped table-sm')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item ID')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Image')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item Name')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Quantity Per 1')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Remaing')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Demand Quantity')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('After Quantity')
														]))
												]))
										]))
								]))
						]));
			}
		} else {
			if (!_v0.a) {
				break _v0$0;
			} else {
				var ingredients = _v0.b.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-md-12')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Ingredients: ' + model.v.l.g5)
								])),
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-bordered table-striped table-sm')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$thead,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item ID')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Image')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Item Name')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Quantity Per 1')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Remaing')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Demand Quantity')
														])),
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('After Quantity')
														]))
												]))
										])),
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									A2(
										$elm$core$List$map,
										$author$project$Page$Wips$Form$viewIngredient(model),
										ingredients))
								]))
						]));
			}
		}
	}
	return A2($elm$html$Html$div, _List_Nil, _List_Nil);
};
var $author$project$Page$Wips$Form$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Wips$Form$SubmittedFormItem = {$: 3};
var $author$project$Page$Wips$Form$SelectOutput = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Wips$Form$viewItem = F2(
	function (targetItem, item) {
		var checkedOutput = _Utils_eq(targetItem.cY, item.cY);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.dQ)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-success', checkedOutput),
											_Utils_Tuple2('btn-light', !checkedOutput)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Wips$Form$SelectOutput(item))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-android-desktop')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Wips$Form$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Wips$Form$viewItem(model.v.l),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Wips$Form$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$Wips$Form$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Template')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control form-control-sm'),
										$elm$html$Html$Attributes$placeholder('Search Template'),
										$elm$html$Html$Events$onInput($author$project$Page$Wips$Form$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Template ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Select')
													]))
											]))
									])),
								$author$project$Page$Wips$Form$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Wips$Form$EnteredComment = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Wips$Form$SelectLocation = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Wips$Form$SubmittedForm = {$: 9};
var $author$project$Page$Wips$Form$EnteredQuantity = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Wips$Form$inputOptions = function () {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions($author$project$Page$Wips$Form$EnteredQuantity);
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Nothing,
			fP: $elm$core$Maybe$Just(0)
		});
}();
var $author$project$Page$Wips$Form$viewLocationOption = function (category) {
	return A2(
		$elm$html$Html$option,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromInt(category.cY))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(category.fU)
			]));
};
var $author$project$Page$Wips$Form$viewLocationOptions = function (categories) {
	return A2($elm$core$List$map, $author$project$Page$Wips$Form$viewLocationOption, categories);
};
var $author$project$Page$Wips$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Wips$Form$viewQuantityComment = function (model) {
	var savingWord = function () {
		var _v2 = model.bD;
		if (_v2) {
			return 'Saving';
		} else {
			return 'Save';
		}
	}();
	var options = function () {
		var _v1 = model.bo;
		if (!_v1.$) {
			var locations = _v1.a;
			return A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Select Location')
						])),
				$author$project$Page$Wips$Form$viewLocationOptions(
					$author$project$PaginatedList$values(locations)));
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Select Location')
						]))
				]);
		}
	}();
	var disabledButton = function () {
		var _v0 = A2($author$project$Page$Wips$Form$validate, model, model.v);
		if (!_v0.$) {
			return false;
		} else {
			return model.bD;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Wips$Form$viewProblem, model.ax)),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Quantity')
							])),
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$Wips$Form$inputOptions,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control form-control-sm')
							]),
						$elm$core$Maybe$Just(model.v.gd))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Working Location')
							])),
						A2(
						$elm$html$Html$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Events$onInput($author$project$Page$Wips$Form$SelectLocation)
							]),
						options)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Wips$Form$EnteredComment)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Wips$Form$SubmittedForm),
						$elm$html$Html$Attributes$disabled(disabledButton)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(savingWord)
					]))
			]));
};
var $author$project$Page$Wips$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('WIP Page')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Wips$Form$viewItemPicker(model),
									$author$project$Page$Wips$Form$viewQuantityComment(model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row'),
									$elm$html$Html$Attributes$hidden(!model.v.l.cY)
								]),
							_List_fromArray(
								[
									$author$project$Page$Wips$Form$viewIngredients(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Wips$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Wips$List$viewItem = F3(
	function (cred, timeZone, item) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.cj) ? item.cj : _Utils_ap($author$project$Image$baseUrl, item.cj);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'width', '100px')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.dQ)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gd))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.dL)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$WipView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Wips$List$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Image')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Item Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Quantity')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Status')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Wips$List$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$Wips$List$EnteredSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Wips$List$SubmittedForm = {$: 4};
var $author$project$Page$Wips$List$viewForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-md-12')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Page$Wips$List$SubmittedForm)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('input-group mb-3')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-control'),
														$elm$html$Html$Attributes$placeholder('Search'),
														$elm$html$Html$Events$onInput($author$project$Page$Wips$List$EnteredSearch)
													]),
												_List_Nil),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('input-group-append')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('btn btn-outline-secondary'),
																$elm$html$Html$Attributes$type_('button')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('ion-search')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(model.ai)
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$Wips$List$viewSearch = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row mb-20')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('col-md-12')
					]),
				_List_fromArray(
					[
						$author$project$Page$Wips$List$viewForm(model)
					]))
			]));
};
var $author$project$Page$Wips$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Wip'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
													$author$project$Route$href($author$project$Route$WipNew),
													A2($elm$html$Html$Attributes$style, 'margin-left', '15px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									$author$project$Page$Wips$List$viewSearch(model),
									A3(
									$author$project$Page$Wips$List$viewItems,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Wips$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Wips$View$ClickedProceed = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Page$Wips$View$DeleteItem = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Wips$View$viewMetaDetailTable = F3(
	function (model, cred, item) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-md-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table-responsive')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('ID')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Items$Slug$toString(model.bG))
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Target Item Name')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.dQ)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Quantity')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$elm$core$String$fromInt(item.gd))
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Status')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.dL)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Comments')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eL)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Creator')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eW)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Created At')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															A2($author$project$Timestamp$view, model.ck, item.eV)
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row'),
									$elm$html$Html$Attributes$hidden(item.dL !== 'Deducted')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-danger'),
													$elm$html$Html$Attributes$disabled(model.ay),
													$elm$html$Html$Events$onClick(
													A2(
														$author$project$Page$Wips$View$DeleteItem,
														cred,
														$author$project$Items$Slug$idToSlug(item.cY)))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Cancel')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Cancel will +Item from Ingredients back to Stock')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('col-md-6')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-success'),
													$elm$html$Html$Attributes$disabled(model.ay),
													$elm$html$Html$Events$onClick(
													A2(
														$author$project$Page$Wips$View$ClickedProceed,
														cred,
														$author$project$Items$Slug$idToSlug(item.cY)))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Proceed')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Proceed will +Target Item to Stock')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Wips$View$viewTr = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fB))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gd))
					]))
			]));
};
var $author$project$Page$Wips$View$viewMoveDetailTr = function (stockStatus) {
	if (stockStatus.$ === 2) {
		var stocks = stockStatus.a;
		return A2($elm$core$List$map, $author$project$Page$Wips$View$viewTr, stocks);
	} else {
		return _List_fromArray(
			[
				A2($elm$html$Html$tr, _List_Nil, _List_Nil)
			]);
	}
};
var $author$project$Page$Wips$View$viewPickListTr = F2(
	function (details, stock) {
		var imageUrl = function () {
			var _v0 = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (i) {
						return _Utils_eq(i.fC, stock.fC);
					},
					details));
			if (!_v0.$) {
				var item = _v0.a;
				return A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.gd))
						]))
				]));
	});
var $author$project$Page$Wips$View$viewPickList = F2(
	function (templateStatus, stockStatus) {
		var _v0 = _Utils_Tuple2(stockStatus, templateStatus);
		if ((_v0.a.$ === 2) && (_v0.b.$ === 2)) {
			var stocks = _v0.a.a;
			var templates = _v0.b.a;
			return A2(
				$elm$core$List$map,
				$author$project$Page$Wips$View$viewPickListTr(templates),
				stocks);
		} else {
			return _List_fromArray(
				[
					A2($elm$html$Html$tr, _List_Nil, _List_Nil)
				]);
		}
	});
var $author$project$Page$Wips$View$viewItem = F3(
	function (item, cred, model) {
		var bigImage = A2($elm$core$String$contains, 'http', item.gO) ? item.gO : _Utils_ap($author$project$Image$baseUrl, item.gO);
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'WIP_ID: ' + $elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A3($author$project$Page$Wips$View$viewMetaDetailTable, model, cred, item),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src(bigImage),
											$elm$html$Html$Attributes$width(500),
											$elm$html$Html$Attributes$height(500)
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-4')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Summary Ingredients')
										])),
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item ID')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Image')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item Name')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Quantity')
																]))
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											$author$project$Page$Wips$View$viewMoveDetailTr(model.br))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h4,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Pick List')
										])),
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('LD ID')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Image')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item Name')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Location Name')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Quantity')
																]))
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											A2($author$project$Page$Wips$View$viewPickList, model.br, model.aV))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Wips$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Wips$View$viewItem, item, cred, model);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + $elm$core$String$fromInt(item.cY)
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Page$Withdraws$Borrow$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Withdraws$Borrow$DeleteItem = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$Page$Withdraws$Borrow$viewItem = F3(
	function (cred, timeZone, item) {
		var imageUrl = A2($elm$core$String$contains, 'http', item.fD) ? item.fD : _Utils_ap($author$project$Image$baseUrl, item.fD);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(imageUrl),
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'width', '100px')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.gd * (-1)))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-info btn-sm'),
									$elm$html$Html$Events$onClick(
									A3($author$project$Page$Withdraws$Borrow$DeleteItem, cred, item.dx, item.fG))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-arrow-return-left')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Withdraws$Borrow$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Withdraw ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Image')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('LD_ID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Quantity')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('ที่เก็บคืน')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('คืน')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Withdraws$Borrow$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$Withdraws$Borrow$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container-fluid')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Borrows'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$WithdrawNew)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									A3(
									$author$project$Page$Withdraws$Borrow$viewItems,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Withdraws$Borrow$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Withdraws$Form$EnteredSearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Withdraws$Form$SubmittedFormItem = {$: 3};
var $author$project$Page$Withdraws$Form$SelectItem = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Withdraws$Form$viewItem = F2(
	function (carts, stock) {
		var filteredCart = A2(
			$elm$core$List$filter,
			function (cart) {
				return _Utils_eq(cart.fG, stock.fG) && _Utils_eq(cart.P, stock.P);
			},
			carts);
		var checked = $elm$core$List$length(filteredCart) > 0;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fC)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.fG))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(stock.fF)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(stock.dx))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('btn btn-sm', true),
											_Utils_Tuple2('btn-primary', checked),
											_Utils_Tuple2('btn-light', !checked)
										])),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Withdraws$Form$SelectItem(stock))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-plus')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Withdraws$Form$viewItemSearchResult = function (model) {
	var _v0 = model.aR;
	if (!_v0.$) {
		var items = _v0.a;
		return A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$author$project$Page$Withdraws$Form$viewItem(model.o),
				$author$project$PaginatedList$values(items)));
	} else {
		return A2($elm$html$Html$tbody, _List_Nil, _List_Nil);
	}
};
var $author$project$Page$Withdraws$Form$viewItemPicker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Events$onSubmit($author$project$Page$Withdraws$Form$SubmittedFormItem)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$label,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Search Item')
									])),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-control'),
										$elm$html$Html$Attributes$placeholder('Search Item'),
										$elm$html$Html$Events$onInput($author$project$Page$Withdraws$Form$EnteredSearchItem)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Name')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('LD_ID')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Location')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Remain')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('#')
													]))
											]))
									])),
								$author$project$Page$Withdraws$Form$viewItemSearchResult(model)
							]))
					]))
			]));
};
var $author$project$Page$Withdraws$Form$EnteredComment = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Withdraws$Form$SelectWithdrawType = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Withdraws$Form$SubmittedForm = {$: 7};
var $author$project$Page$Withdraws$Form$EnteredQuantity = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Withdraws$Form$inputOptions = function (stock) {
	var defaultOptions = $abadi199$elm_input_extra$Input$Number$defaultOptions(
		$author$project$Page$Withdraws$Form$EnteredQuantity(stock));
	return _Utils_update(
		defaultOptions,
		{
			fL: $elm$core$Maybe$Nothing,
			fM: $elm$core$Maybe$Just(stock.dx),
			fP: $elm$core$Maybe$Just(0)
		});
};
var $author$project$Page$Withdraws$Form$viewCart = function (stock) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fF)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$abadi199$elm_input_extra$Input$Number$input,
						$author$project$Page$Withdraws$Form$inputOptions(stock),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control')
							]),
						$elm$core$Maybe$Just(stock.gd))
					]))
			]));
};
var $author$project$Page$Withdraws$Form$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Withdraws$Form$viewWithdrawTypeOption = F2(
	function (ind, typeName) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$elm$core$String$fromInt(ind))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(typeName)
				]));
	});
var $author$project$Page$Withdraws$Form$viewQuantityComment = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-md-6')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('error-messages')
					]),
				A2($elm$core$List$map, $author$project$Page$Withdraws$Form$viewProblem, model.ax)),
				A2(
				$elm$html$Html$table,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table table-bordered table-sm table-striped')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('LotID')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('FromLocation')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Quantity')
											]))
									]))
							])),
						A2(
						$elm$html$Html$tbody,
						_List_Nil,
						A2($elm$core$List$map, $author$project$Page$Withdraws$Form$viewCart, model.o))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Withdraw Type')
							])),
						A2(
						$elm$html$Html$select,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Events$onInput($author$project$Page$Withdraws$Form$SelectWithdrawType)
							]),
						A2($elm$core$List$indexedMap, $author$project$Page$Withdraws$Form$viewWithdrawTypeOption, model.bN))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Comment')
							])),
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$placeholder('Comment'),
								$elm$html$Html$Events$onInput($author$project$Page$Withdraws$Form$EnteredComment)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-primary btn-block'),
						$elm$html$Html$Events$onClick($author$project$Page$Withdraws$Form$SubmittedForm)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Save')
					]))
			]));
};
var $author$project$Page$Withdraws$Form$view = function (model) {
	return {
		eO: function () {
			var _v0 = $author$project$Session$cred(model.aA);
			if (!_v0.$) {
				var cred = _v0.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-fluid')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Withdraw Page')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('row')
								]),
							_List_fromArray(
								[
									$author$project$Page$Withdraws$Form$viewItemPicker(model),
									$author$project$Page$Withdraws$Form$viewQuantityComment(model)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('home-page')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Sign in Please')
						]));
			}
		}(),
		g5: 'Conduit'
	};
};
var $author$project$Page$Withdraws$List$ClickedFeedPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Withdraws$List$viewItem = F3(
	function (cred, timeZone, item) {
		var withdrawTypeStr = function () {
			var _v0 = A2(
				$elm$core$Array$get,
				item.hf,
				$elm$core$Array$fromList($author$project$MovementWithdraw$withdrawTypes));
			if (!_v0.$) {
				var str = _v0.a;
				return str;
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Timestamp$view, timeZone, item.eV)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(withdrawTypeStr)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eW)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(item.eL)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('actions')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary btn-sm'),
									$author$project$Route$href(
									$author$project$Route$WithdrawView(
										$author$project$Items$Slug$idToSlug(item.cY)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('ion-eye')
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$Withdraws$List$viewItems = F3(
	function (cred, timeZone, items) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Created At')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('WithdrawID')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Type')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Creator')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Comments')
										])),
									A2(
									$elm$html$Html$th,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Actions')
										]))
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Withdraws$List$viewItem, cred, timeZone),
						items))
				]));
	});
var $author$project$Page$Withdraws$List$view = function (model) {
	var _v0 = model.aR;
	switch (_v0.$) {
		case 2:
			var items = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Withdraws'),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$author$project$Route$href($author$project$Route$WithdrawNew)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('+')
												]))
										])),
									A3(
									$author$project$Page$Withdraws$List$viewItems,
									cred,
									model.ck,
									$author$project$PaginatedList$values(items)),
									A3(
									$author$project$PaginatedList$viewPagination,
									$author$project$Page$Withdraws$List$ClickedFeedPage,
									model.aP,
									$author$project$PaginatedList$total(items))
								]));
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Items'
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Items - Loding'
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items - Error'
			};
	}
};
var $author$project$Page$Withdraws$View$viewMetaDetailTable = F2(
	function (model, item) {
		var withdrawTypeStr = function () {
			var _v0 = A2(
				$elm$core$Array$get,
				item.hf,
				$elm$core$Array$fromList($author$project$MovementWithdraw$withdrawTypes));
			if (!_v0.$) {
				var str = _v0.a;
				return str;
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-md-4')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table-responsive')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('ID')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$author$project$Items$Slug$toString(model.bG))
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Type')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(withdrawTypeStr)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Comments')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eL)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Creator')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(item.eW)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Created At')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															A2($author$project$Timestamp$view, model.ck, item.eV)
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Withdraws$View$viewTr = function (stock) {
	var imageUrl = A2($elm$core$String$contains, 'http', stock.fD) ? stock.fD : _Utils_ap($author$project$Image$baseUrl, stock.fD);
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fF)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.fG))
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(imageUrl),
								A2($elm$html$Html$Attributes$style, 'margin', '0')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(stock.fC)
					])),
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(stock.gd))
					]))
			]));
};
var $author$project$Page$Withdraws$View$viewMoveDetailTr = function (stockStatus) {
	if (stockStatus.$ === 2) {
		var stocks = stockStatus.a;
		return A2($elm$core$List$map, $author$project$Page$Withdraws$View$viewTr, stocks);
	} else {
		return _List_fromArray(
			[
				A2($elm$html$Html$tr, _List_Nil, _List_Nil)
			]);
	}
};
var $author$project$Page$Withdraws$View$viewItem = F3(
	function (item, cred, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							'Withdraw: ' + $elm$core$String$fromInt(item.cY))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('row')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Withdraws$View$viewMetaDetailTable, model, item),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('col-md-8')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$table,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('table table-bordered table-condensed table-striped')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Location')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('LD_ID')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Image')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Item Name')
																])),
															A2(
															$elm$html$Html$th,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Quantity')
																]))
														]))
												])),
											A2(
											$elm$html$Html$tbody,
											_List_Nil,
											$author$project$Page$Withdraws$View$viewMoveDetailTr(model.br))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Withdraws$View$view = function (model) {
	var _v0 = model.aO;
	switch (_v0.$) {
		case 2:
			var item = _v0.a;
			return {
				eO: function () {
					var _v1 = $author$project$Session$cred(model.aA);
					if (!_v1.$) {
						var cred = _v1.a;
						return A3($author$project$Page$Withdraws$View$viewItem, item, cred, model);
					} else {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('home-page')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sign in Please')
								]));
					}
				}(),
				g5: 'Item: ' + $elm$core$String$fromInt(item.cY)
			};
		case 0:
			return {
				eO: $elm$html$Html$text(''),
				g5: 'Item ' + $author$project$Items$Slug$toString(model.bG)
			};
		case 1:
			return {eO: $author$project$Loading$icon, g5: 'Items'};
		default:
			return {
				eO: $author$project$Loading$error('item'),
				g5: 'Items'
			};
	}
};
var $author$project$Main$view = function (model) {
	var viewer = $author$project$Session$viewer(
		$author$project$Main$toSession(model));
	var viewPage = F3(
		function (page, toMsg, config) {
			var _v10 = A3($author$project$Page$view, viewer, page, config);
			var title = _v10.g5;
			var body = _v10.a7;
			return {
				a7: A2(
					$elm$core$List$map,
					$elm$html$Html$map(toMsg),
					body),
				g5: title
			};
		});
	switch (model.$) {
		case 2:
			var home = model.a;
			return A3(
				viewPage,
				0,
				$author$project$Main$GotHomeMsg,
				$author$project$Page$Home$view(home));
		case 3:
			var login = model.a;
			return A3(
				viewPage,
				1,
				$author$project$Main$GotLoginMsg,
				$author$project$Page$Login$view(login));
		case 4:
			var about = model.a;
			return A3(
				viewPage,
				2,
				$author$project$Main$GotAboutMsg,
				$author$project$Page$About$view(about));
		case 5:
			if (model.a.$ === 1) {
				var _v1 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					5,
					$author$project$Main$GotItemFormMsg,
					$author$project$Page$Items$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotItemFormMsg,
					$author$project$Page$Items$Form$view(item));
			}
		case 6:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotItemMsg,
				$author$project$Page$Items$View$view(item));
		case 7:
			var item = model.a;
			return A3(
				viewPage,
				6,
				$author$project$Main$GotItemListMsg,
				$author$project$Page$Items$List$view(item));
		case 46:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotBorrowListMsg,
				$author$project$Page$Withdraws$Borrow$view(item));
		case 8:
			if (model.a.$ === 1) {
				var _v2 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					5,
					$author$project$Main$GotImageFormMsg,
					$author$project$Page$Images$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotImageFormMsg,
					$author$project$Page$Images$Form$view(item));
			}
		case 9:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotImageViewMsg,
				$author$project$Page$Images$View$view(item));
		case 10:
			var item = model.a;
			return A3(
				viewPage,
				6,
				$author$project$Main$GotImageListMsg,
				$author$project$Page$Images$List$view(item));
		case 11:
			if (model.a.$ === 1) {
				var _v3 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotPoFormMsg,
					$author$project$Page$PurchaseOrders$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotPoFormMsg,
					$author$project$Page$PurchaseOrders$Form$view(item));
			}
		case 12:
			if (model.a.$ === 1) {
				var _v4 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotPoKnownPriceFormMsg,
					$author$project$Page$PurchaseOrders$KnownPriceForm$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotPoKnownPriceFormMsg,
					$author$project$Page$PurchaseOrders$KnownPriceForm$view(item));
			}
		case 13:
			var item = model.b;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPoSupplierUpdateMsg,
				$author$project$Page$PurchaseOrders$Supplier$view(item));
		case 14:
			var item = model.b;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPoConfirmAndChatMsg,
				$author$project$Page$PurchaseOrders$Confirm$view(item));
		case 15:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPoViewMsg,
				$author$project$Page$PurchaseOrders$View$view(item));
		case 16:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPoReceiveMsg,
				$author$project$Page$PurchaseOrders$Receive$view(item));
		case 17:
			var item = model.a;
			return A3(
				viewPage,
				6,
				$author$project$Main$GotPoListMsg,
				$author$project$Page$PurchaseOrders$List$view(item));
		case 19:
			if (model.a.$ === 1) {
				var _v5 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotLocationFormMsg,
					$author$project$Page$Locations$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotLocationFormMsg,
					$author$project$Page$Locations$Form$view(item));
			}
		case 20:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotLocationMsg,
				$author$project$Page$Locations$View$view(item));
		case 21:
			var item = model.a;
			return A3(
				viewPage,
				7,
				$author$project$Main$GotLocationListMsg,
				$author$project$Page$Locations$List$view(item));
		case 25:
			if (model.a.$ === 1) {
				var _v6 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotSupplierFormMsg,
					$author$project$Page$Suppliers$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotSupplierFormMsg,
					$author$project$Page$Suppliers$Form$view(item));
			}
		case 26:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotSupplierMsg,
				$author$project$Page$Suppliers$View$view(item));
		case 27:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotSupplierListMsg,
				$author$project$Page$Suppliers$List$view(item));
		case 22:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPaymentFormMsg,
				$author$project$Page$Payments$Form$view(item));
		case 23:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPaymentMsg,
				$author$project$Page$Payments$View$view(item));
		case 24:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPaymentListMsg,
				$author$project$Page$Payments$List$view(item));
		case 28:
			if (model.a.$ === 1) {
				var _v7 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotPackingFormMsg,
					$author$project$Page$Packings$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotPackingFormMsg,
					$author$project$Page$Packings$Form$view(item));
			}
		case 29:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPackingViewMsg,
				$author$project$Page$Packings$View$view(item));
		case 30:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotPackingListMsg,
				$author$project$Page$Packings$List$view(item));
		case 31:
			if (model.a.$ === 1) {
				var _v8 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotCategoryFormMsg,
					$author$project$Page$Categories$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotCategoryFormMsg,
					$author$project$Page$Categories$Form$view(item));
			}
		case 32:
			var item = model.a;
			return A3(
				viewPage,
				8,
				$author$project$Main$GotCategoryListMsg,
				$author$project$Page$Categories$List$view(item));
		case 33:
			var item = model.a;
			return A3(
				viewPage,
				9,
				$author$project$Main$GotImportNewMsg,
				$author$project$Page$Imports$Form$view(item));
		case 34:
			var item = model.a;
			return A3(
				viewPage,
				9,
				$author$project$Main$GotImportPoMsg,
				$author$project$Page$Imports$Po$view(item));
		case 35:
			var item = model.a;
			return A3(
				viewPage,
				10,
				$author$project$Main$GotImportListMsg,
				$author$project$Page$Imports$List$view(item));
		case 38:
			var item = model.a;
			return A3(
				viewPage,
				9,
				$author$project$Main$GotMoveNewMsg,
				$author$project$Page$Moves$Form$view(item));
		case 39:
			var item = model.a;
			return A3(
				viewPage,
				9,
				$author$project$Main$GotMoveToOriginalMsg,
				$author$project$Page$Moves$Original$view(item));
		case 40:
			var item = model.a;
			return A3(
				viewPage,
				9,
				$author$project$Main$GotWithdrawNewMsg,
				$author$project$Page$Withdraws$Form$view(item));
		case 44:
			if (model.a.$ === 1) {
				var _v9 = model.a;
				var item = model.b;
				return A3(
					viewPage,
					5,
					$author$project$Main$GotTemplateFormMsg,
					$author$project$Page$Templates$Form$view(item));
			} else {
				var item = model.b;
				return A3(
					viewPage,
					3,
					$author$project$Main$GotTemplateFormMsg,
					$author$project$Page$Templates$Form$view(item));
			}
		case 48:
			var item = model.a;
			return A3(
				viewPage,
				14,
				$author$project$Main$GotWipNewMsg,
				$author$project$Page$Wips$Form$view(item));
		case 36:
			var item = model.a;
			return A3(
				viewPage,
				10,
				$author$project$Main$GotMoveListMsg,
				$author$project$Page$Moves$List$view(item));
		case 41:
			var item = model.a;
			return A3(
				viewPage,
				11,
				$author$project$Main$GotMoveViewMsg,
				$author$project$Page$Moves$View$view(item));
		case 45:
			var item = model.a;
			return A3(
				viewPage,
				13,
				$author$project$Main$GotTemplateViewMsg,
				$author$project$Page$Templates$View$view(item));
		case 42:
			var item = model.a;
			return A3(
				viewPage,
				12,
				$author$project$Main$GotWithdrawViewMsg,
				$author$project$Page$Withdraws$View$view(item));
		case 49:
			var item = model.a;
			return A3(
				viewPage,
				14,
				$author$project$Main$GotWipViewMsg,
				$author$project$Page$Wips$View$view(item));
		case 37:
			var item = model.a;
			return A3(
				viewPage,
				10,
				$author$project$Main$GotWithdrawListMsg,
				$author$project$Page$Withdraws$List$view(item));
		case 43:
			var item = model.a;
			return A3(
				viewPage,
				10,
				$author$project$Main$GotTemplateListMsg,
				$author$project$Page$Templates$List$view(item));
		case 47:
			var item = model.a;
			return A3(
				viewPage,
				14,
				$author$project$Main$GotWipListMsg,
				$author$project$Page$Wips$List$view(item));
		case 50:
			var item = model.a;
			return A3(
				viewPage,
				13,
				$author$project$Main$GotTemplateCalMsg,
				$author$project$Page$Templates$Cal$view(item));
		case 18:
			var item = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotImageCropMsg,
				$author$project$Page$Images$Crop$view(item));
		case 0:
			return A3($author$project$Page$view, viewer, 3, $author$project$Page$Blank$view);
		default:
			return A3($author$project$Page$view, viewer, 3, $author$project$Page$NotFound$view);
	}
};
var $author$project$Main$main = A2(
	$author$project$Api$application,
	$author$project$Viewer$decoder,
	{cZ: $author$project$Main$init, dh: $author$project$Main$ChangedUrl, di: $author$project$Main$ClickedLink, dO: $author$project$Main$subscriptions, dX: $author$project$Main$update, d_: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));